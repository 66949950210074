import {Block, BlockSettings} from './block';
import {BlockType} from './block-type';

export class TableCell extends Block {
  settings: TableCellSettings;

  constructor(v: Partial<TableCell> = {}) {
    super(v);
    this.type = v.type ?? BlockType.TableCell;
    this.settings = new TableCellSettings(v.settings ?? {});
  }
}

export class TableCellSettings extends BlockSettings {
  backgroundColor: string;

  constructor(v: Partial<TableCellSettings> = {}) {
    super(v);

    this.backgroundColor = v?.backgroundColor;
  }
}
