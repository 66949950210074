import {ApplicationPolicy} from './application_policy';
import {OrganizationMembershipRole, ProcessTrigger, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class ProcessTriggerPolicy extends ApplicationPolicy {
  record: ProcessTrigger;

  index(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.organization_membership.at_least(OrganizationMembershipRole.admin);
  }

  show(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.organization_membership.at_least(OrganizationMembershipRole.admin);
  }

  create(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.organization_membership.at_least(OrganizationMembershipRole.admin);
  }

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.organization_membership.at_least(OrganizationMembershipRole.admin);
  }

  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.organization_membership.at_least(OrganizationMembershipRole.admin);
  }
}
