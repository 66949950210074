export class EditableSettings {
  enabled: boolean;
  description: string;
  preview: boolean;

  constructor(v: Partial<EditableSettings> = {}) {
    this.enabled = v.enabled ?? false;
    this.description = v.description ?? '';
    this.preview = v.preview ?? true;
  }
}
