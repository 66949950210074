import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {OrganizationMembership, User, WorkspaceMembership} from '@paperlessio/sdk/api/models';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {BaseServiceV1} from './base.service.v1';

@Injectable()
export class UserService extends BaseServiceV1<User> {
  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'users';
    this.serializer = new Serializer<User>(User);
  }

  // handle potentially included wsm and osm
  update(id: number, data: Partial<User> | User): Observable<User> {
    return super.update(id, data).pipe(tap(user => {
      if (user.workspace_memberships?.length) {
        const serializer = new Serializer<WorkspaceMembership>(WorkspaceMembership, 'workspace_membership');
        user.workspace_memberships = user.workspace_memberships.map(wsm => serializer.fromJson(wsm));
      }

      if (user.organization_memberships?.length) {
        const serializer = new Serializer<OrganizationMembership>(OrganizationMembership, 'organization_membership');
        user.organization_memberships = user.organization_memberships.map(osm => serializer.fromJson(osm));
      }
    }));
  }
}
