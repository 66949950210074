import {ContentLocale, RenderingLocale} from '@paperlessio/sdk/api/util';
import {ContentLocaleSetting} from './content-locale-setting';
import {Designs} from './designs';
import {ParticipationFlow} from './participation-flow';

export type LocalizedBucketAttributes = {
  name: string
}

export class Bucket {
  id: number;
  name: string;
  description: string;
  settings: any;
  original_content_locale: ContentLocale;
  rendering_locale: RenderingLocale;
  content_locale_settings: ContentLocaleSetting[];
  localized_attributes: Record<ContentLocale, LocalizedBucketAttributes>;
  source_template?: any;
  designs?: Designs;
  state?: string;
  workspace_id?: number;
  participation_flow: ParticipationFlow;
  participation_flow_id: number;

  get configuredContentLocales(): string[] {
    return this.content_locale_settings.map(locale_setting => locale_setting.content_locale as unknown as string);
  }

  get localized(): boolean {
    return this.content_locale_settings.length > 1;
  }

  localizedName(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedAttribute('name', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedName(locale: ContentLocale, bucketOriginalLocale: ContentLocale, name: string) {
    this.writeLocalizedAttribute('name', locale, bucketOriginalLocale, name);
  }

  localizedDescription(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedAttribute('description', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedDescription(locale: ContentLocale, bucketOriginalLocale: ContentLocale, name: string) {
    this.writeLocalizedAttribute('description', locale, bucketOriginalLocale, name);
  }

  protected readLocalizedAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    fallback: boolean): any {
    if (locale === bucketOriginalLocale) {
      return this[attribute];
    } else {
      return this.localized_attributes[locale]?.[attribute] || (fallback ? this[attribute] : null);
    }
  }

  protected writeLocalizedAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    value: any) {
    if (locale === bucketOriginalLocale) {
      this[attribute] = value;
    } else {
      this.ensureLocalizedPresetForLocale(locale);
      this.localized_attributes[locale][attribute] = value;
    }
  }

  protected ensureLocalizedPresetForLocale(locale: ContentLocale) {
    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as LocalizedBucketAttributes;
    }
    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as any;
    }
  }
}

export enum BucketType {
  Document = 'Document',
  Submittable = 'Submittable',
  Template = 'Template',
  Partial = 'Partial',
  FormVersion = 'FormVersion'
}
