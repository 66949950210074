import {excluded} from './util/excluded';
import {Submittable} from './submittable';
import {SubmitEventValue} from './submit-event-value';
import {CalculatedBlockAttribute} from './calculated-block-attribute';
import {CalculatedTokenValue} from './calculated-token-value';
import {BucketType} from './bucket';
import {FormVersion} from './form-version';
import {Blob} from './blob';
import {Document} from './document';

// TODO: this can be merged with Submission. no need for special class
export class ParticipationSubmission {
  id: number;
  pdf: Blob;
  submittable: Submittable;
  aggregated_submit_event_values: SubmitEventValue[];
  aggregated_calculated_block_attributes: CalculatedBlockAttribute[];
  aggregated_calculated_token_values: CalculatedTokenValue[];

  created_at: Date;
  updated_at: Date;
  dispatched_at: Date;
  expires_at: Date;
  completed_at: Date;

  @excluded values: {[slug: string]: SubmitEventValue} = {};
  @excluded block_owner_mapping: {[id: number]: number[]}

  constructor(v: Partial<ParticipationSubmission>) {
    this.id = v.id;
    this.pdf = new Blob(v.pdf);
    if (v.submittable) {
      this.submittable = v.submittable.type === BucketType.FormVersion ? new FormVersion(v.submittable as FormVersion) : new Document(v.submittable);
    }

    this.aggregated_submit_event_values = v.aggregated_submit_event_values ? v.aggregated_submit_event_values : [];

    this.aggregated_submit_event_values = this.aggregated_submit_event_values.map((el: SubmitEventValue) => {
      el = new SubmitEventValue(el);
      this.values[el.slug] = el;
      return el;
    });

    this.aggregated_calculated_block_attributes = v.aggregated_calculated_block_attributes ? v.aggregated_calculated_block_attributes : [];
    this.aggregated_calculated_block_attributes.map((el: CalculatedBlockAttribute) => new CalculatedBlockAttribute(el));
    this.aggregated_calculated_token_values = v.aggregated_calculated_token_values ? v.aggregated_calculated_token_values : [];
    this.aggregated_calculated_token_values.map((el: CalculatedTokenValue) => new CalculatedTokenValue(el));

    this.block_owner_mapping = v.block_owner_mapping;

    this.created_at = v.created_at ? new Date(v.created_at) : null;
    this.updated_at = v.updated_at ? new Date(v.updated_at) : null;
    this.dispatched_at = v.dispatched_at ? new Date(v.dispatched_at) : null;
    this.expires_at = v.expires_at ? new Date(v.expires_at) : null;
    this.completed_at = v.completed_at ? new Date(v.completed_at) : null;
  }

  public submitEventValueForSlug(slug: string): SubmitEventValue {
    return this.values?.[slug];
  }
}
