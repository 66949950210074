import {CssStyle} from './css-style';

export class SubmissionButtonStyles {
  static light_default = {background_color: '#F1F1F3', color: '#252729'};
  static primary_default = {background_color: '#0083F6', color: '#ffffff'};
  static success_default = {background_color: '#0083F6', color: '#ffffff'};
  static pdf_download_default = SubmissionButtonStyles.primary_default;

  light: CssStyle;
  primary: CssStyle;
  success: CssStyle;
  pdf_download: CssStyle;

  constructor(v: Partial<SubmissionButtonStyles> = {}) {
    this.light = new CssStyle(v.light ?? SubmissionButtonStyles.light_default);
    this.primary = new CssStyle(v.primary ?? SubmissionButtonStyles.primary_default);
    this.success = new CssStyle(v.success ?? SubmissionButtonStyles.success_default);
    this.pdf_download = new CssStyle(v.pdf_download ?? SubmissionButtonStyles.pdf_download_default);
  }
}
