import {DesignType} from '../design-type';
import {BrandDesign} from '../brand-design';
import {SubmissionDesign} from '../submission-design';
import {EmailDesign} from '../email-design';
import {ExportDesign} from '../export-design';
import {Design} from '../design';

export function parseDesign(json: any): Design {
  switch (json?.type) {
    case DesignType.BrandDesign:
      return new BrandDesign(json);
    case DesignType.SubmissionDesign:
      return new SubmissionDesign(json);
    case DesignType.EmailDesign:
      return new EmailDesign(json);
    case DesignType.ExportDesign:
      return new ExportDesign(json);
    default:
      return new Design(json);
  }
}
