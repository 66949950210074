import {Block, BlockSettings} from './block';
import {Blob} from '../blob';

export class Page extends Block {
  settings: PageSettings;
  file: Blob; // stores PDF
  name: string;

  constructor(v: Partial<Page> = {}) {
    super(v);
    this.settings = new PageSettings(v.settings ?? {});
    this.file = new Blob(v.file);
  }

  get overlayMode(): boolean {
    return !!this.file.url;
  }
}

export class PageSettings extends BlockSettings {
  showProgress: boolean;
  showNavigation: boolean;

  constructor(v: Partial<PageSettings> = {}) {
    super(v);
    this.showProgress = v.showProgress ?? true;
    this.showNavigation = v.showNavigation ?? true;
  }
}
