import {Input, InputSettings, InputStyles} from './input';

export class DateInput extends Input {
  default_value: Date;
  settings: DateInputSettings;
  styles: DateInputStyles;

  constructor(v: Partial<DateInput> = {}) {
    super(v);
    this.default_value = v.default_value ? new Date(v.default_value) : undefined;
    this.settings = new DateInputSettings(v.settings ?? {});
    this.styles = new DateInputStyles(v.styles ?? {});
  }
}

export class DateInputSettings extends InputSettings {
  range: boolean;
  today: boolean;
  fontSize: number;

  constructor(v: Partial<DateInputSettings> = {}) {
    super(v);
    this.range = v.range ?? false;
    this.today = v.today ?? true;
    this.resizeAxis = 'x';
    this.fontSize = v.fontSize ?? 16;
  }

  get paddingX(): number {
    return this.fontSize * 0.75;
  }

  get paddingY(): number {
    return this.fontSize * 0.5;
  }
}

export class DateInputStyles extends InputStyles {
  constructor(v: Partial<DateInputStyles> = {}) {
    super(v);
  }
}
