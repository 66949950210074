/**
 * Generator to define expandable attributes of a model and generate the expand-params for it.
 */
export class Expandable<T, U extends string & keyof T | string> {
  getParams(...attributes: ExpandableAttributes<T, U>[]): {} {
    return { 'expand[]': attributes };
  }

  getExpandableAttributes(...attributes: ExpandableAttributes<T, U>[]) {
    return attributes;
  }
}

/**
 * This supports the following expandable attributes:
 * * string attributes of T (must exist)
 * * arbitrary nested child attributes of T's attributes (not checked by TypeScript)
 */
type ExpandableAttributes<T extends Record<string, any>, U extends string & keyof T | string> = keyof Pick<T, U>;
