import {excluded} from './util/excluded';
import {BucketType} from './bucket';
import {RexCalculationType} from './calculation-type';

export class Token {
  static ELIGIBLE_REGEX = /^([A-Za-z0-9_\-.])*$/;

  id: number;
  bucket_type: BucketType;
  bucket_id: number;
  name: string;
  value: any;
  calculation_type: RexCalculationType;
  javascript_definition: string;
  json_logic_definition: object;
  settings: any = {};
  @excluded occurrences: number;

  constructor(v: Partial<Token> = {}) {
    this.id = v.id;
    this.bucket_type = v.bucket_type;
    this.bucket_id = v.bucket_id;
    this.name = v.name;
    this.value = v.value ?? '';
    this.calculation_type = v.calculation_type ?? RexCalculationType.CONSTANT;
    this.javascript_definition = v.javascript_definition;
    this.json_logic_definition = v.json_logic_definition;
    this.settings = v.settings ?? {};
    this.occurrences = 0; // initialize with 0, as only real instances increase the count. undefined would stay forever
  }

  get validForDispatch(): boolean {
    return this.valuePresent || this.occurrences === 0 || this.calculation_type !== RexCalculationType.CONSTANT;
  }

  get calculated(): boolean {
    return this.calculation_type !== RexCalculationType.CONSTANT;
  }

  get valuePresent(): boolean {
    // supports all js data types as value is any typed
    // boolean values and 0 are considered present
    // empty strings, null and undefined are considered not present
    return this.value !== '' && this.value !== null && this.value !== undefined;
  }
}

export interface TokenInstance {
  id: string;
  blockId: number;
  topologicalBlockPosition: number;
  offset: number;
}

