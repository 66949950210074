import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, first, map, switchMap, tap} from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import {Workspace, WorkspaceMembership} from '@paperlessio/sdk/api/models';
import {ToastService} from '@paperlessio/sdk/api/util';
import {AuthenticationStore} from './authentication.store';
import {WorkspaceMembershipService} from '@paperlessio/sdk/api/services';

/**
 * CurrentWorkspaceStore
 * Provides the current workspace (set via workspace resolver)
 * Holds all Workspace-Specific settings for a user (List Columns, Order etc)
 */
@Injectable({providedIn: 'root'})
export class CurrentWorkspaceStore {
  /*
   Warning: might return undefined, when user navigates away from a workspace (e.g. to Organization settings)
  */
  membership: BehaviorSubject<WorkspaceMembership> = new BehaviorSubject<WorkspaceMembership>(null);

  constructor(private authStore: AuthenticationStore,
              private membershipService: WorkspaceMembershipService,
              private toast: ToastService) {
    this.membership
      .pipe(filter(v => !!v))
      .subscribe(membership => {
        Sentry.configureScope(scope => {
          if (this.membership) {
            scope.setTag('membership.id', membership.id.toString());
            scope.setTag('membership.workspace_id', membership.workspace_id.toString());
            scope.setTag('membership.role', membership.role.toString());
          }
        });
      });
  }

  initFromWorkspace(workspace_id: number): Observable<Workspace> {
    if (this.membership?.value?.workspace_id === workspace_id) {
      return this.membership.pipe(first(), map(m => m.workspace));
    }

    return this.authStore.currentWorkspaceMemberships.pipe(
      filter(v => !!v),
      first(),
      map((workspaceMemberships: WorkspaceMembership[]) => {
        const workspaceMembership = workspaceMemberships.find(a => a.workspace_id === workspace_id);
        this.membership.next(workspaceMembership);
        return workspaceMembership?.workspace;
      })
    );
  }

  update(data: Partial<WorkspaceMembership>): Observable<WorkspaceMembership> {
    // Optimistic update:
    this.membership.next(({
      ...this.membership.value,
      ...data
    } as WorkspaceMembership));

    return this.membership
      .pipe(
        first(),
        switchMap((current: WorkspaceMembership) => this.membershipService.update(current.id, data)),
        catchError(error => {
          this.handleError(error);
          return throwError(error);
        }),
        // In-case the server modified the data, it's written into the subject again.
        tap(w => this.membership.next(w))
      );
  }

  delete(id: number): Observable<WorkspaceMembership> {
    return this.membershipService.delete(id)
      .pipe(
        catchError(error => {
          this.handleError(error);
          return throwError(error);
        })
      );
  }

  /**
   * Used to handle errors (e.g. show toasts)
   * @param error - previously returned error object
   */
  protected handleError(error: any): void {
    console.error(error);
    this.toast.error(`workspaces.error`);
    Sentry.captureException(error);
  }
}
