import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionStore} from './session.store';

// This interceptor is used to add the participant token to every request
// to the backend. It is used to identify the participant on the backend.
// This allows users work on multiple submissions at the same time in
// multiple browser tabs.
@Injectable()
export class ParticipantTokenInterceptor implements HttpInterceptor {
  private sessionStore = inject(SessionStore);

  // The token is set by the guard, but the locale files are loaded even earlier, so we'll just do nothing for the requests that don't include a participant_token
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.sessionStore.participant_token) {
      const cloned = req.clone({
        headers: req.headers
          .set('X-ParticipantToken', this.sessionStore.participant_token)
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
