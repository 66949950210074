import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {catchError, concatMap, filter, first, map, switchMap, tap} from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import {TranslocoService} from '@ngneat/transloco';
import {Router} from '@angular/router';
import localforage from 'localforage';
import {Location} from '@angular/common';
import {
  Organization,
  OrganizationMembership,
  Registration,
  Strategy,
  User,
  Workspace,
  WorkspaceMembership
} from '@paperlessio/sdk/api/models';
import {normalizeLocale, ToastService} from '@paperlessio/sdk/api/util';
import {InteropService} from '@paperlessio/sdk/interop';
import {AuthenticationService, IntercomService, UserService} from '@paperlessio/sdk/api/services';

@Injectable()
export class AuthenticationStore implements OnDestroy {
  static RETURN_PATH_KEY = 'returnPath';
  currentUserFetched = false;
  currentUser: Subject<User> = new ReplaySubject<User>(1);

  /**
   * This will NOT have a value (e.g. false) unless explicitly set. The best way to obtain the value is to call
   * reloadCurrentUser(). Caution: use only when really necessary. Most of the time, one of the two existing guards
   * (authenticatedGuard or notAuthenticatedGuard) should be used instead.
   */
  authenticated: Subject<boolean> = new ReplaySubject<boolean>(1);

  private subs = new Subscription();

  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private translate: TranslocoService,
              private router: Router,
              private location2: Location,
              private toast: ToastService,
              private intercomService: IntercomService,
              private interopService: InteropService) {
    this.subs.add(interopService
      .selectCommand('[AuthenticationStore]logout')
      .pipe(switchMap(_ => this.logout()))
      .subscribe(_ => {
        this.router.navigate(['/login']).then(() => location.reload());
      }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public strategies(email: string): Observable<Strategy[]> {
    return this.authService.strategies({email});
  }

  public login(credentials: {email: string, password: string}): Observable<User> {
    return this.authService.login(credentials).pipe(
      switchMap(() => this.reloadCurrentUser())
    );
  }

  public register(registration: Registration): Observable<User> {
    return this.authService.register(registration).pipe(
      switchMap(() => this.reloadCurrentUser())
    );
  }

  public logout(): Observable<null> {
    this.currentUserFetched = false;
    this.authenticated.next(false);
    this.intercomService.shutdown();
    return this.authService.logout();
  }

  public setCurrentUser(user: User): void {
    this.currentUserFetched = true;
    this.currentUser.next(user);
    this.authenticated.next(true);
    this.translate.setActiveLang(normalizeLocale(user.locale));
    this.intercomService.setIntercomSettings(user);
    this.intercomService.update();
    this.interopService.currentUser.next(user);

    Sentry.configureScope(scope =>
      scope.setUser({id: user.id.toString(), email: user.email, username: user.name})
    );
  }

  public reloadCurrentUser(redirectOnError = true): Observable<User> {
    return this.authService.fetchCurrentUser().pipe(
      catchError((e: {status: number, error: {type: string, credential_type?: string}}) => {
        // 401s are expected => not logged-in
        this.authenticated.next(false);

        switch (e.status) {
          case 401: // Unauthorized
            break;
          case 403:
            switch (e.error?.type) {
              case 'Errors.Forbidden.TwoFactorAuthentication.SetupMandatoryError':
                this.router.navigate(['/', '2fa-setup']);
                return of(null);
              case 'Errors.Forbidden.ReauthenticationRequiredError':
                this.router.navigate(['/', 'reauthenticate', e.error.credential_type]);
                return of(null);
              default:
                break;
            }
            break;
          case 504: // Gateway Timeout
            this.toast.error('base.error_504');
            break;
        }

        if (redirectOnError) {
          localforage.setItem(AuthenticationStore.RETURN_PATH_KEY, this.location2.path());
          this.router.navigate(['/login']);
        }

        return of(null);
      }),
      filter(user => !!user),
      tap(user => this.setCurrentUser(user))
    );
  }

  public updateCurrentUser(data: Partial<User>): Observable<User> {
    return this.currentUser.pipe(
      first(),
      switchMap(current => this.userService
        .update(current.id, data)
        .pipe(
          switchMap(() => this.reloadCurrentUser())
        )
      ));
  }

  public deleteCurrentUser(): Observable<User> {
    return this.currentUser.pipe(
      first(),
      map(current => current.id),
      concatMap(id => this.userService.delete(id)));
  }

  public redirectAfterSuccessfulAuthentication() {
    localforage.getItem<string>(AuthenticationStore.RETURN_PATH_KEY).then(async returnPath => {
      if (returnPath && returnPath !== '/') {
        await this.router.navigateByUrl(returnPath).catch(() => {
          this.router.navigate(['/'], {onSameUrlNavigation: 'reload'});
        }).finally(() => {
          localforage.removeItem(AuthenticationStore.RETURN_PATH_KEY);
        });
      } else {
        await this.router.navigate(['/'], {onSameUrlNavigation: 'reload'});
      }
    });
  }

  get currentWorkspaces(): Observable<Workspace[]> {
    return this.currentUser.pipe(map(user => user.workspace_memberships.map(wsm => wsm.workspace)));
  }

  get currentWorkspaceMemberships(): Observable<WorkspaceMembership[]> {
    return this.currentUser.pipe(map(user => user.workspace_memberships));
  }

  get currentOrganizations(): Observable<Organization[]> {
    return this.currentUser.pipe(map(user => user.organization_memberships.map(osm => osm.organization)));
  }

  get currentOrganizationMemberships(): Observable<OrganizationMembership[]> {
    return this.currentUser.pipe(map(user => user.organization_memberships));
  }
}
