import {HttpBackend, HttpClient} from '@angular/common/http';
import {provideTransloco, Translation, TranslocoLoader, TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {APP_INITIALIZER, Injectable, ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {environment} from '@environment';
import {LOCALE} from '@paperlessio/sdk/api/util';
import cacheBusting from '../../../i18n-cache-busting.json';
import {provideTranslocoLocale, TranslocoLocaleModule} from '@ngneat/transloco-locale';
import {setActiveLanguage} from '@shared/transloco-root/set-active-language';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json?v=${cacheBusting.management[lang]}`);
  }
}

@NgModule({
  exports: [
    TranslocoModule
  ],
  imports: [
    TranslocoLocaleModule
  ]
})
export class TranslocoRootModule {
  static forRoot(config?: TranslocoRootModuleConfig): ModuleWithProviders<TranslocoRootModule> {
    const providers: Provider[] = [
      provideTransloco({
        config: {
          availableLangs: Object.values(LOCALE),
          defaultLang: LOCALE.en_US,
          fallbackLang: LOCALE.en_US,
          reRenderOnLangChange: true,
          missingHandler: {
            useFallbackTranslation: config?.useFallbackTranslation ?? false,
            allowEmpty: config?.allowEmpty ?? false,
            logMissingKey: config?.logMissingKey ?? true
          },
          prodMode: environment.production,
        },
        loader: TranslocoHttpLoader
      }),
      provideTranslocoLocale({
        localeConfig: {
          localeBased: {
            'de-DE': {
              date: {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              }
            }
          }
        }
      })
    ];

    if (config.setActiveLanguage) {
      providers.push({
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: setActiveLanguage,
        deps: [TranslocoService]
      });
    }

    return {
      ngModule: TranslocoRootModule,
      providers
    };
  }
}

export interface TranslocoRootModuleConfig {
  setActiveLanguage?: boolean;
  useFallbackTranslation?: boolean;
  allowEmpty?: boolean;
  logMissingKey?: boolean;
}
