import {BaseSerializer} from '@paperlessio/sdk/api/util';
import {SubmitEventValue} from '@paperlessio/sdk/api/models';

export class SubmitEventValueSerializer implements BaseSerializer<SubmitEventValue> {
  fromJson(json: any): SubmitEventValue {
    return new SubmitEventValue(json);
  }

  toJson(obj: Partial<SubmitEventValue> | SubmitEventValue): object {
    return {};
  }

  serialize(obj: Partial<SubmitEventValue> | SubmitEventValue): SubmitEventValue {
    const clone: SubmitEventValue = JSON.parse(JSON.stringify(obj));
    return clone as SubmitEventValue;
  }
}
