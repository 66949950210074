import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';

/**
 * Collect the Ids of changed blocks either set from the client through setChangedBlockIds or
 * from other users through listening to the incoming block mutations
 */
@Injectable({
  providedIn: 'root'
})
export class BlockChangeDetectionService implements OnDestroy {
  private changedBlockIdsSubject: Subject<number> = new Subject<number>();
  public changedBlockIds$: Observable<number> = this.changedBlockIdsSubject.asObservable();
  private subs: Subscription = new Subscription();

  setChangedBlockIds = (blockIds: number[]) =>
    // de-duplicate and compact blockIds array
    [...new Set(blockIds)].filter(id => id).forEach(blockId => this.changedBlockIdsSubject.next(blockId));

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
