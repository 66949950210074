import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotfoundComponent} from './notfound/notfound.component';
import {SessionWrapperComponent} from './session-wrapper/session-wrapper.component';
import {sessionGuard} from './session/session.guard';
import {SessionSuccessComponent} from './session/session/session-success/session-success.component';
import {ModalLayoutComponent} from '@paperlessio/sdk/modal-layout';

const routes: Routes = [
  {
    path: 's/:token/declined',
    loadComponent: () => import('./session/session/session-declined/session-declined.component')
  },
  {
    path: 's/:token/completed',
    component: SessionWrapperComponent,
    canActivate: [sessionGuard],
    data: {hideNavigation: true},
    children: [
      {
        path: '',
        component: SessionSuccessComponent
      }
    ]
  },
  {
    path: 's/:token/pdf_viewer',
    canActivate: [sessionGuard], // sets SessionStore#participant_token, required for setting the x-participant-token header correctly
    loadComponent: () => import('@paperlessio/sdk/components/pdf-viewer/pdf-viewer.component')
  },
  {
    path: 's/:token',
    component: SessionWrapperComponent,
    canActivate: [sessionGuard],
    loadChildren: () => import('./session/session/session-blocks/session-blocks.module').then(m => m.SessionBlocksModule)
  },
  {
    path: 'm/:token',
    canActivate: [sessionGuard],
    outlet: 'modal',
    component: ModalLayoutComponent,
    loadChildren: () => import('./session/session/session-modals/session-modals.module').then(m => m.SessionModalsModule)
  },
  {
    path: 'f/:token',
    loadChildren: () => import('./forms/forms.module').then(m => m.FormsModule)
  },
  {
    path: 'fm/:token',
    outlet: 'modal',
    component: ModalLayoutComponent,
    loadChildren: () => import('./forms/form-modals/form-modals.module').then(m => m.FormModalsModule)
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
