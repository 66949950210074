import {Injectable} from '@angular/core';
import {Block, BucketType, Partial} from '@paperlessio/sdk/api/models';
import {PartialService} from './partial.service';
import {map, tap} from 'rxjs/operators';
import {BlockService} from '@blocks/block/block.service';
import {ToastService} from '@paperlessio/sdk/api/util';
import {CurrentWorkspaceStore, WorkspaceBaseStore} from '@paperlessio/sdk/api/stores';

@Injectable({providedIn: 'root'})
export class PartialStore extends WorkspaceBaseStore<Partial, PartialService> {
  constructor(
    protected service: PartialService,
    protected toast: ToastService,
    protected userMembershipStore: CurrentWorkspaceStore,
    private blockService: BlockService
  ) {
    super(service, toast, userMembershipStore);
  }

  getRootBlock(id: number) {
    return this.blockService
      .allFromBucket(BucketType.Partial, id)
      .pipe(
        map(blocks => blocks.find((block: Block) => !block.parent_id))
      );
  }

  delete(id: number) {
    return this.service.delete(id).pipe(
      tap({
        next: () => {
          const idx = this.all.value.data.findIndex(x => x.id === id);
          this.all.value.data.splice(idx, 1);
          this.all.next(this.all.value);
        },
        error: err => {
          this.handleDeleteError(err);
        }
      })
    );
  }
}
