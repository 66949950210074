import {Block, BlockSettings} from './block';

export class Spacer extends Block {
  settings: SpacerSettings;

  constructor(v: Partial<Spacer> = {}) {
    super(v);
    this.settings = new SpacerSettings(v.settings ?? {});
  }
}

export class SpacerSettings extends BlockSettings {
  size: number;
  customSize: boolean;

  constructor(v: Partial<SpacerSettings> = {}) {
    super(v);
    this.size = v.size ?? 10;
    this.customSize = v.customSize ?? false;
  }
}
