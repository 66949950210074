/* eslint-disable @angular-eslint/no-output-native */
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {SessionControllerService} from '../../session-controller.service';
import {GizmoState, GizmoStateType, NavigationState} from '@shared/submission-gizmo/navigation.service';
import {asyncScheduler, fromEvent, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';
import {BrandDesign, SubmissionDesign, SubmissionNavbarBrandMode} from '@paperlessio/sdk/api/models';
import {AdditionalSubmissionHeaderButtonConfig} from '@paperlessio/sdk/api/util';

/*
* Navigation bar for session
*/
@Component({
  selector: 'app-session-header',
  templateUrl: './session-header.component.html',
  styleUrls: ['./session-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionHeaderComponent implements AfterViewChecked, OnInit, OnDestroy {
  @Input() submissionDesign: SubmissionDesign;
  @Input() brandDesign: BrandDesign;
  @Input() showNavigation: boolean;
  @Input() showFormsNavigation: boolean;
  @Input() showPrev: boolean;
  @Input() showNext: boolean;
  @Input() showReadOnly: boolean;
  @Input() showApprove: boolean;
  @Input() showComplete: boolean;
  @Input() showCompleteArrow: boolean;
  @Input() showSave: boolean;
  @Input() currentParticipantCompleted: boolean;
  @Input() submissionCompleted: boolean;
  @Input() downloadPdfUrl: string;
  @Input() mustAcceptTerms: boolean;
  @Input() termsAccepted: boolean;
  @Input() hasInputs: boolean;
  @Input() documentName: string;
  @Input() authorName: string;
  @Input() authorAvatarUrl: string;
  @Input() additionalInfo: string;
  @Input() additionalButtons?: AdditionalSubmissionHeaderButtonConfig[];
  @Input() navigationState: NavigationState;
  @Input() gizmoState: GizmoState;

  @Output() prev = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() start = new EventEmitter<boolean>();
  @Output() complete = new EventEmitter();
  @Output() save = new EventEmitter();

  @ViewChild('navbar') navbar: ElementRef<HTMLElement>;
  @ViewChild('subheader') subheader: ElementRef<HTMLElement>;

  SubmissionNavbarBrandMode = SubmissionNavbarBrandMode;
  GizmoStateType = GizmoStateType;

  interactive = true;

  public sessionController = inject(SessionControllerService, {optional: true});
  private zone = inject(NgZone);
  private subs = new Subscription();

  get submissionSettings() {
    return this.submissionDesign?.submission_settings;
  }

  get completed(): boolean {
    return this.submissionCompleted || this.currentParticipantCompleted;
  }

  get headerText(): string {
    const path = ['header'];

    if (this.navigationState.pageCount === 1) {
      path.push('single_page');

      if (this.gizmoState.type === GizmoStateType.INITIAL) {
        path.push('not_started');

        if (this.gizmoState.pageHasOnlySignatureInput) {
          path.push('signature_only');
        } else if (this.navigationState.inputCount === 1) {
          path.push('1');
        } else {
          path.push('many');
        }
      } else {
        path.push('started');
        path.push('remaining_fields');

        if (this.navigationState.invalidRequiredInputCount === 0) {
          path.push('0');
          if (this.showApprove) {
            path.push('approve');
          } else {
            path.push('complete');
          }
        } else if (this.navigationState.invalidRequiredInputCount === 1) {
          path.push('1');
        } else {
          path.push('many');
        }
      }
    } else {
      path.push('multi_page');

      if (this.navigationState.currentPage === 0 && this.gizmoState.type === GizmoStateType.INITIAL) {
        path.push('not_started.any');
      } else {
        path.push('started');
        path.push('remaining_fields');

        if (this.navigationState.invalidRequiredInputCount === 0) {
          path.push('0');

          if (this.navigationState.currentPage === this.navigationState.pageCount - 1) {
            path.push('last_page');
            if (this.showApprove) {
              path.push('approve');
            } else {
              path.push('complete');
            }
          } else {
            if (this.navigationState.requiredInputCount === 0) {
              path.push('regular_empty');
            } else {
              path.push('regular');
            }
          }
        } else if (this.navigationState.invalidRequiredInputCount === 1) {
          path.push('1');
        } else {
          path.push('many');
        }
      }
    }

    return path.join('.');
  }

  get buttonText(): string {
    const path = ['header.button'];

    if (this.navigationState.pageCount === 1) {
      if (!this.sessionController?.isSmall // gizmo is not deactivated
        && this.navigationState.invalidRequiredInputCount > 0
        && this.gizmoState.type === GizmoStateType.INITIAL) {
        path.push('start');
      } else {
        if (this.showApprove) {
          path.push('approve');
        } else {
          path.push('complete');
        }
      }
    } else {
      // last page
      if (this.navigationState.currentPage === this.navigationState.pageCount - 1) {
        if (!this.sessionController?.isSmall // gizmo is not deactivated
          && this.navigationState.invalidRequiredInputCount > 0
          && this.gizmoState.type === GizmoStateType.INITIAL) {
          path.push('start');
        } else {
          if (this.showApprove) {
            path.push('approve');
          } else {
            path.push('complete');
          }
        }
      } else { // all other pages
        if (!this.sessionController?.isSmall // gizmo is not deactivated
          && this.navigationState.invalidRequiredInputCount > 0
          && this.gizmoState.type === GizmoStateType.INITIAL) {
          path.push('start');
        } else {
          path.push('next_page');
        }
      }
    }

    return path.join('.');
  }

  ngOnInit() {
    this.subs.add(fromEvent(window, 'resize')
      .pipe(throttleTime(200, asyncScheduler, {leading: true, trailing: true}))
      .subscribe(e => {
        this.setCssVar();
      }));
  }

  ngAfterViewChecked() {
    this.setCssVar();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private setCssVar() {
    // we need zone to circumvent an endless loop, as requestAnimationFrame triggers the next Change Detection Cycle
    this.zone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        document.body.style.setProperty('--submission-subheader-height', `${(this.subheader?.nativeElement?.offsetHeight || 65)}px`);
        document.body.style.setProperty('--submission-header-height', `${(this.navbar?.nativeElement?.offsetHeight || 101)}px`);
      });
    });
  }
}
