import {BaseList, ToastService} from '@paperlessio/sdk/api/util';
import {catchError, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {BaseService, BaseServiceV1} from '@paperlessio/sdk/api/services';
import {BaseStore} from './base.store';
import {CurrentWorkspaceStore} from './current-workspace.store';

export class WorkspaceBaseStore<T extends { id: number }, S extends BaseService<T> | BaseServiceV1<T>> extends BaseStore<T, S> {
  protected workspace_id: number;

  constructor(service: S, toast: ToastService, protected currentWorkspaceStore: CurrentWorkspaceStore) {
    super(service, toast);
    this.currentWorkspaceStore.membership.pipe(
      filter(membership => !!membership?.workspace_id),
      map(membership => +membership?.workspace_id),
      distinctUntilChanged()
    ).subscribe(workspace_id => {
      this.workspace_id = workspace_id;
      this.all.next(undefined);
      this.current.next(undefined);
    });
  }

  fetch(params: object = {}): Observable<BaseList<T>> {
    this.lastFetchParams = params;
    return this.service.fetchByWorkspace(this.workspace_id, params).pipe(
      tap((all: BaseList<T>) => this.all.next(all)),
      catchError(err => {
        this.handleError(err);
        return throwError(err);
      })
    );
  }

  paginate(page: number, params: object = {}): Observable<BaseList<T>> {
    this.lastFetchParams = params;
    const mergedParams = Object.assign({page}, params);
    return this.service.fetchByWorkspace(this.workspace_id, mergedParams).pipe(
      tap((all: BaseList<T>) => {
        this.all.value.data.push(...all.data);
        this.all.next(this.all.value);
      }),
      catchError(err => {
        this.handleError(err);
        return throwError(err);
      })
    );
  }

  create(data: Partial<T>, params: {} = {}, nextCurrent = false, fetchAll = true): Observable<T> {
    // @ts-ignore
    if(!data.workspace_id) {
      // @ts-ignore
      data.workspace_id = this.workspace_id;
    }
    return super.create(data, params, nextCurrent, fetchAll);
  }
}
