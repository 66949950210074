import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ScrollingService} from './scrolling.service';
import {filter} from 'rxjs/operators';
import {totalOffsetTop} from '@paperlessio/sdk/util/helpers';
import {ScrollRequest} from './scroll-request';
import {Subscription} from 'rxjs';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[scrollingContainer]'})
export class ScrollingContainerDirective implements OnInit, OnDestroy {
  constructor(private elementRef: ElementRef<HTMLElement>, private scrollingService: ScrollingService) { }

  /**
   * The name with wich the service calls this container.
   */
  @Input() scrollingContainer: string;

  /**
   * The reserved area in px. Applies to top and bottom.
   * The area itself is between the top and bottom of the visible area of the scroll container + the reservedArea value.
   */
  @Input() reservedArea: number;

  private subs = new Subscription();

  ngOnInit(): void {
    this.subs.add(
      this.scrollingService.scrollRequest
        .pipe(filter(e => (e.containerName === this.scrollingContainer) && (!!e.target || !!e.targetOffset)))
        .subscribe(scrollRequest => {
          switch (scrollRequest.scrollPosition) {
            case 'center': {
              this.scrollCenter(scrollRequest);
              break;
            }
            default:
            case 'nearest': {
              this.scrollNearest(scrollRequest);
            }
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Execute the scrolling operation.
   * @param scrollRequest The informations about the to-be-executed scroll request.
   */
  private scrollNearest(scrollRequest: ScrollRequest) {
    // TODO: Check if target is child of container!!
    const elementOffsetTop = (!!scrollRequest.target ? totalOffsetTop(scrollRequest.target) : scrollRequest.targetOffset);

    // Check if the element lies within reserved area.
    const scrollContainer = this.elementRef.nativeElement;

    // Get app-header-height, to apply to the reserved area
    const appHeaders = document.getElementsByTagName('app-header');
    const appHeaderOffsetHeight = (appHeaders.length >= 1 ? appHeaders[0] as HTMLElement : null)?.offsetHeight ?? 0;

    const upperBoundary = scrollContainer.scrollTop + this.reservedArea + appHeaderOffsetHeight;
    const lowerBoundary = scrollContainer.scrollTop + scrollContainer.offsetHeight - this.reservedArea - appHeaderOffsetHeight;

    // Check for the elementOffsetTop
    let doScroll = !(elementOffsetTop > upperBoundary && elementOffsetTop < lowerBoundary);

    // Check if the target (including scrollHeight) is completely inside boundaries
    if (!!scrollRequest.target) {
      const elementLowerBoundary = elementOffsetTop + scrollRequest.target.scrollHeight;
      doScroll = !(elementOffsetTop > upperBoundary && elementOffsetTop < lowerBoundary &&
                   elementLowerBoundary > upperBoundary && elementLowerBoundary < lowerBoundary);
    }

    doScroll = !!scrollRequest.force ? true : doScroll;

    if (!doScroll) {
      this.scrollingService.finish(scrollRequest);
      return;
    }

    const delta = Math.abs(elementOffsetTop - scrollContainer.scrollTop) / 8;
    let scrollTarget = elementOffsetTop - this.reservedArea - appHeaderOffsetHeight;

    // Scroll from bottom => stop at lowest possible position
    if (!!scrollRequest.target && (elementOffsetTop + scrollRequest.target.offsetHeight) > lowerBoundary) {
      scrollTarget = scrollContainer.scrollTop + elementOffsetTop +
                     scrollRequest.target.offsetHeight - lowerBoundary - appHeaderOffsetHeight;
    }

    scrollContainer.scrollTo({top: scrollTarget, behavior: 'smooth'});

    this.checkArrival(scrollRequest);

    scrollContainer.dispatchEvent(new Event('scroll'));
  }

  private scrollCenter(scrollRequest: ScrollRequest) {
    const scrollContainer = this.elementRef.nativeElement;

    const elementOffsetTop = (!!scrollRequest.target ? totalOffsetTop(scrollRequest.target) : scrollRequest.targetOffset);
    const scrollContainerOffsetTop = totalOffsetTop(scrollContainer);

    const scrollContainerRect = scrollContainer.getBoundingClientRect();
    const scrollTargetRect = scrollRequest.target.getBoundingClientRect();

    let targetOffset = elementOffsetTop - scrollContainerOffsetTop;
    targetOffset -= (scrollContainerRect.height / 2) - (scrollTargetRect.height / 2);

    scrollContainer.scrollTo({
      top: targetOffset,
      behavior: 'smooth'
    });

    if (targetOffset <= 0) {
      this.scrollingService.finish(scrollRequest);
    } else {
      this.checkArrival(scrollRequest);
    }

    scrollContainer.dispatchEvent(new Event('scroll'));
  }

  private scrollTimer: number;

  private arrived(cb: () => void) {
    this.elementRef.nativeElement.removeEventListener('scroll', this.scrollListener);
    cb();
  }

  private scrollListener: () => void;

  private checkArrival(scrollRequest: ScrollRequest) {
    this.scrollListener = () => {
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => this.arrived(() => this.scrollingService.finish(scrollRequest)), 100) as any;
    };

    this.elementRef.nativeElement.addEventListener('scroll', this.scrollListener);
  }
}
