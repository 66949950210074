import {SubmissionData} from './submission-data';
import {Addons} from './addons';

export class ListColumn {
  property: string;
  translate = false; // Should the value get translated? The column name is always translated!
  default = false;
  fixed = false;
  align: 'left' | 'center' | 'right' = 'center';
  type = ListColumnType.default;
  size = ListColumnSize.default;
  activated?: SubmissionData[] = []; // Only used on ListColumnType.SubmissionData
  hasInfo = false;
  requiresAddon: Addons | null = null;
  hasTooltip = false;

  constructor(v: Partial<ListColumn> = {}) {
    Object.assign(this, v);
    this.align = v.align ? v.align : 'center';
    this.type = v.type ? v.type : ListColumnType.default;
    this.size = v.size ? v.size : ListColumnSize.default;
  }
}

export enum ListColumnType {
  default,
  badge,
  activity,
  date,
  timeAgo,
  submissionData,
  documentName,
  creator,
  pageProgress,
  infoArray,
  bucketName,
  submittableName,
  booleanIndicator,
  processRunLabels,
  color,
  countdown
}

export enum ListColumnSize {
  default = 'col',
  auto = 'col-auto',
  col01 = 'col-1',
  col02 = 'col-2',
  col03 = 'col-3',
  col04 = 'col-4',
  col05 = 'col-5',
  col06 = 'col-6',
  col07 = 'col-7',
  col08 = 'col-8',
  col09 = 'col-9',
  col10 = 'col-10',
  col11 = 'col-11',
  col12 = 'col-12'
}
