import {Design} from './design';

export class BrandDesign extends Design {
  brand_settings: BrandSettings;
  brand_styles: BrandStyles;

  constructor(v: Partial<BrandDesign> = {}) {
    super(v);
    this.brand_settings = new BrandSettings(v.brand_settings ?? {});
    this.brand_styles = new BrandStyles(v.brand_styles ?? {});
  }
}

export class BrandSettings {
  constructor(v: Partial<BrandSettings> = {}) {

  }
}

export class BrandStyles {
  colors: BrandColor[];

  constructor(v: Partial<BrandStyles> = {}) {
    this.colors = v.colors ? v.colors?.map(c => new BrandColor(c)) : this.defaultColors();
  }

  private defaultColors() {
    return [
      new BrandColor({name: 'primary', value: '#0083F6', type: 'system'}),
      new BrandColor({name: 'secondary', value: '#6c757d', type: 'system'}),
      new BrandColor({name: 'success', value: '#28a745', type: 'system'}),
      new BrandColor({name: 'danger', value: '#dc3545', type: 'system'}),
      new BrandColor({name: 'warning', value: '#ffc107', type: 'system'}),
    ];
  }
}

export class BrandColor {
  name: string;
  value: string;
  type: 'system' | 'custom';

  constructor(v: Partial<BrandColor> = {}) {
    this.name = v.name ?? '';
    this.value = v.value ?? '';
    this.type = v.type ?? 'custom';
  }

}
