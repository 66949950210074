import {Design} from './design';
import {DesignType} from './design-type';
import {BrandDesign} from './brand-design';
import {SubmissionDesign} from './submission-design';
import {EmailDesign} from './email-design';
import {ExportDesign} from './export-design';
import {parseDesign} from './parsers/design.parser';

export class Designs {
  private readonly list: Design[] = [];
  private map: Map<DesignType, Design[]> = new Map<DesignType, Design[]>();

  constructor(list: Design[]) {
    if (Array.isArray(list)) {
      this.list = list?.map(design => {
        design = parseDesign(design);
        this.map.get(design.type) ? this.map.get(design.type).push(design) : this.map.set(design.type, [design]);
        return design;
      });
    }
  }

  get all() {
    return this.list;
  }

  get brand(): BrandDesign {
    return this.lastOfType(DesignType.BrandDesign) as BrandDesign;
  }

  get brandHierarchy(): BrandDesign[] {
    return this.map.get(DesignType.BrandDesign) as BrandDesign[];
  }

  get submission(): SubmissionDesign {
    return this.lastOfType(DesignType.SubmissionDesign) as SubmissionDesign;
  }

  get submissionHierarchy(): SubmissionDesign[] {
    return this.map.get(DesignType.SubmissionDesign) as SubmissionDesign[];
  }

  get email(): EmailDesign {
    return this.lastOfType(DesignType.EmailDesign) as EmailDesign;
  }

  get emailHierarchy(): EmailDesign[] {
    return this.map.get(DesignType.EmailDesign) as EmailDesign[];
  }

  get export(): ExportDesign {
    return this.lastOfType(DesignType.ExportDesign) as ExportDesign;
  }

  get exportHierarchy(): ExportDesign[] {
    return this.map.get(DesignType.ExportDesign) as ExportDesign[];
  }

  private lastOfType(type: DesignType) {
    const res = this.map.get(type);
    if (res?.length) {
      return res[res.length - 1];
    } else {
      return undefined;
    }
  }
}
