import {ApplicationPolicy} from './application_policy';
import {Submission, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models'

export class SubmissionPolicy extends ApplicationPolicy {
  record: Submission;

  index(): boolean {
    return true;
  }

  show(): boolean {
    return true;
  }

  create(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.record.creator_id === this.user.id;
  }
}
