import {ContentLocale, Expandable} from '@paperlessio/sdk/api/util';
import {ListColumn, ListColumnSize, ListColumnType} from './column';
import {User} from './user';
import {FormVersion} from './form-version';
import {ContentLocaleSetting} from './content-locale-setting';
import {excluded} from './util/excluded';

export type LocalizedFormAttributes = {
  name: string,
  description: string,
}

export class Form {
  static expandable = new Expandable<Form, 'creator' | 'published_form_version' | 'published_form_version.participation_flow' | 'published_form_version.participants'>();

  static columns: ListColumn[] = [
    new ListColumn({property: 'name', default: true, fixed: true, align: 'left', size: ListColumnSize.col03, type: ListColumnType.bucketName}),
    new ListColumn({property: 'state', default: true, align: 'left', type: ListColumnType.badge, size: ListColumnSize.col02}),
    new ListColumn({property: 'created_submissions_count', default: false, align: 'left'}),
    new ListColumn({property: 'completed_submissions_count', default: true, align: 'left'}),
    new ListColumn({property: 'deadline_at', default: false, align: 'left', type: ListColumnType.date}),
    new ListColumn({property: 'updated_at', default: true, align: 'left', type: ListColumnType.timeAgo}),
    new ListColumn({property: 'creator', default: true, align: 'left', type: ListColumnType.creator}),
  ];

  static defaultColumns = Form.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  @excluded id: number;
  @excluded creator_id: number;
  workspace_id: number;
  @excluded published: boolean;
  name: string;
  description: string;
  password?: string;
  @excluded created_submissions_count?: number;
  @excluded completed_submissions_count?: number;
  max_completed_submissions_count?: number;
  deadline_at?: Date;
  @excluded updated_at: Date;
  @excluded created_at: Date;
  @excluded creator: User;
  @excluded public_token: string;
  @excluded public_url: string;
  @excluded published_form_version?: FormVersion;

  original_content_locale: ContentLocale;
  content_locale_settings: ContentLocaleSetting[];
  localized_attributes: Record<ContentLocale, LocalizedFormAttributes>;

  constructor(v: Partial<Form> = {}) {
    this.id = v.id;
    this.creator_id = v.creator_id;
    this.workspace_id = v.workspace_id;
    this.published = !!v.published;
    this.name = v.name;
    this.description = v.description;
    this.password = v.password;
    this.created_submissions_count = v.created_submissions_count;
    this.completed_submissions_count = v.completed_submissions_count;
    this.max_completed_submissions_count = v.max_completed_submissions_count;
    this.deadline_at = v.deadline_at != null ? new Date(v.deadline_at) : undefined;
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : new Date();
    this.created_at = v.created_at != null ? new Date(v.created_at) : new Date();
    this.creator = v.creator != null ? new User(v.creator) : undefined;
    this.public_token = v.public_token;
    this.public_url = v.public_url;
    this.published_form_version = v.published_form_version ? new FormVersion(v.published_form_version) : undefined;

    this.original_content_locale = v.original_content_locale;
    this.content_locale_settings = v.content_locale_settings ?? [];
    this.localized_attributes = v.localized_attributes ?? {} as Record<ContentLocale, LocalizedFormAttributes>;
  }

  // Get a fake state to be able to use the existing badge column type and the already supported badge types
  get state(): string {
    if (this.published) {
      return 'published';
    } else if (this.created_submissions_count === 0) {
      return 'draft';
    } else {
      return 'inactive';
    }
  }

  get pastDeadline(): boolean {
    return this.deadline_at && new Date() > this.deadline_at;
  }

  get usedSubmissionsQuota(): number {
    return this.completed_submissions_count / this.max_completed_submissions_count;
  }

  get quotaExhausted(): boolean {
    return this.completed_submissions_count >= this.max_completed_submissions_count;
  }

  get configuredContentLocales(): ContentLocale[] {
    return this.content_locale_settings.map(locale_setting => locale_setting.content_locale);
  }

  get localized(): boolean {
    return this.content_locale_settings.length > 1;
  }

  localizedName(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedAttribute('name', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedName(locale: ContentLocale, bucketOriginalLocale: ContentLocale, name: string) {
    this.writeLocalizedAttribute('name', locale, bucketOriginalLocale, name);
  }

  localizedDescription(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedAttribute('description', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedDescription(locale: ContentLocale, bucketOriginalLocale: ContentLocale, description: string) {
    this.writeLocalizedAttribute('description', locale, bucketOriginalLocale, description);
  }

  protected readLocalizedAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    fallback: boolean): any {
    if (locale === bucketOriginalLocale) {
      return this[attribute];
    } else {
      return this.localized_attributes[locale]?.[attribute] || (fallback ? this[attribute] : null);
    }
  }

  protected writeLocalizedAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    value: any) {
    if (locale === bucketOriginalLocale) {
      this[attribute] = value;
    } else {
      this.ensureLocalizedPresetForLocale(locale);
      this.localized_attributes[locale][attribute] = value;
    }
  }

  protected ensureLocalizedPresetForLocale(locale: ContentLocale) {
    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as LocalizedFormAttributes;
    }

    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as any;
    }
  }
}

