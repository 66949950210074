import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SubmissionConfig} from '@paperlessio/sdk/api/util';

@Injectable()
export class SubmissionConfigService {
  submissionConfig = new BehaviorSubject<SubmissionConfig>(new SubmissionConfig());

  private readonly SubmissionConfigServiceLocalStorageKey = 'SubmissionConfig';

  loadConfigForToken(token: string) {
    if (!token) {
      this.submissionConfig.next(null);
      return;
    }

    const savedSubmissionConfigString = localStorage.getItem(this.localStorageKeyForToken(token));
    if (savedSubmissionConfigString) {
      const savedSubmissionConfig = SubmissionConfig.decode(savedSubmissionConfigString);
      this.submissionConfig.next(savedSubmissionConfig);
    } else {
      this.submissionConfig.next(null);
    }
  }

  setConfigForToken(token: string, configString: string) {
    if (!token || !configString) {
      return;
    }

    const decodedConfig = SubmissionConfig.decode(configString);
    localStorage.setItem(this.localStorageKeyForToken(token), decodedConfig.encode());
    this.submissionConfig.next(decodedConfig);
  }

  clearForToken(token: string) {
    localStorage.removeItem(this.localStorageKeyForToken(token));
    this.submissionConfig.next(null);
  }

  private localStorageKeyForToken(token: string) {
    return this.SubmissionConfigServiceLocalStorageKey + token;
  }
}
