import {ReminderSettingsScheduleEntry} from './reminder-settings-schedule-entry';
import {DecodeReminderSettingsReturnType} from './decode-reminder-settings-return-type';

export class ReminderSettings {
  reminders_activated: boolean;
  schedule: ReminderSettingsScheduleEntry[];

  constructor(v: Partial<ReminderSettings> = {}) {
    this.reminders_activated = v.reminders_activated ?? false;
    this.schedule = v.schedule?.map(el => new ReminderSettingsScheduleEntry(el)) ?? [];
  }

  decode(): DecodeReminderSettingsReturnType {
    const result: DecodeReminderSettingsReturnType = {
      first_in: 7,
      repeat_every: 7,
      for_times: 5
    };

    const length = this.schedule.length;
    if (length === 1) {
      const days = this.schedule[0].number_of_days_since_dispatch;
      result.first_in = days;
      result.repeat_every = days;
      result.for_times = 1;
    } else if (length > 1) {
      result.first_in = this.schedule[0].number_of_days_since_dispatch;
      result.repeat_every = this.schedule[1].number_of_days_since_dispatch
                            - this.schedule[0].number_of_days_since_dispatch;
      result.for_times = length - 1;
    }

    return result;
  }

  // Important: You must provide numbers, as the API will not understand strings
  encode(first_in: number, for_times: number, repeat_every: number) {
    const schedule = [];
    schedule.push(new ReminderSettingsScheduleEntry({number_of_days_since_dispatch: first_in}));

    // watch out: values coming from select inputs are strings, not numbers
    for (let i = 1; i <= for_times; i++) {
      const days = first_in + (i * repeat_every);
      schedule.push(new ReminderSettingsScheduleEntry({number_of_days_since_dispatch: days}));
    }

    this.schedule = schedule;
  }
}
