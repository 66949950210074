/**
 * We could never ever have a height/width value of 0, so we'll use it to designate 'auto' to the elements, so we can still
 * calculate with the values without hastle.
 */
export class Size {
  width: number;
  height: number;

  constructor(v: Partial<Size> = {}) {
    this.width = v?.width ?? 0;
    this.height = v?.height ?? 0;
  }
}
