import {ChangeDetectorRef, Component, HostBinding, inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet, UrlSegment} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import {Subscription} from 'rxjs';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    NgClass,
    NgIf
  ],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({transform: 'translateY(-200px)', opacity: 0}),
        animate('.1s .1s ease-out', style({transform: 'translateY(0px)', opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translateY(0px)', opacity: 1}),
        animate('.1s .1s ease-out', style({transform: 'translateY(-200px)', opacity: 0}))
      ])
    ]),
    trigger('backdropFade', [
      transition(':enter', [
        style({opacity: 0}),
        animate('.1s ease-out', style({opacity: .5}))
      ]),
      transition(':leave', [
        style({opacity: .5}),
        animate('.1s ease-out', style({opacity: 0}))
      ])
    ]),
    trigger('hostDelay', [
      transition(':leave', [
        animate('.1s .1s ease-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class ModalLayoutComponent implements OnInit, OnDestroy {
  @HostBinding('@hostDelay') public visible = false;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  public cdr = inject(ChangeDetectorRef);
  private injector = inject(Injector);

  public size = 'md';
  public classes = '';
  public centerVertical = false;

  private subs = new Subscription();
  animationsDisabled = false;

  ngOnInit() {
    this.animationsDisabled = this.route.snapshot.children?.[0]?.children?.[0]?.data?.animationsDisabled ?? false;
    this.subs.add(
      this.route.url.subscribe((url: UrlSegment[]) => {
        this.visible = url.length > 0;
      })
    );
  }

  hide() {
    this.router.navigate(['', {outlets: {modal: null}}]);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
