import {Block, BlockStyles} from './block';

export class Divider extends Block {
  styles: DividerStyles = new DividerStyles({});

  constructor(v: Partial<Divider> = {}) {
    super(v);
    this.styles = new DividerStyles(v.styles ?? {});
  }
}

export class DividerStyles extends BlockStyles {
  height: number;
  color: string;
  paddingTop: number;
  paddingBottom: number;

  constructor(v: Partial<DividerStyles> = {}) {
    super(v);
    this.height = v.height ?? 5;
    this.color = v.color ?? '#000000';
    this.paddingTop = v.paddingTop ?? 15;
    this.paddingBottom = v.paddingBottom ?? 15;
  }
}
