import {SubmitEventValue} from '@paperlessio/sdk/api/models'
import {SubmitEventValueSerializer} from '@paperlessio/sdk/api/serializers'

export class ParticipationSubmitEvent {
  created_at: Date;
  submit_event_values: SubmitEventValue[];

  constructor(v: Partial<ParticipationSubmitEvent>) {
    this.created_at = v.created_at ? new Date(v.created_at) : null;

    const serializer = new SubmitEventValueSerializer();
    this.submit_event_values = v.submit_event_values ? v.submit_event_values.map(el => serializer.fromJson(el)) : [];
  }
}
