<ng-container *ngIf="configuredContentLocaleSettings?.length > 1">
  <app-dropdown *transloco="let t; read: 'localization'"
                [renderPanelInBody]="true"
                elevation="3"
                zIndex="1100" >
    <div *dropdownTrigger
         class="d-flex">
      <button type="button"
              class="btn d-none d-sm-block small"
              role="button">
        <fa-icon [icon]="['far', 'globe']"></fa-icon>
        {{t('locales.' + contentLocale)}}
        <fa-icon [icon]="['far', 'chevron-down']" size="sm" class="opacity-50"></fa-icon>
      </button>
      <button type="button" class="btn d-block d-sm-none small" role="button">
        <fa-icon [icon]="['far', 'globe']" size="lg"></fa-icon>
      </button>
    </div>
    <div class="dropdown-menu position-relative d-inline-block border-0 m-0" *dropdownPanel>
      <h6 class="dropdown-header">{{t('switch_locale')}}</h6>
      <button *ngFor="let locale_setting of configuredContentLocaleSettings"
              [class.active]="locale_setting.content_locale === contentLocale"
              (click)="changeLocale(locale_setting.content_locale)"
              class="dropdown-item"
              type="button">
        {{t('locales.' + locale_setting.content_locale)}}
      </button>
    </div>
  </app-dropdown>
</ng-container>
