import {FormParticipant} from './form-participant';
import {Block, BlockType, ContentLocaleSetting, Designs, LocalizedBucketAttributes, Token} from '@paperlessio/sdk/api/models';
import {BlockSerializer, Serializer} from '@paperlessio/sdk/api/serializers';
import {ContentLocale} from '@paperlessio/sdk/api/util';

export class FormMetaInformation {
  organization_name: string;
  workspace_name: string;
  name: string;
  description: string;
  requestable: boolean;
  participants_dispatch_strategy: 'email' | 'api_response_initial_participants';
  password_protected: boolean;
  remaining_completed_submissions_count: number;
  required_participants: FormParticipant[];

  original_content_locale: ContentLocale;
  content_locale_settings: ContentLocaleSetting[];
  localized_attributes: Record<ContentLocale, LocalizedBucketAttributes>;

  blocks: Block[];
  tokens: Token[];
  designs: Designs;
  deadline_at: Date;

  constructor(v?: Partial<FormMetaInformation>) {
    this.organization_name = v?.organization_name ?? '';
    this.workspace_name = v?.workspace_name ?? '';
    this.name = v?.name ?? '';
    this.description = v?.description ?? '';
    this.requestable = !!v?.requestable;
    this.participants_dispatch_strategy = v?.participants_dispatch_strategy ?? 'email';
    this.password_protected = v?.password_protected ?? false;
    this.remaining_completed_submissions_count = v?.remaining_completed_submissions_count ?? 0;
    this.required_participants = v?.required_participants ?? [];
    this.blocks = v?.blocks != null ? v.blocks.map(b => new BlockSerializer().fromJson(b)) : [];
    this.tokens = v?.tokens != null ? v.tokens.map(t => new Serializer<Token>(Token).fromJson(t)) : [];
    this.designs = new Designs(v.designs as any); // Geklaut aus submittable...
    this.deadline_at = v?.deadline_at != null ? new Date(v.deadline_at) : undefined;

    this.original_content_locale = v?.original_content_locale;
    this.content_locale_settings = v?.content_locale_settings;
    this.localized_attributes = v?.localized_attributes;
  }

  get configuredContentLocales(): string[] {
    return this.content_locale_settings.map(locale_setting => locale_setting.content_locale as unknown as string);
  }

  get localized(): boolean {
    return this.content_locale_settings.length > 1;
  }

  get pageCount(): number {
    return this.blocks.filter(b => b.type === BlockType.Page).length;
  }

  get inputBlockCount(): number {
    return this.blocks.filter(b => b.isInput).length;
  }

  localizedName(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedAttribute('name', locale, bucketOriginalLocale, fallback);
  }

  localizedDescription(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedAttribute('description', locale, bucketOriginalLocale, fallback);
  }

  protected readLocalizedAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    fallback: boolean): any {
    if (locale === bucketOriginalLocale) {
      return this[attribute];
    } else {
      return this.localized_attributes[locale]?.[attribute] || (fallback ? this[attribute] : null);
    }
  }

  protected writeLocalizedAttribute(
    attribute: string,
    locale: ContentLocale,
    bucketOriginalLocale: ContentLocale,
    value: any) {
    if (locale === bucketOriginalLocale) {
      this[attribute] = value;
    } else {
      this.ensureLocalizedPresetForLocale(locale);
      this.localized_attributes[locale][attribute] = value;
    }
  }

  protected ensureLocalizedPresetForLocale(locale: ContentLocale) {
    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as LocalizedBucketAttributes;
    }
    if (!this.localized_attributes[locale]) {
      this.localized_attributes[locale] = {} as any;
    }
  }
}
