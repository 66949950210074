import {Input, InputSettings, InputStyles} from './input';

export class BankAccountInput extends Input {
  default_value: BankData;
  settings: BankAccountInputSettings;
  styles: BankAccountInputStyles;

  constructor(v: Partial<BankAccountInput> = {}) {
    super(v);
    this.default_value = v.default_value ?? {iban: '', bic: ''};
    this.settings = new BankAccountInputSettings(v.settings ?? {});
    this.styles = new BankAccountInputStyles(v.styles ?? {});
  }
}

export class BankAccountInputSettings extends InputSettings {
  showBIC: boolean;

  constructor(v: Partial<BankAccountInputSettings> = {}) {
    super(v);
    this.showBIC = v.showBIC ?? true;
  }
}

class BankAccountInputStyles extends InputStyles {
  constructor(v: Partial<BankAccountInputStyles> = {}) {
    super(v);
  }
}

export interface BankData {
  iban: string;
  bic: string;
}
