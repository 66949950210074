import {Design} from './design';
import {SubmissionSettings} from './submission-settings';
import {SubmissionStyles} from './submission-styles';

export class SubmissionDesign extends Design {
  submission_settings: SubmissionSettings;
  submission_styles: SubmissionStyles;

  constructor(v: Partial<SubmissionDesign> = {}) {
    super(v);
    this.submission_settings = new SubmissionSettings(v.submission_settings ?? {});
    this.submission_styles = new SubmissionStyles(v.submission_styles ?? {});
  }
}
