import {excluded} from './util/excluded';
import {Bucket, BucketType} from './bucket';
import {SubmittableSettings} from './submittable-settings';
import {ReminderSettings} from './reminder-settings';
import {SubmittableStyles} from './submittable-styles';
import {Block} from './blocks/block';
import {Token} from './token';
import {User} from './user';
import {Template} from './template';
import {DocumentState} from './document-state';
import {Participant} from './participant';
import {Designs} from './designs';
import {BlockType} from './blocks/block-type';
import {ParticipationFlow} from './participation-flow';
import {parseBlock} from './parsers/block.parser';

export abstract class Submittable extends Bucket {
  id: number;
  workspace_id: number;
  submission_id: number;
  type: BucketType.Document | BucketType.FormVersion | BucketType.Submittable;
  template_id: number;
  // success_template_id: number;
  // success_template: Template;
  name: string;
  description: string;
  settings: SubmittableSettings;
  reminder_settings: ReminderSettings;
  styles: SubmittableStyles;
  forwarding_allowed: boolean;
  delegation_allowed: boolean;

  @excluded blocks: Block[];
  @excluded tokens: Token[];
  @excluded creator_id: number;
  @excluded creator: User;
  @excluded template: Template;
  @excluded state: DocumentState;
  @excluded page_count: number;
  @excluded has_inputs: boolean;
  @excluded participants: Array<Participant>;
  @excluded created_at: Date;
  @excluded updated_at: Date;

  /**
   * Expandable
   */
  @excluded designs?: Designs;

  get inputBlockCount(): number {
    return this.blocks.filter(b => b.isInput).length;
  }

  get pageBlockCount(): number {
    return this.blocks.filter(b => b.type === BlockType.Page).length;
  }

  protected constructor(v: Partial<Submittable> = {}) {
    super();
    Object.assign(this, v);

    this.id = v.id ?? null;
    this.workspace_id = v.workspace_id ?? null;
    this.creator_id = v.creator_id ?? null;
    this.submission_id = v.submission_id ?? null;
    this.type = v.type ?? BucketType.Document;
    this.name = v.name ?? '';
    this.description = v.description ?? '';

    this.creator = v.creator ? new User(v.creator) : undefined;
    this.source_template = v.source_template ? new Template(v.source_template) : undefined;
    this.settings = new SubmittableSettings(v.settings ?? {});
    this.reminder_settings = new ReminderSettings(v.reminder_settings ?? {});
    this.styles = new SubmittableStyles(v.styles ?? {});
    this.designs = new Designs(v.designs as any);
    this.page_count = v.page_count;
    this.has_inputs = v.has_inputs;
    this.created_at = new Date(v.created_at);
    this.updated_at = new Date(v.updated_at);
    this.forwarding_allowed = v.forwarding_allowed ?? true;
    this.delegation_allowed = v.delegation_allowed ?? true;

    this.participants = v.participants ? v.participants.map(r => new Participant(r)) : [];
    this.participation_flow = v.participation_flow ? new ParticipationFlow(v.participation_flow) : null;
    this.blocks = v?.blocks ? v.blocks.map(b => parseBlock(b)) : [];
    this.tokens = v?.tokens ? v.tokens.map(b => new Token(b)) : [];
  }

  valid(step: string): boolean {
    switch (step) {
      case 'editor':
        return true;
      case 'editor-token':
        return true;
      case 'theme':
        return true;
      case 'quickedit':
        return true;
      case 'success-page':
        return true;
      case 'mail':
        return this.settings.mail.content.length > 0 &&
          this.settings.mail.subject.length > 0;
    }
  }
}

