<app-design-colors-inserter [design]="brandDesign" [style.background-color]="submissionDesign?.submission_styles?.body.background_color">
  <app-session-header *ngIf="showHeader"
                      [submissionDesign]="submissionDesign"
                      [brandDesign]="brandDesign"
                      [showNavigation]="showNavigation"
                      [showPrev]="showPrev"
                      [showNext]="showNext"
                      [showReadOnly]="showReadOnly"
                      [showApprove]="showApprove"
                      [showComplete]="showComplete"
                      [showCompleteArrow]="showCompleteArrow"
                      [showSave]="showSave"
                      [currentParticipantCompleted]="participantComplete"
                      [submissionCompleted]="submissionCompleted"
                      [mustAcceptTerms]="mustAcceptTerms"
                      [termsAccepted]="termsAccepted"
                      [hasInputs]="hasInputs"
                      [downloadPdfUrl]="downloadPdfUrl"
                      [documentName]="documentName"
                      [authorName]="authorName"
                      [authorAvatarUrl]="authorAvatarUrl"
                      [additionalInfo]="additionalInfo"
                      [additionalButtons]="additionalButtons"
                      [gizmoState]="gizmoState"
                      [navigationState]="navigationState"
                      (prev)="prev.emit()"
                      (next)="next.emit()"
                      (start)="start.emit($event)"
                      (complete)="complete.emit()"
                      (save)="save.emit()">
  </app-session-header>

  <app-session-content #scrollcontainer
                       scrollingContainer="session-content"
                       [mustAcceptTerms]="!(participantComplete || submissionCompleted) && mustAcceptTerms"
                       [termsAccepted]="participantComplete || submissionCompleted || termsAccepted">
    <ng-content></ng-content>
  </app-session-content>
</app-design-colors-inserter>
