import {Component, Input} from '@angular/core';
import {CssStyle, SubmissionDesign} from '@paperlessio/sdk/api/models';

@Component({
  selector: 'app-styleable-button',
  templateUrl: './styleable-button.component.html',
  styleUrls: ['./styleable-button.component.scss']
})
export class StyleableButtonComponent {

  @Input() design: SubmissionDesign;
  @Input() type: string;

  constructor() { }

  get style() {
    return this.design?.submission_styles?.buttons?.[this.type] as CssStyle;
  }
}
