import {ProcessDefinition} from '../process-definition';
import {Organization} from '../organization';
import {Workspace} from '../workspace';
import {Form} from '../form';
import {Template} from '../template';
import {ProcessTriggerType} from './process-trigger-type';

export class ProcessTrigger {
  id: number;
  triggered_process_definition_id: number;
  organization_id: number;
  workspace_id?: number;
  form_id?: number;
  template_id?: number;
  type: ProcessTriggerType;
  enabled: boolean;
  triggered_process_definition: ProcessDefinition;
  organization: Organization;
  workspace?: Workspace;
  form?: Form;
  template?: Template;
  updated_at: Date;
  created_at: Date;

  constructor(v: Partial<ProcessTrigger> = {}) {
    this.id = v?.id;
    this.triggered_process_definition_id = v?.triggered_process_definition_id;
    this.organization_id = v?.organization_id;
    this.workspace_id = v?.workspace_id;
    this.form_id = v?.form_id;
    this.template_id = v?.template_id;
    this.type = v?.type ?? 'ProcessTrigger::SubmissionCompleted';
    this.enabled = v?.enabled;
    this.triggered_process_definition = v?.triggered_process_definition != null ? new ProcessDefinition(v?.triggered_process_definition) : undefined;
    this.organization = v?.organization != null ? new Organization(v?.organization) : undefined;
    this.workspace = v?.workspace != null ? new Workspace(v?.workspace) : undefined;
    this.form = v?.form != null ? new Form(v?.form) : undefined;
    this.template = v?.template != null ? new Template(v?.template) : undefined;
    this.updated_at = v?.updated_at != null ? new Date(v?.updated_at) : undefined;
    this.created_at = v?.created_at != null ? new Date(v?.created_at) : undefined;
  }

  get level(): ProcessTriggerLevel {
    if (!this.form_id && !this.template_id && !this.workspace_id && this.organization_id) {
      return 'organization';
    } else if (!this.form_id && !this.template_id && this.workspace_id && this.organization_id) {
      return 'workspace';
    } else if (!this.form_id && this.template_id) {
      return 'template';
    } else if (this.form_id && !this.template_id) {
      return 'form';
    }
  }

  get levelName(): string {
    switch (this.level) {
      case 'organization': return this.organization?.name;
      case 'workspace': return this.workspace?.name;
      case 'template': return this.template?.name;
      case 'form': return this.form?.name;
      default: return '';
    }
  }

  get levelSortIndex(): number {
    switch (this.level) {
      case 'organization': return 1;
      case 'workspace': return 2;
      case 'template': return 3;
      case 'form': return 4;
      default: return 5;
    }
  }

  static Compare(a: ProcessTrigger, b: ProcessTrigger) {
    if (a.levelSortIndex > b.levelSortIndex) {
      return 1;
    }

    if (a.levelSortIndex < b.levelSortIndex) {
      return -1;
    }

    if (a.id > b.id) {
      return 1;
    }

    if (a.id < b.id) {
      return -1;
    }

    return 0;
  }
}

export type ProcessTriggerLevel = 'organization' | 'workspace' | 'template' | 'form';

export namespace CreateProcessTrigger {
  export type OnOrganizationLevel = Pick<ProcessTrigger, 'type' | 'triggered_process_definition_id' | 'organization_id' | 'enabled'> & {template_id?: never, form_id?: never};
  export type OnWorkspaceLevel = Pick<ProcessTrigger, 'type' | 'triggered_process_definition_id' | 'workspace_id' | 'enabled'> & {template_id?: never, form_id?: never};
  export type OnFormLevel = Pick<ProcessTrigger, 'type' | 'triggered_process_definition_id' | 'form_id' | 'enabled'> & {template_id?: never};
  export type OnTemplateLevel = Pick<ProcessTrigger, 'type' | 'triggered_process_definition_id' | 'template_id' | 'enabled'> & {form_id?: never};

  export type All = OnOrganizationLevel | OnWorkspaceLevel | OnFormLevel | OnTemplateLevel;
}

export type UpdateProcessTrigger = Pick<ProcessTrigger, 'enabled'>;
