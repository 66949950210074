import {InjectionToken} from '@angular/core';

export const API_BASE_PATH = new InjectionToken<string>('API_BASE_PATH');

export function provideApiBasePath(value: string) {
  return {
    provide: API_BASE_PATH,
    useValue: value
  }
}
