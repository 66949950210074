import {ProcessFlowNode, ProcessFlowNodeType} from './nodes/process-flow-node-type';
import {ProcessFlowEdge} from './process-flow-edge';
import {parseProcessFlowNode} from '../parsers/process-flow-node.parser';

export class ProcessFlow {
  id: number;
  process_run_id?: number;
  process_definition_version_id?: number;
  primary: boolean;
  name: string;
  description: string;
  nodes: ProcessFlowNode[];
  edges: ProcessFlowEdge[];
  updated_at: Date;
  created_at: Date;

  constructor(v: Partial<ProcessFlow>) {
    this.id = v.id;
    this.process_run_id = v.process_run_id;
    this.process_definition_version_id = v.process_definition_version_id;
    this.primary = v.primary;
    this.name = v.name;
    this.description = v.description;
    this.nodes = (v.nodes ?? []).map(n => parseProcessFlowNode(n));
    this.edges = (v.edges ?? []).map(e => new ProcessFlowEdge(e));
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
  }

  get startNode(): ProcessFlowNode {
    return this.nodes.filter(node => node.type === ProcessFlowNodeType.StartNode)[0];
  }
}
