import {excluded} from '../util/excluded';

export class ProcessFlowEdge {
  id: number;
  local_uuid: string;
  source_id: number;
  destination_id: number;
  updated_at: Date;
  created_at: Date;

  @excluded points?: any;
  @excluded oldLine?: any;

  // Is being used in the new process viewer
  @excluded line?: string;

  constructor(v: Partial<ProcessFlowEdge>) {
    this.id = v.id;
    this.local_uuid = v.local_uuid;
    this.source_id = v.source_id;
    this.destination_id = v.destination_id;
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;

    this.points = v.points;
    this.oldLine = v.oldLine;
    this.line = v.line;
  }

  get graphId(): string {
    return `edge-${this.id}`;
  }
}
