// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';

export class AppendProcessFlowNode extends ProcessFlowBaseNode {
  constructor(v: Partial<AppendProcessFlowNode>) {
    super(v);
    this.type = 'ProcessFlow::AppendProcessFlowNode';
    this.settings = new AppendProcessFlowNodeSettings(v.settings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new AppendProcessFlowNodeSettings(v.recalculated_settings);
    }
  }

  settings: AppendProcessFlowNodeSettings;
  recalculated_settings: AppendProcessFlowNodeSettings;
}

export class AppendProcessFlowNodeSettings extends ProcessFlowBaseNodeSettings {
  source_process_flow_id: number;
  join_type: AppendProcessFlowNodeJoinType;

  constructor(v: Partial<AppendProcessFlowNodeSettings> = {}) {
    super(v);

    this.source_process_flow_id = v.source_process_flow_id ?? 0;
    this.join_type = v.join_type ?? AppendProcessFlowNodeJoinType.AND;
  }
}

export enum AppendProcessFlowNodeJoinType {
  AND = 'and',
  OR = 'or'
}
