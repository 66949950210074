export class ParticipantContact {
  id: number;
  creator_id: number;
  workspace_id: number;
  name: string;
  email: string;
  updated_at: Date;
  created_at: Date;

  constructor(v?: Partial<ParticipantContact>) {
    this.id = v?.id ?? 0;
    this.creator_id = v?.creator_id ?? 0;
    this.workspace_id = v?.workspace_id ?? 0;
    this.name = v?.name ?? '';
    this.email = v?.email ?? '';
    this.updated_at = new Date(v?.updated_at);
    this.created_at = new Date(v?.created_at);
  }
}
