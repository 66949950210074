import {ApplicationPolicy} from './application_policy';
import {Organization, OrganizationMembershipRole} from '@paperlessio/sdk/api/models';

export class OrganizationPolicy extends ApplicationPolicy {
  record: Organization;

  // Guests can see organization list
  index(): boolean {
    return true;
  }

  // All members can see a single organization
  show(): boolean {
    return this.organization_membership.at_least(OrganizationMembershipRole.user);
  }

  // User must be at least admin to create a new organization
  create(): boolean {
    return this.organization_membership.at_least(OrganizationMembershipRole.admin);
  }

  // Only owners can edit organizations
  update(): boolean {
    return this.organization_membership.at_least(OrganizationMembershipRole.owner);
  }

  // Only the owners can delete organizations
  destroy(): boolean {
    return this.organization_membership.at_least(OrganizationMembershipRole.owner);
  }
}
