import {Injectable} from '@angular/core';
import {BaseList, ToastService} from '@paperlessio/sdk/api/util';
import {FormVersion} from '@paperlessio/sdk/api/models';
import {CurrentWorkspaceStore, WorkspaceBaseStore} from '@paperlessio/sdk/api/stores';
import {FormVersionsService} from '@paperlessio/sdk/api/services';
import {tap} from 'rxjs/operators';
import {FormStore} from '@management/forms/form.store';

@Injectable({providedIn: 'root'})
export class FormVersionStore extends WorkspaceBaseStore<FormVersion, FormVersionsService> {
  constructor(
    protected service: FormVersionsService,
    protected toast: ToastService,
    protected userMembershipStore: CurrentWorkspaceStore,
    private formStore: FormStore,
  ) {
    super(service, toast, userMembershipStore);
  }

  publish(id: number, revision_message: string) {
    return this.service
      .publish(id, revision_message)
      .pipe(
        tap(formVersion => {
          if (this.all.value) {
            const allBut = this.all.value.data.filter(fv => fv.id !== formVersion.id);

            this.all.next(new BaseList<FormVersion>({
              data: [...allBut, formVersion].sort((a,b) => b.id - a.id),
              count: allBut.length + 1
            }));
          }

          this.formStore.reload(formVersion.form_id).subscribe();
        })
      );
  }

  unpublish(id: number) {
    return this.service
      .unpublish(id)
      .pipe(
        tap(formVersion => {
          // When the unpublish method is called from the form list, this store doesn't contain anything. So we can just ignore this
          if (this.all.value) {
            const allBut = this.all.value.data.filter(fv => fv.id !== formVersion.id);

            this.all.next(new BaseList<FormVersion>({
              data: [...allBut, formVersion].sort((a,b) => b.id - a.id),
              count: allBut.length + 1
            }));
          }

          this.formStore.reload(formVersion.form_id).subscribe();
        })
      );
  }
}
