import {ApplicationPolicy} from './application_policy';
import {Integration} from '@management/integrations/integration';
import {WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class IntegrationPolicy extends ApplicationPolicy {
  record: Integration;

  // Guests can see integrations list
  index(): boolean {
    return true;
  }

  // Guests can see a single integration
  show(): boolean {
    return true;
  }

  // User must be at least admin to create a new integration
  create(): boolean {
    return this.organization_membership.at_least(WorkspaceMembershipRole.admin);
  }

  // Only admin and above can edit integrations
  update(): boolean {
    return this.organization_membership.at_least(WorkspaceMembershipRole.admin);
  }

  // Only admin and above can duplicate integrations (create permission)
  duplicate(): boolean {
    return this.organization_membership.at_least(WorkspaceMembershipRole.admin);
  }

  // Only Admin and above can delete integrations
  destroy(): boolean {
    return this.organization_membership.at_least(WorkspaceMembershipRole.admin);
  }
}
