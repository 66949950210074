import {excluded} from './util/excluded';
import {Participant} from './participant';
import {User} from './user';
import {Submittable} from './submittable';
import {SubmitEventValue} from './submit-event-value';
import {CalculatedBlockAttribute} from './calculated-block-attribute';
import {CalculatedTokenValue} from './calculated-token-value';
import {BucketType} from './bucket';
import {FormVersion} from './form-version';
import {Document} from './document';
import {SubmissionState} from './submission';

export class SubmissionMetaInformation {
  submission_id: number;
  submission_state: SubmissionState | null;
  organization_name: string;
  workspace_name: string;
  participant: Participant;
  dispatching_user: User;
  submittable: Submittable;
  aggregated_submit_event_values: SubmitEventValue[];
  aggregated_calculated_block_attributes: CalculatedBlockAttribute[];
  aggregated_calculated_token_values: CalculatedTokenValue[];
  @excluded block_owner_mapping: {[id: number]: number[]};

  constructor(v?: Partial<SubmissionMetaInformation>) {
    this.submission_id = v?.submission_id ?? 0;
    this.submission_state = v?.submission_state ?? null;
    this.organization_name = v?.organization_name ?? '';
    this.workspace_name = v?.workspace_name ?? '';
    this.participant = v.participant ? new Participant(v.participant) : null;
    this.dispatching_user = v.dispatching_user ? new User(v.dispatching_user) : null;

    if (v.submittable?.type === BucketType.Document) {
      this.submittable = v.submittable ? new Document(v.submittable) : null;
    }

    if (v.submittable?.type === BucketType.FormVersion) {
      this.submittable = v.submittable ? new FormVersion(v.submittable as FormVersion) : null;
    }

    this.aggregated_submit_event_values = v.aggregated_submit_event_values?.map(sev => new SubmitEventValue(sev));

    this.aggregated_calculated_block_attributes = v.aggregated_calculated_block_attributes ? v.aggregated_calculated_block_attributes : [];
    this.aggregated_calculated_block_attributes.map((el: CalculatedBlockAttribute) => new CalculatedBlockAttribute(el));
    this.aggregated_calculated_token_values = v.aggregated_calculated_token_values ? v.aggregated_calculated_token_values : [];
    this.aggregated_calculated_token_values.map((el: CalculatedTokenValue) => new CalculatedTokenValue(el));

    this.block_owner_mapping = v.block_owner_mapping;
  }
}
