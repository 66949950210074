<main class="content px-0 px-sm-3" [attr.aria-hidden]="mustAcceptTerms && !termsAccepted">
  <div class="page">
    <ng-content></ng-content>
  </div>

  <div class="note d-none p-4 text-center text-muted small">
    Der Absender nutzt Paperless für die sichere und bequeme digitale Vertragsunterzeichnung.
    <a href="https://paperless.io">
      Mehr erfahren
      <fa-icon [icon]="['fal', 'long-arrow-right']" size="sm"></fa-icon>
    </a>
  </div>
</main>

<div class="terms-overlay" *ngIf="mustAcceptTerms && !termsAccepted">
</div>
