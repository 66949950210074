import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {BrandDesign} from '@paperlessio/sdk/api/models';

@Injectable({
  providedIn: 'root'
})
export class BrandDesignStore {

  design: ReplaySubject<BrandDesign> = new ReplaySubject<BrandDesign>(1);
  editable = false;

  constructor() { }

  init(design: BrandDesign) {
    this.design.next(design);
  }
}
