import {Block, BlockSettings, BlockStyles} from './block';
import {Blob} from '../blob';

export class PdfPart extends Block {
  settings: PdfPartSettings;
  styles: PdfPartStyles;
  file: Blob;

  constructor(v: Partial<PdfPart> = {}) {
    super(v);
    this.settings = new PdfPartSettings(v.settings ?? {});
    this.styles = new PdfPartStyles(v.styles ?? {});
    this.file = new Blob(v.file);
  }

  get width(): number {
    return this.file.metadata.cropbox
      ? +this.file.metadata.cropbox.width
      : +this.file.metadata.mediabox.width;
  }

  get height(): number {
    return this.file.metadata.cropbox
      ? +this.file.metadata.cropbox.height
      : +this.file.metadata.mediabox.height
  }

  get aspectRatio(): number {
    return this.height/this.width;
  }
}

export class PdfPartSettings extends BlockSettings {
  constructor(v: Partial<PdfPartSettings> = {}) {
    super(v);
  }
}

export class PdfPartStyles extends BlockStyles {
  constructor(v: Partial<PdfPartStyles> = {}) {
    super(v);
  }
}
