import {Block, BlockSettings, BlockStyles} from './block';
import {Button} from './button';
import {Blob} from '../blob';

export class Attachment extends Block {
  fileId: string;
  file: Blob;
  settings: AttachmentSettings;
  styles: AttachmentStyles;

  constructor(v: Partial<Attachment> = {}) {
    super(v);
    this.file = new Blob(v.file);
    this.fileId = v.file?.signed_id;
    this.settings = new AttachmentSettings(v.settings ?? {});
    this.styles = new AttachmentStyles(v.styles ?? {});
  }

  reset() {
    this.file = new Blob();
    this.settings.name = null;
    this.settings.size = null;
    this.settings.type = null;
    this.settings.label = null;
  }
}

export class AttachmentSettings extends BlockSettings {
  name: string;
  label: string;
  size: number;
  type: string;
  view_enabled: boolean;
  fakeButton: Button;

  constructor(v: Partial<AttachmentSettings> = {}) {
    super(v);
    this.name = v.name;
    this.label = v.label;
    this.size = v.size;
    this.type = v.type;
    this.view_enabled = v.view_enabled ?? false;
  }
}

export class AttachmentStyles extends BlockStyles {
  button_background: string;
  button_color: string;
  format: 'default' | 'button';

  constructor(v: Partial<AttachmentStyles> = {}) {
    super(v);
    this.button_background = v.button_background ?? '#F1F1F3';
    this.button_color = v.button_color ?? '#252729';
    this.format = v.format ?? 'default';
  }
}
