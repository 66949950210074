import {Design} from './design';
import {ExportSettings} from './export-settings';
import {ExportStyles} from './export-styles';

export class ExportDesign extends Design {
  export_settings: ExportSettings;
  export_styles: ExportStyles;

  constructor(v: Partial<ExportDesign> = {}) {
    super(v);
    this.export_settings = new ExportSettings(v.export_settings ?? {});
    this.export_styles = new ExportStyles(v.export_styles ?? {});
  }
}
