import {BlockSerializer, Serializer, SubmitEventValueSerializer} from '@paperlessio/sdk/api/serializers';
import {ParticipationSubmission, Token} from '@paperlessio/sdk/api/models'
import {BaseSerializer} from '@paperlessio/sdk/api/util';

export class ParticipationSubmissionSerializer implements BaseSerializer<ParticipationSubmission> {
  private blockSerializer = new BlockSerializer();
  private tokenSerializer = new Serializer<Token>(Token);
  private submitEventValueSerializer = new SubmitEventValueSerializer();

  fromJson(json: Partial<ParticipationSubmission>): ParticipationSubmission {
    if (json.aggregated_submit_event_values?.length) {
      json.aggregated_submit_event_values = json.aggregated_submit_event_values.map(sev => {
        return this.submitEventValueSerializer.fromJson(sev);
      });
    }

    return new ParticipationSubmission(json);
  }

  toJson(obj: Partial<ParticipationSubmission> | ParticipationSubmission): object {
    return {};
  }
}
