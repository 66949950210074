import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GizmoComponent} from './gizmo/gizmo.component';
import {NavigationService} from '@shared/submission-gizmo/navigation.service';
import {GizmoDestinationDirective} from './gizmo-destination.directive';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [GizmoComponent, GizmoDestinationDirective],
  exports: [
    GizmoDestinationDirective,
    GizmoComponent
  ],
  imports: [CommonModule, TranslocoModule]
})
export class SubmissionGizmoModule {
  static forRoot(): ModuleWithProviders<SubmissionGizmoModule> {
    return {
      ngModule: SubmissionGizmoModule,
      providers: [NavigationService]
    };
  }
}
