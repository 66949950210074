import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {CalculatedTokenValue, RexCalculationType, Token} from '@paperlessio/sdk/api/models'

@Injectable({providedIn: 'root'})
export class CalculatedTokenValueStore {
  private calculatedTokenValues = new ReplaySubject<{name: string, value: any}>(1);
  private internalCalculatedTokenValues: Record<string, ReplaySubject<boolean>> = {};

  tokenValue(tokenName: string): Observable<any> {
    this.ensureTokenValueSubject(tokenName);
    return this.internalCalculatedTokenValues[tokenName];
  }

  distributeTokenValue(name: string, value: any) {
    this.ensureTokenValueSubject(name);
    this.internalCalculatedTokenValues[name].next(value);
    this.calculatedTokenValues.next({
      name,
      value
    });
  }

  loadAggregatedCalculatedTokenValues(calculatedTokenValues: CalculatedTokenValue[]) {
    calculatedTokenValues.forEach(calculatedTokenValue => {
      this.distributeTokenValue(calculatedTokenValue.token_name, calculatedTokenValue.value);
    });
  }

  loadConstantTokenValues(tokens: Token[]) {
    tokens?.filter(token => token.calculation_type === RexCalculationType.CONSTANT)?.forEach(token => {
      this.distributeTokenValue(token.name, token.value);
    });
  }

  ngOnDestroy(): void {
    this.calculatedTokenValues.complete();
    Object.values(this.internalCalculatedTokenValues).forEach(v => {
      v.complete();
    });
  }

  private ensureTokenValueSubject(tokenName: string) {
    if (!this.internalCalculatedTokenValues[tokenName]) {
      this.internalCalculatedTokenValues[tokenName] = new ReplaySubject(1);
    }
  }
}
