export enum Addons {
  library = 'library',
  forms = 'forms',
  quickedits = 'quickedits',
  wizards = 'wizards',
  performance_dispatch = 'performance_dispatch',
  leaderboard = 'leaderboard',
  converter = 'converter',
  auto_reminders = 'auto_reminders',
  group_block = 'group_block',
  table_block = 'table_block',
  bulk_send = 'bulk_send',
  participant_completed_redirect = 'participant_completed_redirect',
  secure_file_upload = 'secure_file_upload',
  dataset_input = 'dataset_input',
  content_localization = 'content_localization',
  qualified_electronic_signature = 'qualified_electronic_signature',
  rules_engine = 'rules_engine',
  participation_impersonation = 'participation_impersonation',
  processes = 'processes',
  html_block = 'html_block',
  incineration = 'incineration'
}
