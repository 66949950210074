import {User} from './user';
import {Participant} from './participant';

export class Email {
  id: number;
  workspace_id: number;
  sender_id: number;
  sender: User;
  submittable_id: number;
  submission_id: number;
  participant_id: number;
  participant: Participant;
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  text_body: string;
  html_body_url: string;
  signature: string;
  status: string;
  created_at: Date;
  updated_at: Date;

  constructor(v: Partial<Email> = {}) {
    this.id = v.id;
    this.workspace_id = v.workspace_id;
    this.sender_id = v.sender_id;
    this.sender = v.sender ? new User(v.sender) : null;
    this.submittable_id = v.submittable_id;
    this.submission_id = v.submission_id;
    this.participant_id = v.participant_id;
    this.participant = v.participant ? new Participant(v.participant) : null;
    this.from = v.from ?? '';
    this.to = v.to ?? '';
    this.cc = v.cc ?? '';
    this.bcc = v.bcc ?? '';
    this.subject = v.subject ?? '';
    this.body = v.body ?? '';
    this.text_body = v.text_body ?? '';
    this.html_body_url = v.html_body_url ?? '';
    this.signature = v.signature ?? '';
    this.status = v.status ?? '';
    this.created_at = new Date(v.created_at);
    this.updated_at = new Date(v.updated_at);
  }
}
