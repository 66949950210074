import {DeltaStatic} from 'quill';
import {Block, BlockSettings, BlockStyles} from './block';
import {ContentLocale} from '@paperlessio/sdk/api/util';
import { Size } from '../util/size';
import {excluded} from '../util/excluded';

export type RichTextVerticalAlign = 'flex-start' | 'flex-end' | 'center';

export class RichTextSettings extends BlockSettings {
  constructor(v: Partial<RichTextSettings> = {}) {
    super(v);

    this.verticalAlignment = v?.verticalAlignment ?? 'flex-start';
  }

  verticalAlignment: RichTextVerticalAlign;

  get minSize(): Size {
    return new Size({
      width: 100,
      height: 20
    });
  }

  get maxSize(): Size {
    return new Size({
      width: 0,
      height: 0
    });
  }

  get defaultSize(): Size {
    return new Size({
      width: 300,
      height: 100
    });
  }
}

type LocalizedAttributes = {
  settings: {
    name: string; // unused, but required by Block.LocalizedAttributes => TODO: refactor
    content: string
    delta: any;
  }
}
export class RichText extends Block {
  styles: TextStyles;
  settings: RichTextSettings;
  localized_attributes: Record<ContentLocale, LocalizedAttributes>;
  @excluded delta: DeltaStatic; // only used in client for performance optimizations

  constructor(v: Partial<RichText> = {}) {
    super(v);
    this.styles = new TextStyles(v.styles ?? {});
    this.settings = new RichTextSettings(v.settings ?? {});
  }

  localizedDelta(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): DeltaStatic {
    return this.readLocalizedSettingsAttribute('delta', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedDelta(locale: ContentLocale, bucketOriginalLocale: ContentLocale, delta: DeltaStatic) {
    this.writeLocalizedSettingsAttribute('delta', locale, bucketOriginalLocale, delta);
  }

  localizedContent(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedSettingsAttribute('content', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedContent(locale: ContentLocale, bucketOriginalLocale: ContentLocale, content: string) {
    this.writeLocalizedSettingsAttribute('content', locale, bucketOriginalLocale, content);
  }
}

export class TextStyles extends BlockStyles {
  align: TextAlign;
  color: string;
  // font: Font;

  constructor(v: Partial<TextStyles> = {}) {
    super(v);
    this.align = v.align ?? TextAlign.left;
    this.color = v.color ?? '#000000';
    // this.font = v.font ? new Font(v.font) : new Font({family: 'Roboto'});
  }
}

export enum TextAlign {
  left = 'text-left',
  center = 'text-center',
  right = 'text-right',
  justify = 'text-justify'
}
