import {Injectable, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

export interface SingleCalculatedParticipantNodeAttribute {
  id: number;
  attributeName: string;
  value: boolean;
}

@Injectable({providedIn: 'root'})
export class CalculatedParticipantNodeAttributeStore implements OnDestroy {
  private calculatedParticipantNodeAttributeValues = new ReplaySubject<SingleCalculatedParticipantNodeAttribute>(1);
  private internalCalculatedParticipantNodeAttributeValues: Record<number, Record<string, ReplaySubject<boolean>>> = {};

  participantNodeAttributeValue(id: number, attributeName: string): Observable<boolean> {
    this.ensureParticipantNodeAttributeSubject(id, attributeName);
    return this.internalCalculatedParticipantNodeAttributeValues[id][attributeName];
  }

  distributeParticipantNodeAttributeValue(id: number, attributeName: string, value: boolean) {
    this.ensureParticipantNodeAttributeSubject(id, attributeName);
    this.internalCalculatedParticipantNodeAttributeValues[id][attributeName].next(value);
    this.calculatedParticipantNodeAttributeValues.next({
      id,
      attributeName,
      value
    });
  }

  ngOnDestroy(): void {
    this.calculatedParticipantNodeAttributeValues.complete();
    Object.values(this.internalCalculatedParticipantNodeAttributeValues).forEach(v => {
      Object.values(v).forEach(v => {
        v.complete();
      });
    });
  }

  private ensureParticipantNodeAttributeSubject(id: number, attributeName: string) {
    if (!this.internalCalculatedParticipantNodeAttributeValues[id]) {
      this.internalCalculatedParticipantNodeAttributeValues[id] = {};
    }
    if (!this.internalCalculatedParticipantNodeAttributeValues[id][attributeName]) {
      this.internalCalculatedParticipantNodeAttributeValues[id][attributeName] = new ReplaySubject(1);
    }
  }
}
