import {SubmissionNavbarBrandMode} from './submission-navbar-brand-mode';
import {SubmissionNavbarMessageMode} from './submission-navbar-message-mode';
import {SuccessPageSettings} from './success-page-settings';

export class SubmissionSettings {
  navbar_brand_mode: SubmissionNavbarBrandMode;
  navbar_brand_text: string;
  navbar_message_mode: SubmissionNavbarMessageMode;
  navbar_message_text: string;
  success_page: SuccessPageSettings;

  showDocumentName: boolean;
  showSenderName: boolean;
  showSenderAvatar: boolean;
  showAdditionalInformation: boolean;
  addAdditionalText: boolean;
  additionalText: string;
  showSave: boolean;

  constructor(v: Partial<SubmissionSettings> = {}) {
    this.navbar_brand_mode = v.navbar_brand_mode ?? SubmissionNavbarBrandMode.PaperlessLogo;
    this.navbar_brand_text = v.navbar_brand_text ?? '';
    this.navbar_message_mode = v.navbar_message_mode ?? SubmissionNavbarMessageMode.Secure;
    this.navbar_message_text = v.navbar_message_text ?? '';
    this.success_page = new SuccessPageSettings(v.success_page ?? {});

    this.showDocumentName = v.showDocumentName ?? true;
    this.showSenderName = v.showSenderName ?? false;
    this.showSenderAvatar = v.showSenderAvatar ?? false;
    this.showAdditionalInformation = v.showAdditionalInformation ?? false;
    this.addAdditionalText = v.addAdditionalText ?? false;
    this.additionalText = v.additionalText ?? '';
    this.showSave = v.showSave ?? false;
  }
}
