import {IconLookup} from '@fortawesome/fontawesome-common-types';
import {plIcons} from '@paperlessio/sdk/icons';

export type OAuthAuthorizeResponseStatus = 'redirect' | 'Pre-authorization';

/**
 * https://gitlab.com/paperless.io/api/-/blob/develop/app/models/oauth_provider.rb
 */
export enum SCOPE_DOMAINS {
  CURRENT_USER = 'current_user',
  DOCUMENT = 'document',
  WEBHOOK = 'webhook',
  SUBMISSION = 'submission',
  TEMPLATE = 'template',
  WORKSPACE = 'workspace'
}

export class OAuthAuthorizeResponse {
  status: OAuthAuthorizeResponseStatus;
  redirect_uri: string;
  client_id?: string;
  state?: string;
  response_type?: string;
  scope?: string; // space delimited
  scopes?: Map<SCOPE_DOMAINS, { icon: IconLookup | plIcons, operations: string }>;
  client_name?: string;

  constructor(v: Partial<OAuthAuthorizeResponse> = {}) {
    Object.assign(this, v);

    this.scopes = new Map<SCOPE_DOMAINS, { icon: IconLookup, operations: string }>();
    if (this.scope?.length) {
      this.scope
        .split(' ')
        .sort((a, b) => a.localeCompare(b))
        .map(s => s.split('.'))
        .map(s => ({domain: s[0] as SCOPE_DOMAINS, operation: s[1]}))
        .sort((a, b) => (a.operation).localeCompare(b.operation))
        .forEach(s => {
          if (this.scopes.has(s.domain)) {
            const existing = this.scopes.get(s.domain);
            this.scopes.set(s.domain, {...existing, operations: existing.operations + s.operation});
          } else {
            this.scopes.set(s.domain, {icon: getOauthScopeDomainIconDefinition(s.domain), operations: s.operation});
          }
        });
    }
  }
}

export function getOauthScopeDomainIconDefinition(scopeDomain: SCOPE_DOMAINS): IconLookup | plIcons {
  switch (scopeDomain) {
    case(SCOPE_DOMAINS.CURRENT_USER): return {prefix: 'far', iconName: 'user'};
    case(SCOPE_DOMAINS.DOCUMENT): return 'navbarDocuments';
    case(SCOPE_DOMAINS.WEBHOOK): return 'integrationsWebhook';
    case(SCOPE_DOMAINS.SUBMISSION): return {prefix: 'far', iconName: 'file-alt'};
    case(SCOPE_DOMAINS.TEMPLATE): return 'navbarTemplates';
    case(SCOPE_DOMAINS.WORKSPACE): return {prefix: 'far', iconName: 'folder-tree'};
  }
}
