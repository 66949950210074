import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {ActionTypes, AdditionalSubmissionHeaderButtonConfig, ToastService} from '@paperlessio/sdk/api/util';
import {StyleableButtonModule} from '../styleable-button/styleable-button.module';
import {concat, Observable} from 'rxjs';
import {SessionControllerService} from '../../../session-controller.service';
import {SessionStore} from '../../../session.store';
import {tap} from 'rxjs/operators';
import {LoadingBubblesComponent} from '@paperlessio/sdk/components/loading-bubbles';
import {WINDOW} from '@paperlessio/sdk/util/helpers';

@Component({
  selector: 'app-additional-buttons',
  standalone: true,
  imports: [
    StyleableButtonModule,
    LoadingBubblesComponent
  ],
  templateUrl: './additional-buttons.component.html',
  styleUrl: './additional-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalButtonsComponent {
  private sessionControllerService = inject(SessionControllerService);
  private sessionStore = inject(SessionStore);
  private cdr = inject(ChangeDetectorRef);
  private toastService = inject(ToastService);
  private window = inject(WINDOW);

  @Input() additionalButtons?: AdditionalSubmissionHeaderButtonConfig[];

  private executableActions: { [K in ActionTypes]: (params: string[]) => Observable<any> } = {
    decline: params => {
      return this.sessionStore.decline().pipe(tap(_ => console.log('decline')));
    },
    redirect: params => {
      return new Observable<any>(s => {
        console.log('redirect');

        try {
          const url = new URL(params?.[0])?.toString();
          if (url) {
            this.window.location.href = url;
          }
        } catch (e) {
          console.error('Redirect failed', e);
        } finally {
          s.complete();
        }
      });
    },
    save: params => {
      return this.sessionControllerService.save().pipe(tap(_ => console.log('save')));
    },
    complete: params => {
      return this.sessionStore.complete().pipe(tap(_ => console.log('complete')));
    }
  };

  executeActions(button: AdditionalSubmissionHeaderButtonConfig) {
    button.loading = true;
    this.cdr.detectChanges();

    concat(
      ...button.actions.map(action => {
        return this.executableActions[action.actionType](action.parameters);
      })
    ).subscribe({
      complete: () => {
        button.loading = false;
        this.cdr.markForCheck();
      },
      error: err => {
        button.loading = false;
        this.toastService.error('session.additional_buttons.error');
        this.cdr.markForCheck();
      }
    });
  }
}
