export enum DocumentState {
  draft = 'draft',
  dispatched = 'dispatched',
  completed = 'completed',
  dispatch_failed = 'dispatch_failed', // virtual state to indicate problem with participant
  cancelled = 'cancelled',
  declined = 'declined',
  published = 'published',
  unpublished = 'unpublished'
}
