import {AbstractControl, ValidationErrors} from '@angular/forms';

export class Helper {
  static EmailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

  public static downloadFile(blob: any, fileName: string) {
    const a: any = document.createElement('a');
    document.body.appendChild(a);
    const url = (window.URL as any).createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    (window.URL as any).revokeObjectURL(url);
    a.remove();
  }

  public static downloadJson(object: object, fileName: string) {
    const encodedUri = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(object));
    this.download(encodedUri, fileName + '.json');
  }

  public static downloadCsv(content: string, fileName: string) {
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(content);
    this.download(encodedUri, fileName + '.csv');
  }

  public static validateEmail(control: AbstractControl, allowEmpty = false): ValidationErrors | null {
    if (allowEmpty && (!control?.value || control?.value?.length === 0)) {
      return null;
    }
    if (Helper.validEmailString(control?.value)) {
      return null;
    } else {
      return {validateEmail: 'Not an Email'};
    }
  }

  public static validEmailString(email: string): boolean {
    return !!email?.match(this.EmailRegex);
  }

  public static convertArrayOfObjectsToCsv(data: object[]): string {
    const keys = Object.keys(data[0]);
    let result = '';

    result += keys.join(',');
    result += '\n';

    data.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        let val = item[key];

        if(typeof val === 'string') {
          val = '"' + val + '"';
        } else if (typeof val === 'object') {
          val = JSON.stringify(val);
          val = val.replace(/"|{|}|'|;/g, '');
          val = val.replace(/,/g, ' ');
        }

        if (ctr > 0 && val) result += ',';

        result += val ?? '';
        ctr++;
      });
      result += '\n';
    });

    return result;
  }

  private static download(href: string, fileName: string) {
    const a = document.createElement('a');

    a.setAttribute('href', href);
    a.setAttribute('download', fileName);
    document.body.appendChild(a); // required for firefox
    a.click();
    a.remove();
  }
}
