import {ApplicationPolicy} from './application_policy';

export class NullPolicy extends ApplicationPolicy {

  constructor() {
    super(null, null, null);
  }

  index(): boolean {
    return false;
  }

  show(): boolean {
    return false;
  }

  create(): boolean {
    return false;
  }

  update(): boolean {
    return false;
  }

  destroy(): boolean {
    return false;
  }
}
