export class QesIdentityVerificationProcess {
  id: string;
  url: string;
  username: string;
  state: 'initialized' | 'completed' | 'aborted' | 'failed';
  identification_type: 'id' | 'video';
  expires_at: Date;
  created_at: Date;

  constructor(v: Partial<QesIdentityVerificationProcess> = {}) {
    this.id = v.id;
    this.url = v.url;
    this.username = v.username;
    this.state = v.state;
    this.identification_type = v.identification_type;
    this.expires_at = v.expires_at != null ? new Date(v.expires_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
  }
}
