import {ContentLocale} from '@paperlessio/sdk/api/util';
import {Input, InputSettings} from './input';
import {BlockStyles} from './block';
import {excluded} from '../util/excluded';

export class DataSetInput extends Input {
  settings: DataSetInputSettings;
  styles: DataSetInputStyles;

  @excluded default_value: any;

  constructor(v: Partial<DataSetInput> = {}) {
    super(v);
    this.default_value = v.default_value ?? [];
    this.settings = new DataSetInputSettings(v.settings ?? {});
    this.styles = new DataSetInputStyles(v.styles ?? {});

    this.disableDropzone = {
      left: true,
      right: true
    };
  }

  localizedAddButtonLabel(locale: ContentLocale, bucketOriginalLocale: ContentLocale, fallback = false): string {
    return this.readLocalizedSettingsAttribute('addButtonLabel', locale, bucketOriginalLocale, fallback);
  }

  setLocalizedAddButtonLabel(locale: ContentLocale, bucketOriginalLocale: ContentLocale, help_text: string) {
    this.writeLocalizedSettingsAttribute('addButtonLabel', locale, bucketOriginalLocale, help_text);
  }
}

export class DataSetInputSettings extends InputSettings {
  addButtonLabel: string;
  minRows: number | null;
  maxRows: number | null;

  constructor(v: Partial<DataSetInputSettings> = {}) {
    super(v);
    this.addButtonLabel = v.addButtonLabel ?? '';
    this.minRows = v.minRows ?? null;
    this.maxRows = v.maxRows ?? null;
    this.calculateRequired();
  }

  calculateRequired() {
    this.required = !!(this.minRows != null && this.minRows > 0);
  }
}

export class DataSetInputStyles extends BlockStyles {
  button_background: string;
  button_color: string;

  constructor(v: Partial<DataSetInputStyles> = {}) {
    super(v);
    this.button_background = v.button_background ?? '#F1F1F3';
    this.button_color = v.button_color ?? '#252729';
  }
}
