import {Component, HostBinding, inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {HelloService} from '@paperlessio/sdk/api/services';
import {SubmissionSignatureStorage} from '@shared/signature-input-modal/submission-signature-storage';
import {SubmissionSignatureBlobStorage} from '@shared/signature-input-modal/submission-signature-blob-storage';
import {RouterStore} from '@paperlessio/sdk/util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class.touch') touch = false;

  private document = inject(DOCUMENT);
  private helloService = inject(HelloService);
  private routerStore = inject(RouterStore);

  ngOnInit() {
    this.touch = 'ontouchstart' in this.document;
    this.helloService.hello().subscribe();

    new SubmissionSignatureStorage().cleanSignatures();
    new SubmissionSignatureBlobStorage().cleanSignatures();

    this.routerStore.init();
  }
}
