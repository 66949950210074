import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionStore} from '../session/session.store';
import {Subscription} from 'rxjs';
import {SessionControllerService} from '../session/session-controller.service';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {BlockStore} from '@blocks/block/block.store';
import {
  BrandDesign,
  Page,
  ParticipantRole,
  ParticipantState,
  ParticipationSubmission,
  SubmissionDesign,
  SubmissionMetaInformation
} from '@paperlessio/sdk/api/models';
import {ToastService} from '@paperlessio/sdk/api/util';
import {GizmoState, GizmoStateType, NavigationService, NavigationState} from '@shared/submission-gizmo/navigation.service';
import {RouterStore} from '@paperlessio/sdk/util';
import {LocalizationService} from '@shared/i18n/localization.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {SubmissionConfig} from '@paperlessio/sdk/api/util/submission-config';
import {SubmissionConfigService} from '../submission-config.service';

/*
 * Just wraps session stuff within submission frontend
 * Belongs directly to AppModule to be loaded and available immediately
 * Does not belong in SessionModule, as this is used to share code with the designer
 * Acts as a scroll container
 */
@Component({
  selector: 'app-session-wrapper',
  templateUrl: './session-wrapper.component.html',
  styleUrls: ['./session-wrapper.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionWrapperComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(NgForm) form: NgForm;

  smi: SubmissionMetaInformation;
  brandDesign: BrandDesign;
  submissionDesign: SubmissionDesign;
  subs = new Subscription();
  showNavigation: boolean;
  showPrev: boolean;
  showNext: boolean;
  showReadOnly: boolean;
  showApprove: boolean;
  showComplete: boolean;
  showCompleteArrow: boolean;
  showSave: boolean;
  disableComplete: boolean;
  isCompleted: boolean;
  currentPage: Page;
  hasInputs = false;
  currentParticipantCompleted: boolean;
  submissionCompleted: boolean;
  pdf_url = '';

  submissionConfig?: SubmissionConfig;
  gizmoState: GizmoState;
  navigationState: NavigationState;

  startButton: HTMLElement;
  tosCheckbox: HTMLElement;

  private routeData: any = {};

  private sessionStore = inject(SessionStore);
  private sessionController = inject(SessionControllerService);
  private toastService = inject(ToastService);
  private blockStore = inject(BlockStore);
  private routerStore = inject(RouterStore);
  private navigationService = inject(NavigationService);
  private cdr = inject(ChangeDetectorRef);
  private localizationService = inject(LocalizationService);
  private title = inject(Title);
  private route = inject(ActivatedRoute);
  private submissionConfigService = inject(SubmissionConfigService);

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get('token');
    const submissionConfigString = this.route.snapshot.queryParamMap.get(SubmissionConfig.QueryParamName);
    if (token && submissionConfigString) {
      this.submissionConfigService.setConfigForToken(token, submissionConfigString);
    } else if (token) {
      this.submissionConfigService.loadConfigForToken(token);
    }

    this.subs.add(this.submissionConfigService.submissionConfig.subscribe({
      next: submissionConfig => {
        this.submissionConfig = submissionConfig;
        console.log('submissionConfig', submissionConfig);
        this.cdr.markForCheck();
      }
    }));

    this.subs.add(this.sessionStore.submission
      .pipe(filter(s => !!s))
      .subscribe((submission: ParticipationSubmission) => {
        this.pdf_url = submission.pdf?.url;
        this.submissionCompleted = !!submission.completed_at;
        this.cdr.markForCheck();
      }));

    this.subs.add(this.sessionStore.submissionMetaInformation
      .subscribe(smi => {
        // for localized buckets: on first load, set content and ui locale
        if (!this.smi && smi.submittable.localized) {
          this.localizationService.attemptToSetOptimalUIAndContentLocaleForParticipant(smi.participant);
        }

        this.smi = smi;
        this.brandDesign = smi.submittable.designs.brand;
        this.submissionDesign = smi.submittable.designs.submission;
        this.currentParticipantCompleted = smi.participant.state === ParticipantState.completed;
        this.showSave = smi.submittable.designs.submission.submission_settings.showSave;

        this.title.setTitle([this.smi.submittable?.name, this.smi.organization_name, 'Paperless'].join(' | '));

        this.cdr.detectChanges();
      }));

    // TODO: Maxbe move into gizmo service
    this.subs.add(this.sessionStore.tosApproved.subscribe(tosApproved => {
      this.navigationService.updateState({tosApproved});
    }));

    this.subs.add(this.sessionStore.mayComplete
      .subscribe(mayComplete => {
        this.disableComplete = !mayComplete;
        this.cdr.markForCheck();
      })
    );

    this.subs.add(this.navigationService?.navigationState
      .subscribe(state => {
        this.navigationState = state;
        this.cdr.markForCheck();
      }));

    // Debounce is what we want here! No leading-edge value. This reduces flickering in the ui!
    this.subs.add(this.navigationService?.gizmoState
      .pipe(filter(gizmoState => !!gizmoState))
      .subscribe(gizmoState => {
        this.gizmoState = gizmoState;
        this.showCompleteArrow = gizmoState.type === GizmoStateType.HIDDEN && !this.showSave;
        this.updateState();
      }));

    this.subs.add(this.sessionController.currentPageValid
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.updateState();
      }));

    this.subs.add(this.sessionController.currentPage
      .subscribe(page => {
        this.currentPage = page;
        this.updateState();
      }));

    this.sessionController.init(this.form);

    this.subs.add(this.blockStore.blocks
      .pipe(filter(x => !!x))
      .subscribe(blocks => {
        this.hasInputs = blocks.some(x => x.type.includes('::Input::'));
        this.updateState();
      }));

    this.subs.add(this.routerStore.routeData.subscribe(data => {
      this.routeData = data;
      this.updateState();
    }));
  }

  ngAfterViewInit() {
    this.getHtmlElements();
  }

  prev() {
    this.sessionController.goToPreviousPage();
  }

  next() {
    this.sessionController.goToNextPage();
  }

  save() {
    this.subs.add(this.sessionController.save().subscribe());
  }

  start(termsAccepted: boolean) {
    // Uses sessionController loading mechanism to prevent multiple submits
    if (termsAccepted && !this.sessionController.loading.value) {
      this.sessionController.loading.next(true);

      this.sessionStore
        .approveTermsAndConditions()
        .subscribe({
          next: _ => {
            this.getHtmlElements();

            // No optimistic UI here, as we need
            this.smi.participant.terms_and_conditions_approved_at = new Date();
            this.sessionController.loading.next(false);
          },
          error: err => {
            this.sessionController.loading.next(false);
            this.smi.participant.terms_and_conditions_approved_at = null;
            this.cdr.detectChanges();
            this.toastService.error('session.terms.error');
          }
        });
    }
  }

  complete() {
    this.sessionController.completeSession();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private updateState() {
    this.navigationService.updateState({
      pageCount: this.sessionController.pageCount,
      currentPage: this.sessionController.currentPageNumber - 1
    });

    this.showPrev = this.sessionController.hasPreviousPage && !this.isCompleted;

    this.showNext = this.sessionController.hasNextPage && !this.isCompleted;

    this.showNavigation = !this.routeData?.hideNavigation &&
    (
      this.sessionController.pageCount > 1
      || this.hasInputs
      || this.smi?.participant?.role === ParticipantRole.approver
      || this.smi?.participant?.role === ParticipantRole.viewer
    );

    this.showReadOnly = this.smi?.participant?.role === ParticipantRole.viewer;

    this.showApprove = this.sessionController.isLastPage && !this.isCompleted
                       && this.smi?.participant?.role === ParticipantRole.approver;

    this.showComplete = this.sessionController.isLastPage && !this.isCompleted
                        && this.smi?.participant?.role !== ParticipantRole.approver
                        && this.smi?.participant?.role !== ParticipantRole.viewer;

    this.showSave = this.submissionDesign.submission_settings.showSave
      && this.gizmoState.type !== GizmoStateType.INITIAL
      && (this.showComplete || this.showApprove || this.showNext);

    this.cdr.markForCheck();
  }

  focusActionButton() {
    (document.querySelector('#complete-button') as HTMLElement)?.focus();
    (document.querySelector('#next-button') as HTMLElement)?.focus();
  }

  action() {
    if (this.tosCheckbox != null) {
      this.tosCheckbox.querySelector('input').focus();
      return;
    }

    if (this.startButton != null) {
      this.startButton.click();
    } else {
      this.focusPage();
    }
  }

  focusPage() {
    const firstFocusableElement = document.body
      .querySelector('app-page')
      .querySelector('a[href], button, input:not([type="file"]), textarea, select, details, [tabindex]:not([tabindex="-1"])') as HTMLElement;

    firstFocusableElement.focus();
  }

  private getHtmlElements() {
    setTimeout(() => {
      this.startButton = document.querySelector<HTMLButtonElement>('#start-button');
      this.tosCheckbox = document.querySelector<HTMLElement>('pl-session-tos-checkbox');
      this.cdr.markForCheck();
    }, 300);
  }
}
