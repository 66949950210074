import {Block, BlockSettings, BlockStyles} from './block';
import {ImageBorder} from './image';

export class Group extends Block {
  settings: GroupSettings;
  styles: GroupStyles;

  constructor(v: Partial<Group> = {}) {
    super(v);
    this.settings = new GroupSettings(v.settings ?? {});
    this.styles = new GroupStyles(v.styles ?? {});
  }
}

export class GroupSettings extends BlockSettings {

  constructor(v: Partial<GroupSettings> = {}) {
    super(v);
  }
}

export class BlockPadding {
  top: string;
  right: string;
  bottom: string;
  left: string;

  constructor(v: Partial<BlockPadding> = {}) {
    this.top = v.top ?? '0';
    this.right = v.right ?? '0';
    this.bottom = v.bottom ?? '0';
    this.left = v.left ?? '0';
  }
}

export class GroupStyles extends BlockStyles {
  background: string;
  full_width: boolean;
  padding: BlockPadding;
  border: ImageBorder;
  border_radius: number;

  constructor(v: Partial<GroupStyles> = {}) {
    super(v);
    this.background = v.background ?? '#fff';
    this.padding = new BlockPadding(v.padding);
    this.border = new ImageBorder(v.border);
    this.full_width = v.full_width ?? v.full_width;
    this.border_radius = v.border_radius ?? 0;
  }
}
