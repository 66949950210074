import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BrandColor, BrandDesign} from '@paperlessio/sdk/api/models';

@Component({
  selector: 'app-design-colors-inserter',
  templateUrl: './design-colors-inserter.component.html'
})
export class DesignColorsInserterComponent implements OnChanges {
  @Input() design: BrandDesign;

  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setStyleProperties();
  }

  setStyleProperties() {
    this.design?.brand_styles?.colors?.forEach((color: BrandColor) => {
      this.elementRef.nativeElement.style.setProperty('--design-' + color.name, color.value);
    });
  }

}
