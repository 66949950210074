export class CalculatedBlockAttribute {
  id: number;
  block_id: number;
  submission_id: number;
  virtual_slug: string;
  required: boolean;
  visible: boolean;
  html: string;
  created_at: Date;
  updated_at: Date;

  constructor(v: Partial<CalculatedBlockAttribute>) {
    this.id = v.id;
    this.block_id = v.block_id;
    this.submission_id = v.submission_id;
    this.virtual_slug = v.virtual_slug;
    this.required = v.required;
    this.visible = v.visible;
    this.html = v.html;
    this.created_at = v.created_at ? new Date(v.created_at) : null;
    this.updated_at = v.updated_at ? new Date(v.updated_at) : null;
  }
}
