import {Expandable} from '@paperlessio/sdk/api/util';
import {User} from './user';
import {Incineratable} from './util/incineratable';
import {parseIncineratable} from './parsers/incineratable.parser';
import {ListColumn, ListColumnSize, ListColumnType} from './column';

export class IncinerationOrder {
  static expandable = new Expandable<IncinerationOrder, 'creator' | 'incineratable'>();

  static columns: ListColumn[] = [
    new ListColumn({property: 'incineratable_type', default: true, fixed: true, align: 'left', size: ListColumnSize.col06, type: ListColumnType.default}),
    new ListColumn({property: 'scheduled_execution_at', default: true, fixed: true, align: 'left', size: ListColumnSize.col02, type: ListColumnType.countdown}),
    new ListColumn({property: 'creator', default: true, align: 'left', size: ListColumnSize.col02, type: ListColumnType.creator}),
    new ListColumn({property: 'created_at', default: false, align: 'left', size: ListColumnSize.auto, type: ListColumnType.timeAgo})
  ];

  static defaultColumns = IncinerationOrder.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  /**
   * Table names that aren't filtered out
   */
  static interestingTableNames: string[] = [
    'submissions',
    'submittables',
    'process_definitions',
    'process_definition_versions',
    'process_flows',
    'process_runs',
    'process_triggers',
    'process_labels',
    'forms'
  ];

  static interestingTableNameIncineratableTypeMap = {
    'Submission': 'submissions',
    'Submittable': 'submittables',
    'ProcessDefinition': 'process_definitions',
    'ProcessDefinitionVersion': 'process_definition_versions',
    'ProcessFlow': 'process_flows',
    'ProcessRun': 'process_runs',
    'ProcessTrigger': 'process_triggers',
    'ProcessLabel': 'process_labels',
    'Partial': 'partials',
    'Template': 'templates',
    'Form': 'forms'
  };

  id: number;
  creator_id: number;
  incineratable_id: number;
  incineratable_type: string;
  state: IncinerationState;
  incinerated_counts_by_table_name: { [table_name: string]: number };
  interesting_incinerated_counts_by_table_name: [table_name: string, count: number][];
  creator: User;
  incineratable: Incineratable;
  scheduled_execution_at: Date;
  pending_at?: Date;
  executed_at?: Date;
  updated_at: Date;
  created_at: Date;

  constructor(v?: Partial<IncinerationOrder>) {
    this.id = v.id;
    this.creator_id = v.creator_id;
    this.incineratable_id = v.incineratable_id;
    this.incineratable_type = v.incineratable_type;
    this.state = v.state;
    this.incinerated_counts_by_table_name = v.incinerated_counts_by_table_name;
    this.creator = v.creator ? new User(v.creator) : null;
    this.incineratable = v.incineratable ? parseIncineratable(v.incineratable) : null;
    this.scheduled_execution_at = v.scheduled_execution_at ? new Date(v.scheduled_execution_at) : null;
    this.pending_at = v.pending_at ? new Date(v.pending_at) : null;
    this.executed_at = v.executed_at ? new Date(v.executed_at) : null;
    this.updated_at = v.updated_at ? new Date(v.updated_at) : null;
    this.created_at = v.created_at ? new Date(v.created_at) : null;

    this.interesting_incinerated_counts_by_table_name = Object
      .entries(v.incinerated_counts_by_table_name)
      .filter(e => IncinerationOrder.interestingTableNames.includes(e[0]));
  }
}

export const IncinerationState = {
  initialized: 'initialized',
  pending: 'pending',
  executed: 'executed'
} as const;

export type IncinerationState = typeof IncinerationState[keyof typeof IncinerationState];
