/**
 * Temporary, until server-side implementation of role-permissions system is complete
 */
export const Roles = {
  guest: {
    name: 'Guest', // backend: guest
    permissions: {
      root: {
        templates: {show: true},
        submittables: {show: true},
        submissions: {show: true},
        partials: {show: true},
        integrations: {show: true},
        organizations: {show: true},
        forms: {show: true},
        form_versions: {show: true},
        process_triggers: {},
        process_definitions: {},
        process_definition_versions: {}
      }
    }
  },
  sender: {
    name: 'Sender', // backend: sender
    permissions: {
      root: {
        templates: {show: true},
        submittables: true,
        submissions: {show: true, create: true},
        partials: true,
        integrations: {show: true},
        organizations: {show: true},
        forms: {show: true},
        form_versions: {show: true},
        process_triggers: {show: true},
        process_definitions: {},
        process_definition_versions: {}
      }
    }
  },
  admin: {
    name: 'Admin', // backend: admin
    permissions: {
      root: {
        templates: true,
        submissions: true,
        submittables: true,
        partials: true,
        settings: {
          workspace: {
            show: true,
            edit: true
          },
          memberships: true
        },
        integrations: true,
        organizations:  true,
        forms: true,
        form_versions: true,
        rules: true,
        process_triggers: true,
        process_definitions: true,
        process_definition_versions: true,
      }
    }
  },
  owner: {
    name: 'Owner', // backend: owner
    permissions: {root: true,}
  }
};
