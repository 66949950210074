import {Injectable, OnDestroy} from '@angular/core';
import {AuthenticationStore, CurrentWorkspaceStore} from '@paperlessio/sdk/api/stores';
import {
  Document,
  Form,
  FormVersion,
  IWorkspaceMembership,
  Organization,
  Partial,
  ParticipantContact,
  ProcessDefinition,
  ProcessDefinitionVersion,
  ProcessTrigger,
  Submission,
  Template,
  User,
  Workspace,
  WorkspaceMembership
} from '@paperlessio/sdk/api/models';
import {Permission} from './permission';
import {Roles} from './roles';
import * as Sentry from '@sentry/angular-ivy';
import {ApplicationPolicy, RoleMap} from './policies/application_policy';
import {NullPolicy} from './policies/null_policy';
import {DocumentPolicy} from './policies/document_policy';
import {TemplatePolicy} from './policies/template_policy';
import {PartialPolicy} from './policies/partial_policy';
import {WorkspacePolicy} from './policies/workspace_policy';
import {WorkspaceMembershipPolicy} from './policies/workspace_membership_policy';
import {IntegrationPolicy} from './policies/integration_policy';
import {Integration} from '@management/integrations/integration';
import {Subscription} from 'rxjs';
import {OrganizationPolicy} from './policies/organization_policy';
import {ParticipantContactPolicy} from '@management/base/authorization/policies/participant_contact_policy';
import {FormPolicy} from './policies/form_policy';
import {FormVersionPolicy} from '@management/base/authorization/policies/form_version_policy';
import {SubmissionPolicy} from '@management/base/authorization/policies/submission_policy';
import {ProcessDefinitionPolicy} from '@management/base/authorization/policies/process_definition_policy';
import {ProcessDefinitionVersionPolicy} from '@management/base/authorization/policies/process_definition_version_policy';
import {ProcessTriggerPolicy} from '@management/base/authorization/policies/process_trigger_policy';

@Injectable()
export class AuthorizationService implements OnDestroy {
  private current_user: User;
  private current_membership: WorkspaceMembership; // currently active workspace (membership)
  private role_cache: RoleMap = {workspaces: {}, organizations: {}}; // maps from workspace/organization to role
  private subs = new Subscription();

  constructor(
    private authStore: AuthenticationStore,
    private currentWorkspaceStore: CurrentWorkspaceStore
  ) {
    this.subs.add(
      this.authStore.currentUser.subscribe(user => {
        this.current_user = user;
        this.role_cache.workspaces = this.current_user.workspace_memberships.reduce(
          (obj, item: IWorkspaceMembership) => {
            obj[+item.workspace_id] = item.role;
            return obj;
          }, {});
        this.role_cache.organizations = this.current_user.organization_memberships.reduce(
          (obj, item) => {
            obj[+item.organization_id] = item.role;
            return obj;
          }, {});
      })
    );

    this.subs.add(
      this.currentWorkspaceStore.membership.subscribe(membership => {
        this.current_membership = membership;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public can(action: string, record?: any): boolean {
    if (record) {
      return this.canRecord(action, record);
    } else {
      return this.canGlobal(action);
    }
  }

  private canRecord(action: string, record: any): boolean {
    const policy: ApplicationPolicy = this.getPolicy(record);
    return policy[action] ? policy[action]() : false;
  }

  private canGlobal(action: string): boolean {
    if(this.current_membership) {
      return Permission.allowed(Roles[this.current_membership.role].permissions, action);
    } else {
      Sentry.captureException(new Error('Auth: Not ready'));
      return false;
    }
  }

  private getPolicy(record: any): ApplicationPolicy {
    if (!this.current_user) {
      Sentry.captureException(new Error('Auth: Not ready'));
      return new NullPolicy();
    }

    if (!record || !record.constructor) {
      Sentry.captureException(new Error('Auth: No record given'));
      return new NullPolicy();
    }

    switch (record.constructor) {
      case Document: return new DocumentPolicy(this.current_user, this.role_cache, record);
      case Partial: return new PartialPolicy(this.current_user, this.role_cache, record);
      case Template: return new TemplatePolicy(this.current_user, this.role_cache, record);
      case Integration: return new IntegrationPolicy(this.current_user, this.role_cache, record);
      case Workspace: return new WorkspacePolicy(this.current_user, this.role_cache, record);
      case WorkspaceMembership: return new WorkspaceMembershipPolicy(this.current_user, this.role_cache, record);
      case Organization: return new OrganizationPolicy(this.current_user, this.role_cache, record);
      case ParticipantContact: return new ParticipantContactPolicy(this.current_user, this.role_cache, record);
      case Form: return new FormPolicy(this.current_user, this.role_cache, record);
      case FormVersion: return new FormVersionPolicy(this.current_user, this.role_cache, record);
      case Submission: return new SubmissionPolicy(this.current_user, this.role_cache, record);
      case ProcessDefinition: return new ProcessDefinitionPolicy(this.current_user, this.role_cache, record);
      case ProcessDefinitionVersion: return new ProcessDefinitionVersionPolicy(this.current_user, this.role_cache, record);
      case ProcessTrigger: return new ProcessTriggerPolicy(this.current_user, this.role_cache, record);
      default: return new NullPolicy();
    }
  }
}
