import {ElementRef, inject, Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {ScrollingService} from '@shared/scrolling/scrolling.service';
import {take, throttleTime} from 'rxjs/operators';
import {GizmoDestinationDirective} from '@shared/submission-gizmo/gizmo-destination.directive';
import {BlockType, Input} from '@paperlessio/sdk/api/models';

export interface TargetState {
  targetElementId: string;
  targetElement: HTMLElement;
  targetBlockType: BlockType;
  parentBlockType: BlockType;
  pdfDocument: boolean;
  sortOrder: number;
  top: number;
  required: boolean;
  valid: boolean;
  visited: boolean;
  directive: GizmoDestinationDirective;
}

export enum GizmoStateType {
  'INITIAL' = 'INITIAL',
  'INPUT' = 'INPUT',
  'NEXT' = 'NEXT',
  'NEXT_PAGE' = 'NEXT_PAGE',
  'HIDDEN' = 'HIDDEN',
}

export interface GizmoState {
  type: GizmoStateType;
  currentTarget?: TargetState;
  pageHasOnlySignatureInput?: boolean;
}

export interface NavigationState {
  pageCount: number;
  currentPage: number;
  inputCount: number;
  requiredInputCount: number;
  validRequiredInputCount: number;
  invalidRequiredInputCount: number;
}

@Injectable()
export class NavigationService {
  targets = new ReplaySubject<TargetState[]>(1);
  gizmoState = new BehaviorSubject<GizmoState>(null);
  navigationState = new BehaviorSubject<NavigationState>(null);
  // we do want to debounce the gizmo state because it is (1) dependent on DOM changes and (2) triggered on every user input (e.g. keyup)
  buildGizmoStateDebounce = new Subject<void>();
  debounceTimeMs = 25;
  nextPageTrigger = new Subject<void>();

  /**
   * This subject is mainly used to add a class to the target _after_ the browser has scrolled to it.
   */
  gizmoStateAfterScroll = new ReplaySubject<GizmoState>(1);

  private registeredTargets = new Map<string, { block: Input, elementRef: ElementRef<HTMLElement>, directive: GizmoDestinationDirective }>();
  private sortedTargets: TargetState[] = [];

  private currentGizmoStateType = GizmoStateType.INITIAL;
  private currentTargetIndex?: number;
  private pageCount: number;
  private currentPage: number;
  private tosApproved: boolean;

  // We save the registered BlockTypes of our targets here, so we can tell if there's only a signature input target
  private targetBlockTypes = new Set<BlockType>();

  private scrollingService = inject(ScrollingService);

  get invalidRequiredInputCount(): number {
    return this.sortedTargets?.filter(st => !st.valid && st.required)?.length ?? 0;
  }

  get validRequiredInputCount(): number {
    return this.sortedTargets?.filter(st => st.valid && st.required)?.length ?? 0;
  }

  get visitedRequiredTargets(): number {
    return this.sortedTargets?.filter(st => st.visited && st.required)?.length ?? 0;
  }

  get requiredInputCount(): number {
    return this.sortedTargets?.filter(st => st.required)?.length ?? 0;
  }

  get currentTarget(): TargetState {
    return this.currentTargetIndex != null ? this.sortedTargets?.[this.currentTargetIndex] : null;
  }

  constructor() {
    this.buildGizmoStateDebounce
      // as we are using throttleTime with leading: false, we will effectively delay buildGizmoState by debounceTimeMs
      // this allows a change detection pass before buildGizmoState to get the correct DOM (position) readings
      // TODO: we now use leading: true because of failing cypress tests. to be investigated.
      .pipe(throttleTime(this.debounceTimeMs, undefined, {leading: true, trailing: true}))
      .subscribe(() => this.buildGizmoState());
  }

  registerTarget(block: Input, elementRef: ElementRef<HTMLElement>, directive: GizmoDestinationDirective) {
    if (!block || !elementRef) {
      return;
    }

    if (!elementRef?.nativeElement?.id) {
      throw new Error('registerTarget needs an element with an id');
    }

    if (!block.type.includes('::Input::')) {
      throw new Error('registerTarget block must be an input block');
    }

    if ('readonly' in block.settings && block.settings.readonly) {
      return;
    }

    const sessionContent = elementRef.nativeElement.closest('app-session-content');

    let offset = 0;
    offset += sessionContent?.scrollTop ?? 0;

    const rekt = elementRef.nativeElement.getBoundingClientRect();
    offset += (rekt.y ?? 0) * 10;
    offset += rekt.x ?? 0;

    this.sortedTargets.push({
      targetElementId: elementRef.nativeElement.id,
      targetElement: elementRef.nativeElement,
      targetBlockType: block.type,
      parentBlockType: block.parent.type,
      pdfDocument: block.hasPdfPartParent,
      sortOrder: offset,
      top: 0,
      required: block.settings.required,
      valid: false,
      visited: false,
      directive
    });

    // TODO: replace with IntersectionObserver
    this.sortedTargets.forEach(target => {
      const rekt = target.targetElement.getBoundingClientRect();
      let offset = 0;
      offset += sessionContent?.scrollTop ?? 0;

      offset += (rekt.y ?? 0) * 10;
      offset += rekt.x ?? 0;
      target.sortOrder = offset;
    });

    this.targetBlockTypes.add(block.parent.type === BlockType.SignatureInput ? BlockType.SignatureInput : block.type);

    this.sortedTargets.sort((a, b) => a.sortOrder - b.sortOrder);

    this.registeredTargets.set(elementRef.nativeElement.id, {block, elementRef, directive});
    this.targets.next(this.sortedTargets);
    this.buildGizmoStateDebounce.next();
  }

  unregisterTarget(targetElementId: string) {
    this.registeredTargets.delete(targetElementId);
    this.sortedTargets = this.sortedTargets.filter(e => e.targetElementId !== targetElementId);
    this.targets.next(this.sortedTargets);
  }

  updateTargetValidity(targetElementId: string, valid: boolean) {
    if (!this.sortedTargets.find(sb => sb.targetElementId === targetElementId)) {
      return;
    }

    this.sortedTargets.find(sb => sb.targetElementId === targetElementId).valid = valid;
    this.targets.next(this.sortedTargets);

    this.buildGizmoStateDebounce.next();
  }

  gizmoAction() {
    if (this.currentGizmoStateType === GizmoStateType.HIDDEN) {
      return;
    }

    switch (this.currentGizmoStateType) {
      case GizmoStateType.INITIAL: {
        this.scrollToNextTarget();
        break;
      }
      case GizmoStateType.INPUT: {
        switch (this.currentTarget.targetBlockType) {
          case BlockType.SignatureInput:
          case BlockType.FileUploadInput: {
            this.currentTarget.targetElement.click();
            break;
          }
          case BlockType.DataSetInput: {
            this.currentTarget.targetElement.click();
            break;
          }
          case BlockType.DateInput: {
            this.currentTarget.targetElement.focus();

            if ('showPicker' in this.currentTarget.targetElement) {
              (this.currentTarget.targetElement as any).showPicker();
            }

            break;
          }
          default: {
            this.currentTarget.targetElement.focus();
          }
        }

        this.currentTarget.directive.highlight();

        break;
      }
      case GizmoStateType.NEXT: {
        // If we're in the 'location' input of a signature input block, and the signature isn't filled, direct user up to the signature-box
        if (this.currentTarget.targetBlockType === BlockType.TextInput
          && this.currentTarget.targetElement.closest('.location-input') != null
          && !this.sortedTargets[this.currentTargetIndex - 1]?.valid) {
          this.currentTargetIndex -= 1;
          this.scrollToNextTarget();
          break;
        }

        if (this.invalidRequiredInputCount > 0 && this.currentTargetIndex === this.sortedTargets.length - 1) {
          this.currentTargetIndex = 0;
        }

        this.scrollToNextTarget();
        break;
      }
      case GizmoStateType.NEXT_PAGE: {
        this.nextPage();

        break;
      }
    }

    this.buildGizmoStateDebounce.next();
  }

  updateState({pageCount, currentPage, tosApproved}: {pageCount?: number, currentPage?: number, tosApproved?: boolean}) {
    // This gets called from the session-wrapper component

    if (this.currentPage !== currentPage) {
      this.currentTargetIndex = null;
    }

    if (pageCount != null) {
      this.pageCount = pageCount;
    }

    if (currentPage != null) {
      this.currentPage = currentPage;
    }

    if (tosApproved != null) {
      this.tosApproved = tosApproved;
    }
    this.buildGizmoStateDebounce.next();
  }

  buildGizmoState() {
    if (this.tosApproved
      && this.currentTargetIndex == null
      && this.sortedTargets.length > 0
      && this.invalidRequiredInputCount > 0
      && this.visitedRequiredTargets === 0) {
      this.currentGizmoStateType = GizmoStateType.INITIAL;
    } else if (this.tosApproved
      && this.currentTarget != null
      && !this.currentTarget.valid
      && this.sortedTargets.length > 0) {
      this.currentGizmoStateType = GizmoStateType.INPUT;
    } else if (this.tosApproved
      && this.currentTarget?.valid
      && this.sortedTargets.length > 0
      && this.invalidRequiredInputCount > 0) {
      this.currentGizmoStateType = GizmoStateType.NEXT;
    } else if (this.tosApproved
      && this.currentTarget?.valid
      && this.invalidRequiredInputCount <= 0
      && this.sortedTargets.length > 0
      && this.pageCount > 1
      && this.currentPage < this.pageCount - 1) {
      this.currentGizmoStateType = GizmoStateType.NEXT_PAGE;
    } else {
      this.currentGizmoStateType = GizmoStateType.HIDDEN;
    }

    if (this.currentTarget != null) {
      this.currentTarget.top = this.calculateTopOffset(this.currentTarget.targetElementId);
    }

    const oldGizmoValue = {...this.gizmoState.value};

    const pageHasOnlySignatureInput = this.targetBlockTypes.size === 1 && this.targetBlockTypes.has(BlockType.SignatureInput);

    if (oldGizmoValue.pageHasOnlySignatureInput !== pageHasOnlySignatureInput
      || oldGizmoValue.currentTarget !== this.currentTarget
      || oldGizmoValue.type !== this.currentGizmoStateType) {
      this.gizmoState.next({
        type: this.currentGizmoStateType,
        currentTarget: this.currentTarget,
        pageHasOnlySignatureInput
      });
    }

    const newNavigationState = {
      pageCount: this.pageCount,
      currentPage: this.currentPage,
      inputCount: this.sortedTargets.length,
      validRequiredInputCount: this.validRequiredInputCount,
      invalidRequiredInputCount: this.invalidRequiredInputCount,
      requiredInputCount: this.requiredInputCount
    };

    if (JSON.stringify(newNavigationState) !== JSON.stringify(this.navigationState.value)) {
      this.navigationState.next(newNavigationState);
    }
  }

  activateTarget(targetElementId: string) {
    const foundTargetIndex = this.sortedTargets.findIndex(st => st.targetElementId === targetElementId);
    if (foundTargetIndex != null) {
      this.currentTargetIndex = foundTargetIndex;
      this.currentTarget.visited = true;
      this.buildGizmoStateDebounce.next();
    }
  }

  activateFirstTargetWithin(parentElement: HTMLElement) {
    // returns the first child element within the parent element having our cute little gizmo
    const foundTargetIndex = this.sortedTargets.findIndex(targetCandidate => {
      if (targetCandidate.required) {
        return parentElement.contains(targetCandidate.targetElement);
      } else {
        return null;
      }
    });

    if (!foundTargetIndex || foundTargetIndex < 0) {
      return;
    }

    this.activateTargetIndex(foundTargetIndex);

    // after activating the first child element within the parent element having the gizmo destination directive
    // the gizmo action once, to handle focus and automatically trigger file /date selector etc
    this.gizmoAction();
  }

  private activateTargetIndex(index: number) {
    if (index != null) {
      this.currentTargetIndex = index;
      this.currentTarget.visited = true;
      this.buildGizmoStateDebounce.next();
    }
  }

  scrollToNextTarget() {
    this.advanceIndex();
    this.buildGizmoStateDebounce.next();

    this.scrollingService.scrollFinish
      .pipe(take(1))
      .subscribe(_ => {
        if (this.currentGizmoStateType !== GizmoStateType.NEXT_PAGE && this.currentGizmoStateType !== GizmoStateType.HIDDEN) {
          this.gizmoStateAfterScroll.next(this.gizmoState.value);
          this.gizmoAction(); // will highlight, too
        }
      });

    // This fixes the recursion error
    setTimeout(() => {
      this.scrollingService.scrollToTarget('session-content', this.currentTarget.targetElement, false, 'center');
    });
  }

  fireGizmoStateAfterScroll() {
    this.gizmoStateAfterScroll.next(this.gizmoState.value);
  }

  private nextPage() {
    this.currentTargetIndex = null;
    this.buildGizmoStateDebounce.next();
    this.nextPageTrigger.next();
  }

  private advanceIndex() {
    // If the currentTargetIndex is null, the gizmo is in the "start" state
    if (this.currentTargetIndex == null) {
      this.currentTargetIndex = 0;

      // Continue to 'next invalid target'-logic if the first target is valid
      if (this.sortedTargets?.[0] != null && !this.sortedTargets[0].valid) {
        return;
      }
    }

    // Go to the next invalid target
    while (this.currentTarget != null && this.currentTarget?.valid && this.currentTargetIndex < this.sortedTargets.length - 1) {
      this.currentTargetIndex += 1;
      this.currentTargetIndex %= this.sortedTargets.length;
    }
  }

  private calculateTopOffset(targetElementId: string) {
    const targetElement = this.sortedTargets.find(st => st.targetElementId === targetElementId).targetElement;
    const sessionPageRect = targetElement?.closest('app-session-page')?.getBoundingClientRect();

    if (sessionPageRect == null) {
      return 0;
    }

    const elementRect = targetElement.getBoundingClientRect();

    let offsetTop = 0;

    offsetTop -= sessionPageRect.y;
    offsetTop += elementRect.y;
    offsetTop += elementRect.height / 2;

    return offsetTop;
  }
}
