import {ApplicationPolicy} from './application_policy';
import {ProcessDefinition, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class ProcessDefinitionPolicy extends ApplicationPolicy {
  record: ProcessDefinition;

  index(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  show(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  create(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }
}
