export class BorderStyle {
  color: string;
  width: string;
  width_unit: string;
  style: string;

  constructor(v: Partial<BorderStyle> = {}) {
    Object.assign(this, v);
    this.width_unit = 'px';
  }
}
