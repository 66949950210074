import {BaseSettings, LOCALE} from '@paperlessio/sdk/api/util';
import {MailSettings} from './mail-settings';
import {SignatureModalTabs} from './signature-modal-tabs';

export class SubmittableSettings extends BaseSettings {
  mail: MailSettings;
  wizardMode: boolean;
  disabledSignatureModalTabs?: SignatureModalTabs[];
  locales?: LOCALE[];
  qes?: boolean;
  hideCreatorInAuditTrail?: boolean;
  localeOverrides?: { [locale in LOCALE]?: { [key: string]: any } };

  constructor(v: Partial<SubmittableSettings> = {}) {
    super(v);
    this.mail = new MailSettings(v.mail);
    this.wizardMode = v.wizardMode ?? false;
    this.disabledSignatureModalTabs = v.disabledSignatureModalTabs;
    this.locales = v.locales ?? [];
    this.qes = v.qes ?? false;
    this.hideCreatorInAuditTrail = v.hideCreatorInAuditTrail ?? false;
    this.localeOverrides = v.localeOverrides;
  }
}
