<a (click)="action()"
   (keydown.enter)="action()"
   id="skippy"
   tabindex="0"
   class="sr-only sr-only-focusable d-block overflow-hidden position-absolute w-100 bg-white elevation-2 py-2"
   style="z-index: 999999; outline: 1px solid transparent; top: 0;">
  <div class="container-xl">

    <div class="mb-2">
      <p>{{'base.introduction' | transloco: {name: smi.submittable.name, organization_name: smi.organization_name, page_count: smi.submittable.pageBlockCount, input_block_count: smi.submittable.inputBlockCount} }}</p>
      <p *ngIf="tosCheckbox != null">
        {{'base.introduction_tos' | transloco: {name: smi.submittable.name, organization_name: smi.organization_name, page_count: smi.submittable.pageBlockCount, input_block_count: smi.submittable.inputBlockCount} }}
      </p>
    </div>

    <span class="btn btn-light">
      {{(tosCheckbox != null ? 'base.skip_to_tos' : 'base.skip_to_content') | transloco}}
    </span>
  </div>
</a>

<app-session [brandDesign]="brandDesign"
             [submissionDesign]="submissionDesign"
             [showNavigation]="showNavigation"
             [showPrev]="!submissionConfig?.hidePrev && showPrev"
             [showNext]="!submissionConfig?.hideNext && showNext"
             [showReadOnly]="showReadOnly"
             [showApprove]="!submissionConfig?.hideApprove && showApprove"
             [showComplete]="!submissionConfig?.hideComplete && showComplete"
             [showCompleteArrow]="!submissionConfig?.hideCompleteArrow && showCompleteArrow && !disableComplete"
             [showSave]="!submissionConfig?.hideSave && showSave"
             [participantComplete]="currentParticipantCompleted"
             [submissionCompleted]="submissionCompleted"
             [mustAcceptTerms]="true"
             [termsAccepted]="!!smi?.participant?.terms_and_conditions_approved_at"
             [hasInputs]="hasInputs"
             [downloadPdfUrl]="pdf_url"
             [documentName]="smi?.submittable.name"
             [authorName]="smi?.dispatching_user?.name"
             [authorAvatarUrl]="smi?.dispatching_user?.avatarUrl"
             [additionalInfo]="submissionDesign?.submission_settings?.additionalText === 'email' ? smi?.dispatching_user?.email : smi?.dispatching_user?.position"
             [additionalButtons]="submissionConfig?.additionalButtons"
             [gizmoState]="gizmoState"
             [navigationState]="navigationState"
             (prev)="prev()"
             (next)="next()"
             (complete)="complete()"
             (start)="start($event)"
             (save)="save()">
  <form #form="ngForm">
    <router-outlet></router-outlet>
  </form>
</app-session>

<div class="sr-only" tabindex="0" (focusin)="focusActionButton()">
</div>
