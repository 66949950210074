export function totalOffsetTop(element: HTMLElement): number {
  if (!element) {
    return 0;
  }

  let top = element.offsetTop;
  let el = element;

  while ((el as HTMLElement) != null && el.offsetParent) {
    el = el.offsetParent as HTMLElement;
    if ((el as HTMLElement) != null) {
      top = top + el.offsetTop;
    }
  }

  return top;
}
