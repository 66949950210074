export interface AdditionalSubmissionHeaderButtonConfig {
  label: string;
  backgroundColor: string;
  textColor: string;
  actions: AdditionalSubmissionHeaderButtonAction[];
  loading?: boolean;
}

export const ActionTypes = ['save', 'decline', 'redirect', 'complete'] as const;
export type ActionTypes = typeof ActionTypes[number];

export interface AdditionalSubmissionHeaderButtonAction {
  actionType: ActionTypes;
  parameters?: string[];
}

export class SubmissionConfig {
  additionalButtons: AdditionalSubmissionHeaderButtonConfig[];

  hidePrev: boolean;
  hideNext: boolean;
  hideCompleteArrow: boolean;
  hideComplete: boolean;
  hideApprove: boolean;
  hideSave: boolean;

  static QueryParamName = 'submissionConfig';

  constructor(v?: Partial<SubmissionConfig>) {
    this.additionalButtons = v?.additionalButtons ?? [];
    this.hidePrev = v?.hidePrev;
    this.hideNext = v?.hideNext;
    this.hideCompleteArrow = v?.hideCompleteArrow;
    this.hideComplete = v?.hideComplete;
    this.hideApprove = v?.hideApprove;
    this.hideSave = v?.hideSave;
  }

  encode(): string {
    try {
      return btoa(JSON.stringify(this));
    } catch (e) {
      return '';
    }
  }

  static decode(value: string) {
    if (!value || value.length <= 0) {
      return null;
    }

    try {
      const v = JSON.parse(atob(value));
      return new SubmissionConfig(v);
    } catch (e) {
      return null;
    }
  }
}
