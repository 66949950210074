import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';
import {BaseServiceV1} from './base.service.v1';
import {Document, DocumentState, Email} from '@paperlessio/sdk/api/models';
import {SubmittableSerializer} from '@paperlessio/sdk/api/serializers';
import {API_BASE_PATH} from '@paperlessio/sdk/util/tokens';
import {BaseList} from '@paperlessio/sdk/api/util';

@Injectable({providedIn: 'root'})
export class SubmittableService extends BaseServiceV1<Document> {
  private URL = inject(API_BASE_PATH);

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'documents';
    this.serializer = new SubmittableSerializer<Document>(Document);
  }

  createAndDispatch(data: Document | Partial<Document>, params = {}): Observable<Document> {
    return this.http
      .post<Document>(`${this.BASE_URL + this.endpoint}`,
        (this.serializer as SubmittableSerializer<Document>).toDispatchJson(data), {params})
      .pipe(
        map(res => this.serializer.fromJson(res)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  getEmails(id: number, params): Observable<BaseList<Email>> {
    return this.http
      .get<BaseList<Email>>(`${this.URL}v0/submittables/${id}/emails`, {params})
      .pipe(
        map(res => {
          res.data = res.data.map(obj => new SubmittableSerializer<Email>(Email, 'email').fromJson(obj));
          return res;
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  dispatch(id: number) {
    return this.http
      .put<Document>(`${this.BASE_URL_V1 + this.endpoint}/${id}`, {state: DocumentState.dispatched})
      .pipe(
        filter(x => !!x),
        map(x => new SubmittableSerializer<Document>(Document).fromJson(x)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  launch(id: number) {
    return this.http
      .post<Document>(`${this.BASE_URL + this.endpoint}/${id}/launch`, {})
      .pipe(
        filter(x => !!x),
        map(x => new SubmittableSerializer<Document>(Document, 'document').fromJson(x)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  archive(id: number) {
    return this.update(id, {archived_at: new Date()})
      .pipe(
        filter(x => !!x),
        map(x => new SubmittableSerializer<Document>(Document, 'document').fromJson(x)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  cancel(id: number) {
    return this.http
      .put<Document>(`${this.BASE_URL_V1 + this.endpoint}/${id}`, {state: DocumentState.cancelled})
      .pipe(
        filter(x => !!x),
        map(x => new SubmittableSerializer<Document>(Document).fromJson(x)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }
}
