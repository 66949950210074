import {SuccessPageMode} from './success-page-mode';

export class SuccessPageSettings {
  mode: SuccessPageMode;
  redirect_url: string;
  header: string;
  content: string;
  allow_pdf_download: boolean;
  show_page_background: boolean;
  show_paperless_ad: boolean;

  constructor(v: Partial<SuccessPageSettings> = {}) {
    this.mode = v.mode ?? SuccessPageMode.Default;
    this.redirect_url = v.redirect_url ?? '';
    this.header = v.header ?? '';
    this.content = v.content ?? '';
    this.allow_pdf_download = v.allow_pdf_download ?? true;
    this.show_page_background = v.show_page_background ?? true;
    this.show_paperless_ad = v.show_paperless_ad ?? true;
  }
}
