import {ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {SessionStore} from '../../session.store';
import {WINDOW} from '@paperlessio/sdk/util/helpers';
import {BlockStore} from '@blocks/block/block.store';
import {debounceTime, filter} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {
  ParticipationSubmission,
  Registration,
  Submission,
  SubmissionDesign,
  SubmissionMetaInformation,
  SuccessPageMode
} from '@paperlessio/sdk/api/models';
import {TranslocoService} from '@ngneat/transloco';
import {environment} from '@environment';
import {combineLatest, Subscription} from 'rxjs';
import {SubmissionData} from '../../../../../../render/src/app/submission-render/submission-data';

@Component({
  selector: 'app-session-success',
  templateUrl: './session-success.component.html',
  styleUrls: ['./session-success.component.scss']
})
export class SessionSuccessComponent implements OnInit, OnDestroy {
  @Input() submissionDesign: SubmissionDesign;

  SuccessPageMode = SuccessPageMode;

  loading = true;
  refreshes = 0;

  pdfReady = false;
  waitingForParticipants: boolean;
  submissionMetaInformation: SubmissionMetaInformation;
  submission: Submission | ParticipationSubmission | SubmissionData;
  registration: Registration;

  public blockStore = inject(BlockStore);
  public sanitizer = inject(DomSanitizer);
  private sessionStore = inject(SessionStore);
  private locale = inject(TranslocoService);
  private cdr = inject(ChangeDetectorRef);
  private window = inject(WINDOW);

  private subs = new Subscription();

  ngOnInit() {
    this.subs.add(
      combineLatest([
        this.sessionStore.submissionMetaInformation.pipe(
          filter(x => !!x),
          debounceTime(200)
        ),
        this.sessionStore.fetchSubmission().pipe(
          filter(x => !!x),
          debounceTime(200)
        )
      ])
        .subscribe(async ([smi, submission]) => {
          this.submissionMetaInformation = smi;
          this.submissionDesign = smi.submittable?.designs?.submission;
          this.waitingForParticipants = submission.completed_at == null
            && smi.participant?.state === 'completed';

          // redirect mechanism
          if (this.submissionDesign?.submission_settings?.success_page?.mode === SuccessPageMode.Redirect
          && this.submissionDesign?.submission_settings?.success_page.redirect_url) {
            this.window.location.replace(this.submissionDesign.submission_settings.success_page.redirect_url);
          }

          if (!this.waitingForParticipants && smi.submittable?.designs?.submission?.submission_settings?.success_page?.allow_pdf_download) {
            setTimeout(async () => await this.pollForUpdates(), 1000);
          }

          this.loading = this.submission == null || this.submissionMetaInformation == null;
          this.cdr.markForCheck();
        }));

    this.subs.add(this.sessionStore.submission.subscribe(submission => {
      this.submission = submission;
      this.pdfReady = 'pdf' in submission && !!submission?.pdf?.url;

      this.loading = this.submission == null || this.submissionMetaInformation == null;
      this.cdr.markForCheck();
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async pollForUpdates() {
    if (this.refreshes > 10 || this.pdfReady) {
      return;
    }

    this.refreshes += 1;

    const submission = await this.sessionStore.fetchSubmission().toPromise().catch(() => null);
    this.pdfReady = 'pdf' in submission && !!submission?.pdf?.url;

    if (!this.pdfReady) {
      setTimeout(async () => await this.pollForUpdates(), this.refreshes * 1000 + 1000);
    }

    this.cdr.markForCheck();
  }

  signup(email: string) {
    const queryParams = {
      email,
      locale: this.locale.getActiveLang(),
      force: true
    };

    const url = new URL(`${environment.deployUrl}/signup/password`);
    url.searchParams.set('email', email);
    url.searchParams.set('locale', this.locale.getActiveLang());
    url.searchParams.set('force', 'true');

    this.window.location.href = url.toString();
  }
}
