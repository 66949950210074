export class CalculatedTokenValue {
  id: number;
  submission_id: number;
  token_id: number;
  token_name: string;
  value: any;
  created_at: Date;
  updated_at: Date;

  constructor(v: Partial<CalculatedTokenValue>) {
    this.id = v.id;
    this.submission_id = v.submission_id;
    this.token_id = v.token_id;
    this.token_name = v.token_name;
    this.value = v.value;
    this.created_at = v.created_at ? new Date(v.created_at) : null;
    this.updated_at = v.updated_at ? new Date(v.updated_at) : null;
  }
}
