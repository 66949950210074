import {
  Organization,
  OrganizationMembershipRole,
  User,
  Workspace,
  WorkspaceMembership,
  WorkspaceMembershipRole
} from '@paperlessio/sdk/api/models';
import {Integration, IntegrationOwnerTypes} from '@management/integrations/integration';

export class ApplicationPolicy {
  user: User;
  roles: RoleMap;
  record: any;

  constructor(user: User, roles: RoleMap, record: any) {
    this.user = user;
    this.roles = roles;
    this.record = record;
  }

  index(): boolean {
    return false;
  }

  show(): boolean {
    return false;
  }

  create(): boolean {
    return false;
  }

  update(): boolean {
    return false;
  }

  destroy(): boolean {
    return false;
  }

  protected get workspace_membership() {
    let workspace_id = -1;
    switch (this.record.constructor) {
      case Workspace: workspace_id = +this.record.id; break;
      default: workspace_id = +this.record.workspace_id;
    }
    const role = this.roles.workspaces[workspace_id];
    return new MembershipMock(role);
  }

  protected get organization_membership() {
    let organization_id = -1;
    switch (this.record.constructor) {
      case Integration: organization_id = this.record.owner_type === IntegrationOwnerTypes.ORGANIZATION ? this.record.owner_id : -1; break;
      case Organization: organization_id = this.record.id ? this.record.id : -1; break;
      case WorkspaceMembership: organization_id = this.record.workspace?.organization_id ? this.record.workspace?.organization_id : -1; break;
      default: organization_id = +this.record.organization_id;
    }
    const role = this.roles.organizations[organization_id];
    return new MembershipMock(role);
  }
}

class MembershipMock {
  role: WorkspaceMembershipRole | OrganizationMembershipRole;

  constructor(role = null) {
    this.role = role;
  }

  at_least(role: WorkspaceMembershipRole | OrganizationMembershipRole): boolean {
    switch (role) {
      case WorkspaceMembershipRole.owner:
      case OrganizationMembershipRole.owner:
        return this.role === WorkspaceMembershipRole.owner || this.role === OrganizationMembershipRole.owner;
      case WorkspaceMembershipRole.admin:
      case OrganizationMembershipRole.admin:
        return this.role === WorkspaceMembershipRole.owner
          || this.role === OrganizationMembershipRole.owner
          || this.role === WorkspaceMembershipRole.admin
          || this.role === OrganizationMembershipRole.admin;
      case WorkspaceMembershipRole.sender:
        return this.role === WorkspaceMembershipRole.owner
          || this.role === OrganizationMembershipRole.owner
          || this.role === WorkspaceMembershipRole.admin
          || this.role === OrganizationMembershipRole.admin
          || this.role === WorkspaceMembershipRole.sender;
      case WorkspaceMembershipRole.guest:
        return this.role === WorkspaceMembershipRole.owner
          || this.role === OrganizationMembershipRole.owner
          || this.role === WorkspaceMembershipRole.admin
          || this.role === OrganizationMembershipRole.admin
          || this.role === WorkspaceMembershipRole.sender
          || this.role === WorkspaceMembershipRole.guest;
      case OrganizationMembershipRole.user:
        return this.role === OrganizationMembershipRole.owner
          || this.role === OrganizationMembershipRole.admin
          || this.role === WorkspaceMembershipRole.guest;
      default:
        return false;
    }
  }
}

export class RoleMap {
  workspaces: {number?: number} = {};
  organizations: {number?: string} = {};
}
