import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingContainerDirective } from './scrolling-container.directive';
import { ScrollingService } from './scrolling.service';



@NgModule({
  declarations: [
    ScrollingContainerDirective
  ],
  exports: [
    ScrollingContainerDirective
  ],
  imports: [
    CommonModule
  ],
  providers: [
    ScrollingService
  ]
})
export class ScrollingModule { }
