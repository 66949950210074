export class MailSettings {
  sender_name: string;
  reply_to_email;
  subject: string;
  content: string;
  signature: string;

  constructor(v: Partial<MailSettings> = {}) {
    Object.assign(this, v);
  }
}
