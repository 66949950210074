import {Expandable, RenderingLocale} from '@paperlessio/sdk/api/util';
import {ListColumn, ListColumnSize, ListColumnType} from './column';
import {Page} from './blocks/page';
import {User} from './user';
import {Participant} from './participant';
import {CalculatedBlockAttribute} from './calculated-block-attribute';
import {CalculatedTokenValue} from './calculated-token-value';
import {Submittable} from './submittable';
import {ParticipationFlow} from './participation-flow';
import {BucketType} from './bucket';
import {FormVersion} from './form-version';
import {DownloadableSubmitEventValue, SubmitEventValue} from './submit-event-value';
import {BlockType} from './blocks/block-type';
import {Blob} from './blob';
import {Document} from './document';
import {excluded} from './util/excluded';

export class Submission {
  static expandable = new Expandable<Submission,
    'submittable' |
    'participation_flow' |
    'participants' |
    'block_owner_mapping' |
    'aggregated_submit_event_values' |
    'aggregated_calculated_token_values' |
    'aggregated_calculated_block_attributes'
  >();

  static columns: ListColumn[] = [
    new ListColumn({property: 'name', type: ListColumnType.default, align: 'left', fixed: true, size: ListColumnSize.col03}),
    new ListColumn({property: 'state', type: ListColumnType.badge, align: 'left', default: true}),
    new ListColumn({property: 'created_at', type: ListColumnType.timeAgo, align: 'left', default: true}),
    new ListColumn({property: 'updated_at', type: ListColumnType.timeAgo, align: 'left'}),
    new ListColumn({property: 'completed_at', type: ListColumnType.timeAgo, align: 'left', default: true}),
    new ListColumn({property: 'revision_number', type: ListColumnType.default, align: 'left', size: ListColumnSize.col01})
  ];
  static defaultColumns = Submission.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  id: number;
  workspace_id: number;
  submittable_id: number;
  organization_id: number;
  pages: Page[];
  creator_id: number;
  creator: User;
  participant_id: number;
  participant: Participant;
  state: SubmissionState;
  token: string;
  url: string;
  pdf: Blob;
  sealed_pdf: Blob;
  audit_trail_pdf: Blob;
  data: object;
  aggregated_submit_event_values: SubmitEventValue[];
  aggregated_calculated_block_attributes: CalculatedBlockAttribute[];
  aggregated_calculated_token_values: CalculatedTokenValue[];
  values: {[slug: string]: SubmitEventValue} = {};
  rendering_locale: RenderingLocale;
  meta: object;
  email_sent_at: Date;
  last_email_open_at: Date;
  last_email_click_at: Date;
  dispatched_at: Date;
  completed_at: Date;
  created_at: Date;
  updated_at: Date;
  expires_at: Date;
  expired_at: Date;

  @excluded submittable?: Submittable;
  @excluded participation_flow?: ParticipationFlow;
  @excluded participants?: Participant[];
  @excluded dispatching_user?: User;
  @excluded block_owner_mapping: {[id: number]: number[]};

  // tslint:disable-next-line:variable-name
  private _icon: string;

  constructor(v: Partial<Submission> = {}) {
    Object.assign(this, v);
    this.pages = v.pages ? v.pages.map(p => new Page(p)) : [];
    this.creator = new User(v.creator);
    this.participant = new Participant(v.participant);
    this.pdf = new Blob(v.pdf);
    this.sealed_pdf = new Blob(v.sealed_pdf);
    this.audit_trail_pdf = new Blob(v.audit_trail_pdf);
    this.data = v.data ?? {};
    this.aggregated_submit_event_values = v.aggregated_submit_event_values ? v.aggregated_submit_event_values : [];

    this.aggregated_submit_event_values = this.aggregated_submit_event_values.map((el: SubmitEventValue) => {
      el = new SubmitEventValue(el);
      this.values[el.slug] = el;
      return el;
    });

    this.aggregated_calculated_block_attributes = v.aggregated_calculated_block_attributes ? v.aggregated_calculated_block_attributes : [];
    this.aggregated_calculated_block_attributes.map((el: CalculatedBlockAttribute) => new CalculatedBlockAttribute(el));
    this.aggregated_calculated_token_values = v.aggregated_calculated_token_values ? v.aggregated_calculated_token_values : [];
    this.aggregated_calculated_token_values.map((el: CalculatedTokenValue) => new CalculatedTokenValue(el));

    this.meta = v.meta ?? {};
    this.email_sent_at = v.email_sent_at ? new Date(v.email_sent_at) : undefined;
    this.last_email_open_at = v.last_email_open_at ? new Date(v.last_email_open_at) : undefined;
    this.last_email_click_at = v.last_email_click_at ? new Date(v.last_email_click_at) : undefined;
    this.dispatched_at = v.dispatched_at ? new Date(v.dispatched_at) : undefined;
    this.completed_at = v.completed_at ? new Date(v.completed_at) : undefined;
    this.created_at = v.created_at ? new Date(v.created_at) : undefined;
    this.updated_at = v.updated_at ? new Date(v.updated_at) : undefined;
    this.expires_at = v.expires_at ? new Date(v.expires_at) : undefined;
    this.expired_at = v.expired_at ? new Date(v.expired_at) : undefined;

    if (v.submittable) {
      this.submittable = v.submittable.type === BucketType.FormVersion ? new FormVersion(v.submittable as FormVersion) : new Document(v.submittable);
    }

    this.participation_flow = v?.participation_flow != null ? new ParticipationFlow(v?.participation_flow) : null;
    this.participants = v?.participants != null ? v.participants.map(p => new Participant(p)) : [];

    this.dispatching_user = new User(v.dispatching_user);
    this.block_owner_mapping = v.block_owner_mapping;

    this.rendering_locale = v.rendering_locale;
  }

  get name(): string {
    if (this.submittable?.type === BucketType.Document) {
      return (this.submittable as Document).name;
    } else if (this.participants?.length === 1) {
      return this.participants[0].name;
    } else if (this.participants?.length > 1) {
      return this.participants
        .filter(p => p.provided_by_form_requester)
        .map(p => p.name).join(', ');
    } else if (this.participation_flow?.participantNodes?.length === 1) {
      return this.participation_flow.participantNodes[0].participant?.name ?? '';
    } else if (this.participation_flow?.participantNodes?.length > 1) {
      return this.participation_flow.participantNodes
        .filter(p => p.participant?.provided_by_form_requester)
        .map(p => p.participant?.name)
        .join(', ');
    } else {
      return this.submittable?.name ?? `Submission ${this.id}`;
    }
  }

  get revision_number(): number {
    return this.submittable.type === BucketType.FormVersion ? (this.submittable as FormVersion).revision_number : null;
  }

  downloadableSubmitEventValues(participationFlow: ParticipationFlow): DownloadableSubmitEventValue[] {
    let values = [];

    this.aggregated_submit_event_values.forEach((el: SubmitEventValue) => {
      const val = el.toDownloadableSubmitEventValue(participationFlow);
      values = [...values, ...val];
    });

    return values;
  }

  public submitEventValueForSlug(slug: string): SubmitEventValue {
    return this.values?.[slug];
  }
}

export class EnhancedSubmissionDatum {
  id: number;
  type: BlockType;
  name: string;
  slug: string;
  value: any;

  constructor(v: Partial<EnhancedSubmissionDatum>) {
    Object.assign(this, v);
  }
}

export enum SubmissionState {
  draft = 'draft',
  dispatched = 'dispatched',
  completed = 'completed',
  cancelled = 'cancelled',
  declined = 'declined',
  expired = 'expired'
}
