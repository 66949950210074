import {Block, BlockSettings, BlockStyles} from './block';
import {FlexAlign} from './flex-align';
import {FlexJustify} from './flex-justify';

export class Button extends Block {
  settings: ButtonSettings;
  styles: ButtonStyles;

  constructor(v: Partial<Button> = {}) {
    super(v);
    this.settings = new ButtonSettings(v.settings ?? {});
    this.styles = new ButtonStyles(v.styles ?? {});
  }

  private get sizeClass(): string {
    return this.styles.size ? this.styles.size : '';
  }

  private get blockClass(): string {
    return this.styles.block ? 'btn-block' : '';
  }

  get classes(): string {
    return `btn ${this.sizeClass} ${this.blockClass}`;
  }
}

export class ButtonStyles extends BlockStyles {
  background: string;
  fontcolor: string;
  size: ButtonSize;
  align: FlexAlign;
  justify: FlexJustify;
  outline;
  block;

  constructor(v: Partial<ButtonStyles> = {}) {
    super(v);
    this.background = v.background ?? '#000000';
    this.fontcolor = v.fontcolor ?? '#FFFFFF';
    this.size = v.size ?? ButtonSize.default;
    this.align = v.align ?? FlexAlign.left;
    this.justify = v.justify ?? FlexJustify.start;
    this.outline = v.outline ?? false;
    this.block = v.block ?? false;
  }
}

export enum ButtonSize {
  default = '',
  small = 'btn-sm',
  large = 'btn-lg'
}

export class ButtonSettings extends BlockSettings {

  constructor(v: Partial<ButtonSettings> = {}) {
    super(v);
  }
}
