import {BlockType} from '../blocks/block-type';
import {Page} from '../blocks/page';
import {PdfPart} from '../blocks/pdf-part';
import {RichText} from '../blocks/rich-text';
import {FileDownload} from '../blocks/file-download';
import {Image} from '../blocks/image';
import {Video} from '../blocks/video';
import {Spacer} from '../blocks/spacer';
import {Divider} from '../blocks/divider';
import {Table} from '../blocks/table';
import {TableRow} from '../blocks/table-row';
import {TableCell} from '../blocks/table-cell';
import {TextInput} from '../blocks/text.input';
import {SelectInput} from '../blocks/select.input';
import {SelectOption} from '../blocks/select-option';
import {CheckboxInput} from '../blocks/checkbox-input';
import {RadioInput} from '../blocks/radio.input';
import {FileUploadInput} from '../blocks/file-upload.input';
import {SecureFileUploadInput} from '../blocks/secure-file-upload.input';
import {SignatureInput} from '../blocks/signature.input';
import {BankAccountInput} from '../blocks/bank-account-input';
import {DateInput} from '../blocks/date-input';
import {DataSetInput} from '../blocks/data-set-input';
import {Attachment} from '../blocks/attachment';
import {Group} from '../blocks/group';
import {Row} from '../blocks/row';
import {Column} from '../blocks/column';
import {Button} from '../blocks/button';
import {Html} from '../blocks/html';
import {PageBreak} from '../blocks/page-break';
import {Block, BlockStyles, BlockWidth} from '../blocks/block';

export function parseBlock(json: any): Block {
  if (json.styles) {
    json.styles = new BlockStyles(json.styles);
  }

  if (json.styles?.width) {
    json.styles.width = new BlockWidth(json.styles.width);
  }

  switch (json.type) {
    case BlockType.Page:
      return new Page(json);
    case BlockType.PdfPart:
      return new PdfPart(json);
    case BlockType.RichText:
      return new RichText(json);
    case BlockType.FileDownload:
      return new FileDownload(json);
    case BlockType.Image:
      return new Image(json);
    case BlockType.Video:
      return new Video(json);
    case BlockType.Spacer:
      return new Spacer(json);
    case BlockType.Divider:
      return new Divider(json);
    case BlockType.Table:
      return new Table(json);
    case BlockType.TableRow:
      return new TableRow(json);
    case BlockType.TableCell:
      return new TableCell(json);
    case BlockType.TextInput:
      return new TextInput(json);
    case BlockType.SelectInput:
      return new SelectInput(json);
    case BlockType.SelectOption:
      return new SelectOption(json);
    case BlockType.CheckboxInput:
      return new CheckboxInput(json);
    case BlockType.RadioInput:
      return new RadioInput(json);
    case BlockType.FileUploadInput:
      return new FileUploadInput(json);
    case BlockType.SecureFileUploadInput:
      return new SecureFileUploadInput(json);
    case BlockType.SignatureInput:
      return new SignatureInput(json);
    case BlockType.BankAccount:
      return new BankAccountInput(json);
    case BlockType.DateInput:
      return new DateInput(json);
    case BlockType.DataSetInput:
      return new DataSetInput(json);
    case BlockType.Attachment:
      return new Attachment(json);
    case BlockType.Group:
      return new Group(json);
    case BlockType.Row:
      return new Row(json);
    case BlockType.Column:
      return new Column(json);
    case BlockType.Button:
      return new Button(json);
    case BlockType.Html:
      return new Html(json);
    case BlockType.PageBreak:
      return new PageBreak(json);
    default:
      return new Block(json);
  }
}
