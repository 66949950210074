import {Block, BlockSettings, BlockStyles, BlockWidth} from './block';
import {FlexAlign} from './flex-align';
import {Blob} from '../blob';

export class Image extends Block {
  file: Blob;
  aspect_ratio?: number;
  progress = -1;

  settings: ImageSettings;
  styles: ImageStyles;

  constructor(v: Partial<Image> = {}) {
    super(v);
    this.file = new Blob(v.file);
    this.settings = new ImageSettings(v.settings ?? {});
    this.styles = new ImageStyles(v.styles ?? {});
    this.calculateAspectRatio();
  }

  reset() {
    this.file = new Blob();
    this.settings.localFileUrl = undefined;
  }

  calculateAspectRatio(forceNaturalDimensions = false) {
    if (!this.file.default_url && !forceNaturalDimensions) {
      this.aspect_ratio = null; // reset if attached image was removed
    } else if (this.file?.metadata?.height && this.file?.metadata?.width && !forceNaturalDimensions) {
      // dimensions from file (as analyzed by backend) take precedence over local file, UNLESS a localFileUrl (i.e. currently uploading a
      // new file) is present
      this.aspect_ratio = (this.file.metadata.height/this.file.metadata.width) * 100;
    } else if (this.settings.naturalWidth && this.settings.naturalHeight) {
      this.aspect_ratio = (this.settings.naturalHeight/this.settings.naturalWidth) * 100;
    } else {
      this.aspect_ratio = null;
    }
  }
}

export class ImageStyles extends BlockStyles {
  align: FlexAlign;
  border: ImageBorder = new ImageBorder();
  width = new BlockWidth();
  border_radius: number;

  constructor(v: Partial<ImageStyles> = {}) {
    super(v);
    this.align = v.align ?? FlexAlign.center;
    this.border = new ImageBorder(v.border);
    this.width = new BlockWidth(v.width ?? {value: 940, unit: 'pixel'});
    this.border_radius = v.border_radius ?? 0;
  }
}

export class ImageBorder {
  active = false;
  width = 5;
  color = '#000000';

  constructor(v: Partial<ImageBorder> = {}) {
    this.active = v.active ?? false;
    this.width = v.width ?? 5;
    this.color = v.color ?? '#000000';
  }
}

export class ImageSettings extends BlockSettings {
  naturalHeight: number;
  naturalWidth: number;
  localFileUrl: string;
  altText: string;

  constructor(v: Partial<ImageSettings> = {}) {
    super(v);
    this.naturalHeight = v.naturalHeight ?? 0;
    this.naturalWidth = v.naturalWidth ?? 0;
    this.localFileUrl = v.localFileUrl;
    this.altText = v.altText;
  }
}
