import {Addon} from './addon';

export class WorkspaceAddon {
  constructor(v?: Partial<WorkspaceAddon>) {
    this.id = v?.id ?? 0;
    this.workspace_id = v?.workspace_id ?? 0;
    this.addon = new Addon(v?.addon);
    this.created_at = v?.created_at ? new Date(v.created_at) : new Date();
    this.updated_at = v?.updated_at ? new Date(v.updated_at) : new Date();
  }

  id: number;
  workspace_id: number;
  addon: Addon;
  created_at: Date;
  updated_at: Date;
}
