import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseModalComponent} from './base-modal.component';
import {BaseModalService} from './base-modal.service';
import {A11yModule} from '@angular/cdk/a11y';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [BaseModalComponent],
  exports: [
    BaseModalComponent
  ],
  imports: [
    CommonModule,
    A11yModule,
    TranslocoModule
  ]
})
export class BaseModalModule {
  static forRoot(): ModuleWithProviders<BaseModalModule> {
    return {
      ngModule: BaseModalModule,
      providers: [
        BaseModalService
      ]
    };
  }
}
