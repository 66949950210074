import {DestroyRef, inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {
  BucketChannelEvents,
  BucketChannelSubscriber,
  ChannelActions,
  ChannelService,
  EditorsChangedItem,
  SelectedBlocksChangedItem
} from '@paperlessio/sdk/realtime/channel';
import {BlockMutationResult, BucketType, ParticipationFlowMutationResult, SubscribedUser, Token} from '@paperlessio/sdk/api/models';
import {WEBSOCKET_CONFIG} from '@paperlessio/sdk/util/tokens';

@Injectable({providedIn: 'root'})
export class BucketChannelService {
  public readonly blocksChangedSubject: BehaviorSubject<BlockMutationResult> = new BehaviorSubject<BlockMutationResult>(null);
  public readonly editorsChangedSubject: BehaviorSubject<SubscribedUser[]> = new BehaviorSubject<SubscribedUser[]>(null);
  public readonly selectedBlocksChangedSubject: BehaviorSubject<SelectedBlocksChangedItem[]> = new BehaviorSubject<SelectedBlocksChangedItem[]>(null);
  public readonly tokensChangedSubject: BehaviorSubject<Token[]> = new BehaviorSubject<Token[]>(null);
  public readonly participantsChangedSubject: BehaviorSubject<ParticipationFlowMutationResult> = new BehaviorSubject<ParticipationFlowMutationResult>(null);

  private bucketChannelSubscriber: BucketChannelSubscriber;

  get subscription_uuid() {
    return this.channelService.subscription_uuid;
  }

  private websocketConfig = inject(WEBSOCKET_CONFIG);
  private channelService = inject(ChannelService);

  sendSelectedBlocks(selectedBlocks: number[]): boolean {
    if (this.bucketChannelSubscriber?.identifier) {
      const data = {selected_block_ids: selectedBlocks};
      return this.channelService.send(this.bucketChannelSubscriber.identifier, ChannelActions.blocks_selected, data);
    }
    return false;
  }

  subscribe(bucketType: BucketType, bucketId: number) {
    return this.channelService.subscribe(this.createBucketChannelSubscriber(bucketType, bucketId));
  }

  subscribeUntil(bucketType: BucketType, bucketId: number, destroyRef: DestroyRef) {
    destroyRef.onDestroy(() => {
      this.unsubscribe();
    });

    return this.channelService.subscribe(this.createBucketChannelSubscriber(bucketType, bucketId));
  }

  unsubscribe() {
    this.channelService.unsubscribe(this.bucketChannelSubscriber);
    this.editorsChangedSubject.next([]);
  }

  private onBlocksChangedEvent = (blockMutationResult: BlockMutationResult) =>
    this.blocksChangedSubject.next(blockMutationResult);

  private onEditorsChangedEvent = (editorsChangedItems: EditorsChangedItem[]) => {
    const subscribedUsers: SubscribedUser[] =
      editorsChangedItems
        .filter((editorsChangedItem: EditorsChangedItem) =>
          editorsChangedItem.subscription_uuid !== this.channelService.subscription_uuid)
        .map((editorsChangedItem: EditorsChangedItem, index: number) =>
          new SubscribedUser(editorsChangedItem.user)
            .setSubscriptionUuid(editorsChangedItem.subscription_uuid)
            .setColorIndex(index % 9)
        );

    this.editorsChangedSubject.next(subscribedUsers);
  };

  private onSelectedBlocksChangedEvent = (selectedBlocksChangedItem: SelectedBlocksChangedItem[]) =>
    this.selectedBlocksChangedSubject.next([...selectedBlocksChangedItem]);

  private onTokensChangedEvent = (tokens: Token[]) =>
    this.tokensChangedSubject.next(tokens);

  private onParticipationFlowChangedEvent = (participationFlowMutationResult: ParticipationFlowMutationResult) =>
    this.participantsChangedSubject.next(participationFlowMutationResult);

  private createBucketChannelSubscriber(bucket_type: BucketType, bucket_id: number): BucketChannelSubscriber {
    this.bucketChannelSubscriber = {
      identifier: {
        channel: this.websocketConfig.channel.bucketChannel,
        bucket_type,
        bucket_id,
      },
      receiver: {
        [BucketChannelEvents.BlocksChangedEvent]: this.onBlocksChangedEvent,
        [BucketChannelEvents.EditorsChangedEvent]: this.onEditorsChangedEvent,
        [BucketChannelEvents.SelectedBlocksChangedEvent]: this.onSelectedBlocksChangedEvent,
        [BucketChannelEvents.TokensChangedEvent]: this.onTokensChangedEvent,
        [BucketChannelEvents.ParticipationFlowChangedEvent]: this.onParticipationFlowChangedEvent,
      }
    };
    return this.bucketChannelSubscriber;
  }
}
