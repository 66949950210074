import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SessionStore} from '../../../session.store';
import {ParticipantRole, ParticipantState, SubmissionMetaInformation, Submittable} from '@paperlessio/sdk/api/models';
import {filter, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-other-actions',
  templateUrl: './other-actions.component.html',
  styleUrls: ['./other-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherActionsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private sessionStore: SessionStore,
    private cdr: ChangeDetectorRef
  ) {}

  showDeclineButton = false;
  participantIsViewer = false;
  invalidParticipantState = false;
  submittable: Submittable;

  token = '';

  ngOnInit(): void {
    this.token = this.route.snapshot.params.token || '';

    this.sessionStore.tosApproved
      .pipe(
        switchMap(_ => this.sessionStore.submissionMetaInformation),
        filter(pmi => !!pmi)
      )
      .subscribe(pmi => {
        this.setSubmissionMetaInformation(pmi);
      });
  }

  private setSubmissionMetaInformation(smi: SubmissionMetaInformation) {
    this.submittable = smi.submittable;

    this.invalidParticipantState =
      smi.participant.state === ParticipantState.disabled ||
      smi.participant.state === ParticipantState.completed ||
      smi.participant.state === ParticipantState.skipped;

    this.participantIsViewer = smi.participant.role === ParticipantRole.viewer;

    this.showDeclineButton = smi.participant.role === ParticipantRole.approver || smi.participant.role === ParticipantRole.default;

    this.cdr.markForCheck();
  }
}
