import {Expandable} from '@paperlessio/sdk/api/util';
import {Submission} from './submission';

export class Bundle {
  static expandable = new Expandable<Bundle, 'submissions' | 'submissions.participants' | 'submissions.submittable' | 'submissions.participation_flow'>();

  id: number;
  workspace_id: number;
  name: string;
  submissions: Submission[];
  updated_at: Date;
  created_at: Date;

  constructor(v: Partial<Bundle> = {}) {
    this.id = v.id || null;
    this.workspace_id = v.workspace_id || null;
    this.name = v.name || '';
    this.submissions = v.submissions?.map(s => new Submission(s)) || [];
    this.updated_at = v.updated_at ? new Date(v.updated_at) : null;
    this.created_at = v.created_at ? new Date(v.created_at) : null;
  }
}
