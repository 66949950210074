import {BehaviorSubject, Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {SessionFormService} from './session-form.service';
import {FormMetaInformation} from './form-meta-information';
import {switchMap, tap} from 'rxjs/operators';
import {FormRequestParams} from './form-request-params';

@Injectable({providedIn: 'root'})
export class SessionFormStore {
  formMetaInformation: BehaviorSubject<FormMetaInformation> = new BehaviorSubject<FormMetaInformation>(null);
  currentToken: string;

  private formService = inject(SessionFormService);

  init(form_public_token: string): Observable<FormMetaInformation> {
    if (this.currentToken === form_public_token) {
      return this.formMetaInformation.asObservable();
    }

    return this.formService
      .getFormMetaInformation(form_public_token)
      .pipe(
        tap(fmi => {
          this.formMetaInformation.next(fmi);
          this.currentToken = form_public_token;
        }),
        switchMap(x => this.formMetaInformation.asObservable())
      );
  }

  createFormRequest(formRequestParams: FormRequestParams) {
    return this.formService.createFormRequest(formRequestParams);
  }

  reset() {
    this.formMetaInformation.next(null);
    this.currentToken = undefined;
  }
}
