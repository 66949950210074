export class NotificationSettings {
  notificationLevel: NotificationLevel | SpecialNotificationLevel = NotificationLevel.important;
  ownActivity = false;
  custom = {};

  constructor(v: Partial<NotificationSettings> = {}) {
    Object.assign(this, v);
    this.notificationLevel = v.notificationLevel ?? NotificationLevel.important;
  }

  setDefaultsForCustomEvents() {
    this.custom = Object.keys(NotificationEvent)
      .reduce((x, event) => Object.assign(x, {[event]: NotificationState.off}), {});
  }
}

export enum NotificationLevel {
  watch = 'watch',
  important = 'important',
  disabled = 'disabled'
}

export enum SpecialNotificationLevel {
  global = 'global',
  custom = 'custom'
}

export enum NotificationEvent {
  document_dispatched = 'document_dispatched',
  document_opened = 'document_opened',
  document_completed = 'document_completed',
}

export enum NotificationState {
  off = 'off',
  mine = 'mine',
  all = 'all'
}
