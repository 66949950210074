import {Submission} from './submission';
import {Form} from './form';
import {FormVersion} from './form-version';
import {ProcessRun} from './process-run';
import {ProcessDefinition} from './process-definition';
import {Partial as GroupPartial} from './partial';
import {Document} from './document';
import {IncinerationOrder} from './incineration-order';

export class WorkspaceMembershipSettings {
  templates = {columns: []};
  submissions = {columns: Submission.defaultColumns};
  documents = {columns: Document.defaultColumns};
  forms = {columns: Form.defaultColumns};
  form_versions = {columns: FormVersion.defaultColumns};
  partials = {columns: GroupPartial.defaultColumns};
  events = {highlightFilter: ''};
  form_submissions = {columns: Submission.defaultColumns};
  process_run_submissions = {columns: Submission.defaultColumns};
  process_runs = {columns: ProcessRun.defaultColumns};
  process_definitions: any = {columns: ProcessDefinition.defaultColumns};
  process_definition_versions: any = {columns: ProcessDefinition.defaultColumns};
  incineration_orders: any = {columns: IncinerationOrder.defaultColumns};

  constructor(v: Partial<WorkspaceMembershipSettings> = {}) {
    Object.assign(this, v);
    this.templates = v.templates ?? {columns: []};
    this.submissions = v.submissions ?? {columns: Submission.defaultColumns};
    this.documents = v.documents ?? {columns: Document.defaultColumns};
    this.forms = v.forms ?? {columns: Form.defaultColumns};
    this.form_versions = v.form_versions ?? {columns: FormVersion.defaultColumns};
    this.partials = v.partials ?? {columns: GroupPartial.defaultColumns};
    this.events = v.events ?? {highlightFilter: null};
    this.form_submissions = v.form_submissions ?? {columns: Submission.defaultColumns};
    this.process_run_submissions = v.process_run_submissions ?? {columns: Submission.defaultColumns};
    this.process_runs = v.process_runs ?? {columns: ProcessRun.defaultColumns};
    this.process_definitions = v.process_definitions ?? {columns: ProcessDefinition.defaultColumns};
    this.process_definition_versions = v.process_definition_versions ?? {columns: ProcessDefinition.defaultColumns};
    this.incineration_orders = v.incineration_orders ?? {columns: IncinerationOrder.defaultColumns};
  }
}
