// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';

export class SetProcessRunCustomDataNode extends ProcessFlowBaseNode {
  constructor(v: Partial<SetProcessRunCustomDataNode>) {
    super(v);
    this.type = 'ProcessFlow::SetProcessRunCustomDataNode';
    this.settings = new SetProcessRunCustomDataNodeSettings(v.settings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new SetProcessRunCustomDataNodeSettings(v.recalculated_settings);
    }
  }

  settings: SetProcessRunCustomDataNodeSettings;
  recalculated_settings: SetProcessRunCustomDataNodeSettings;
}

export class SetProcessRunCustomDataNodeSettings extends ProcessFlowBaseNodeSettings {
  custom_data_calculation_type: RexCalculationType;
  custom_data_calculation_javascript_definition: string;
  custom_data: any;
  mode: SetProcessRunCustomDataNodeMode;

  constructor(v: Partial<SetProcessRunCustomDataNodeSettings> = {}) {
    super(v);

    this.custom_data_calculation_type = v.custom_data_calculation_type ?? RexCalculationType.JAVASCRIPT;
    this.custom_data_calculation_javascript_definition = v.custom_data_calculation_javascript_definition ?? '';
    this.custom_data = v.custom_data ?? {};
    this.mode = v.mode ?? SetProcessRunCustomDataNodeMode.MERGE;
  }
}

export enum SetProcessRunCustomDataNodeMode {
  SET = 'set',
  MERGE = 'merge'
}
