import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormUnavailableComponent} from './form-unavailable.component';
import {TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [
    FormUnavailableComponent
  ],
  exports: [
    FormUnavailableComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule
  ]
})
export class FormUnavailableModule { }
