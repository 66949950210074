import {Point} from '@angular/cdk/drag-drop';
import type {ProcessFlowNodeType} from './process-flow-node-type';
import {excluded} from '../../util/excluded';
import {Size} from '../../util/size';

export abstract class ProcessFlowBaseNode {
  id: number;
  local_uuid: string;
  node_uuid: string;
  type: ProcessFlowNodeType;
  name: string;
  slug: string;
  executed: boolean;
  completed: boolean;
  disabled: boolean;
  updated_at: Date;
  created_at: Date;
  input: any;
  output: any;
  submission_id: number;
  submittable_id: number;
  settings: ProcessFlowBaseNodeSettings;
  recalculated_settings: ProcessFlowBaseNodeSettings;

  @excluded process_flow_id: number;

  @excluded iconClickable: boolean;
  @excluded meta: any;
  @excluded dimension: Size;
  @excluded position: Point;
  @excluded transform: string;
  @excluded order: number;

  protected constructor(v: Partial<ProcessFlowBaseNode>) {
    this.id = v.id;
    this.local_uuid = v.local_uuid;
    this.node_uuid = v.node_uuid;
    this.type = v.type;
    this.name = v.name;
    this.slug = v.slug;
    this.executed = !!v.executed;
    this.completed = !!v.completed;
    this.disabled = !!v.disabled;
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
    this.input = v.input;
    this.output = v.output;
    this.submission_id = v.submission_id;
    this.submittable_id = v.submittable_id;
    // IMPORTANT: this.settings must be initialized in the child class constructor!
    // IMPORTANT: this.recalculated_settings must be initialized in the child class constructor!

    this.process_flow_id = v.process_flow_id;

    this.iconClickable = false;

    this.meta = v.meta ?? {};
    this.dimension = v.dimension ? new Size(v.dimension) : null;
    this.position = {
      x: v.position?.x ?? 0,
      y: v.position?.y ?? 0
    };
    this.transform = v.transform;
  }

  get graphId(): string {
    return `node-${this.id}`;
  }
}

export abstract class ProcessFlowBaseNodeSettings {
  protected constructor(v: Partial<ProcessFlowBaseNodeSettings>) {
    Object.assign(this, v);
  }
}
