<div *transloco="let t; read: 'session.success'" class="content-wrapper mt-5">
  <pl-loading-bubbles *ngIf="loading"></pl-loading-bubbles>

  <div *ngIf="!loading" class="d-flex flex-column align-items-center justify-content-center text-center">
    <fa-icon [icon]="['fal', 'circle-check']" class="text-success" size="3x"></fa-icon>

    <h2 class="mt-3 mb-2 font-weight-bolder">
      {{t('header')}}
    </h2>

    <p class="text-muted my-4">{{t('subheader')}}</p>

    <p *ngIf="waitingForParticipants; else notWaitingForParticipants" class="text-muted">
      {{t('waiting_for_participants')}}
    </p>

    <ng-template #notWaitingForParticipants>
      <a *ngIf="pdfReady && submission?.pdf?.url; else pdfNotReady"
         [href]="submission?.pdf?.url + '?disposition=attachment'"
         class="btn btn-primary my-2 px-3 py-2 rounded-md">
        <fa-icon [icon]="['far', 'arrow-down-to-line']" class="mr-3" size="lg"></fa-icon>
        <span>{{t('download_pdf')}}</span>
      </a>

      <ng-template #pdfNotReady>
        <div class="mt-3 d-flex flex-column justify-content-center align-items-center" style="inset: 0;">
          <pl-loading-bubbles></pl-loading-bubbles>

          <span class="mt-2 text-muted small">{{t('generating_pdf')}}</span>
        </div>
      </ng-template>
    </ng-template>

    <div *ngIf="submissionDesign?.submission_settings?.success_page?.show_paperless_ad"
         class="paperless-ad bg-white mt-5 py-4 py-lg-5 d-flex align-items-center justify-content-center">
      <div class="px-4 px-lg-5 d-flex flex-column align-items-start text-left">
        <span class="text-muted small mb-1">{{t('paperless_ad.powered_by')}}</span>
        <img
          alt="Paperless Logo"
          height="30"
          src="/assets/images/Paperless_Logo-maindark.svg">

        <ul class="list-unstyled">
          <li class="position-relative pl-5 mt-4">
            <fa-icon [icon]="['fal', 'signature']" class="position-absolute ml-n5"></fa-icon>
            <span>{{t('paperless_ad.benefit_list.1')}}</span>
          </li>
          <li class="position-relative pl-5 mt-3">
            <fa-icon [icon]="['fal', 'file-circle-check']" class="position-absolute ml-n5"></fa-icon>
            <span>{{t('paperless_ad.benefit_list.2')}}</span>
          </li>
          <li class="position-relative pl-5 mt-3">
            <pl-icon icon="logosGdpr" class="position-absolute ml-n5" style="width: 24px"></pl-icon>
            <span>{{t('paperless_ad.benefit_list.3')}}</span>
          </li>
        </ul>

        <a class="text-primary mt-1 mt-lg-3" [href]="t('paperless_ad.test_paperless_link_url')" target="_blank">
          <span>{{t('paperless_ad.test_paperless_link_text')}}</span>
          <fa-icon [icon]="['far', 'arrow-right-long']" class="ml-2"></fa-icon>
        </a>
      </div>
    </div>

    <p *ngIf="submissionDesign?.submission_settings?.success_page?.mode === SuccessPageMode.Custom" class="my-3 custom-content text-break">
      {{submissionDesign?.submission_settings?.success_page?.content}}
    </p>

    <p *ngIf="submissionDesign?.submission_settings?.success_page?.mode === SuccessPageMode.Redirect"
       [innerHTML]="t('being_redirected', {url: submissionDesign?.submission_settings?.success_page.redirect_url})"
       class="my-3 custom-content text-break text-muted">
    </p>
  </div>
</div>
