<ng-container *transloco="let t; read: 'session'">
  <nav *ngIf="submissionSettings" #navbar class="navbar justify-content-between navbar-light p-0">
    <div [style.background-color]="submissionDesign?.submission_styles?.headerBar.background_color"
         class="header w-100 px-3 py-1 d-flex flex-row justify-content-start align-items-center">

      <div [style.color]="submissionDesign?.submission_styles?.navbar_logo['color']"
           class="navbar-brand d-inline-flex p-0 mr-0 flex-shrink-0">
        <ng-container [ngSwitch]="submissionSettings?.navbar_brand_mode">
          <ng-container *ngSwitchCase="SubmissionNavbarBrandMode.PaperlessLogo">
            <img alt="Paperless Logo" class="logo" src="/assets/images/Paperless_Logo-mainlight.svg"
                 style="width: 124px; height: 28px;">
          </ng-container>
          <ng-container *ngSwitchCase="SubmissionNavbarBrandMode.SpecialLogo">
            <img *ngIf="submissionDesign?.logo.default_url" [src]="submissionDesign?.logo.default_url" alt="Logo" class="logo">
          </ng-container>
          <ng-container *ngSwitchCase="SubmissionNavbarBrandMode.Text">
            {{submissionSettings?.navbar_brand_text}}
          </ng-container>
        </ng-container>
      </div>

      <div class="d-none d-md-inline-flex flex-row h-100 align-items-center flex-shrink-0">
        <ng-container *ngIf="submissionSettings?.showSenderName && authorName">
          <div [style.borderLeftColor]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'" class="d-none d-sm-block h-50 mx-2 mx-lg-3"
               style="width: 1px; border-left-width: 1px; border-left-style: solid; opacity: .4;"></div>

          <div *ngIf="submissionSettings.showSenderAvatar && authorAvatarUrl" class="h-100 py-1 mr-2">
            <img [src]="authorAvatarUrl" alt="avatar" class="avatar">
          </div>

          <div class="d-flex flex-column">
            <span [style.color]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'"
                  class="authorName">{{authorName}}</span>
            <span *ngIf="submissionSettings?.showAdditionalInformation"
                  [style.color]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'"
                  class="small additionalInfo">{{additionalInfo}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="submissionSettings?.showDocumentName && documentName">
          <div [style.borderLeftColor]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'" class="d-none d-sm-block h-50 mx-2 mx-lg-3"
               style="width: 1px; border-left-width: 1px; border-left-style: solid; opacity: .4;"></div>

          <h1 style="font-size: 16px" class="m-0 font-weight-normal">
            <span [style.color]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'">
              {{documentName}}
            </span>
          </h1>
        </ng-container>
      </div>

      <div class="d-none d-md-inline-flex h-100 align-items-center overflow-hidden">
        <ng-container *ngIf="submissionSettings?.addAdditionalText && submissionSettings?.navbar_message_text">
          <div [style.borderLeftColor]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'" class="d-inline-block h-50 mx-2 mx-lg-3"
               style="width: 1px; border-left-width: 1px; border-left-style: solid; opacity: .4;"></div>

          <span [style.color]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'"
                [title]="submissionSettings?.navbar_message_text"
                class="navbarMessageText">
            {{submissionSettings?.navbar_message_text}}
          </span>
        </ng-container>
      </div>

      <div class="ml-auto d-flex flex-row">
        <pl-locale-switcher [uiLocaleHandling]="true"
                            [routeChange]="false"
                            [style.color]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'">
        </pl-locale-switcher>

        <app-dropdown [renderPanelInBody]="true" elevation="3" zIndex="1100" [navigateEntriesWithArrowkeys]="true">
          <div *dropdownTrigger class="d-flex">
            <button [style.color]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'" class="btn d-none d-sm-block small" role="button"
                    type="button">
              {{t('header.other_actions.title')}}
              <fa-icon [icon]="['far', 'chevron-down']" size="sm" class="opacity-50"></fa-icon>
            </button>
            <button [style.color]="submissionDesign?.submission_styles?.navbar_message['color'] || '#fff'" class="btn d-block d-sm-none" role="button"
                    type="button">
              <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 7h22M4 15h22M4 23h22" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
              </svg>
            </button>
          </div>
          <div *dropdownPanel>
            <app-other-actions></app-other-actions>
          </div>
        </app-dropdown>
      </div>
    </div>

    <div *ngIf="mustAcceptTerms && !termsAccepted" class="terms-subheader-wrap">
      <div class="terms-subheader row no-gutters px-3 py-3 py-sm-1 d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center flex-nowrap">
        <pl-session-tos-checkbox #termsAcceptedCheckbox [arrow]="true"></pl-session-tos-checkbox>

        <app-styleable-button [design]="submissionDesign" type="primary">
          <button [disabled]="!termsAcceptedCheckbox.value || (sessionController?.loading | async)" (click)="start.emit(termsAcceptedCheckbox.value)"
                  [class.hide-loading-indicator]="!termsAcceptedCheckbox.value"
                  class="btn btn-primary ml-0 ml-md-auto mt-3 mt-sm-0 has-loading-indicator">
            <span>
              {{t('accept_tos')}}
            </span>
            <pl-loading-bubbles></pl-loading-bubbles>
          </button>
        </app-styleable-button>

        <div tabindex="0" (focusin)="termsAcceptedCheckbox.focus()"></div>
      </div>
    </div>

    <div #subheader
         *ngIf="(!mustAcceptTerms || termsAccepted) && showNavigation"
         [class.multipage]="navigationState && navigationState.pageCount > 1"
         [style.background-color]="submissionDesign?.submission_styles?.navbar.background_color" class="subheader d-flex">
      <div *ngIf="showFormsNavigation"
           class="subheader-inner px-3 py-1 d-flex flex-row align-items-center">
        <span [innerHTML]="'forms.header.start.text' | transloco"
              (click)="start.emit()"></span>

        <app-styleable-button [design]="submissionDesign" type="primary" class="ml-auto">
          <fa-icon [icon]="['far','arrow-right']"
                   class="arrow d-none d-sm-inline-block"
                   size="1x">
          </fa-icon>

          <button [disabled]="sessionController?.loading | async"
                  (click)="start.emit()"
                  id="start-button"
                  class="btn px-4 has-loading-indicator elevation-gizmo">
            <span>{{'forms.header.start.button' | transloco}}</span>
            <pl-loading-bubbles></pl-loading-bubbles>
          </button>
        </app-styleable-button>
      </div>


      <div *ngIf="gizmoState && navigationState"
           class="subheader-inner px-3 py-1">
        <div *ngIf="!completed && navigationState && navigationState.pageCount > 1"
             class="center-wrapper">
          <div class="center">
            <div *ngIf="!(this.navigationState.currentPage === this.navigationState.pageCount - 1 && navigationState.invalidRequiredInputCount === 0)"
                 class="page-counter flex-shrink-0">
              {{t('header.page_counter', {current: navigationState.currentPage + 1, count: navigationState.pageCount})}}
            </div>

            <div *ngIf="interactive"
                 [innerHTML]="t(headerText, {count: navigationState.invalidRequiredInputCount})"
                 class="field-info">
            </div>
          </div>
        </div>

        <div [class.document_completed]="submissionCompleted"
            class="controls">
          <div class="left">
            <button *ngIf="showPrev"
                    (click)="prev.emit()"
                    class="back-button btn p-0 mr-3">
              <fa-icon [icon]="['far', 'chevron-left']"></fa-icon>
              <span>{{t('back')}}</span>
            </button>

            <div class="texts">
              <p *ngIf="submissionCompleted">
                {{t('header.document_completed')}}
              </p>

              <p *ngIf="currentParticipantCompleted && !showReadOnly && !submissionCompleted && hasInputs">
                {{t('header.current_participant_completed')}}
              </p>

              <p *ngIf="!completed && !showReadOnly && navigationState.pageCount === 1"
                 [innerHTML]="t(headerText, {count: navigationState.invalidRequiredInputCount})"
                 class="check-document mb-0 mr-2">
              </p>

              <p *ngIf="!submissionCompleted && showReadOnly" class="check-document mb-0 mr-2">
                {{t('header.read_only')}}
              </p>
            </div>
          </div>

          <div class="right">
            <button *ngIf="showSave"
                    [attr.data-tooltip-text]="t('save')"
                    data-tooltip-position="top"
                    [disabled]="sessionController?.loading | async"
                    [class.loading]="sessionController?.loading | async"
                    (click)="save.emit()"
                    class="btn btn-outline-secondary btn-icon has-loading-indicator mr-2">
              <fa-icon [icon]="['fal','save']" size="lg"></fa-icon>
              <pl-loading-bubbles></pl-loading-bubbles>
            </button>

            <app-styleable-button *ngIf="showNext" [design]="submissionDesign" type="primary">
              <button [disabled]="sessionController?.loading | async"
                      [class.loading]="sessionController?.loading | async"
                      (click)="next.emit()"
                      id="next-button"
                      class="btn px-3 has-loading-indicator elevation-gizmo">
                <span>{{t(buttonText)}}</span>
                <pl-loading-bubbles></pl-loading-bubbles>
              </button>
            </app-styleable-button>

            <span *ngIf="showReadOnly" class="btn btn-pl-primary border-0 px-3 read_only">
              <fa-icon [icon]="['far','eye']" class="mr-1"></fa-icon>
              {{t('read_only')}}
            </span>

            <app-styleable-button *ngIf="showComplete || showApprove" [design]="submissionDesign" type="primary">
              <fa-icon *ngIf="showCompleteArrow"
                       [icon]="['far','arrow-right']"
                       class="arrow d-none d-xl-inline-block"
                       size="1x">
              </fa-icon>

              <button [disabled]="sessionController?.loading | async"
                      [class.loading]="sessionController?.loading | async"
                      (click)="complete.emit()"
                      id="complete-button"
                      class="btn px-3 has-loading-indicator elevation-gizmo">
                <span>{{t(buttonText)}}</span>
                <pl-loading-bubbles></pl-loading-bubbles>
              </button>
            </app-styleable-button>

            <app-styleable-button *ngIf="submissionCompleted && downloadPdfUrl?.length > 0" [design]="submissionDesign" type="primary">
              <a [href]="downloadPdfUrl+'?disposition=attachment'" class="btn px-3 ml-1" download>
                <fa-icon [icon]="['far','download']" class="mr-1"></fa-icon>
                {{t('download_pdf')}}
              </a>
            </app-styleable-button>

            @if (additionalButtons) {
              <app-additional-buttons
                [additionalButtons]="additionalButtons"
              />
            }
          </div>
        </div>
      </div>
    </div>
  </nav>
</ng-container>
