export enum LOCALE {
  de_DE = 'de-DE',
  en_US = 'en-US',
  es_ES = 'es-ES',
  fr_FR = 'fr-FR',
  it_IT = 'it-IT',
  pl_PL = 'pl-PL',
  tr_TR = 'tr-TR',
  uk_UA = 'uk-UA',
  zh_CN = 'zh-CN', // Mandarin-Chinesisch
  ru_RU = 'ru-RU', // Russisch
  bs_BA = 'bs-BA', // Bosnisch
  hr_HR = 'hr-HR', // Kroatisch
  sr_RS = 'sr-RS', // Serbisch
  ti_ER = 'ti-ER', // Tigrinya (Eritrea)
  de_DE_x_simple_language = 'de-DE-x-simple-language'
}

export enum ManagementUILocale {
  de_DE = LOCALE.de_DE,
  en_US = LOCALE.en_US
}

export enum UILocale {
  de_DE = LOCALE.de_DE,
  en_US = LOCALE.en_US
}

export enum ContentLocale {
  de_DE = LOCALE.de_DE,
  en_US = LOCALE.en_US,
  es_ES = LOCALE.es_ES,
  fr_FR = LOCALE.fr_FR,
  it_IT = LOCALE.it_IT,
  pl_PL = LOCALE.pl_PL,
  tr_TR = LOCALE.tr_TR,
  uk_UA = LOCALE.uk_UA,
  zh_CN = LOCALE.zh_CN,
  ru_RU = LOCALE.ru_RU,
  bs_BA = LOCALE.bs_BA,
  hr_HR = LOCALE.hr_HR,
  sr_RS = LOCALE.sr_RS,
  ti_ER = LOCALE.ti_ER,
  de_DE_x_simple_language = LOCALE.de_DE_x_simple_language
}

export enum RenderingLocale {
  en_US = LOCALE.en_US,
  de_DE = LOCALE.de_DE
}

export function normalizeLocale(localeString: string): LOCALE {
  switch (localeString?.toLowerCase()?.substring(0, 2)) {
    case 'en': return LOCALE.en_US;
    case 'de': return LOCALE.de_DE;
    default: return LOCALE.en_US;
  }
}
