export enum BlockType {
  Page = 'Block::Page',
  PdfPart = 'Block::PdfPart',
  LandingPage = 'Block::Page::LandingPage',
  SuccessPage = 'Block::Page::SuccessPage',

  RichText = 'Block::RichText',

  // Input = 'Block::Input',
  TextInput = 'Block::Input::TextInput',
  SelectInput = 'Block::Input::SelectInput',
  CheckboxInput = 'Block::Input::CheckboxInput',
  MultiSelectInput = 'Block::Input::MultiSelectInput',
  RadioInput = 'Block::Input::RadioInput',
  RadioSelectInput = 'Block::Input::RadioSelectInput',
  DropdownInput = 'Block::Input::DropdownInput',
  FileUploadInput = 'Block::Input::FileUploadInput',
  SecureFileUploadInput = 'Block::Input::SecureFileUploadInput',
  SignatureInput = 'Block::Input::SignatureInput',
  SelectOption = 'Block::SelectOption',
  BankAccount = 'Block::Input::BankAccountInput',
  DateInput = 'Block::Input::DateInput',
  DataSetInput = 'Block::Input::DataSetInput',

  Table = 'Block::Table',
  TableRow = 'Block::TableRow',
  TableCell = 'Block::TableCell',

  Group = 'Block::Group',
  SignDocumentGroup = 'Block::Group::SignDocumentGroup',
  Button = 'Block::Button',
  StartButton = 'Block::Button::StartButton',

  FileDownload = 'Block::FileDownload',

  Image = 'Block::Image',
  Video = 'Block::Video',
  Row = 'Block::Row',
  Column = 'Block::Column',
  Attachment = 'Block::Attachment',

  Spacer = 'Block::Spacer',
  TOC = 'Block::Toc',
  Divider = 'Block::Divider',
  Html = 'Block::Html',
  PageBreak = 'Block::PageBreak'
}
