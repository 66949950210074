import {Size} from '../util/size';
import {Input, InputSettings, InputStyles} from './input';

export class RadioInput extends Input {
  default_value: number;
  settings: RadioInputSettings;
  styles: RadioInputStyles;

  constructor(v: Partial<RadioInput> = {}) {
    super(v);
    this.default_value = v.default_value ?? undefined;
    this.settings = new RadioInputSettings(v.settings ?? {});
    this.styles = new RadioInputStyles(v.styles ?? {});
  }
}

export class RadioInputSettings extends InputSettings {
  fullWidth: boolean;
  fontSize: number;

  get minSize(): Size {
    return new Size({
      width: this.fontSize * 3 + 2,
      height: this.fontSize * 2 + 2
    });
  }

  get maxSize(): Size {
    return new Size({
      width: 0,
      height: 0
    });
  }

  get defaultSize(): Size {
    return new Size({
      width: 180,
      height: 37
    });
  }

  get paddingX(): number {
    return this.fontSize * 0.75;
  }

  get paddingY(): number {
    return this.fontSize * 0.5;
  }

  constructor(v: Partial<RadioInputSettings> = {}) {
    super(v);
    this.fullWidth = v.fullWidth ?? true;
    this.resizeAxis = 'x';
    this.fontSize = v.fontSize ?? 16;
  }
}

export class RadioInputStyles extends InputStyles {
  inline: boolean;
  format: RadioInputFormat;

  constructor(v: Partial<RadioInputStyles> = {}) {
    super(v);
    this.inline = v.inline ?? false;
    this.format = v.format ?? RadioInputFormat.LIST;
  }
}

export enum RadioInputFormat {
  LIST,
  BUTTONS,
  DROPDOWN
}
