import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseServiceV1} from './base.service.v1';
import {Document, ListableParams, Page, Submittable, Template} from '@paperlessio/sdk/api/models';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {BaseList} from '@paperlessio/sdk/api/util';
import * as Sentry from '@sentry/angular-ivy';
import {Serializer} from '@paperlessio/sdk/api/serializers';

type TemplateFetchParams = ListableParams<Template> & Partial<Pick<Template, 'workspace_id' | 'creator_id'>>;

@Injectable({providedIn: 'root'})
export class TemplateService extends BaseServiceV1<Template> {
  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'templates';
    this.serializer = new Serializer<Template>(Template);
  }

  pages(template_id: number): Observable<BaseList<Page>> {
    return this.http.get<BaseList<Page>>(`${this.BASE_URL + this.endpoint}/${template_id}/pages`)
      .pipe(
        map(x => {
          x.data = x.data.map(a => new Page(a));
          return x;
        }),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  prepare(template_id: number, name: string): Observable<Submittable> {
    return this.http.post<Submittable>(`${this.BASE_URL + this.endpoint}/${template_id}/prepare`, {name})
      .pipe(
        map(x => new Serializer<Document>(Document).fromJson(x)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  fetch(params: TemplateFetchParams): Observable<BaseList<Template>> {
    return super.fetch(params);
  }
}
