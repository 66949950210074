import {CssStyle} from './css-style';
import {SubmissionButtonStyles} from './submission-button-styles';

export class SubmissionStyles {
  headerBar: CssStyle;
  navbar: CssStyle;
  navbar_logo: CssStyle;
  navbar_message: CssStyle;
  body: CssStyle;
  page: CssStyle;
  buttons: SubmissionButtonStyles;

  constructor(v: Partial<SubmissionStyles> = {}) {
    this.headerBar = new CssStyle(v.headerBar ?? {background_color: '#05101A'});
    this.navbar = new CssStyle(v.navbar ?? {background_color: '#fff'});
    this.navbar_logo = new CssStyle(v.navbar_logo ?? {color: '#000000'});
    this.navbar_message = new CssStyle(v.navbar_message ?? {color: '#ffffff'});
    this.body = new CssStyle(v.body);
    this.page = new CssStyle(v.page);
    this.buttons = new SubmissionButtonStyles(v.buttons);
  }
}
