// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';

export class SetProcessRunLabelsNode extends ProcessFlowBaseNode {
  constructor(v: Partial<SetProcessRunLabelsNode>) {
    super(v);
    this.type = 'ProcessFlow::SetProcessRunLabelsNode';
    this.settings = new SetProcessRunLabelsNodeSettings(v.settings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new SetProcessRunLabelsNodeSettings(v.recalculated_settings);
    }
  }

  settings: SetProcessRunLabelsNodeSettings;
  recalculated_settings: SetProcessRunLabelsNodeSettings;
}

export class SetProcessRunLabelsNodeSettings extends ProcessFlowBaseNodeSettings {
  mode: 'set' | 'add' | 'remove';

  label_slugs_calculation_type: RexCalculationType;
  label_slugs_calculation_javascript_definition: string;
  label_slugs: string[];

  constructor(v: Partial<SetProcessRunLabelsNodeSettings> = {}) {
    super(v);

    this.mode = v?.mode ?? 'add';

    this.label_slugs_calculation_type = v.label_slugs_calculation_type ?? RexCalculationType.CONSTANT;
    this.label_slugs_calculation_javascript_definition = v.label_slugs_calculation_javascript_definition ?? '';
    this.label_slugs = v.label_slugs ?? [];
  }
}
