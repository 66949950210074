import {SignatureInputData} from '@shared/signature-input-modal/signature-input-data';

export const SIGNATURE_BLOB_SESSION_LIFETIME = 5 * 60 * 1000; // five minutes
export const SIGNATURE_BLOB_SESSION_KEY = 'signature_blob_data';

export type SubmissionSignatureBlobStorageEntry = {
  participantEmail: string,
  participantId: number,
  createdAt: Date,
  data: SignatureInputData
}

export class SubmissionSignatureBlobStorage {
  signatures: { [identifier: string]: SubmissionSignatureBlobStorageEntry };

  /**
   * Add a signature to the storage or update the createdAt date.
   */
  addSignature(participantEmail: string, participantId: number, data: SignatureInputData) {
    this.load();

    let signatureEntry = this.signatures[this.identifier(participantEmail, participantId)];
    if (!signatureEntry) {
      signatureEntry = {
        participantEmail,
        participantId,
        createdAt: new Date(),
        data
      };

      this.signatures[this.identifier(participantEmail, participantId)] = signatureEntry;
    } else {
      signatureEntry.createdAt = new Date();
      signatureEntry.data = data;
    }

    this.save();
  }

  /**
   * Removes a signature from the storage.
   */
  removeSignature(participantEmail: string, participantId: number) {
    this.load();

    if (this.signatures[this.identifier(participantEmail, participantId)] != null) {
      delete this.signatures[this.identifier(participantEmail, participantId)];
    }

    this.save();
  }

  /**
   * Get the signature signedId for a specific participant. Does not respect the createdAt date on porpose.
   */
  getSignatureEntries(participantEmail: string): SubmissionSignatureBlobStorageEntry[] | null {
    this.load();

    const signatureEntries = Object
      .getOwnPropertyNames(this.signatures)
      .map(pn => this.signatures[pn])
      .filter(entry => entry.participantEmail === participantEmail);

    if (!signatureEntries?.length) {
      return null;
    }

    return signatureEntries;
  }

  /**
   * Clean the signatures that are beyond their "lifetime".
   */
  cleanSignatures() {
    this.load();

    Object.getOwnPropertyNames(this.signatures).forEach(identifier => {
      const signatureEntry = this.signatures[identifier];
      if (signatureEntry) {
        const diffMilliseconds = new Date().getTime() - new Date(signatureEntry.createdAt).getTime();
        if (diffMilliseconds > SIGNATURE_BLOB_SESSION_LIFETIME) {
          delete this.signatures[identifier];
        }
      }
    });

    this.save();
  }

  private load() {
    // Wrapped in try, because of possible QuotaErrors
    try {
      this.signatures = JSON.parse(localStorage.getItem(SIGNATURE_BLOB_SESSION_KEY) ?? '{}') ?? {};
    } catch (e) {
      this.signatures = {};
    }
  }

  private save() {
    // Wrapped in try, because of possible QuotaErrors
    try {
      localStorage.setItem(SIGNATURE_BLOB_SESSION_KEY, JSON.stringify(this.signatures));
    } catch (e) {}
  }

  private identifier(email: string, id: number): string {
    return `${email}@${id}`;
  }
}
