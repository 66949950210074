import {ContentLocale, UILocale} from '@paperlessio/sdk/api/util';
import {TinyColor} from '@ctrl/tinycolor';
import {Helper} from '@paperlessio/sdk/util/helpers';
import {RexCalculationType} from './calculation-type';
import {User} from './user';
import {Blob} from './blob';
import {excluded} from './util/excluded';

export const ParticipantColors = [
  '#0083f6',
  '#26ad75',
  '#f36650',
  '#9f4cd3',
  '#ffbb45',
  '#266aad',
  '#f176a7',
  '#8b572a',
  '#1b887a'
];

type ParticipantColorVariations = Partial<{
  backgroundColor: string,
  backgroundColorHighAlpha: string,
  backgroundColorLightLowAlpha: string,
  borderColor: string,
}>;
const ParticipantColorVariationsCache: Record<string, ParticipantColorVariations> = {};

export class Participant {
  id: number;
  email: string;
  name: string;
  slot_name: string;
  role: ParticipantRole;
  @excluded state: ParticipantState;
  @excluded token?: string;
  @excluded participation_url: string;
  color: string;
  content_locale: ContentLocale;
  ui_locale: UILocale;
  description: string;
  @excluded provided_by_form_requester: boolean;
  @excluded progress: ParticipantProgress[];
  @excluded terms_and_conditions_approved_at: Date;
  @excluded terms_and_conditions_approved: boolean;
  @excluded updated_at: Date;
  @excluded created_at: Date;
  @excluded dispatch_failed_at: Date;
  @excluded replaced_by_participant_id?: number;
  @excluded mapped_user_id?: number;
  dispatch_failed_message: string;
  allow_stored_signature_image_use: boolean;
  stored_signature_image: Blob;
  dispatch_strategy: 'email' | 'api_response_initial_participants';
  email_subject: string;
  email_body: string;
  email_signature: string;
  receive_submission_completed_mail: boolean;
  @excluded qes_in_progress: boolean;
  @excluded qes_dispatch_completed_at: Date;
  @excluded qes_dispatch_failed_at: Date;
  @excluded qes_dispatching_at: Date;
  @excluded qes_opened_at: Date;
  @excluded qes_started_at: Date;
  @excluded qes_waiting_at: Date;

  // Participation Participants
  @excluded may_complete_calculation_type: RexCalculationType;
  @excluded may_complete_calculation_javascript_definition: string;

  get hasCustomEmail(): boolean {
    return this.email_subject?.length > 0 || this.email_body?.length > 0 || this.email_signature?.length > 0;
  }

  get isPreAssigned() {
    return this.name?.length > 0 && this.email?.length > 0;
  }

  get isPartlyPreAssigned() {
    return this.name?.length > 0 || this.email?.length > 0;
  }

  get borderColor(): string {
    return this.memoizedColorVariant(
      'borderColor',
      () => new TinyColor(this.color).setAlpha(0.8).toHex8String()
    );
  }

  get backgroundColor(): string {
    return this.memoizedColorVariant(
      'backgroundColor',
      () => new TinyColor(this.color).setAlpha(0.2).toHex8String()
    );
  }

  get backgroundColorHighAlpha(): string {
    return this.memoizedColorVariant(
      'backgroundColorHighAlpha',
      () => new TinyColor(this.color).setAlpha(0.5).toHex8String()
    );
  }

  get backgroundColorLightLowAlpha(): string {
    return this.memoizedColorVariant(
      'backgroundColorLightLowAlpha',
      () => new TinyColor(this.color).mix(new TinyColor('#fff'), 90).setAlpha(0.9).toHex8String()
    );
  }

  get readOnlyRole(): boolean {
    return this.role === ParticipantRole.viewer || this.role === ParticipantRole.copy_receiver;
  }

  get remindable(): boolean {
    return [
      ParticipantState.dispatch_completed,
      ParticipantState.opened,
      ParticipantState.started
    ].includes(this.state);
  }

  allowsImpersonationByUser(user: User): boolean {
    if (!user || !this.mapped_user_id) {
      return false;
    }

    return this.mapped_user_id === user.id && [ParticipantState.dispatch_completed, ParticipantState.opened, ParticipantState.started].includes(this.state);
  }

  /**
   * This property is ONLY used when creating a participant. Due tu the generic nature of the participant-store,
   * it expects to receive a participant-object in the create method.
   */
  submittable_id: number;

  constructor(v: Partial<Participant> = {}) {
    this.id = v.id;
    this.email = v.email ?? '';
    this.name = v.name ?? '';
    this.slot_name = v.slot_name ?? '';
    this.role = v.role ?? ParticipantRole.default;
    this.state = v.state ?? ParticipantState.initialized;
    this.token = v.token;
    this.participation_url = v.participation_url;
    this.color = v.color ?? '#ff00ff';
    this.content_locale = v.content_locale;
    this.ui_locale = v.ui_locale;
    this.description = v.description;
    this.provided_by_form_requester = v.provided_by_form_requester;
    this.progress = v?.progress ? v.progress.map(p => new ParticipantProgress(p)) : [];
    this.terms_and_conditions_approved_at = v.terms_and_conditions_approved_at ? new Date(v.terms_and_conditions_approved_at) : null;
    this.terms_and_conditions_approved = !!v.terms_and_conditions_approved;
    this.created_at = v.created_at ? new Date(v.created_at) : null;
    this.updated_at = v.updated_at ? new Date(v.updated_at) : null;
    this.submittable_id = undefined;
    this.dispatch_failed_at = v.dispatch_failed_at ? new Date(v.dispatch_failed_at) : null;
    this.replaced_by_participant_id = v.replaced_by_participant_id;
    this.mapped_user_id = v.mapped_user_id;
    this.dispatch_failed_message = v.dispatch_failed_message;
    this.allow_stored_signature_image_use = v.allow_stored_signature_image_use;
    this.stored_signature_image = new Blob(v.stored_signature_image);
    this.dispatch_strategy = v.dispatch_strategy;
    this.email_subject = v.email_subject;
    this.email_body = v.email_body;
    this.email_signature = v.email_signature;
    this.receive_submission_completed_mail = v.receive_submission_completed_mail;
    this.qes_in_progress = v.qes_in_progress;
    this.qes_dispatch_completed_at = v?.qes_dispatch_completed_at != null ? new Date(v?.qes_dispatch_completed_at) : null;
    this.qes_dispatch_failed_at = v?.qes_dispatch_failed_at != null ? new Date(v?.qes_dispatch_failed_at) : null;
    this.qes_dispatching_at = v?.qes_dispatching_at != null ? new Date(v?.qes_dispatching_at) : null;
    this.qes_opened_at = v?.qes_opened_at != null ? new Date(v?.qes_opened_at) : null;
    this.qes_started_at = v?.qes_started_at != null ? new Date(v?.qes_started_at) : null;
    this.qes_waiting_at = v?.qes_waiting_at != null ? new Date(v?.qes_waiting_at) : null;
    this.may_complete_calculation_type = v?.may_complete_calculation_type;
    this.may_complete_calculation_javascript_definition = v?.may_complete_calculation_javascript_definition;
  }

  get avatarUrl(): string {
    // TODO: refactor to use svg like with workspace icon.
    const color = this.color.substring(1, 7);
    const backgroundColor = new TinyColor(color).mix('#ffffff', 80).toHex();
    return `https://eu.ui-avatars.com/api/?color=${color}&background=${backgroundColor}&font-size=0.4&size=64&name=${this.initials}`;
  }

  get initials(): string {
    if (this.name) {
      const names = this.name.split(' ');
      let initials = names[0].substring(0, 1);

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1);
      }
      return initials.toUpperCase();
    } else if (this.email) {
      return this.email.substring(0, 2).toUpperCase();
    } else if (this.slot_name) {
      return this.slot_name.substring(0, 1).toUpperCase();
    } else {
      return '';
    }
  }

  get touched(): boolean {
    return !!this.slot_name || !!this.name || !!this.email;
  }

  get validForDispatch(): boolean {
    return this.email && this.email.length > 0 &&
      this.name && this.name.length > 0 &&
      Helper.validEmailString(this.email);
  }

  get emailValid(): boolean {
    return Helper.validEmailString(this.email);
  }

  clearCustomEmail() {
    this.email_subject = '';
    this.email_body = '';
    this.email_signature = '';
  }

  private memoizedColorVariant(variant: keyof ParticipantColorVariations, fn) {
    if (this.color == null) {
      return null;
    }
    if (!ParticipantColorVariationsCache[this.color]) {
      ParticipantColorVariationsCache[this.color] = {};
    }
    if (!ParticipantColorVariationsCache[this.color][variant]) {
      ParticipantColorVariationsCache[this.color][variant] = fn();
    }

    return ParticipantColorVariationsCache[this.color][variant];
  }
}

export class ParticipantProgress {
  block_id: number;
  block_name: string;
  block_type: string;
  position: number;
  state: ProgressState;

  constructor(v: Partial<ParticipantProgress>) {
    this.block_id = v.block_id;
    this.block_name = v.block_name;
    this.block_type = v.block_type;
    this.position = v.position;
    this.state = v.state;
  }
}

export enum ProgressState {
  untouched = 'untouched',
  opened = 'opened',
  started = 'started',
  completed = 'completed'
}

export enum ParticipantRole {
  default = 'default',
  viewer = 'viewer',
  approver = 'approver',
  copy_receiver = 'copy_receiver'
}

export enum ParticipantState {
  initialized = 'initialized',
  waiting = 'waiting',
  dispatching = 'dispatching',
  dispatch_failed = 'dispatch_failed',
  dispatch_completed = 'dispatch_completed',
  opened = 'opened',
  started = 'started',
  completed = 'completed',
  skipped = 'skipped',
  disabled = 'disabled',
  qes_waiting = 'qes_waiting',
  qes_dispatching = 'qes_dispatching',
  qes_dispatch_failed = 'qes_dispatch_failed',
  qes_dispatch_completed = 'qes_dispatch_completed',
  qes_opened = 'qes_opened',
  qes_started = 'qes_started',
}
