import {excluded} from './util/excluded';
import {Addons} from './addons';

export class Addon {
  constructor(data?: Partial<Addon>) {
    this.id = data?.id ?? 0;
    this.name = data?.name as Addons;
    this.hidden = data?.hidden ?? false;
  }

  id: number;
  name: Addons | null;

  @excluded
  hidden: boolean;
}
