import {Injectable, Injector, OnDestroy, Optional} from '@angular/core';
import {BrandDesignStore, SubmittableStore} from '@paperlessio/sdk/api/stores';
import {TemplateStore} from '../../templates/template.store';
import {Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {Bucket, BucketType, FormVersion, Submittable, Template} from '@paperlessio/sdk/api/models'
import {PartialStore} from '../../partials/partial.store';
import {filter} from 'rxjs/operators';
import {AuthorizationService} from '../authorization/authorization.service';
import {TitleService} from '@management/base/title.service';
import {FormVersionStore} from '@management/forms/form-version.store';

@Injectable({providedIn: 'root'})
export class BucketStore implements OnDestroy {
  bucket: ReplaySubject<Bucket> = new ReplaySubject(1);
  bucketType: BucketType;
  currentBucket: Bucket;
  currentBucketSub: Subscription;
  editable = false;
  store: SubmittableStore | TemplateStore | PartialStore | FormVersionStore;
  currentExpandAttributes = [];

  constructor(private injector: Injector,
              private brandDesignStore: BrandDesignStore,
              @Optional() private titleService: TitleService,
              @Optional() private authorizationService: AuthorizationService) {
    this.bucket.subscribe(bucket => {
      if (this.authorizationService && this.currentBucket?.id !== bucket.id) {
        this.editable = this.authorizationService.can('update', bucket);
      }

      this.currentBucket = bucket;

      if (this.titleService) {
        this.titleService.currentBucket = this.currentBucket;
        this.titleService.setTitle();
      }
    });
  }

  init(type: BucketType, id: number, reuse = true, params = {}): Observable<Bucket> {
    if (reuse && type === this.bucketType && id === this.currentBucket?.id && this.sameExpandParams(params)) {
      return of(this.currentBucket);
    }

    this.store = this.resolveStore(type);
    if (this.currentBucketSub) {
      this.currentBucketSub.unsubscribe();
    }

    // @ts-ignore
    // explicit id check to prevent loading a stale object from store's current until the new object is loaded
    this.currentBucketSub = this.store?.current?.pipe(filter(x => !!x && x.id === id)).subscribe((bucket: Bucket) => {
      this.currentExpandAttributes = this.expandParamsFrom(params);
      this.bucketType = type;
      this.bucket.next(bucket);
      if (bucket instanceof Submittable || bucket instanceof Template || bucket instanceof FormVersion) {
        this.brandDesignStore.init(bucket.designs.brand);
      }
    }, err => {
      this.currentExpandAttributes = [];
    });

    return this.store.init(id, reuse, params);
  }

  resolveStore(type: BucketType): SubmittableStore | TemplateStore | PartialStore | FormVersionStore {
    switch (type) {
      case BucketType.Document: return this.injector.get<SubmittableStore>(SubmittableStore);
      case BucketType.Submittable: return this.injector.get<SubmittableStore>(SubmittableStore);
      case BucketType.Template: return this.injector.get<TemplateStore>(TemplateStore);
      case BucketType.Partial: return this.injector.get<PartialStore>(PartialStore);
      case BucketType.FormVersion: return this.injector.get<FormVersionStore>(FormVersionStore);
    }
  }

  /*
  * returns the url path fragment (namespace) for the current BucketType
  */
  get bucketTypeNamespace(): string {
    const map = {
      [BucketType.Submittable]: 'documents',
      [BucketType.Document]: 'documents',
      [BucketType.Partial]: 'partials',
      [BucketType.Template]: 'templates',
      [BucketType.FormVersion]: 'form_versions'
    };
    return map[this.bucketType];
  }

  ngOnDestroy(): void {
    if (this.currentBucketSub) {
      this.currentBucketSub.unsubscribe();
    }
  }

  private sameExpandParams(fullParams: any): boolean {
    return JSON.stringify(this.currentExpandAttributes) === JSON.stringify(this.expandParamsFrom(fullParams));
  }

  private expandParamsFrom(fullParams: any): string[] {
    return fullParams?.['expand[]'] ?? [];
  }
}
