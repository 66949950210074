import {Block, BlockStyles} from './block';

export class PageBreak extends Block {
  styles: PageBreakStyles = new PageBreakStyles({});

  constructor(v: Partial<PageBreak> = {}) {
    super(v);
    this.styles = new PageBreakStyles(v.styles ?? {});

    this.disableDropzone = {
      left: true,
      right: true
    };

    this.disableDropping = {
      left: true,
      right: true
    };

    this.dropOnPageOnly = true;
  }
}

export class PageBreakStyles extends BlockStyles {
  full_width: boolean;

  constructor(v: Partial<PageBreakStyles> = {}) {
    super(v);
    this.full_width = true;
  }
}
