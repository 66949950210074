import {Injectable} from '@angular/core';
import {PlIcon, plIcons} from './icons/pl-icons';

@Injectable()
export class IconLibraryService {
  private library = new Map<string, string>();

  addIcons(...icons: PlIcon[]): void {
    icons.forEach((icon: PlIcon) => this.library.set(icon.name, icon.data));
  }

  getIcon(iconName: plIcons): string | undefined {
    if (!this.library.has(iconName)) {
      console.warn(`We could not find the icon with name '${iconName}', did you add it to the icon library?`);
      return undefined;
    }

    return this.library.get(iconName);
  }
}
