export class BaseSettings {

  constructor(v: Partial<BaseSettings> = {}) {
    Object.assign(this, v);
  }
}

export class BaseStyles {

  constructor(v: Partial<BaseStyles> = {}) {
    Object.assign(this, v);
  }
}
