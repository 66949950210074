import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ScrollRequest} from './scroll-request';

@Injectable({providedIn: 'root'})
export class ScrollingService {
  /**
   * The scrolling operations which should be executed by the directive.
   */
  scrollRequest = new Subject<ScrollRequest>();

  /**
   * Events of this subject mark a finished scrolling operations.
   */
  scrollFinish = new Subject<ScrollRequest>();
  private id = 0;

  /**
   * Scrolls in the defined container to target element.
   * When the element is already in the view within the defined bounds, the container isn't scrolled unless the force param is set to true.
   * @param containerName The container to scroll.
   * @param target The element which the container should be scrolled to. Has to be a child of the container.
   * @param force Force the scroll even if the target is within bounds.
   * @param scrollPosition TODO: Write comment
   */
  scrollToTarget(containerName: string, target: HTMLElement, force = false, scrollPosition: ScrollLogicalPosition = 'nearest'): ScrollRequest {
    const req = new ScrollRequest({
      containerName,
      target,
      scrollId: ++this.id,
      force,
      scrollPosition
    });

    this.scrollRequest.next(req);
    return req;
  }

  /**
   * Scrolls in the defined container to target offset.
   * When the offset is already in the view within the defined bounds, the container isn't scrolled unless the force param is set to true.
   * @param containerName The container to scroll.
   * @param targetOffset The offset which the container should be scrolled to.
   * @param force Force the scroll even if the target is within bounds.
   */
  scrollToOffset(containerName: string, targetOffset: number, force = false): ScrollRequest {
    const req = new ScrollRequest({
      containerName,
      targetOffset,
      scrollId: ++this.id,
      force
    });

    this.scrollRequest.next(req);
    return req;
  }

  /**
   * Mark the scroll to be complete, so following actions (like highlighting) can be execudted,
   */
  finish(scrollRequest: ScrollRequest) {
    this.scrollFinish.next(scrollRequest);
  }
}
