// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';

export class SendEmailNode extends ProcessFlowBaseNode {
  constructor(v: Partial<SendEmailNode>) {
    super(v);
    this.type = 'ProcessFlow::SendEmailNode';
    this.settings = new SendEmailNodeSettings(v.settings as SendEmailNodeSettings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new SendEmailNodeSettings(v.recalculated_settings as SendEmailNodeSettings);
    }
  }

  settings: SendEmailNodeSettings;
  recalculated_settings: SendEmailNodeSettings;
}

export class SendEmailNodeSettings extends ProcessFlowBaseNodeSettings {
  to_calculation_type: RexCalculationType;
  to_calculation_javascript_definition: string;
  to: string;
  sender_name: string;
  reply_to_email: string;
  subject_calculation_type: RexCalculationType;
  subject_calculation_javascript_definition: string;
  subject: string;
  body_calculation_type: RexCalculationType;
  body_calculation_javascript_definition: string;
  body: string;
  max_total_attachments_byte_size: number;
  attachments_instructions_calculation_type: RexCalculationType;
  attachments_instructions_calculation_javascript_definition: string;
  attachments_instructions: SubmissionAttachmentsInstruction[];

  constructor(v: Partial<SendEmailNodeSettings> = {}) {
    super(v);
    this.to_calculation_type = v.to_calculation_type || RexCalculationType.CONSTANT;
    this.to_calculation_javascript_definition = v.to_calculation_javascript_definition || '';
    this.to = v.to || '';
    this.sender_name = v.sender_name || '';
    this.reply_to_email = v.reply_to_email || '';
    this.subject_calculation_type = v.subject_calculation_type || RexCalculationType.CONSTANT;
    this.subject_calculation_javascript_definition = v.subject_calculation_javascript_definition || '';
    this.subject = v.subject || '';
    this.body_calculation_type = v.body_calculation_type || RexCalculationType.CONSTANT;
    this.body_calculation_javascript_definition = v.body_calculation_javascript_definition || '';
    this.body = v.body || '';
    this.max_total_attachments_byte_size = v.max_total_attachments_byte_size || 10000000;
    this.attachments_instructions_calculation_type = v.attachments_instructions_calculation_type || RexCalculationType.CONSTANT;
    this.attachments_instructions_calculation_javascript_definition = v.attachments_instructions_calculation_javascript_definition || '';
    this.attachments_instructions = v.attachments_instructions || [];
  }
}

export class SubmissionAttachmentsInstruction {
  node_slug: string;
  attach_submission_pdf: boolean;
  attach_submission_audit_trail_pdf: boolean;
  attach_submission_sealed_pdf: boolean;
  attach_file_upload_input_uploads: boolean;
  attach_secure_file_upload_input_uploads: boolean;
  attach_attachments: boolean;

  constructor(v: Partial<SubmissionAttachmentsInstruction> = {}) {
    this.node_slug = v.node_slug || '';
    this.attach_submission_pdf = v.attach_submission_pdf || false;
    this.attach_submission_audit_trail_pdf = v.attach_submission_audit_trail_pdf || false;
    this.attach_submission_sealed_pdf = v.attach_submission_sealed_pdf || false;
    this.attach_file_upload_input_uploads = v.attach_file_upload_input_uploads || false;
    this.attach_secure_file_upload_input_uploads = v.attach_secure_file_upload_input_uploads || false;
    this.attach_attachments = v.attach_attachments || false;
  }
}
