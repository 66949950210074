import {BorderStyle} from './border-style';

export class CssStyle {
  background_color: string;
  border_bottom: BorderStyle;
  box_shadow: string;
  color: string;

  constructor(v: Partial<CssStyle> = {}) {
    Object.assign(this, v);
    this.border_bottom = new BorderStyle(v.border_bottom);
  }
}
