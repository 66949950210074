/* tslint:disable:member-ordering triple-equals */
import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  GuardsCheckEnd,
  NavigationExtras,
  Params,
  ResolveEnd,
  Router,
  RoutesRecognized
} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, filter, map, pluck} from 'rxjs/operators';
import {coerceNumberProperty} from '@angular/cdk/coercion';
import {fastDeepEqual} from './fastDeepCompare';

export type ActiveRouteState = {
  url: string;
  urlAfterRedirects: string;
  fragment: string;
  params: Params;
  queryParams: Params;
  data: Data;
  navigationExtras: NavigationExtras | undefined;
};

export type RouterState = {
  state: ActiveRouteState | null;
  titleFragments: string[];
};

@Injectable()
export class RouterStore {
  constructor(private router: Router) {
  }

  private lastRouterState = new ReplaySubject<RouterState>(1);

  private fastCompare = (a, b) => a == '' + b;

  /*
   Observables that represent the current application-state based on the route-infos.
   */
  routeData: Observable<Data> = this.lastRouterState.pipe(map(s => s.state.data), distinctUntilChanged(fastDeepEqual));
  routeParams: Observable<Params> = this.lastRouterState.pipe(map(s => s.state.params), distinctUntilChanged(fastDeepEqual));
  routeQueryParams: Observable<Params> = this.lastRouterState.pipe(map(s => s.state.queryParams), distinctUntilChanged(fastDeepEqual));
  titleFragments: Observable<string[]> = this.lastRouterState.pipe(map(s => s.titleFragments), distinctUntilChanged(this.fastCompare));
  url: Observable<string> = this.lastRouterState.pipe(map(s => s.state.url), distinctUntilChanged(this.fastCompare));
  isModalActive: Observable<boolean> = this.url.pipe(map(url => /(\(modal:m\/.*\))/g.test(url)));

  init() {
    /*
     Subscribe to the relevant events and push the state into the buffer-subject.
     By appending and overwriting the existing state, no state is lost.
     */
    this.router.events
      .pipe(filter(e => e instanceof ResolveEnd || e instanceof GuardsCheckEnd || e instanceof RoutesRecognized))
      .subscribe((e: ResolveEnd) => {
        this.lastRouterState.next(this.serializeRoutingEvents(e));
      });
  }

  /**
   * Returns an observable with the value of the selected queryParam value.
   * @param paramName
   */
  selectQueryParam(paramName: string): Observable<string> {
    return this.routeQueryParams
      .pipe(
        pluck(paramName),
        distinctUntilChanged()
      );
  }

  /**
   * Returns an observable with the value of the selected param value.
   * @param paramName
   */
  selectParam(paramName: string): Observable<string> {
    return this.routeParams
      .pipe(
        pluck(paramName),
        distinctUntilChanged()
      );
  }

  selectParams<const T extends string>(...paramNames: T[]): Observable<Record<T, number>> {
    return this.routeParams
      .pipe(
        map(params => {
          return paramNames.reduce((prev, curr) => {
            // @ts-ignore
            prev[curr] = coerceNumberProperty(params[curr], 0);
            return prev;
          }, {});
        })
      ) as Observable<Record<T, number>>;
  }

  /**
   * Serializes the incoming routing-events. Returns the known-format RouterState.
   * @param navigationEvent The event wich is to be serialized.
   */
  private serializeRoutingEvents(navigationEvent: RoutesRecognized | GuardsCheckEnd | ResolveEnd): RouterState {
    const titleFragments: string[] = [];
    let lastFragmentName = '';
    let state: ActivatedRouteSnapshot = navigationEvent.state.root;
    while (state.firstChild) {
      state = state.firstChild;

      if (state.firstChild?.data?.title?.length != null
        && state.firstChild?.data?.title.length > 0
        && state.firstChild?.data?.title !== lastFragmentName) {
        lastFragmentName = state.firstChild?.data?.title;
        titleFragments.push(state.firstChild?.data?.title);
      }
    }

    const {
      params,
      data,
      queryParams,
      fragment
    } = state;

    return {
      state: {
        url: navigationEvent.url,
        urlAfterRedirects: navigationEvent.urlAfterRedirects,
        params,
        queryParams,
        fragment,
        data,
        navigationExtras: this.router.getCurrentNavigation().extras ? this.router.getCurrentNavigation().extras.state : {}
      },
      titleFragments
    };
  }
}
