import {excluded} from '@paperlessio/sdk/api/models';

export class Integration { // OAuthApplication
  @excluded id: number;
  owner_id: number; // organization_id or user_id
  owner_type: IntegrationOwnerTypes;
  name: string;
  @excluded global: boolean;
  scopes: IntegrationScope[];

  constructor(v: Partial<Integration> = {}) {
    Object.assign(this, v);

    this.name = v.name ?? '';
    this.scopes = v.scopes ?? [];
  }
}

export enum IntegrationOwnerTypes {
  USER = 'User',
  ORGANIZATION = 'Organization'
}

export enum IntegrationScope {
  CURRENTUSERREAD = 'current_user.read',
  DOCUMENTREAD = 'document.read',
  DOCUMENTWRITE = 'document.write',
  WEBHOOKREAD = 'webhook.read',
  WEBHOOKWRITE = 'webhook.write',
  SUBMISSIONREAD = 'submission.read',
  TEMPLATEREAD = 'template.read'
}
