import {inject, Injectable, OnDestroy} from '@angular/core';
import * as uuid from 'uuid';
import {createConsumer} from '@rails/actioncable';
import {Subject} from 'rxjs';
import {
  ChannelIdentifier,
  ChannelIdentifierSubscription,
  ChannelMessage,
  ChannelMessageType,
  ChannelReceiver,
  ChannelSubscriber
} from './channel.type';
import {filter} from 'rxjs/operators';
import {WEBSOCKET_CONFIG} from '@paperlessio/sdk/util/tokens';

/**
 * the channel service opens a socket connection to the Api, see the Api-Docs at /api/v1/block_mutations
 * and https://guides.rubyonrails.org/action_cable_overview.html
 * Users can subscribe to a channel, which is uniquely identified by the SocketIdentifier
 * As long as the subscription is active incoming messages are received. The channel is closed by unsubscribing
 */
@Injectable({providedIn: 'root'})
export class ChannelService implements OnDestroy {
  private connection;
  private subscriptionsMap = new Map<string, ChannelIdentifierSubscription>();
  // tslint:disable-next-line:variable-name
  private _subscription_uuid: string = uuid.v4();

  private websocketConfig = inject(WEBSOCKET_CONFIG);

  get subscription_uuid() {
    return this._subscription_uuid;
  }

  constructor() {
    this.connect(this.websocketConfig.url);
  }

  subscribe(subscriber: ChannelSubscriber) {
    if (this.subscriptionsMap.has(this.asKey(subscriber.identifier))) { // you can only subscribe once to a channel
      return;
    }

    const subject = new Subject<any>();
    const subscriberIdentifierUnique = {...subscriber.identifier, subscription_uuid: this.subscription_uuid};

    const subscription: ChannelIdentifierSubscription = {
      // open the channel
      channelSubscription: this.connection.subscriptions.create(subscriberIdentifierUnique, this.createSubscriber(subject)),
      // create an observable which forwards the incoming message
      observableSubscription: subject.pipe(
        // only keep specific message types and messages originating from another application instance
        filter((message: ChannelMessage) => {
          return message.acting_subscription_uuid !== this.subscription_uuid || this.keepMessageType(message.type);
        })
      ).subscribe(this.createObserver(subscriber.receiver)),
    };

    this.subscriptionsMap.set(this.asKey(subscriber.identifier), subscription);
  }

  unsubscribe(subscriber: ChannelSubscriber) {
    return this.unsubscribeIdentifier(this.asKey(subscriber?.identifier));
  }

  send(identifier: ChannelIdentifier, action: string, data: any) {
    return this.getChannelSubscription(identifier)?.perform(action, data);
  }

  ngOnDestroy() {
    [...this.subscriptionsMap.keys()].map((channelIdentifier: string) => this.unsubscribeIdentifier(channelIdentifier));
    this.subscriptionsMap.clear();
    this.disconnect();
  }

  private getChannelSubscription(identifier: ChannelIdentifier) {
    return this.subscriptionsMap.get(this.asKey(identifier))?.channelSubscription;
  }

  private asKey(identifier: ChannelIdentifier): string {
    return JSON.stringify(identifier);
  }

  // store the receiver and map the in coming ChannelMessage to the receiving function message.type => function(message.data)
  private createChannelReceiver = (receiver: ChannelReceiver) => (message: ChannelMessage) =>
    receiver[message.type] ? receiver[message.type](message.data) : null;

  // call the receiver stored in the createChannelReceiver function
  private createObserver(receiver: ChannelReceiver) {
    return {next: this.createChannelReceiver(receiver)};
  };

  // these are callbacks and will be called by action_cable.js
  private createSubscriber(subject: Subject<any>) {
    return {
      connected: () => {
      },
      disconnected: () => {
      },
      // call the observer from createObserver
      received: (payload: string) => {
        subject.next(payload);
      },

      rejected: () => {
      },
      initialized: () => {
      }
    };
  }

  private connect(url: string) {
    this.connection = this.connection || createConsumer(url);
  }

  private disconnect() {
    this.connection?.disconnect();
    this.connection = null;
  }

  // cancel the socket subscription to the channel and the subscription to the observable here
  private unsubscribeIdentifier(identifierKey: string) {
    const subscription: ChannelIdentifierSubscription = this.subscriptionsMap.get(identifierKey);

    if (subscription?.channelSubscription) {
      this.connection.subscriptions.remove(subscription.channelSubscription);
    }
    if (subscription?.observableSubscription) {
      subscription.observableSubscription.unsubscribe();
    }

    this.subscriptionsMap.delete(identifierKey);
  }

  // these types of messages are kept, because local instance asked for them and we need them
  private keepMessageType(type: ChannelMessageType): boolean {
    return type === ChannelMessageType.BucketChannelEvents_EditorsChangedEvent
      || type === ChannelMessageType.BucketChannelEvents_SelectedBlocksChangedEvent;
  }
}
