import {Form} from '../form';
import {FormVersion} from '../form-version';
import {Incineratable} from '../util/incineratable';
import {Partial} from '../partial';
import {ProcessDefinition} from '../process-definition';
import {ProcessDefinitionVersion} from '../process-definition-version';
import {ProcessRun} from '../process-run';
import {ProcessTrigger} from '../process_trigger/process-trigger';
import {Submission} from '../submission';
import {Template} from '../template';
import {Workspace} from '../workspace';
import {Document} from '../document';

export function parseIncineratable(json: any): Incineratable {
  switch (json.constructor) {
    case Workspace: return new Workspace(json);
    case Template: return new Template(json);
    case Partial: return new Partial(json);
    case Document: return new Document(json);
    case FormVersion: return new FormVersion(json);
    case Form: return new Form(json);
    case ProcessDefinition: return new ProcessDefinition(json);
    case ProcessDefinitionVersion: return new ProcessDefinitionVersion(json);
    case ProcessRun: return new ProcessRun(json);
    case ProcessTrigger: return new ProcessTrigger(json);
    case Submission: return new Submission(json);
    default: return json;
  }
}
