import {Block, BlockSettings} from './block';
import {BlockType} from './block-type';

export class SelectOption extends Block {
  settings: SelectOptionSettings;

  constructor(v: Partial<SelectOption> = {}) {
    super(v);
    this.settings = new SelectOptionSettings(v.settings ?? {});
    this.type = v.type ?? BlockType.SelectOption;
  }
}

export class SelectOptionSettings extends BlockSettings {
  name: string;

  constructor(v: Partial<SelectOptionSettings> = {}) {
    super(v);
    this.name = v.name ?? '';
  }
}
