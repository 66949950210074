import {Input, InputSettings} from './input';

export class SecureFileUploadInput extends Input {
  settings: SecureFileUploadInputSettings;
  file: Blob;

  constructor(v: Partial<SecureFileUploadInput> = {}) {
    super(v);
    this.settings = new SecureFileUploadInputSettings(v.settings ?? {});
  }

  reset() {
    this.file = undefined;
  }
}

export class SecureFileUploadInputSettings extends InputSettings {
  maxFiles: number;

  constructor(v: Partial<SecureFileUploadInputSettings> = {}) {
    super(v);

    v.maxFiles = v.maxFiles ?? null;
  }
}
