import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, Subscription} from 'rxjs';
import {filter, first, map, mergeAll, switchMap, tap} from 'rxjs/operators';
import {ParticipationFlowService} from '@shared/participants/participation-flow.service';
import {
  Block,
  Participant,
  ParticipantRole,
  ParticipationFlow,
  ParticipationFlowEdge,
  ParticipationFlowMutationResult,
  ParticipationFlowNode,
  ParticipationFlowNodeDestroyErrors,
  ParticipationFlowNodeType
} from '@paperlessio/sdk/api/models';
import {ParticipationFlowMutationCollection} from '@shared/participants/participation-flow-mutation';
import {BucketChannelService} from '@paperlessio/sdk/realtime/bucket-channel';
import {BlockStore} from '@blocks/block/block.store';
import {ToastService} from '@paperlessio/sdk/api/util';
import {BaseStore} from '@paperlessio/sdk/api/stores';

@Injectable({providedIn: 'root'})
export class ParticipationFlowStore extends BaseStore<ParticipationFlow, ParticipationFlowService> implements OnDestroy {
  selectedParticipantId = new BehaviorSubject<number|null>(null);

  private subs = new Subscription();
  private processedLocalUuid: string[] = [];
  private participantMap = new Map<number, Participant>();

  constructor(public service: ParticipationFlowService,
              public toast: ToastService,
              private blockStore: BlockStore,
              private bucketChannelService: BucketChannelService) {
    super(service, toast);

    this.subs.add(this.participantNodes.subscribe((nodes: ParticipationFlowNode[]) => {
      this.participantMap.clear();
      nodes.forEach(node => this.participantMap.set(node.participant.id, node.participant));
    }));

    this.subscribeToParticipationFlowMutations();

    // Subscribe on subject to prevent circular dependency
    this.subs.add(blockStore.onMutated$
      .pipe(
        map(r => r.created_blocks),
        map(created_blocks => created_blocks.map(b => new Block(b)).filter(b => b.isInput)),
        switchMap(input_blocks => input_blocks.map(input_block => {
          return this.ensureParticipantRoles(input_block.block_owner_participant_ids, ParticipantRole.default);
        })),
        mergeAll()
      )
      .subscribe(results => {})
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get participantNodes(): Observable<ParticipationFlowNode[]> {
    return this.current.pipe(
      filter(flow => !!flow?.nodes),
      map(flow => flow.nodes.filter(node => node.type === ParticipationFlowNodeType.ParticipationFlowParticipantNode))
    );
  }

  initWithObject(object: ParticipationFlow) {
    this.current.next(object);
  }

  getParticipant(id: number): Participant {
    return this.participantMap.get(id);
  }

  /**
   * Retrieves the participant nodes of the given participant ids.
   */
  getParticipantNodes(participant_ids: number[]): ParticipationFlowNode[] {
    return this.current.value.participantNodes.filter(pn => participant_ids.indexOf(pn.participant.id) >= 0);
  }

  getLatestParticipant(): Participant {
    const id = Array.from(this.participantMap.keys()).sort().pop();
    return this.getParticipant(id);
  }

  getNode(node_id: number, continuous: boolean = false) {
    return this.current.pipe(
      filter(x => !!x),
      (!continuous ? first() : map(el => el)),
      map(flow => flow.nodes.find(node => node.id === node_id))
    );
  }

  /**
   * Shorthand to update & send single node.
   */
  updateNode(node: ParticipationFlowNode) {
    return this.send(
      new ParticipationFlowMutationCollection(this.current.value).updateNode(node)
    );
  }

  /**
   * Shorthand to update & send multiple nodes at once.
   */
  updateNodes(nodes: ParticipationFlowNode[]) {
    const collection = new ParticipationFlowMutationCollection(this.current.value);
    nodes.forEach(node => collection.updateNode(node));
    return this.send(collection);
  }

  checkDestroyPreconditions(node: ParticipationFlowNode): ParticipationFlowNodeDestroyErrors[] {
    const errors = [];
    if (node.type === ParticipationFlowNodeType.ParticipationFlowParticipantNode) {
      if (this.current.value.participantNodes.length === 1) {
        errors.push(ParticipationFlowNodeDestroyErrors.LAST_NODE);
      }

      if (this.blockStore.hasOwnedBlocks(node.participant)) {
        errors.push(ParticipationFlowNodeDestroyErrors.OWNS_BLOCKS);
      }
    }

    return errors;
  }

  /**
   * Ensure that the given participants have the given role.
   */
  ensureParticipantRoles(participant_ids: number[], role: ParticipantRole) {
    if (participant_ids.length <= 0) {
      return EMPTY;
    }

    const participantNodes = this.getParticipantNodes(participant_ids)
      .map(pn => {
        pn.participant.role = role;
        return pn;
      });

    return this.updateNodes(participantNodes);
  }

  /**
   * Send the collection to the server.
   */
  send(collection: ParticipationFlowMutationCollection): Observable<ParticipationFlowMutationResult> {
    // ignore changes coming form the server
    return this.service.mutate(collection).pipe(tap(this.mutate));
  }

  private mutate = (result: ParticipationFlowMutationResult) => {
    if (!result || this.processedLocalUuid.includes(result.local_uuid)) {
      return;
    }

    this.processMutationResult(result);
    this.processedLocalUuid.push(result.local_uuid);
  };

  private processMutationResult(result: ParticipationFlowMutationResult) {
    const createdNodes = result.created_nodes.map(node => new ParticipationFlowNode(node));
    const updatedNodes = result.updated_nodes.map(node => new ParticipationFlowNode(node));
    const deletedNodeIds = result.deleted_node_ids;
    const deleteNodesFilter = (node: ParticipationFlowNode) => !deletedNodeIds.includes(node.id);
    const localNodeFilter = (node: ParticipationFlowNode) => !createdNodes.find(createdNode => createdNode?.local_uuid === node.local_uuid);

    const createdEdges = result.created_edges.map(edge => new ParticipationFlowEdge(edge));
    const deletedEdgeIds = result.deleted_edge_ids;
    const deleteEdgesFilter = (edge: ParticipationFlowEdge) => !deletedEdgeIds.includes(edge.id);
    const localEdgeFilter = (edge: ParticipationFlowEdge) => !createdEdges.find(createdEdge => createdEdge?.local_uuid === edge.local_uuid);

    // replace node with updated one from server if updated
    const updateNodes = (node: ParticipationFlowNode) => updatedNodes.find(updatedNode => updatedNode.id === node.id) || node;

    const currentValue: ParticipationFlow = new ParticipationFlow({...this.current.value});
    currentValue.nodes = [...currentValue.nodes.filter(localNodeFilter), ...createdNodes].filter(deleteNodesFilter).map(updateNodes);
    currentValue.edges = [...currentValue.edges.filter(localEdgeFilter), ...createdEdges].filter(deleteEdgesFilter);
    this.current.next(currentValue);
  }

  private subscribeToParticipationFlowMutations() {
    this.subs.add(this.bucketChannelService.participantsChangedSubject?.pipe(
      tap((result: ParticipationFlowMutationResult) => this.mutate(result))
    ).subscribe());
  }
}
