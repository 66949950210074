import {BehaviorSubject, EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {BaseList, Expandable, ToastService} from '@paperlessio/sdk/api/util';
import {SentryHelper} from '@paperlessio/sdk/util/helpers';
import {ListableParams} from '@paperlessio/sdk/api/models';
import {BaseService, BaseServiceV1} from '@paperlessio/sdk/api/services';

export class BaseStore<T extends { id: number }, S extends BaseService<T> | BaseServiceV1<T>> {
  current: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);
  currentExpandAttributes = [];

  all: BehaviorSubject<BaseList<T>> = new BehaviorSubject<BaseList<T>>(undefined);
  lastFetchParams: Record<string, any> & ListableParams<T> = {};
  lastInitParams = {};
  lastPage = 1;

  protected entityName: string;

  constructor(protected service: S, protected toast: ToastService) {
    this.entityName = this.service.endpoint;
  }

  expandCurrent(expandable: Expandable<T, any>, attributes: any[]): Observable<T> {
    const currentValue = this.current.value;
    if (!currentValue) {
      return;
    }

    const params = expandable.getParams(...attributes);

    return this
      .get(currentValue.id, false, params)
      .pipe(
        switchMap(v => {
          if (v) {
            this.current.next(v);
            this.currentExpandAttributes = attributes;
          }

          return this.current.asObservable();
        })
      );
  }

  /**
   * Fetch all entries from entity, result will be represented in {@link BaseStore#all}
   * GET /entity
   * @param params - pass additional params (optional, e.g. for sorting, filtering etc.)
   */
  fetch(params: Record<string, any> & ListableParams<T> = {}): Observable<BaseList<T>> {
    this.lastFetchParams = params;
    return this.service.fetch(params).pipe(
      tap(all => this.all.next(all)),
      catchError(err => {
        this.handleFetchError(err);
        return throwError(err);
      })
    );
  }

  refetch(): Observable<BaseList<T>> {
    return this.service.fetch(this.lastFetchParams).pipe(
      tap(all => this.all.next(all)),
      catchError(err => {
        this.handleFetchError(err);
        return throwError(err);
      })
    );
  }

  refetchAll(params: Record<string, any> & ListableParams<T> = {}): Observable<BaseList<T>> {
    const newParams: ListableParams<T> = {
      ...this.lastFetchParams,
      ...params,
      page: 1,
      per: this.lastPage * (this.lastFetchParams?.per || 20)
    };

    if (this.lastFetchParams.all) {
      delete newParams.per;
      delete newParams.page;
    }

    return this.service
      .fetch(newParams)
      .pipe(
        tap(all => {
          this.all.next(all);
        }),
        catchError(err => {
          this.handlePaginateError(err);
          return throwError(err);
        })
      );
  }

  /**
   * Pushes data from another page to {@link BaseStore#all}
   * @param page - page as number (starting with 1)
   * @param params (additional params to pass (e.g. sorting, filtering)
   */
  paginate(page: number, params: Record<string, any> & ListableParams<T> = {}): Observable<BaseList<T>> {
    this.lastFetchParams = params;
    const mergedParams = Object.assign({page}, params);
    return this.service
      .fetch(mergedParams)
      .pipe(
        tap(all => {
          this.all.value.data.push(...all.data);
          this.all.next(this.all.value);
          if (all.data.length > 0) {
            this.lastPage = page;
          }
        }),
        catchError(err => {
          this.handlePaginateError(err);
          return throwError(err);
        })
      );
  }

  /**
   * Finds the object in {@link BaseStore#all} and returns it instantly, while requesting newest data from server
   * GET /entity/:id
   *
   * This might return a value from all which might not have the correct expanded properties.
   * @param id - id that needs to be fetched
   * @param returnExisting if current.id == id, return current immediately, then refetch asynchronously
   * @param params -
   */
  init(id: number, returnExisting = false, params = {}): Observable<T> {
    if (returnExisting && id === this.current?.value?.id && this.sameExpandParams(params)) {
      this._init(id, params).subscribe();
      return of(this.current.value);
    }

    if (!!this.all.value) {
      // @ts-ignore (property id does not exist on type T (x.id))
      const found = this.all.value.data.find(x => x.id === id);
      if (!!found) {
        this.current.next(found);
      }
    }
    return this._init(id, params);
  }

  protected _init(id: number, params = {}): Observable<T> {
    this.lastInitParams = params;

    return this.service.get(id, params).pipe(
      tap(current => {
        this.current.next(current);
        this.currentExpandAttributes = this.expandParamsFrom(params);
      }),
      catchError(error => {
        this.current.next(null);
        this.currentExpandAttributes = [];
        this.handleInitError(error);
        return throwError(error);
      })
    );
  }

  /**
   * Finds the object in {@link BaseStore#all} and returns it instantly, while requesting newest data from server
   * Just use all as cache
   * GET /entity/:id
   * @param id - id that needs to be fetched
   * @param returnExisting - re-fetch from server and ignore local values
   * @param params - the params to add to the request
   */
  get(id: number, returnExisting = true, params = {}): Observable<T> {
    if (returnExisting && (!!this.all.value || (!!this.current.value && this.current.value.id === id))) {
      const found = this.all?.value?.data?.find(x => x.id === id);
      if (!!found) {
        return of(found);
      } else if (!!this.current.value && this.current.value.id === id) {
        return of(this.current.value);
      }
    }
    return this.service
      .get(id, params)
      .pipe(
        catchError(error => {
          this.handleGetError(error);
          return throwError(error);
        })
      );
  }

  /**
   * Refreshes the current object in {@link BaseStore#current} from server.
   * GET /entity/:id
   */
  reloadCurrent(): Observable<T> {
    if (!this.current.value?.id) {
      return of(null);
    }

    const params = {};
    if (this.currentExpandAttributes.length) {
      params['expand[]'] = this.currentExpandAttributes;
    }

    return this._init(this.current.value.id, params);
  }

  /**
   * Refreshes a specific record from server.
   * Updates current if the reloaded record is current.
   * Updates the record in all if included there.
   * GET /entity/:id
   */
  reload(id: number): Observable<T> {
    return this.get(id, false, this.lastInitParams).pipe(tap(reloaded => {
      if (this.isCurrent(reloaded.id)) {
        this.current.next(reloaded);
      }

      if (!!this.all.value) {
        const idx = this.all.value.data.findIndex(x => x.id === reloaded.id);
        if (idx >= 0) {
          this.all.value.data[idx] = reloaded;
          this.all.next(this.all.value);
        }
      }
    }));
  }

  /**
   * Updates current and sends update request to server
   * PATCH/PUT /entity/:id
   * @param data - Updated data that should be sent to server
   * @param update_subjects - Optionally prevent updating the 'current' and 'all' subjects
   */
  update(data: Partial<T>, update_subjects = true): Observable<T> {
    if (this.isCurrent(data.id)) {
      Object.assign(this.current.value, data);
    }

    const params = {};
    if (this.currentExpandAttributes.length) {
      params['expand[]'] = this.currentExpandAttributes;
    }

    return this.service.update(data.id, data, params).pipe(
      tap(updated => {
        if (!update_subjects) {
          return;
        }

        if (this.isCurrent(updated.id)) {
          this.current.next(updated);
        }
        if (!!this.all.value) {
          const idx = this.all.value.data.findIndex(x => x.id === updated.id);
          if (idx >= 0) {
            this.all.value.data[idx] = updated;
            this.all.next(this.all.value);
          }
        }
      }),
      catchError(error => {
        this.handleUpdateError(error);
        return throwError(error);
      })
    );
  }

  /**
   * Creates a new object
   * POST /entity
   * @param data - the new object
   * @param params - additional queryParams to pass with the request
   * @param nextCurrent - use created object as next {@link BaseStore#current} after creation
   * @param fetchAll - by default, reload collection
   */
  create(data: Partial<T>, params = {}, nextCurrent = false, fetchAll = true): Observable<T> {
    return this.service.create(data, params).pipe(
      tap(created => {
        if (fetchAll) {
          this.fetch(this.lastFetchParams).subscribe();
        }
        if (nextCurrent) {
          this.current.next(created);
          this.currentExpandAttributes = this.expandParamsFrom(params);
        }
      }),
      catchError(error => {
        this.handleCreateError(error);
        return throwError(error);
      })
    );
  }

  /**
   * Duplicates an existing object
   * POST /entity
   * @param id - the id of the existing object
   * @param name - the name of the new object
   */
  duplicate(id: number, name: string): Observable<T> {
    return this.service.duplicate(id, name).pipe(
      tap(created => {
        this.fetch().subscribe();
      }),
      catchError(error => {
        this.handleDuplicateError(error);
        return throwError(error);
      })
    );
  }

  /**
   * Deletes {@link BaseStore#current} from Server and from Observable
   * DELETE /entity/:id
   * @param id - Optional, delete a specific entry instead of {@link BaseStore#current}
   */
  delete(id?: number): Observable<any> {
    if (!id && this.currentPresent) {
      // @ts-ignore
      id = this.current.value.id;
    } else if (!id && !this.currentPresent) {
      SentryHelper.captureBaseStoreError('Delete failed, no id & no current value present', 'error', {});
      return EMPTY;
    }

    return this.service.delete(id).pipe(
      tap(() => {
        if (this.isCurrent(id)) {
          this.current.next(undefined);
        }

        if (this.all.value?.data) {
          const idx = this.all.value.data.findIndex((x: any) => x.id === id);
          this.all.value.data.splice(idx, 1);
          this.all.value.count -= 1;
          this.all.next(this.all.value);
        }
      }),
      catchError(error => {
        this.handleDeleteError(error);
        return throwError(error);
      }));
  }

  handleFetchError(error: any) {
    this.handleError(error, BaseStoreOperationType.Fetch);
  }

  handlePaginateError(error: any) {
    this.handleError(error, BaseStoreOperationType.Paginate);
  }

  handleInitError(error: any) {
    this.handleError(error, BaseStoreOperationType.Init);
  }

  handleGetError(error: any) {
    this.handleError(error, BaseStoreOperationType.Get);
  }

  handleUpdateError(error: any) {
    this.handleError(error, BaseStoreOperationType.Update);
  }

  handleCreateError(error: any) {
    this.handleError(error, BaseStoreOperationType.Create);
  }

  handleDuplicateError(error: any) {
    this.handleError(error, BaseStoreOperationType.Duplicate);
  }

  handleDeleteError(error: any) {
    this.handleError(error, BaseStoreOperationType.Delete);
  }

  /**
   * Used to handle errors (e.g. show toasts)
   * @param error - previously returned error object
   */
  protected handleError(error: any, operation: BaseStoreOperationType = BaseStoreOperationType.Other): void {
    this.toast.error(`${this.entityName}.error`);
    SentryHelper.captureBaseStoreError(`[${this.entityName.toUpperCase()}] [${operation.toUpperCase()}]`, 'error', error);
  }

  protected get currentPresent() {
    return !!this.current.value;
  }

  protected isCurrent(id: number) {
    return this.currentPresent && this.current.value.id === id;
  }

  protected cleanup() {
    this.current.complete();
    this.current.unsubscribe();
    this.all.complete();
    this.all.unsubscribe();
  }

  protected sameExpandParams(fullParams: any): boolean {
    return JSON.stringify(this.currentExpandAttributes) === JSON.stringify(this.expandParamsFrom(fullParams));
  }

  /**
   * Get expand params from full params hash. Returns an empty array, when no expand params have been set.
   * @param fullParams
   * @private
   */
  private expandParamsFrom(fullParams: any): string[] {
    return fullParams?.['expand[]'] ?? [];
  }
}

enum BaseStoreOperationType {
  Other = 'Other',
  Fetch = 'Fetch',
  Paginate = 'Paginate',
  Init = 'Init',
  Get = 'Get',
  Update = 'Update',
  Create = 'Create',
  Duplicate = 'Duplicate',
  Delete = 'Delete'
}
