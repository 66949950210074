import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {DropdownTriggerDirective} from './directives/dropdown-trigger.directive';
import {DropdownPanelDirective} from './directives/dropdown-panel.directive';

// Popper imports
import {createPopper, Instance} from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import offset from '@popperjs/core/lib/modifiers/offset';
import {Placement} from '@popperjs/core/lib/enums';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import {Point} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {
  private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
  private renderer = inject(Renderer2);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private parentDropdownComponent = inject(DropdownComponent, {skipSelf: true, optional: true});

  @ViewChild('trigger') triggerContainer: ElementRef;
  @ContentChild(DropdownTriggerDirective) trigger: DropdownTriggerDirective;
  triggerTemplateRef: TemplateRef<unknown>;

  @ViewChild('panel') panelContainer: ElementRef<HTMLElement>;
  @ContentChild(DropdownPanelDirective) panel: DropdownPanelDirective;

  @Input() placement: Placement = 'bottom-end';
  @Input() showArrow = false;
  @Input() offsetTop = false;
  @Input() offset = 4;
  @Input() zIndex = 1001;

  /**
   * Set to true to have the panel have the same width as the trigger.
   */
  @Input() widthSynchronized = false;
  @Input() setPanelMaxHeight = false;

  @Input() externalTarget: ElementRef | HTMLElement;
  @Input() dropdownPosition: Point = null;

  /**
   * Please note: When 'renderPanelInBody' is true, this has no effect if the dropdown is nested.
   */
  @Input() hideOnInnerClick = true;
  @Input() hideOnOuterClick = true;
  @Input() hideOnOuterClickTrigger: 'mouseup' | 'mousedown' = 'mouseup';
  @Input() nestedDropdown = false;

  @Input() panelClasses: string[] = [];
  @Input() renderPanelInBody = false;
  @Input() elevation: 1 | 2 | 3 | 4 | '3-overlay' = 1;
  @Input() borderless = true;
  @Input() transparent = false;
  @Input() navigateEntriesWithArrowkeys = false;

  // Optional modifiers for the popper instance
  @Input() flip = true;
  @Input() preventOverflow = false;
  @Input() preventOverflowPadding = 10;
  @Input() preventOverflowBoundaryElement: HTMLDivElement | 'clippingParents' = 'clippingParents';
  @Input() hideMethod: 'ngif' | 'css' = 'ngif';

  @Output() closed = new EventEmitter();
  @Output() opened = new EventEmitter();

  visible = null;
  lock = false;
  initialized = false;
  prohibitCloseOnOuterClick = false;
  maxPanelHeight: number;

  private readonly hideClass = 'd-none';
  private popperInstance: Instance;
  private globalEventListeners: any[] = [];
  private dropdownWrapperId = 'pl-sdk-dropdown-wrapper';

  private runningInCypress = !!(window as any).Cypress;

  ngOnInit() {
    if (this.runningInCypress) {
      this.hideMethod = 'css';
    }
  }

  ngAfterViewInit() {
    if (this.renderPanelInBody) {
      this.ensureDropdownWrapperExistis();

      this.renderer.appendChild(this.getDropdownWrapper(), this.panelContainer.nativeElement);
    }
  }

  ngAfterContentInit(): void {
    // Has to be assigned here that it's accessible in the template
    this.triggerTemplateRef = this.trigger?.template;
  }

  ngOnDestroy(): void {
    this.hide(false);

    let wrapperElement = this.getDropdownWrapper();

    if (this.renderPanelInBody && this.panelContainer && wrapperElement) {
      this.renderer.removeChild(wrapperElement, this.panelContainer.nativeElement);
      wrapperElement = this.getDropdownWrapper();
      if (wrapperElement.children.length <= 0) {
        this.renderer.removeChild(document.body, wrapperElement);
      }
    }
  }

  show() {
    if (this.lock) {
      return;
    }

    const popperModifiers: any[] = [
      arrow,
      {
        name: 'arrow',
        options: {padding: 10},
      },
      offset,
      {
        name: 'offset',
        options: {offset: [0, this.offsetTop ? this.offset : 0],},
      },
      {
        name: 'computeStyles',
        options: {adaptive: false} // true by default, disabled for partial-preview to enable easing
      }
    ];

    if (!!this.flip) {
      popperModifiers.push(flip);
    }

    if (!!this.preventOverflow) {
      popperModifiers.push(preventOverflow);
      popperModifiers.push({
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: {top: this.preventOverflowPadding},
          boundary: this.preventOverflowBoundaryElement
        }
      });
    }

    if (this.popperInstance != null) {
      this.popperInstance.destroy();
    }

    if (this.widthSynchronized && (this.triggerContainer || this.externalTarget)) {
      let triggerWidth = 0;

      if (this.triggerContainer) {
        triggerWidth = (this.triggerContainer.nativeElement as HTMLElement).offsetWidth;
      } else if (this.externalTarget instanceof ElementRef && (this.externalTarget as ElementRef)?.nativeElement) {
        triggerWidth = (this.externalTarget.nativeElement as HTMLElement).offsetWidth;
      } else if (this.externalTarget instanceof HTMLElement && !(this.externalTarget as any)?.nativeElement) {
        triggerWidth = this.externalTarget.offsetWidth;
      }

      (this.panelContainer.nativeElement as HTMLElement).style.width = `${triggerWidth}px`;
    }

    let target = this.triggerContainer?.nativeElement;

    if (this.externalTarget) {
      target = this.externalTarget;
    }

    if (this.dropdownPosition) {
      target = this.getVirtualElement(this.dropdownPosition.x, this.dropdownPosition.y);
    }

    if (!target) {
      return;
    }

    // The automatic max height setting breaks the editor toolbar
    if (this.setPanelMaxHeight) {
      const targetRect = (target as HTMLElement)?.getBoundingClientRect() ?? {top: 0, height: 0};
      const viewportHeight = window?.visualViewport?.height ?? 0;

      // Calculate the max dropdown height and substract a 20px buffer
      const maxDropdownHeight = viewportHeight - (targetRect?.top ?? 0) - (targetRect.height ?? 0) - 20;

      if (maxDropdownHeight > 0) {
        (this.panelContainer.nativeElement as HTMLElement).style.overflowY = `auto`;
        (this.panelContainer.nativeElement as HTMLElement).style.maxHeight = `${maxDropdownHeight}px`;
      }
    }

    this.popperInstance = createPopper(
      target,
      this.panelContainer.nativeElement, {
        placement: this.placement,
        modifiers: popperModifiers
      });

    this.visible = true;

    if (this.parentDropdownComponent) {
      this.parentDropdownComponent.prohibitCloseOnOuterClick = true;
    }

    if (this.hideMethod === 'css' && this.panel?.ngIfDirective != null && !this.initialized) {
      this.panel.ngIfDirective.ngIf = true;
      this.initialized = true;
    }

    if (this.hideMethod === 'ngif') {
      if (this.panel?.ngIfDirective) {
        this.panel.ngIfDirective.ngIf = true;
      }
    } else if (this.panelClasses.indexOf(this.hideClass) >= 0) {
      const set = new Set(this.panelClasses);
      set.delete(this.hideClass);
      this.panelClasses = Array.from(set);
    }

    this.changeDetectorRef.detectChanges();

    requestAnimationFrame(() => {
      this.popperInstance.update();

      this.maxPanelHeight = document.body.getBoundingClientRect().height - this.panelContainer.nativeElement.getBoundingClientRect().top;
      this.maxPanelHeight *= 0.9;
      this.changeDetectorRef.markForCheck();
    });

    if (!!this.hideOnOuterClick) {
      setTimeout(() => {
        this.popperInstance.update();

        if (this.hideOnOuterClickTrigger === 'mousedown') {
          this.globalEventListeners.push(this.renderer.listen('document', 'touchstart', this.hideOnClickOutsideHandler.bind(this)));
          this.globalEventListeners.push(this.renderer.listen('document', 'mousedown', this.hideOnClickOutsideHandler.bind(this)));
        } else if (this.hideOnOuterClickTrigger === 'mouseup') {
          this.globalEventListeners.push(this.renderer.listen('document', 'touchend', this.hideOnClickOutsideHandler.bind(this)));
          this.globalEventListeners.push(this.renderer.listen('document', 'mouseup', this.hideOnClickOutsideHandler.bind(this)));
        }
      }, 10);
    }

    if (this.navigateEntriesWithArrowkeys) {
      this.addArrowKeyListeners();
    }

    setTimeout(() => {
      const el = this.elementRef.nativeElement.getElementsByClassName('autofocus')[0] as HTMLElement;
      if (el) {
        el.focus();
      }
    }, 20);

    this.opened.emit();
  }

  getVirtualElement(x: number, y: number): { getBoundingClientRect: () => ClientRect } {
    return {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        left: x,
        right: x,
        top: y,
        bottom: y
      }) as any
    };
  }

  hide(fireCdr = true) {
    this.visible = false;

    if (this.parentDropdownComponent) {
      this.parentDropdownComponent.prohibitCloseOnOuterClick = false;
    }

    if (this.hideMethod === 'ngif') {
      if (this.panel?.ngIfDirective) {
        this.panel.ngIfDirective.ngIf = false;
      }
    } else if (this.panelClasses.indexOf(this.hideClass) < 0) {
      this.panelClasses.push(this.hideClass);
    }

    if (fireCdr) {
      this.changeDetectorRef.detectChanges();
    }

    if (!!this.popperInstance) {
      this.popperInstance.destroy();
    }

    this.clearGlobalEventListeners();
    this.closed.emit();
  }

  toggle() {
    if (!this.visible) {
      this.show();
    } else {
      this.hide();
    }
  }

  hideOnClickOutsideHandler(event: MouseEvent): void {
    if (this.prohibitCloseOnOuterClick || this.runningInCypress) {
      return;
    }

    if (event.srcElement && event.srcElement === this.panelContainer.nativeElement ||
      this.panelContainer.nativeElement.contains(event.srcElement as HTMLElement)) {
      return;
    }

    this.lock = true;
    setTimeout(() => this.lock = false, 20);

    this.hide();
  }

  onInnerPanelClick() {
    if (this.hideOnInnerClick) {
      setTimeout(() => this.hide(), 1);
    }
  }

  updateDimensions() {
    setTimeout(() => this.popperInstance?.update(), 1);
  }

  private clearGlobalEventListeners() {
    this.globalEventListeners.forEach(evt => {
      if (evt && typeof evt === 'function') {
        evt();
      }
    });
  }

  private addArrowKeyListeners() {
    requestAnimationFrame(() => {
      this.globalEventListeners.push(this.renderer.listen('document', 'keydown', event => {
        if (!['ArrowUp', 'ArrowDown', 'Escape'].includes(event.code)) {
          return;
        }

        if (event.code === 'Escape') {
          this.hide();
          return;
        }

        const anchorElements= Array.from(this.panelContainer.nativeElement.querySelectorAll<HTMLAnchorElement>('a, button'));
        const focusedAnchorElementIndex = () => anchorElements.findIndex(a => document.activeElement.isSameNode(a));

        let focusIndex = 0;
        if (event.code === 'ArrowUp') {
          focusIndex = (focusedAnchorElementIndex() - 1) % anchorElements.length;
          if (focusIndex < 0) {
            focusIndex = anchorElements.length - 1;
          }
        } else if (event.code === 'ArrowDown') {
          focusIndex = (focusedAnchorElementIndex() + 1) % anchorElements.length;
          if (focusIndex >= anchorElements.length) {
            focusIndex = 0;
          }
        }

        anchorElements[focusIndex].focus();
      }));
    });
  }

  private ensureDropdownWrapperExistis() {
    if (!this.getDropdownWrapper()) {
      const wrapperElement = document.createElement('div');
      wrapperElement.id = this.dropdownWrapperId;
      this.renderer.appendChild(document.body, wrapperElement);
    }
  }

  private getDropdownWrapper() {
    return document.body.querySelector(`#${this.dropdownWrapperId}`);
  }
}
