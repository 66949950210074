import {Input, InputSettings, InputStyles} from './input';
import {Size} from '../util/size';

export class CheckboxInput extends Input {
  default_value: number[];
  settings: CheckboxInputSettings;
  styles: CheckboxInputStyles;

  constructor(v: Partial<CheckboxInput> = {}) {
    super(v);
    this.default_value = v.default_value ? v.default_value.map(d => +d) : [];
    this.settings = new CheckboxInputSettings(v.settings ?? {});
    this.styles = new CheckboxInputStyles(v.styles ?? {});
  }
}

export class CheckboxInputSettings extends InputSettings {
  fullWidth: boolean;
  aspectRatio = 1.0;

  constructor(v: Partial<CheckboxInputSettings> = {}) {
    super(v);
    this.fullWidth = v.fullWidth ?? true;
  }

  get minSize(): Size {
    return new Size({
      width: 10,
      height: 10
    });
  }

  get maxSize(): Size {
    return new Size({
      width: 300,
      height: 300
    });
  }

  get defaultSize(): Size {
    return new Size({
      width: 30,
      height: 30
    });
  }
}

export class CheckboxInputStyles extends InputStyles {
  inline: boolean;
  format: CheckboxInputFormat;

  constructor(v: Partial<CheckboxInputStyles> = {}) {
    super(v);
    this.inline = v.inline ?? false;
    this.format = v.format ?? CheckboxInputFormat.LIST;
  }
}

export class CheckboxInputValidations {
  min: number;
  max: number;

  constructor(v: Partial<CheckboxInputValidations> = {}) {
    this.min = v.min ?? 0;
    this.max = v.max ?? -1;
  }
}

export enum CheckboxInputFormat {
  LIST,
  BUTTONS
}
