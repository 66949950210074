import {ApplicationPolicy} from './application_policy';
import {Document, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models'

export class DocumentPolicy extends ApplicationPolicy {
  record: Document;

  index(): boolean {
    return true;
  }

  show(): boolean {
    return true;
  }

  create(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  dizpatch(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  send_reminder(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  launch(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  download_pdf(): boolean {
    return this.record?.completed && this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  duplicate(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  cancel(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }
}
