import {Injectable} from '@angular/core';
import {IndividualConfig, ToastrService} from 'ngx-toastr';
import {TranslocoService} from '@ngneat/transloco';
import {first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ToastService {
  constructor(
    private toastrService: ToastrService,
    private translate: TranslocoService) {
  }

  /**
   * shows a translated error toast
   *
   * i18nString can either return a string (message only) or a object ({title: string, message: string})
   *
   * @param i18nString - following naming convention "entity.view.action(optional).error" e.g. "documents.list.error"
   * @param params - params object to fill string interpolations
   */
  error(i18nString: string, params?: object, config?: Partial<IndividualConfig>): void {
    this.toast('error', i18nString, params, config);
  }

  /**
   * shows a translated warning toast
   *
   * i18nString can either return a string (message only) or a object ({title: string, message: string})
   *
   * @param i18nString - following naming convention "entity.view.action(optional).error" e.g. "documents.list.error"
   * @param params - params object to fill string interpolations
   */
  warning(i18nString: string, params?: object, config?: Partial<IndividualConfig>): void {
    this.toast('warning', i18nString, params, config);
  }

  /**
   * shows a translated success toast
   *
   * i18nString can either return a string (message only) or a object ({title: string, message: string})
   *
   * @param i18nString - following naming convention "entity.view.action(optional).error" e.g. "documents.list.error"
   * @param params - params object to fill string interpolations
   */
  success(i18nString: string, params?: object, config?: Partial<IndividualConfig>): void {
    this.toast('success', i18nString, params, config);
  }

  /**
   * shows a translated info toast
   *
   * i18nString can either return a string (message only) or a object ({title: string, message: string})
   *
   * @param i18nString - following naming convention "entity.view.action(optional).error" e.g. "documents.list.error"
   * @param params - params object to fill string interpolations
   */
  info(i18nString: string, params?: object, config?: Partial<IndividualConfig>): void {
    this.toast('info', i18nString, params, config);
  }

  private toast(type: 'error' | 'success' | 'warning' | 'info', translate: string, params = {}, config?: Partial<IndividualConfig>): void {
    this.translate.selectTranslate(translate, params).pipe(
      first(),
      switchMap(translated => {
        if (translated === translate) {
          return this.translate.selectTranslateObject(translate, {title: params, message: params}).pipe(first());
        } else {
          return of(translated);
        }
      })
    ).subscribe(translated => {
      if (translated instanceof Object) {
        this.toastrService[type](translated.message, translated.title, config ? config : {});
      } else {
        this.toastrService[type](translated, null, config ? config : {});
      }
    });
  }
}
