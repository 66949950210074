import {excluded} from './util/excluded';
import {IWorkspaceMembership} from './workspace-membership.interface';
import {ListColumn, ListColumnSize} from './column';
import {WorkspaceMembershipRole} from './workspace-membership-role';
import {NotificationSettings, SpecialNotificationLevel} from './notification-settings';
import {WorkspaceMembershipSettings} from './workspace-membership-settings';
import {Workspace} from './workspace';
import {User} from './user';

export class WorkspaceMembership implements IWorkspaceMembership {
  static columns: ListColumn[] = [
    new ListColumn({property: 'name', align: 'left', size: ListColumnSize.col05}),
    new ListColumn({property: 'email', align: 'left', size: ListColumnSize.col04}),
    new ListColumn({property: 'role', align: 'left', size: ListColumnSize.col02}),
    new ListColumn({property: null, align: 'left', size: ListColumnSize.col01}),
  ];
  // static defaultColumns = Submission.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  id: number;
  workspace_id: number;
  user_id: number;
  role: WorkspaceMembershipRole;
  notification_settings: NotificationSettings;
  settings: WorkspaceMembershipSettings;
  organization_membership_id: number;

  @excluded workspace: Workspace;
  @excluded invite_email: string;
  @excluded user: User;
  @excluded creator: User;
  @excluded scim_managed: boolean;
  @excluded support_membership: boolean; // used to mark memberships from paperless support team
  @excluded created_at: Date;
  @excluded updated_at: Date;

  constructor(v: Partial<WorkspaceMembership> = {}) {
    Object.assign(this, v);
    this.workspace = new Workspace(v.workspace);
    this.user = v.user ? new User(v.user) : undefined;
    this.creator = v.creator ? new User(v.creator) : undefined;
    this.role = v.role ?? WorkspaceMembershipRole.guest;
    this.notification_settings = v.notification_settings
      ? new NotificationSettings(v.notification_settings)
      : new NotificationSettings({notificationLevel: SpecialNotificationLevel.global});
    this.settings = new WorkspaceMembershipSettings(v.settings ?? {});
    this.scim_managed = v.scim_managed ?? false;
    this.support_membership = v.support_membership ?? false;
    this.created_at = new Date(v.created_at);
    this.updated_at = new Date(v.updated_at);
  }

  get name() {
    return this.user?.name;
  }

  get email() {
    return this.user?.email;
  }
}
