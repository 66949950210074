import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalizationService} from '@shared/i18n/localization.service';
import {DropdownModule} from '@paperlessio/sdk/components/dropdown';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslocoModule} from '@ngneat/transloco';
import {ContentLocale, UILocale} from '@paperlessio/sdk/api/util';
import {faChevronDown, faGlobe} from '@fortawesome/pro-regular-svg-icons';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {ContentLocaleSetting} from '@paperlessio/sdk/api/models';

@Component({
  selector: 'pl-locale-switcher',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FontAwesomeModule,
    TranslocoModule
  ],
  templateUrl: './locale-switcher.component.html',
  styleUrls: ['./locale-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocaleSwitcherComponent implements OnInit, OnDestroy {
  @Input() routeChange = true;
  @Input() uiLocaleHandling: boolean;

  contentLocale: ContentLocale;
  ContentLocale = ContentLocale;

  private localizationService = inject(LocalizationService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);
  private subs = new Subscription();

  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIcons(faGlobe, faChevronDown);
  }

  ngOnInit() {
    this.subs.add(this.localizationService.contentLocale.subscribe(locale => {
      this.contentLocale = locale;
      this.cdr.detectChanges();
    }));
    this.subs.add(this.route.queryParams.pipe(first()).subscribe(queryParams => {
      if (queryParams.locale) {
        this.changeLocale(queryParams.locale, false);
      }
    }));
  }

  changeLocale(locale: ContentLocale, route = true) {
    this.localizationService?.setContentLocale(locale);

    let uiLocale = locale as unknown as string;
    if (uiLocale.includes('-x-')) {
      uiLocale = uiLocale.split('-x-')[0];
    }

    if (this.uiLocaleHandling && Object.values(UILocale).includes(uiLocale as unknown as UILocale)) {
      this.localizationService?.setUILocale(uiLocale as unknown as UILocale);
    }

    if (this.routeChange && route) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {locale: locale},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  get configuredContentLocaleSettings(): ContentLocaleSetting[] {
    return this.localizationService.configuredContentLocaleSettings;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
