import {ApplicationPolicy} from './application_policy';
import {Partial, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class PartialPolicy extends ApplicationPolicy {
  record: Partial;

  index(): boolean {
    return true;
  }

  show(): boolean {
    return true;
  }

  create(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  prepare(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  duplicate(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.record.creator_id === this.user.id;
  }
}
