import {BaseSerializer} from '@paperlessio/sdk/api/util';
import {Block, parseBlock} from '@paperlessio/sdk/api/models';

export class BlockSerializer implements BaseSerializer<Block> {
  fromJson(json: any): Block {
    return parseBlock(json)
  }

  toJson(obj: Block): object {
    obj.settings.hasToken = obj._hasToken;
    let params: any = {block: obj};
    params = JSON.parse(JSON.stringify(params));
    return params;
  }
}
