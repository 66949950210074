@if (additionalButtons?.length > 0) {
  @for (additionalButton of additionalButtons; track $index + additionalButton.label; let last = $last) {
    <app-styleable-button
      [class.mr-2]="!last"
    >
      <button
        type="button"
        class="btn has-loading-indicator"
        [class.loading]="additionalButton.loading"
        [style.color]="additionalButton.textColor"
        [style.background-color]="additionalButton.backgroundColor"
        (click)="executeActions(additionalButton)"
      >
        {{additionalButton.label}}
        <div class="loading-indicator">
          <pl-loading-bubbles></pl-loading-bubbles>
        </div>
      </button>
    </app-styleable-button>
  }
}
