import {ApplicationPolicy} from './application_policy';
import {FormVersion, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class FormVersionPolicy extends ApplicationPolicy {
  record: FormVersion;

  index(): boolean {
    return true;
  }

  show(): boolean {
    return true;
  }

  create(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }
}
