import {Document, Participant, Token} from '@paperlessio/sdk/api/models';
import {Serializer} from './serializer';

export class SubmittableSerializer<T> extends Serializer<T> {
  toDispatchJson(obj: Document | Partial<Document>): object {
    const params: any = {...obj};

    const participants = {};
    params.participants?.forEach((p: Participant) => {
      participants[p.slot_name] = {email: p.email, name: p.name};
    });
    params.participants = participants;

    const tokens = {};
    params.tokens?.forEach((t: Token) => {
      tokens[t.name] = t.value;
    });
    params.tokens = tokens;

    // do not send an empty array of blocks to the server
    if (params.blocks?.length === 0) {
      delete params.blocks;
    }

    return params;
  }
}
