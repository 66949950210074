import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {fromEvent, Observable, Subject, Subscription, timer} from 'rxjs';
import {debounceTime, delay, retryWhen, startWith, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

export interface ConnectionState {
  hasNetworkConnection: boolean;
  hasInternetAccess: boolean;
}

interface ConnectionServiceOptions {
  enableHeartbeat?: boolean;
  heartbeatUrl?: string;
  heartbeatInterval?: number;
  heartbeatRetryInterval?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ConnectionService implements OnDestroy {
  private options: ConnectionServiceOptions = {
    enableHeartbeat: false,
    heartbeatUrl: '/heartbeat', // todo: change dis to apesign.de
    heartbeatInterval: 30000,
    heartbeatRetryInterval: 10000
  };

  private stateChangeEventEmitter = new EventEmitter<ConnectionState>();

  private currentState: ConnectionState = {
    hasInternetAccess: true,
    hasNetworkConnection: window.navigator.onLine
  };

  private wasConnected: boolean = null;
  private offlineSubscription: Subscription;
  private onlineSubscription: Subscription;
  private httpSubscription: Subscription;

  onReconnect: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {
    this.checkNetworkState();
    this.checkInternetState();
  }

  private checkInternetState() {
    if (!!this.httpSubscription) {
      this.httpSubscription.unsubscribe();
    }

    if (this.options.enableHeartbeat) {
      this.httpSubscription = timer(0, this.options.heartbeatInterval)
        .pipe(
          switchMap(() => this.http.head(this.options.heartbeatUrl, {responseType: 'text'})),
          retryWhen(errors =>
            errors.pipe(
              // log error message
              tap(val => {
                console.error('Http error:', val);
                this.currentState.hasInternetAccess = false;
                this.emitEvent();
              }),
              // restart after 5 seconds
              delay(this.options.heartbeatRetryInterval)
            )
          )
        )
        .subscribe(result => {
          this.currentState.hasInternetAccess = true;
          this.emitEvent();
        });
    } else {
      this.currentState.hasInternetAccess = true;
      this.emitEvent();
    }
  }

  private checkNetworkState() {
    this.onlineSubscription = fromEvent(window, 'online').subscribe(() => {
      this.currentState.hasNetworkConnection = true;
      this.checkInternetState();
      this.emitEvent();
    });

    this.offlineSubscription = fromEvent(window, 'offline').subscribe(() => {
      this.currentState.hasNetworkConnection = false;
      this.checkInternetState();
      this.emitEvent();
    });
  }

  private updateIsConnected() {
    const newIsConnected = this.currentState.hasNetworkConnection && this.currentState.hasInternetAccess;
    if (newIsConnected && this.wasConnected === false) {
      this.onReconnect.next(true);
    }
    this.wasConnected = newIsConnected;
  }

  private emitEvent() {
    this.updateIsConnected();
    this.stateChangeEventEmitter.emit(this.currentState);
  }

  ngOnDestroy(): void {
    this.offlineSubscription?.unsubscribe();
    this.onlineSubscription?.unsubscribe();
    this.httpSubscription?.unsubscribe();
  }

  /**
   * Monitor Network & Internet connection status by subscribing to this observer. If you set "reportCurrentState" to "false" then
   * function will not report current status of the connections when initially subscribed.
   * @param reportCurrentState Report current state when initial subscription. Default is "true"
   */
  monitor(reportCurrentState = true): Observable<ConnectionState> {
    return reportCurrentState ?
      this.stateChangeEventEmitter.pipe(
        debounceTime(300),
        startWith(this.currentState),
      )
      :
      this.stateChangeEventEmitter.pipe(
        debounceTime(300)
      );
  }
}
