<ng-container *transloco="let t; read: 'session'">
  <fa-icon *ngIf="arrow && !value"
           [icon]="['far','arrow-right']"
           class="arrow d-none d-xl-inline-block"
           size="1x">
  </fa-icon>

  <label class="small my-n1">
    <input #i="ngModel"
           [ngModel]="value"
           (ngModelChange)="changeValue($event)"
           type="checkbox"
           id="terms"
           name="terms"
           aria-describedby="#terms-description">

    <span id="terms-description"
          class="text1"
          [innerHTML]="t('terms.text1')">
    </span>
    <span class="text2"
          [innerHTML]="t('terms.text2')">
    </span>
  </label>
</ng-container>
