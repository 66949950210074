import {ChangeDetectorRef, Component, ElementRef, forwardRef, inject, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'pl-session-tos-checkbox',
  templateUrl: './session-tos-checkbox.component.html',
  styleUrls: ['./session-tos-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SessionTosCheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SessionTosCheckboxComponent,
      multi: true
    }
  ]
})
export class SessionTosCheckboxComponent implements ControlValueAccessor {
  private cdr = inject(ChangeDetectorRef);
  private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  @Input() disabled: boolean;
  @Input() value: boolean;
  @Input() arrow: boolean;
  @Input() required: boolean;

  onChange = (value: boolean) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.value = !!value;

    this.cdr.markForCheck();
  }

  changeValue(value: boolean): void {
    if (this.disabled) {
      return;
    }

    const valueChanged = value !== this.value;

    if (valueChanged) {
      this.writeValue(value);
      this.onChange(value);
    }

    this.onTouched();
  }

  validate({value}: FormControl) {
    if (this.required && !value) {
      return {required: true};
    }

    return null;
  }

  focus() {
    this.elementRef.nativeElement.querySelector('input').focus();
  }
}
