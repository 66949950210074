import {Design} from './design';
import {EmailSettings} from './email-settings';
import {EmailStyles} from './email-styles';

export class EmailDesign extends Design {
  email_settings: EmailSettings;
  email_styles: EmailStyles;

  constructor(v: Partial<EmailDesign> = {}) {
    super(v);
    this.email_settings = new EmailSettings(v.email_settings ?? {});
    this.email_styles = new EmailStyles(v.email_styles ?? {});
  }
}
