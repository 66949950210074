export class Permission {
  templates: { // only sales/general workspace
    show: false, create: false, edit: false, delete: false
  };
  submissions: {
    show: false, create: false, edit: false, delete: false
  };
  submittables: {
    show: false, create: false, edit: false, delete: false
  };
  partials: {
    show: false, create: false, edit: false, delete: false
  };
  settings: {
    workspace: {show: false, edit: false, delete: false},
    membership: {show: false, create: false, edit: false, delete: false}
  };

  static allowed(permissions: object, permission: string): boolean {
    const v = ('root.' + permission).split('.');
    let obj = permissions;
    let index = 0;

    while (obj !== null && obj !== undefined && index < v.length) {
      obj = obj[v[index++]];
      if (typeof obj === 'boolean') {
        return obj;
      } else if (typeof obj === 'symbol') {
        return false;
      }
    }
    return false;
  }
}
