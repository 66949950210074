import {Expandable} from '@paperlessio/sdk/api/util';
import {Submittable} from './submittable';
import {ListColumn, ListColumnSize, ListColumnType} from './column';
import {Addons} from './addons';
import {Participant, ParticipantState} from './participant';
import {Submission} from './submission';
import {DocumentState} from './document-state';
import {User} from './user';
import {excluded} from './util/excluded';

export class Document extends Submittable {
  static expandable = new Expandable<Document, 'tokens' | 'designs' | 'creator' | 'participants' | 'participation_flow' | 'participation_flow.participant'>();

  static columns: ListColumn[] = [
    new ListColumn({property: 'name', default: true, fixed: true, align: 'left', size: ListColumnSize.col03, type: ListColumnType.documentName}),
    new ListColumn({property: 'state', default: true, align: 'left', type: ListColumnType.badge, translate: true}),
    new ListColumn({property: 'updated_at', default: true, align: 'left', type: ListColumnType.timeAgo}),
    new ListColumn({property: 'activity', default: true, align: 'left', type: ListColumnType.activity}),
    new ListColumn({property: 'page_progress', align: 'left', type: ListColumnType.pageProgress, hasInfo: true, requiresAddon: Addons.wizards}),
    new ListColumn({property: 'creator', default: true, align: 'left', type: ListColumnType.creator}),
    new ListColumn({property: 'source_template_name', align: 'left', size: ListColumnSize.col02}),
    new ListColumn({property: 'completed_at', align: 'left', type: ListColumnType.timeAgo}),
  ];

  static defaultColumns = Document.columns.filter(x => x.default && !x.fixed).map(x => x.property);
  static states = ['draft', 'dispatched', 'completed'];

  participants: Array<Participant>;
  participants_string: string;
  submission: Submission;
  submission_id: number;
  document_id?: number; // to duplicate an existing document
  archived_at: Date;
  @excluded participants_dispatch_strategy: 'email' | 'api_response_initial_participants'; // for Advanced document creation only
  participant_completed_redirect_url?: string;

  // The signed Blob ID
  pdf: string;

  constructor(v: Partial<Document> = {}) {
    super(v);
    this.setParticipants(v.participants?.map(p => new Participant(p)) ?? []);
    this.submission = v.submission ? new Submission(v.submission) : undefined;
    this.submission_id = v.submission_id ?? undefined;
    this.participants_dispatch_strategy = v.participants_dispatch_strategy ?? 'email';
    this.participant_completed_redirect_url = v.participant_completed_redirect_url ?? undefined;
  }

  /**
   * This method only works in a advanced document creation context
   */
  get validForAdvancedDispatch(): boolean {
    return this.participants.length > 0 && !!this.name &&
      !!this.template_id && !!this.workspace_id &&
      this.participants_dispatch_strategy === 'email' &&
      this.state === DocumentState.dispatched &&
      this.participants.every(p => p.validForDispatch) &&
      this.tokens.every(t => t.validForDispatch);
  }

  /**
   * This state combines the documents state and the state of the participants.
   * If there's only one participant, it's state should be returned. Otherwise the documents state should be returned.
   */
  get combinedState(): DocumentState | ParticipantState {
    if (this.state === DocumentState.cancelled || this.state === DocumentState.declined) {
      return this.state;
    }

    if (this.participants.length === 1 && this.participants[0].state !== ParticipantState.initialized) {
      return this.participants[0].state;
    } else {
      return this.humanState;
    }
  }

  get humanState(): DocumentState {
    if (this.state !== DocumentState.declined && this.participants.some(p => p.state === ParticipantState.dispatch_failed)) {
      return DocumentState.dispatch_failed;
    } else {
      return this.state;
    }
  }

  get eligibleForPrepare() {
    return this.state === DocumentState.draft;
  }

  allowsImpersonationByUser(user: User): boolean {
    const p = this.participants.find(p => p.allowsImpersonationByUser(user));
    return ![DocumentState.completed, DocumentState.cancelled, DocumentState.declined].includes(this.state) && p != null;
  }

  // /m/participants/:id/replace
  // Participant => state
  // * dispatch_failed => error
  // * waiting => langweiliges replace
  // * alle anderen => modal schließen

  // *P1* => P2 => P3

  get completed() {
    return this.state === 'completed';
  }

  get source_template_name(): string {
    return this.source_template?.name;
  }

  get isDeletable(): boolean {
    return this.state === DocumentState.draft;
  }

  get isCancellable(): boolean {
    return this.state === DocumentState.dispatched;
  }

  get isArchivable(): boolean {
    return (
      this.state === DocumentState.completed ||
      this.state === DocumentState.cancelled ||
      this.state === DocumentState.declined
    ) && this.archived_at == null;
  }

  setParticipants(value: Array<Participant>) {
    this.participants = value;
    this.participants_string = this.buildParticipantsString();
  }

  private buildParticipantsString() {
    // Filter out participants without name/email and the ones that have been replaced
    return this.participants
      .filter(p => (p.name || p.email).length && p.replaced_by_participant_id == null)
      .map(p => p.name || p.email)
      .join(', ');
  }
}
