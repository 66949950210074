/**
 * Decorator to exclude properties from a typed class
 */
export function excluded(type, name) {
  // I have no clue why this is needed. Honestly. But this bug is strictly contained within the e2e tests.
  // When blocks are added via the addRawBlock helper in cypress, somehow the excluded decorator here fails. I don't know why.
  if (!type) {
    return;
  }

  // Sever the reference to the pre-existing json_exclusions array to not modify the json_exclusions array of the parent
  type.json_exclusions = (type.json_exclusions || []).slice();

  type.json_exclusions.push(name);
}
