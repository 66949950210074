export class ScrollRequest {
  constructor(v: Partial<ScrollRequest>) {
    this.containerName = v.containerName ?? null;
    this.target = v.target ?? null;
    this.scrollId = v.scrollId ?? null;
    this.targetOffset = v.targetOffset ?? null;
    this.force = v.force ?? false;
    this.scrollPosition = v.scrollPosition ?? 'nearest';
  }

  /**
   * The name of the container, which has to be scrolled.
   * Is defined by the directive which marks the container.
   */
  public containerName: string;

  /**
   * The target element which should be scrolled into view.
   * This Element has to be a child element of the container.
   * Either this has to be defined, or the targetOffset. Not both.
   */
  public target: HTMLElement | null;

  /**
   * The id of the scrolling operation.
   */
  public scrollId: number;

  /**
   * The targetOffset where the container should be scrolled to.
   * Either this has to be defined, or the target. Not both.
   */
  public targetOffset: number | null;

  /**
   * Defines if the scroll should be executed even if the target(Offset) is currently in view or not.
   */
  public force: boolean;

  /**
   * TODO: Description
   */
  public scrollPosition?: ScrollLogicalPosition;
}
