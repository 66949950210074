export class QesSignatureProcess {
  id: number;
  state: string;
  username: string;
  url: string;
  updated_at: Date;
  created_at: Date;

  constructor(v: Partial<QesSignatureProcess>) {
    this.id = v.id;
    this.state = v.state;
    this.username = v.username;
    this.url = v.url;
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
  }
}
