import {Size} from '../util/size';
import {Input, InputSettings, InputStyles} from './input';

export const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
export const vatIdRegex = /^([A-Za-z]{2,4}[0-9]{5,9})$/;
export const zipDERegex = /^([0-9]{5})$/;

export class TextInput extends Input {
  settings: TextInputSettings;
  styles: TextInputStyles;

  constructor(v: Partial<TextInput> = {}) {
    super(v);
    this.settings = new TextInputSettings(v.settings ?? {});
    this.styles = new TextInputStyles(v.styles ?? {});
  }

  get inputType() {
    return (this.settings.type !== InputType.vat_id) ?
      this.settings.type : 'text';
  }

  get regex() {
    switch (this.settings.type) {
      case InputType.email:
        // RFC 5322 Official Standard
        return emailRegex;
      case InputType.vat_id:
        return vatIdRegex;
      case InputType.zip:
        return zipDERegex;
      default:
        return '';
    }
  }
}

export class TextInputSettings extends InputSettings {
  type: InputType;
  multiline: boolean;
  fontSize: number;
  validate: boolean;
  regex: string;
  padding: boolean;
  minLength: number;
  maxLength: number;

  get minSize(): Size {
    return new Size({
      width: this.padding ? this.fontSize * 3 + 3 : this.fontSize,
      height: this.padding ? this.fontSize * 2 + 10 : this.fontSize
    });
  }

  get maxSize(): Size {
    return new Size({
      width: 0,
      height: 0
    });
  }

  get defaultSize(): Size {
    return new Size({
      width: 180,
      height: 37
    });
  }

  get paddingX(): number {
    return this.fontSize * 0.75;
  }

  get paddingY(): number {
    return this.fontSize * 0.5;
  }

  constructor(v: Partial<TextInputSettings> = {}) {
    super(v);
    this.type = v.type ?? InputType.text;
    this.multiline = v.multiline ?? false;
    this.fontSize = v.fontSize ?? 16;
    this.validate = v.validate ?? false;
    this.regex = v.regex ?? '';
    this.resizeAxis = this.multiline ? 'both' : 'x';
    this.padding = v.padding ?? true;
    this.minLength = v.minLength ?? undefined;
    this.maxLength = v.maxLength ?? undefined;
  }
}

class TextInputStyles extends InputStyles {
  constructor(v: Partial<TextInputStyles> = {}) {
    super(v);
  }
}

export enum InputType {
  text = 'text',
  number = 'number',
  email = 'email',
  vat_id = 'vat_id',
  zip = 'zip'
}
