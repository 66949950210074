import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {
  OAuthAuthorizeResponse,
  Organization,
  OrganizationMembership,
  Registration,
  Strategy,
  User,
  WorkspaceMembership
} from '@paperlessio/sdk/api/models';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {concatMap, map, retryWhen} from 'rxjs/operators';
import {API_BASE_PATH} from '@paperlessio/sdk/util/tokens';
import {HelloService} from './hello.service';

@Injectable()
export class AuthenticationService {
  private BASE_URL = inject(API_BASE_PATH) + 'v1/authentication/';

  constructor(
    private http: HttpClient,
    private helloService: HelloService
  ) { }

  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>(this.BASE_URL + 'current_user').pipe(
      map(res => this.deserializeCurrentUser(res)),
    );
  }

  resendVerificationEmail(userId: number): Observable<void> {
    return this.http.post<void>(this.BASE_URL + 'email_verification_reminders', {user_id: userId});
  }

  oauthCallback(params): Observable<User> {
    return this.http.post<User>(this.BASE_URL + 'sso/google/callback', params).pipe(
      map(res => this.deserializeCurrentUser(res)),
    );
  }

  getOauthAuthorize(params): Observable<OAuthAuthorizeResponse> {
    return this.http
      .get<OAuthAuthorizeResponse>(this.BASE_URL + 'oauth/authorize', {params})
      .pipe(map(response => new OAuthAuthorizeResponse(response)));
  }

  postOauthAuthorize(params): Observable<OAuthAuthorizeResponse> {
    return this.http.post<OAuthAuthorizeResponse>(this.BASE_URL + 'oauth/authorize', params);
  }

  deleteOauthAuthorize(params): Observable<OAuthAuthorizeResponse> {
    return this.http.delete<OAuthAuthorizeResponse>(this.BASE_URL + 'oauth/authorize', {params});
  }

  strategies(params: {email: string}): Observable<Strategy[]> {
    return this.http.post<Strategy[]>(this.BASE_URL + 'strategies', params).pipe(
      retryWhen(notifier => this.handleInvalidCSRFTokenError(notifier))
    );
  }

  login(credentials): Observable<User> {
    return this.http.post<User>(this.BASE_URL + 'session', credentials).pipe(
      retryWhen(notifier => this.handleInvalidCSRFTokenError(notifier)),
      map(res => this.deserializeCurrentUser(res)),
    );
  }

  logout(): Observable<null> {
    return this.http.delete<null>(this.BASE_URL + 'session');
  }

  /**
   * Register as a new user for Paperless.
   *
   * # Register
   *
   * Registers a new user for Paperless. After successful registration the user is automatically logged in so
   * no additional request is needed.
   *
   * Registrations can happen for two different cases either as fresh registrations or as registrations following
   * an invitation.
   *
   * ## Fresh registration
   * The user provides all information about himself together with the names of the initial organization and
   * workspace he wants to create. The user, organization and workspace will be created in that case.
   *
   * ## Registration following an invitation
   * The user provides all information about himself, except from the email address together with an invite token.
   * The invite token references the invitation from which the system will defer the organization to add the user
   * to on successful registration.
   */
  register(registration: Registration): Observable<User> {
    const data: any = {};

    if (registration.user?.id) {
      data.existing_user = {
        name: registration.user.name,
        locale: registration.user.locale,
        position: registration.user.position,
        marketing_tracking_data: registration.user.marketing_tracking_data
      };
    } else {
      data.user = {
        name: registration.user.name,
        email: registration.user.email,
        password: registration.user.password,
        password_confirmation: registration.user.password_confirmation,
        locale: registration.user.locale,
        position: registration.user.position,
        marketing_tracking_data: registration.user.marketing_tracking_data
      };
    }

    if (registration.invitation.invite_token) {
      data.invitation = {invite_token: registration.invitation.invite_token};
      delete data.user.email;
    }

    if (registration.organization.name) {
      data.organization = {name: registration.organization.name};
    }

    if (registration.workspace.name) {
      data.workspace = {
        name: registration.workspace.name,
        settings: {email_defaults: {subject: registration.workspace.settings.email_defaults.subject}}
      };
    }

    if (registration.blueprints) {
      data.blueprints = {};

      if (registration.blueprints.with_any_tag) {
        data.blueprints.with_any_tag = registration.blueprints.with_any_tag;
      }
      if (registration.blueprints.with_every_tag) {
        data.blueprints.with_every_tag = registration.blueprints.with_every_tag;
      }
    }

    return this.http.post<User>(this.BASE_URL + 'registrations', data).pipe(
      map(res => this.deserializeCurrentUser(res)),
    );
  }

  requestResetPassword(email: string) {
    return this.http.post(this.BASE_URL + 'password_reset', {email});
  }

  resetPassword(token: string, password: string, password_confirmation: string) {
    return this.http.patch<boolean>(this.BASE_URL + 'password_reset',
      {token, user: {password, password_confirmation}}
    );
  }

  private deserializeCurrentUser(data: any): User {
    const user = new Serializer<User>(User, 'user').fromJson(data.user);
    const organizations = new Map<number, Organization>();

    if (data.organization_memberships) {
      const serializer = new Serializer<OrganizationMembership>(OrganizationMembership);
      user.organization_memberships = data.organization_memberships.map(osm => {
        const serialized = serializer.fromJson(osm);
        organizations.set(serialized.organization_id, serialized.organization);
        return serialized;
      });
    }

    if (data.workspace_memberships) {
      const serializer = new Serializer<WorkspaceMembership>(WorkspaceMembership);
      user.workspace_memberships = data.workspace_memberships.map(wsm => {
        const serialized = serializer.fromJson(wsm);
        serialized.workspace.organization = organizations.get(serialized.workspace.organization_id);
        return serialized;
      });
    }

    return user;
  }

  private handleInvalidCSRFTokenError(errors: Observable<{status: number, error: {type: string, message: string}}>) {
    return errors.pipe(concatMap(error => {
      if (error.error?.type === 'Errors.Forbidden.InvalidCSRFTokenError') {
        return this.helloService.hello();
      } else {
        return throwError(error);
      }
    }));
  }
}
