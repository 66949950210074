import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {NgIf} from '@angular/common';

@Directive({
  selector: '[dropdownPanel]'
})
export class DropdownPanelDirective implements OnInit {
  public ngIfDirective: NgIf;
  @Input() can: string; // action
  @Input() canRecord: any;
  @Input() canElse: TemplateRef<any>;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) { }

  ngOnInit(): void {
    this.ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
    this.ngIfDirective.ngIf = false;
  }
}
