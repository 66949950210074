import {Block, BlockSettings, BlockStyles} from './block';
import {excluded} from '../util/excluded';

export class Video extends Block {
  settings: VideoSettings;
  styles: VideoStyles;
  @excluded url: string;

  constructor(v: Partial<Video> = {}) {
    super(v);
    this.settings =  new VideoSettings(v.settings ?? {});
    this.styles = new VideoStyles(v.styles ?? {});
    this.generateEmbedUrl();
  }

  generateEmbedUrl() {
    if (!this.settings.source) {
      return undefined;
    }

    let url = JSON.parse(JSON.stringify(this.settings.source));
    const isValid = url.length > 0 && (url.includes('youtube.com')) && url.includes('watch?v=') || url.includes('youtu.be') ||
      (url.includes('https://vimeo.com/') || url.includes('https://www.vimeo.com/'));

    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const youtubeId = this.extractYoutubeId(url);
      url = `https://www.youtube-nocookie.com/embed/${youtubeId}`;
    } else if (url.includes('vimeo.com')) {
      url = url.replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
        .replace('https://www.vimeo.com/', 'https://player.vimeo.com/video/');
      url += (url.includes('?') ? '&' : '?') + 'transparent=0';
    }

    this.url = isValid ? url : undefined;
  }

  extractYoutubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
}

export class VideoStyles extends BlockStyles {

  constructor(v: Partial<VideoStyles> = {}) {
    super(v);
  }
}

export class VideoSettings extends BlockSettings {
  source: string;

  constructor(v: Partial<VideoSettings> = {}) {
    super(v);
    this.hideInExport = true;
    this.source = v.source ?? '';
  }
}
