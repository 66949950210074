import {Block, Group, Page} from '@paperlessio/sdk/api/models';

/**
 * This is a dfs in PreOrder mode.
 *
 * @param blocks The blocks. Duh.
 */
export function topologicalBlockSort(blocks: Block[], blockMap?: Map<number, Block>): void {
  if (!blockMap) {
    blockMap = buildBlockMap(blocks);
  }

  const visited = []; // id list

  const rootBlocks = blocks
    .filter(b => !b.parent_id)
    .sort((a, b) => a.position - b.position);

  for (const block of blocks) {
    const parent = blockMap.get(block.parent_id);

    parent
      ? parent.addChild(block, block.position)
      : rootBlocks.push(block as Page);
  }

  helper(rootBlocks);

  function helper(blocks: Block[]) {
    for (const block of blocks) {
      if (visited.indexOf(block.id) > -1) {
        continue;
      }

      visited.push(block.id);

      block.topologicalPosition = visited.length;

      helper(block.children);
    }
  }
}

export function recalculateBlockTree(blocks: Block[], blockMap?: Map<number, Block>): Page[] & Group[] {
  if (!blockMap) {
    blockMap = buildBlockMap(blocks);
  }

  const rootBlocks = [];

  for (const block of blocks) {
    block.children = [];
  }

  for (const block of blocks) {
    const parent = blockMap.get(block.parent_id);

    parent
      ? parent.addChild(block, block.position)
      : rootBlocks.push(block as Page);
  }

  rootBlocks.sort((a, b) => a.position - b.position);
  return rootBlocks;
}

export function buildBlockMap(blocks: Block[], blockMap?: Map<number, Block>) {
  if (!blockMap) {
    blockMap = new Map<number, Block>();
  }

  for (const block of blocks) {
    blockMap.set(block.id, block);

    if (block.children) {
      buildBlockMap(block.children, blockMap);
    }
  }

  return blockMap;
}
