import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import {ParticipationFlowMutationCollection} from '@shared/participants/participation-flow-mutation';
import {ParticipationFlow, ParticipationFlowMutationResult} from '@paperlessio/sdk/api/models';
import {BaseServiceV1} from '@paperlessio/sdk/api/services';
import {environment} from '@environment';
import {BaseList} from '@paperlessio/sdk/api/util';

@Injectable({providedIn: 'root'})
export class ParticipationFlowService extends BaseServiceV1<ParticipationFlow> {
  constructor(http: HttpClient) {
    super(http);

    this.endpoint = 'participation_flows';
    this.serializer = new Serializer<ParticipationFlow>(ParticipationFlow);
  }

  mutate(participationFlowMutationCollection: ParticipationFlowMutationCollection) {
    const url = environment.api.core + 'v1/' + 'participation_flow_mutations';
    return this.http
      .post<ParticipationFlowMutationResult>(url, JSON.stringify(participationFlowMutationCollection))
      .pipe(
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        })
      );
  }

  //#region unsupported stuff
  fetch(params: {} = {}): Observable<BaseList<ParticipationFlow>> {
    throw new Error('fetch not supported!');
  }

  create(data: Partial<ParticipationFlow> | ParticipationFlow, params: {} = {}): Observable<ParticipationFlow> {
    throw new Error('create not supported!');
  }

  update(id: number, data: Partial<ParticipationFlow> | ParticipationFlow): Observable<ParticipationFlow> {
    throw new Error('update not supported!');
  }

  fetchByWorkspace(workspace_id: number, params: {} = {}): Observable<BaseList<ParticipationFlow>> {
    throw new Error('fetchByWorkspace not supported!');
  }

  fetchByOrganization(organization_id: number, params: {} = {}): Observable<BaseList<ParticipationFlow>> {
    throw new Error('fetchByOrganization not supported!');
  }

  duplicate(id: number, name: string): Observable<ParticipationFlow> {
    throw new Error('duplicate not supported!');
  }

  delete(id: number): Observable<ParticipationFlow> {
    throw new Error('delete not supported!');
  }
  //#endregion
}
