import {BaseSerializer} from '@paperlessio/sdk/api/util';
import {ParticipationSubmitEvent} from './participation-submit-event';

export class ParticipationSubmitEventSerializer implements BaseSerializer<ParticipationSubmitEvent> {
  fromJson(json: any): ParticipationSubmitEvent {
    return new ParticipationSubmitEvent(json);
  }

  toJson(obj: Partial<ParticipationSubmitEvent> | ParticipationSubmitEvent): object {
    return {};
  }
}
