import {Injectable, InjectionToken, Injector, Optional} from '@angular/core';
import {ComponentType, GlobalPositionStrategy, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Subject, Subscription} from 'rxjs';

export const MODAL_DATA_TOKEN = new InjectionToken<any>('MODAL_DATA_TOKEN');
export const MODAL_OVERLAY_REF_TOKEN = new InjectionToken<OverlayRef>('MODAL_OVERLAY_REF_TOKEN');

@Injectable()
export class BaseModalService {
  subs = new Subscription();
  currentModalRef: OverlayRef;

  closing = new Subject<any>();

  constructor(@Optional() public overlay: Overlay) { }

  open(componentType: ComponentType<unknown>, modal_data?: any, options?: { panelClass: string[], ignoreBackdropClick?: boolean }) {
    this.currentModalRef = this.overlay?.create({
      hasBackdrop: true,
      backdropClass: 'overlay-backdrop',
      panelClass: ['modal-content', 'modal-dialog', ...(options?.panelClass ?? [])],
      positionStrategy: new GlobalPositionStrategy().centerHorizontally().top(),
    });

    if (!options?.ignoreBackdropClick) {
      this.subs.add(this.currentModalRef.backdropClick().subscribe(_ => {
        this.close();
      }));
    }

    const injector = Injector.create({
      providers: [
        {
          provide: MODAL_DATA_TOKEN,
          useValue: modal_data
        },
        {
          provide: MODAL_OVERLAY_REF_TOKEN,
          useValue: this.currentModalRef
        }
      ]
    });

    this.currentModalRef.attach(new ComponentPortal(componentType, null, injector));

    return this.currentModalRef;
  }

  close(data?: any) {
    this.closing.next(data);
    this.currentModalRef.detach();
    this.currentModalRef.dispose();
    this.subs.unsubscribe();
    this.subs = new Subscription();
  }
}
