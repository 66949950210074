import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {SessionStore} from './session.store';

const tokenValid = (token: string): boolean => {
  return token && token.length === 20 && new RegExp(/^[a-zA-Z0-9]+$/).test(token);
};

/*
* SessionGuard checks for valid (i.e. plausible) token and loads all required data
* (especially design) except for blocks itself for a given session from the
* server. Improves perceived loading time, of design is present as fast as possible.
* Must pass length check and must match regex
* Used to abort navigation very early instead of trying to load stuff from server.
*/
export const sessionGuard: CanActivateFn = (route, state) => {
  const sessionStore = inject(SessionStore);
  const router = inject(Router);

  if (!tokenValid(route.params.token)) {
    return of(router.parseUrl('404'));
  }

  return sessionStore
    .initMetaInformation(route.params.token)
    .pipe(
      map(pmi => {
        if (pmi.participant.state.includes('qes_') && !state.url.includes('/qes/')) {
          return router.parseUrl(`s/${route.params.token}/qes`);
        }

        return !!pmi;
      }),
      // TODO: handle different errors correctly
      catchError(err => of(router.parseUrl('404')))
    );
};
