import * as uuid from 'uuid';
import {
  Participant,
  ParticipationFlow,
  ParticipationFlowEdge,
  ParticipationFlowNode,
  ParticipationFlowNodeType,
  RexCalculationType
} from '@paperlessio/sdk/api/models';

export enum ParticipationFlowMutationAction {
  create_node = 'create_node',
  update_node = 'update_node',
  delete_node = 'delete_node',
  create_edge = 'create_edge',
  delete_edge = 'delete_edge',
  replace_participant_node = 'replace_participant_node',
  clone_participant_node = 'clone_participant_node'
}

export class ParticipationFlowMutation {
  local_uuid: string;
  participation_flow_id: number;
  name: ParticipationFlowMutationAction;

  constructor(participation_flow_id: number) {
    this.local_uuid = uuid.v4();
    this.participation_flow_id = participation_flow_id;
  }

  // we implement toJSON for JSON.stringify to compose API compliant request bodies
  toJSON() {
    return {
      local_uuid: this.local_uuid,
      participation_flow_id: this.participation_flow_id,
      name: this.name
    };
  }
}

export class ParticipationFlowMutationCreateNode extends ParticipationFlowMutation {
  type: ParticipationFlowNodeType;
  participant?: Participant;

  constructor(node: ParticipationFlowNode, participation_flow_id: number) {
    super(participation_flow_id);
    this.name = ParticipationFlowMutationAction.create_node;
    this.type = node.type;
    if (this.type === ParticipationFlowNodeType.ParticipationFlowParticipantNode) {
      this.participant = node.participant;
    }
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      local_uuid: this.local_uuid,
      type: this.type,
      participant: this.participant ? {
        email: this.participant.email,
        name: this.participant.name,
        role: this.participant.role,
        content_locale: this.participant.content_locale,
        ui_locale: this.participant.ui_locale,
        // TODO discuss
        color: this.participant.color?.toLowerCase(),
        slot_name: this.participant.slot_name,
        description: this.participant.description
      } : null
    });
  }
}

export class ParticipationFlowMutationUpdateNode extends ParticipationFlowMutation {
  id: number;
  may_complete_calculation_javascript_definition?: string;
  may_complete_calculation_type: RexCalculationType;
  dispatch_condition_calculation_javascript_definition?: string;
  dispatch_condition_calculation_type: RexCalculationType;

  participant?: Participant;

  constructor(node: ParticipationFlowNode, participation_flow_id: number) {
    super(participation_flow_id);
    this.name = ParticipationFlowMutationAction.update_node;
    this.id = node.id;
    this.may_complete_calculation_type = node.may_complete_calculation_type;
    this.may_complete_calculation_javascript_definition = node.may_complete_calculation_javascript_definition;
    this.dispatch_condition_calculation_type = node.dispatch_condition_calculation_type;
    this.dispatch_condition_calculation_javascript_definition = node.dispatch_condition_calculation_javascript_definition;

    if (node.type === ParticipationFlowNodeType.ParticipationFlowParticipantNode) {
      this.participant = node.participant;
    }
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      id: this.id,
      may_complete_calculation_type: this.may_complete_calculation_type,
      may_complete_calculation_javascript_definition: this.may_complete_calculation_javascript_definition,
      dispatch_condition_calculation_type: this.dispatch_condition_calculation_type,
      dispatch_condition_calculation_javascript_definition: this.dispatch_condition_calculation_javascript_definition,
      participant: this.participant ? {
        email: this.participant.email,
        name: this.participant.name,
        role: this.participant.role,
        content_locale: this.participant.content_locale,
        ui_locale: this.participant.ui_locale,
        // TODO discuss
        color: this.participant.color?.toLowerCase(),
        slot_name: this.participant.slot_name,
        description: this.participant.description,
        email_subject: this.participant.email_subject,
        email_body: this.participant.email_body,
        email_signature: this.participant.email_signature,
        receive_submission_completed_mail: this.participant.receive_submission_completed_mail
      } : null
    });
  }
}

export class ParticipationFlowMutationReplaceParticipantNode extends ParticipationFlowMutation {
  id: number;
  participant?: Participant;

  constructor(node: ParticipationFlowNode, participation_flow_id: number) {
    super(participation_flow_id);
    this.name = ParticipationFlowMutationAction.replace_participant_node;
    this.id = node.id;

    if (node.type !== ParticipationFlowNodeType.ParticipationFlowParticipantNode) {
      throw TypeError(`Expected type '${ParticipationFlowNodeType.ParticipationFlowParticipantNode}', got type '${node.type}'`);
    }

    this.participant = node.participant;
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      id: this.id,
      participant: this.participant ? {
        email: this.participant.email,
        name: this.participant.name,
        content_locale: this.participant.content_locale,
        ui_locale: this.participant.ui_locale,
      } : null
    });
  }
}

export class ParticipationFlowMutationCloneParticipantNode extends ParticipationFlowMutation {
  id: number;
  participant?: Participant;

  constructor(node: ParticipationFlowNode,
              participation_flow_id: number,
              name?: string,
              email?: string,
              dispatch_strategy?: Participant['dispatch_strategy'],
              receive_submission_completed_mail?: boolean,
              terms_and_conditions_approved?: boolean
  ) {
    super(participation_flow_id);
    this.name = ParticipationFlowMutationAction.clone_participant_node;
    this.id = node.id;

    if (node.type !== ParticipationFlowNodeType.ParticipationFlowParticipantNode) {
      throw TypeError(`Expected type '${ParticipationFlowNodeType.ParticipationFlowParticipantNode}', got type '${node.type}'`);
    }

    this.participant = {
      ...node.participant,
      name: name ?? node.participant.name,
      email: email ?? node.participant.email,
      dispatch_strategy: dispatch_strategy ?? node.participant.dispatch_strategy,
      receive_submission_completed_mail: receive_submission_completed_mail ?? undefined,
      terms_and_conditions_approved: terms_and_conditions_approved ?? undefined
    } as Participant;
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      id: this.id,
      participant: this.participant ? {
        email: this.participant.email,
        name: this.participant.name,
        content_locale: this.participant.content_locale,
        ui_locale: this.participant.ui_locale,
        dispatch_strategy: this.participant.dispatch_strategy,
        receive_submission_completed_mail: this.participant.receive_submission_completed_mail,
        terms_and_conditions_approved: this.participant.terms_and_conditions_approved
      } : null
    });
  }
}

export class ParticipationFlowMutationDeleteNode extends ParticipationFlowMutation {
  id: number;

  constructor(node: ParticipationFlowNode, participation_flow_id: number) {
    super(participation_flow_id);
    this.name = ParticipationFlowMutationAction.delete_node;
    this.id = node.id;
  }

  toJSON() {
    return Object.assign(super.toJSON(), {id: this.id});
  }
}

export class ParticipationFlowMutationCreateEdge extends ParticipationFlowMutation {
  source_id: number;
  source_local_uuid: string;
  destination_id: number;
  destination_local_uuid: string;

  constructor(source: ParticipationFlowNode, destination: ParticipationFlowNode, participation_flow_id: number) {
    super(participation_flow_id);
    this.name = ParticipationFlowMutationAction.create_edge;
    this.source_id = source.id;
    this.source_local_uuid = source.local_uuid ?? undefined;
    this.destination_id = destination.id;
    this.destination_local_uuid = destination.local_uuid ?? undefined;
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      source_id: this.source_id,
      source_local_uuid: this.source_local_uuid,
      destination_id: this.destination_id,
      destination_local_uuid: this.destination_local_uuid
    });
  }
}

export class ParticipationFlowMutationDeleteEdge extends ParticipationFlowMutation {
  id: number;

  constructor(edge: ParticipationFlowEdge, participation_flow_id: number) {
    super(participation_flow_id);
    this.name = ParticipationFlowMutationAction.delete_edge;
    this.id = edge.id;
  }

  toJSON() {
    return Object.assign(super.toJSON(), {id: this.id});
  }
}

export class ParticipationFlowMutationCollection {
  local_uuid: string = uuid.v4();
  participation_flow: ParticipationFlow;
  mutations: ParticipationFlowMutation[] = [];

  constructor(participation_flow: ParticipationFlow) {
    this.participation_flow = participation_flow;
  }

  createNode(node: ParticipationFlowNode): ParticipationFlowMutationCollection {
    const mutation = new ParticipationFlowMutationCreateNode(node, this.participation_flow.id);
    node.local_uuid = mutation.local_uuid;
    this.mutations.push(mutation);
    return this;
  }

  updateNode(node: ParticipationFlowNode): ParticipationFlowMutationCollection {
    this.mutations.push(new ParticipationFlowMutationUpdateNode(node, this.participation_flow.id));
    return this;
  }

  replaceParticipantNode(node: ParticipationFlowNode): ParticipationFlowMutationCollection {
    this.mutations.push(new ParticipationFlowMutationReplaceParticipantNode(node, this.participation_flow.id));
    return this;
  }

  cloneParticipantNode(node: ParticipationFlowNode,
                       name?: string,
                       email?: string,
                       dispatch_strategy?: Participant['dispatch_strategy'],
                       receive_submission_completed_mail?: boolean,
                       terms_and_conditions_approved?: boolean): ParticipationFlowMutationCollection {
    this.mutations.push(new ParticipationFlowMutationCloneParticipantNode(
      node,
      this.participation_flow.id,
      name,
      email,
      dispatch_strategy,
      receive_submission_completed_mail,
      terms_and_conditions_approved
    ));
    return this;
  }

  deleteNode(node: ParticipationFlowNode): ParticipationFlowMutationCollection {
    this.mutations.push(new ParticipationFlowMutationDeleteNode(node, this.participation_flow.id));
    return this;
  }

  createEdge(source: ParticipationFlowNode, destination: ParticipationFlowNode): ParticipationFlowMutationCollection {
    this.mutations.push(new ParticipationFlowMutationCreateEdge(source, destination, this.participation_flow.id));
    return this;
  }

  deleteEdge(edge: ParticipationFlowEdge): ParticipationFlowMutationCollection {
    this.mutations.push(new ParticipationFlowMutationDeleteEdge(edge, this.participation_flow.id));
    return this;
  }

  toJSON() {
    return {
      local_uuid: this.local_uuid,
      participation_flow_id: this.participation_flow.id,
      mutations: this.mutations
    };
  }
}
