import {Block, BlockSettings, BlockStyles} from './block';
import {RexCalculationType} from '../calculation-type';

export class Html extends Block {
  settings: HtmlSettings;
  styles: HtmlStyles;

  html: string;
  html_calculation_type: RexCalculationType;
  html_calculation_javascript_definition: string;
  html_calculation_liquid_definition: string;

  constructor(v: Partial<Html> = {}) {
    super(v);
    this.settings = new HtmlSettings(v.settings ?? {});
    this.styles = new HtmlStyles(v.styles ?? {});
    this.html = v.html ?? '';
    this.html_calculation_type = v.html_calculation_type ?? RexCalculationType.CONSTANT;
    this.html_calculation_javascript_definition = v.html_calculation_javascript_definition ?? '';
    this.html_calculation_liquid_definition = v.html_calculation_liquid_definition ?? '';
  }

  get calculated(): boolean {
    return super.calculated || this.html_calculation_type !== RexCalculationType.CONSTANT;
  }
}

class HtmlSettings extends BlockSettings {
  constructor(v: Partial<HtmlSettings> = {}) {
    super(v);
  }
}

class HtmlStyles extends BlockStyles {
  constructor(v: Partial<HtmlStyles> = {}) {
    super(v);
  }
}
