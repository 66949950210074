import {ApplicationPolicy} from './application_policy';
import {OrganizationMembershipRole, WorkspaceMembership, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class WorkspaceMembershipPolicy extends ApplicationPolicy {
  record: WorkspaceMembership;

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.record.user_id === this.user.id;
  }

  update_role(): boolean {
    return this.organization_membership.at_least(OrganizationMembershipRole.admin)
      || this.workspace_membership.at_least(WorkspaceMembershipRole.owner);
  }

  destroy(): boolean {
    return this.organization_membership.at_least(OrganizationMembershipRole.admin)
      || this.workspace_membership.role === WorkspaceMembershipRole.owner
      || (this.workspace_membership.role === WorkspaceMembershipRole.admin &&
        (this.record.role === WorkspaceMembershipRole.sender || this.record.role === WorkspaceMembershipRole.guest)
      );
  }
}
