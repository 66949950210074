import {Block, BlockSettings} from './block';
import {SidesPixelValue} from '@paperlessio/sdk/api/util';
import {BlockType} from './block-type';

export class Table extends Block {
  settings: TableSettings;

  constructor(v: Partial<Table> = {}) {
    super(v);
    this.type = v.type ?? BlockType.Table;
    this.settings = new TableSettings(v.settings ?? {
      colWidths: [33.33, 33.33, 33.33]
    });
  }
}

export class TableSettings extends BlockSettings {
  header: boolean;
  footer: boolean;
  headerColumn: boolean;
  outline: boolean;
  horizontalBorders: boolean;
  verticalBorders: boolean;
  borderWidth: number;
  borderColor: string;
  cellPadding: SidesPixelValue;
  colWidths: number[];

  constructor(v: Partial<TableSettings> = {}) {
    super(v);

    this.header = v?.header ?? false;
    this.footer = v?.footer ?? false;
    this.headerColumn = v?.headerColumn ?? false;

    this.outline = v?.outline ?? true;
    this.horizontalBorders = v?.horizontalBorders ?? true;
    this.verticalBorders = v?.verticalBorders ?? true;
    this.borderWidth = v?.borderWidth ?? 1;
    this.borderColor = v?.borderColor ?? '#ddd';

    this.cellPadding = {
      top: 5,
      bottom: 5,
      left: 6,
      right: 6,
      ...v?.cellPadding
    };

    this.colWidths = v?.colWidths ?? [];
  }
}
