import {PointGroup} from 'signature_pad';
import {Point} from '@angular/cdk/drag-drop';

export const SIGNATURE_SESSION_LIFETIME = 5 * 60 * 1000; // five minutes
export const SIGNATURE_SESSION_KEY = 'signature_data';

export class SubmissionSignatureStorage {
  signatures: { [email: string]: {participantEmail: string, createdAt: Date, signature: PointGroup[], signaturePadSize: Point} };

  /**
   * Add a signature to the storage or update the createdAt date.
   * @param participantEmail The email of the participant.
   * @param signature The PointGroup coming from the toData() method of the signature pad.
   * @param signaturePadSize The size of the canvas at the point of signing.
   */
  addSignature(participantEmail: string, signature: PointGroup[], signaturePadSize: Point) {
    this.load();

    let signatureEntry = this.signatures[participantEmail];
    if (!signatureEntry) {
      signatureEntry = {
        participantEmail,
        createdAt: new Date(),
        signature,
        signaturePadSize: signaturePadSize
      };

      this.signatures[participantEmail] = signatureEntry;
    } else {
      signatureEntry.createdAt = new Date();
      signatureEntry.signature = signature;
      signatureEntry.signaturePadSize = signaturePadSize;
    }

    this.save();
  }

  /**
   * Removes a signature from the storage.
   * @param participantEmail The email of the participant.
   */
  removeSignature(participantEmail: string) {
    this.load();

    this.signatures[participantEmail] != null && delete this.signatures[participantEmail];

    this.save();
  }

  /**
   * Get the signature for a specific participant. Does not respect the createdAt date on porpose.
   * @param participantEmail The email of the participant.
   */
  getSignatureInfo(participantEmail: string): { signature: PointGroup[], signaturePadSize: Point } | null {
    this.load();

    const signatureEntry = this.signatures[participantEmail];
    if (!signatureEntry) {
      return null;
    }

    return {
      signature: signatureEntry.signature,
      signaturePadSize: signatureEntry.signaturePadSize
    };
  }

  /**
   * Clean the signatures that are beyond their "lifetime".
   */
  cleanSignatures() {
    this.load();

    Object.getOwnPropertyNames(this.signatures).forEach(participantEmail => {
      const signatureEntry = this.signatures[participantEmail];
      if (signatureEntry) {
        const diffMilliseconds = new Date().getTime() - new Date(signatureEntry.createdAt).getTime();
        if (diffMilliseconds > SIGNATURE_SESSION_LIFETIME) {
          delete this.signatures[participantEmail];
        }
      }
    });

    this.save();
  }

  private load() {
    // Wrapped in try, because of possible QuotaErrors
    try {
      this.signatures = JSON.parse(localStorage.getItem(SIGNATURE_SESSION_KEY) ?? '{}') ?? {};
    } catch (e) {
      this.signatures = {};
    }
  }

  private save() {
    // Wrapped in try, because of possible QuotaErrors
    try {
      localStorage.setItem(SIGNATURE_SESSION_KEY, JSON.stringify(this.signatures));
    } catch (e) {}
  }
}
