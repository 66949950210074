import {DesignType} from './design-type';
import {DesignLevel} from './design-level';
import {Blob} from './blob';

export class Design {
  id: number;
  organization_id: number;
  workspace_id: number;
  bucket_id: number;
  bucket_type: string;
  type: DesignType;
  level: DesignLevel;
  created_at: Date;
  updated_at: Date;
  logo: Blob;

  constructor(v: Partial<Design> = {}) {
    Object.assign(this, v);
    if (this.bucket_type) {
      this.level = this.bucket_type as DesignLevel;
    } else if (this.workspace_id) {
      this.level = DesignLevel.Workspace;
    } else if (this.organization_id) {
      this.level = DesignLevel.Organization;
    } else {
      this.level = DesignLevel.Merge;
    }

    this.created_at = v.created_at ? new Date(v.created_at) : null;
    this.updated_at = v.created_at ? new Date(v.updated_at) : null;
    this.logo = new Blob(v.logo);
  }
}

