import {InjectionToken} from '@angular/core';

export const INTERCOM_APP_ID = new InjectionToken<string>('INTERCOM_APP_ID');

export function provideIntercomAppId(value: string) {
  return {
    provide: INTERCOM_APP_ID,
    useValue: value
  }
}
