import {InjectionToken} from '@angular/core';

/**
 * Basically mirrors the websocket config in the environment.
 */
export interface WebsocketConfig {
  channel: {
    bucketChannel: string;
    processRunsChannel: string;
  };
  url: string
}

export const WEBSOCKET_CONFIG = new InjectionToken<WebsocketConfig>('WEBSOCKET_CONFIG');

export function provideWebsocketConfig(value: WebsocketConfig) {
  return {
    provide: WEBSOCKET_CONFIG,
    useValue: value
  };
}
