// eslint-disable-next-line max-classes-per-file
import {ProcessFlowBaseNode, ProcessFlowBaseNodeSettings} from './process-flow-base-node';
import {RexCalculationType} from '../../calculation-type';
import {Participant} from '../../participant';

export class RequestFormNode extends ProcessFlowBaseNode {
  constructor(v: Partial<RequestFormNode>) {
    super(v);
    this.type = 'ProcessFlow::RequestFormNode';
    this.settings = new RequestFormNodeSettings(v.settings as RequestFormNodeSettings);
    if (v.recalculated_settings) {
      this.recalculated_settings = new RequestFormNodeSettings(v.recalculated_settings as RequestFormNodeSettings);
    }
  }

  settings: RequestFormNodeSettings;
  recalculated_settings: RequestFormNodeSettings;
}

export class RequestFormNodeSettings extends ProcessFlowBaseNodeSettings {
  current_user_id: number;

  form_id_calculation_type: RexCalculationType;
  form_id_calculation_javascript_definition: string;
  form_id: number;

  rendering_locale_calculation_type: RexCalculationType;
  rendering_locale_calculation_javascript_definition: string;
  rendering_locale: string;

  participants_calculation_type: RexCalculationType;
  participants_calculation_javascript_definition: string;
  participants: { [slot_name: string]: Pick<Participant, 'name' | 'email' | 'slot_name' | 'dispatch_strategy'> };

  tokens_calculation_type: RexCalculationType;
  tokens_calculation_javascript_definition: string;
  tokens: Record<string, string>;

  inherited_submit_event_values_calculation_type: RexCalculationType;
  inherited_submit_event_values_calculation_javascript_definition: string;
  inherited_submit_event_values: {
    submission_id: number,
    slugs: {
      all: boolean,
      only: string[],
      except: string[]
    }
  }[];

  constructor(v: Partial<RequestFormNodeSettings> = {}) {
    super(v);

    this.current_user_id = v?.current_user_id;

    this.form_id_calculation_type = v.form_id_calculation_type ?? RexCalculationType.CONSTANT;
    this.form_id_calculation_javascript_definition = v.form_id_calculation_javascript_definition ?? '';
    this.form_id = v.form_id ?? null;

    this.rendering_locale_calculation_type = v.rendering_locale_calculation_type ?? RexCalculationType.CONSTANT;
    this.rendering_locale_calculation_javascript_definition = v.rendering_locale_calculation_javascript_definition ?? '';
    this.rendering_locale = v.rendering_locale ?? null;

    this.participants_calculation_type = v.participants_calculation_type ?? RexCalculationType.CONSTANT;
    this.participants_calculation_javascript_definition = v.participants_calculation_javascript_definition ?? '';
    this.participants = v.participants ?? {};

    this.tokens_calculation_type = v.tokens_calculation_type ?? RexCalculationType.CONSTANT;
    this.tokens_calculation_javascript_definition = v.tokens_calculation_javascript_definition ?? '';
    this.tokens = v.tokens ?? {};

    this.inherited_submit_event_values_calculation_type = v.inherited_submit_event_values_calculation_type ?? RexCalculationType.CONSTANT;
    this.inherited_submit_event_values_calculation_javascript_definition = v.inherited_submit_event_values_calculation_javascript_definition ?? '';
    this.inherited_submit_event_values = v.inherited_submit_event_values ?? [];
  }
}
