import {Block, BlockSettings} from './block';

export class Row extends Block {
  settings: RowSettings;

  constructor(v: Partial<Row> = {}) {
    super(v);
    this.settings = new RowSettings(v.settings ?? {});
  }
}

export class RowSettings extends BlockSettings {
  gutters: boolean;
  justify: 'align-self-start' | 'align-self-center' | 'align-self-end';

  constructor(v: Partial<RowSettings> = {}) {
    super(v);
    this.gutters = v.gutters ?? true;
    this.justify = v.justify ?? 'align-self-start';
  }
}
