import {inject, Injectable} from '@angular/core'
import {User} from '@paperlessio/sdk/api/models';
import {INTERCOM_APP_ID} from '@paperlessio/sdk/util/tokens';

/**
 * A provider with every Intercom.JS method
 */
@Injectable()
export class IntercomService {

  private intercomAppId = inject(INTERCOM_APP_ID);

  constructor() {

    // Subscribe to router changes
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
    //   this.update()
    // })
  }

  public setIntercomSettings(user: User) {
    (window as any).intercomSettings = {
      app_id: this.intercomAppId,
      user_id: user?.id?.toString(),
      email: user?.email,
      name: user?.name,
      language_override: user?.locale?.substr(0, 2),
      first_workspace_id: user?.firstWorkspaceId,
      companies: user?.organization_memberships?.map(om => {
        return {
          company_id: om.organization?.id?.toString(),
          name: om.organization?.name
        } as IntercomCompany
      })
    } as Partial<IntercomSettings>;
  }

  /**
   * Clear users’ conversations anytime they logout. Otherwise, the cookie we use to track who was most recently logged in on a given device
   * or computer will keep these conversations in the Messenger for one week. This method will effectively clear out any user data that has
   * been passed through the JS API.
   */
  public shutdown(): void {
    return this._callIntercom('shutdown')
  }

  /**
   * Calling the update method without any other arguments will trigger the JavaScript to look for new messages
   * that should be displayed to the current user (the one whose details are in the window.intercomSettings variable)
   * and show them if they exist.
   *
   * Calling the update method with a JSON object of user details will update those fields on the current user
   * in addition to logging an impression at the current URL and looking for new messages for the user.
   */
  public update(data?: any): void {
    return this._callIntercom('update', data)

  }

  /**
   * This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
   */
  public hide(): void {
    return this._callIntercom('hide')
  }

  /**
   * This will show the Messenger. If there are no conversations it will open with the new message view,
   * if there are it will open with the message list.
   *
   * If a `message` parameter is supplied, it will automatically open a new message window, aliasing showNewMessage().
   *
   */
  public show(message?: string): void {
    if (message) {
      return this.showNewMessage(message)
    }
    return this._callIntercom('show')

  }

  /**
   * To open the message window with the message list you can call `showMessages()`.
   */
  public showMessages(): void {
    return this._callIntercom('showMessages')
  }

  /**
   * To open the message window with the new message view you can call showNewMessage().
   *
   * This function takes an optional parameter that can be used to pre-populate the message composer as shown below.
   */
  public showNewMessage(message?: string): void {
    return this._callIntercom('showNewMessage', message)

  }

  /**
   * You can submit an event using the trackEvent method.
   * This will associate the event with the currently logged in user and send it to Intercom.
   * The final parameter is a map that can be used to send optional metadata about the event.
   *
   * You can also add custom information to events in the form of event metadata.
   */
  public trackEvent(eventName: string, metadata?: any): void {
    return this._callIntercom('trackEvent', eventName, metadata)
  }


  /**
   * A visitor is someone who goes to your site but does not use the messenger.
   * You can track these visitors via the visitor user_id.
   * This user_id can be used to retrieve the visitor or lead through the REST API.
   */
  public getVisitorId(): string {
    return this._callIntercom('getVisitorId')
  }

  /**
   * Alias for getVisitorId()
   * @alias getVisitorId()
   * @readonly
   */
  get visitorId(): string {
    return this._callIntercom('getVisitorId')
  }

  /**
   * Gives you the ability to hook into the show event. Requires a function argument.
   */
  public onShow(handler: () => void): void {
    return this._callIntercom('onShow', handler)
  }

  /**
   * Gives you the ability to hook into the hide event. Requires a function argument.
   */
  public onHide(handler: () => void): void {
    return this._callIntercom('onHide', handler)
  }

  /**
   * This method allows you to register a function that will be called when the current number of unread messages changes.
   */
  public onUnreadCountChange(handler: (unreadCount?: number) => void): void {
    return this._callIntercom('onUnreadCountChange', handler)
  }

  /**
   * If you would like to trigger a tour based on an action a user or visitor takes in your site or application,
   * ou can use this API method. You need to call this method with the id of the tour you wish to show. The id of
   * the tour can be found in the “Use tour everywhere” section of the tour editor.
   *
   * Please note that tours shown via this API must be published and the “Use tour everywhere” section must be
   * turned on. If you're calling this API using an invalid tour id, nothing will happen.
   */
  public startTour(tourId: number): void {
    return this._callIntercom('startTour', tourId)
  }

  /**
   * Private handler to run Intercom methods safely
   */
  private _callIntercom(fn: string, ...args) {
    if ((window as any).Intercom) {
      return (window as any).Intercom(fn, ...args)
    }
    return
  }
}

class IntercomSettings {
  email: string; // The email address of the currently logged in user
  user_id: string; // The user_id address of the currently logged in user
  created_at: number; // The Unix timestamp (in seconds) when the user signed up to your app
  name: string; // Name of the current user/lead
  phone: string; // Phone number of the current user/lead
  unsubscribed_from_emails: boolean; // Sets the unsubscribe status of the record
  language_override: string; // Set the messenger language programmatically (instead of relying on browser language settings) [de, en]
  avatar: IntercomAvatar; // Set the avatar/profile image associated to the current record
  user_hash: string; // Used for identity verification
  company: IntercomCompany; // Current user's company
  companies: IntercomCompany[]; // An array of companies the user is associated to
}

interface IntercomAvatar {
  type: 'avatar';
  image_url: string;
}

interface IntercomCompany {
  company_id: string; // The company ID of the company
  name: string; // The name of the company
  created_at: number; // The time the company was created in your system (unix timestamp)
  plan: string; // The name of the plan the company is on
  monthly_spend: number; // How much revenue the company generates for your business
  size: number; // The number of employees in the company
  website: string; // The URL for the company website
  industry: string; // The industry of the company
}
