export class BillingSubscription {
  id: number;
  connected_with_external_payment_provider: boolean;
  state: BillingState;
  billing_type: 'quota_based' | 'seat_based';
  current_period_starts_at: Date;
  current_period_ends_at: Date;
  trial_ends_at: Date;
  cancel_at_period_end: boolean
  updated_at: Date;
  created_at: Date;

  constructor(v: Partial<BillingSubscription>) {
    this.id = v.id;
    this.connected_with_external_payment_provider = v.connected_with_external_payment_provider;
    this.state = v.state;
    this.billing_type = v.billing_type;
    this.current_period_starts_at = v.current_period_starts_at ? new Date(v.current_period_starts_at): null;
    this.current_period_ends_at = v.current_period_ends_at ? new Date(v.current_period_ends_at): null;
    this.trial_ends_at = v.trial_ends_at ? new Date(v.trial_ends_at) : null;
    this.cancel_at_period_end = v.cancel_at_period_end;
    this.updated_at = v.updated_at ? new Date(v.updated_at): null;
    this.created_at = v.created_at ? new Date(v.created_at): null;
  }

  get remaining_trial_days(): number {
    if (this.state === BillingState.TRIALING && this.trial_ends_at) {
      const now = new Date();
      const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      const diff = this.trial_ends_at.getTime() - new Date(utc).getTime();
      return Math.floor(diff / (1000 * 3600 * 24));
    } else {
      return -1;
    }
  }
}

export class CheckoutSession {
  url: string;

  constructor(v: Partial<CheckoutSession>) {
    this.url = v.url;
  }
}

export class CustomerPortalSession {
  url: string;

  constructor(v: Partial<CustomerPortalSession>) {
    this.url = v.url;
  }
}

export class CostEstimation {
  price_per_seat: string;
  interval: string

  constructor(v: Partial<CostEstimation>) {
    this.price_per_seat = v.price_per_seat;
    this.interval = v.interval;
  }

  get price_per_seat_in_euros(): number {
    return +this.price_per_seat / 100;
  }
}

export enum BillingState {
  ACTIVE = 'active',
  PASTDUE = 'past_due',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETEEXPIRED = 'incomplete_expired',
  TRIALING = 'trialing'
}
