import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
  faCheckCircle,
  faFileCircleCheck,
  faGlobe,
  faLock,
  faLockAlt,
  faLongArrowRight,
  faSave,
  faSignature
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowDownToLine,
  faArrowRight,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faDownload,
  faEye,
  faLongArrowRight as faRegularLongArrowRight
} from '@fortawesome/pro-regular-svg-icons';
import {TranslocoModule} from '@ngneat/transloco';
import {DesignColorsInserterModule} from '@shared/design/design-colors-inserter/design-colors-inserter.module';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {DropdownModule} from '@paperlessio/sdk/components/dropdown';
import {SessionComponent} from './session.component';
import {SessionHeaderComponent} from './session-header/session-header.component';
import {SessionContentComponent} from './session-content/session-content.component';
import {SessionSuccessComponent} from './session-success/session-success.component';
import {StyleableButtonModule} from './session-header/styleable-button/styleable-button.module';
import {OtherActionsComponent} from './session-header/other-actions/other-actions.component';
import {SessionTosCheckboxModule} from '@shared/session-tos-checkbox/session-tos-checkbox.module';
import {ScrollingModule} from '@shared/scrolling/scrolling.module';
import {LayoutModule} from '@angular/cdk/layout';
import {IconLibraryService, IconsModule, plLogosGdpr} from '@paperlessio/sdk/icons';
import {LocaleSwitcherComponent} from '@shared/i18n/locale-switcher/locale-switcher.component';
import {LoadingSpinnerModule} from '@paperlessio/sdk/components/loading-spinner';
import {LoadingBubblesComponent} from '@paperlessio/sdk/components/loading-bubbles';
import {AdditionalButtonsComponent} from './session-header/additional-buttons/additional-buttons.component';

@NgModule({
  declarations: [
    SessionComponent,
    SessionHeaderComponent,
    SessionContentComponent,
    SessionSuccessComponent,
    OtherActionsComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslocoModule,
    DesignColorsInserterModule,
    RouterModule,
    LoadingSpinnerModule,
    FormsModule,
    DropdownModule,
    LoadingBubblesComponent,
    SessionTosCheckboxModule,
    ScrollingModule,
    LayoutModule,
    StyleableButtonModule,
    IconsModule,
    LocaleSwitcherComponent,
    AdditionalButtonsComponent
  ],
  exports: [
    SessionComponent,
    SessionSuccessComponent,
    SessionHeaderComponent
  ]
})
export class SessionModule {
  constructor(library: FaIconLibrary, iconLibrary: IconLibraryService) {
    library.addIcons(
      faLockAlt,
      faCheckCircle,
      faLongArrowRight,
      faChevronLeft,
      faArrowRight,
      faCheck,
      faDownload,
      faChevronDown,
      faEye,
      faArrowDownToLine,
      faSignature,
      faFileCircleCheck,
      faLock,
      faSave,
      faRegularLongArrowRight,
      faGlobe);

    iconLibrary.addIcons(plLogosGdpr);
  }
}
