import {Injectable} from '@angular/core';
import {SubmittableService} from '@paperlessio/sdk/api/services';
import {ToastService} from '@paperlessio/sdk/api/util';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Document, MailSettings} from '@paperlessio/sdk/api/models';
import {WorkspaceBaseStore} from './workspace.base.store';
import {CurrentWorkspaceStore} from './current-workspace.store';

@Injectable({providedIn: 'root'})
export class SubmittableStore extends WorkspaceBaseStore<Document, SubmittableService> {
  constructor(service: SubmittableService, toast: ToastService, currentWorkspaceStore: CurrentWorkspaceStore) {
    super(service, toast, currentWorkspaceStore);
  }

  /**
   * Dispatch an existing submittable
   * POST /entity
   * @param id - the id of the existing object
   */
  dispatch(id: number): Observable<Document> {
    return this.service.dispatch(id).pipe(
      tap(created => {
        // we redirect to document list after dispatch. list fetches data once and shows doc state (email sending)
        // this will refresh data once again after 5 seconds to pick up updated doc state (email sent)
        setTimeout(_ => this.fetch(this.lastFetchParams).subscribe(), 5000);
      }),
      catchError(error => {
        this.toast.error(`documents.dispatch.error`);
        return throwError(error);
      })
    );
  }

  archive(id: number): Observable<Document> {
    return this.service.archive(id).pipe(
      tap(archivedDocument => {
        const existingDocument = this.all.value.data.filter(d => d.id === id);
        if (existingDocument != null) {
          this.all.next({
            count: this.all.value.count - 1,
            data: [...this.all.value.data.filter(d => d.id !== id)]
          });
        }
      }),
      catchError(error => {
        this.toast.error(`documents.archive.error`);
        return throwError(error);
      })
    );
  }

  cancel(id: number): Observable<Document> {
    return this.service
      .cancel(id)
      .pipe(
        tap(cancelledDocument => {
          this.all.next({
            count: this.all.value.count,
            data: [
              cancelledDocument, // move to top, as the list is ordered by updated_at
              ...this.all.value.data.filter(d => d.id !== id)
            ]
          });
        }),
        catchError(error => {
          this.toast.error(`documents.cancel.error`);
          return throwError(error);
        })
      );
  }

  create(data: Partial<Document>, params: {} = {}, nextCurrent: boolean = false, fetchAll: boolean = true): Observable<Document> {
    const email_defaults = this.currentWorkspaceStore?.membership?.value?.workspace?.settings?.email_defaults;

    if (email_defaults?.content != null || email_defaults?.subject != null) {
      if (!data.settings) {
        data.settings = {} as any;
      }

      if (!data.settings.mail) {
        data.settings.mail = new MailSettings();
      }

      data.settings.mail.content = email_defaults.content;
      data.settings.mail.subject = email_defaults.subject;
    }

    return super.create(data, params, nextCurrent, fetchAll);
  }
}
