import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/*
* Wrapper for projected content from SessionComponent.
* Handles content width.
*/
@Component({
  selector: 'app-session-content',
  templateUrl: './session-content.component.html',
  styleUrls: ['./session-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionContentComponent {
  @Input() mustAcceptTerms = false;
  @Input() termsAccepted = true;
}
