import {inject, Injectable, OnDestroy} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject, Subscription} from 'rxjs';
import {ContentLocale, LOCALE, UILocale} from '@paperlessio/sdk/api/util';
import {TranslocoService} from '@ngneat/transloco';
import {BlockChangeDetectionService} from '@blocks/services/block-change-detection/block-change-detection.service';
import {BlockStore} from '@blocks/block/block.store';
import {BucketStore} from '@management/base/bucket/bucket.store';
import {SessionStore} from '../../submission/src/app/session/session.store';
import {Bucket, ContentLocaleSetting, Participant, Submission} from '@paperlessio/sdk/api/models';
import {defaultIfEmpty, filter, map} from 'rxjs/operators';
import {SessionFormStore} from '../../submission/src/app/forms/session-form.store';

@Injectable({providedIn: 'root'})
export class LocalizationService implements OnDestroy {
  contentLocale: ReplaySubject<ContentLocale> = new ReplaySubject(1);
  currentContentLocale: ContentLocale;
  uiLocale: ReplaySubject<UILocale> = new ReplaySubject(1);
  currentUILocale: UILocale;

  private translocoService = inject(TranslocoService);
  private blockChangeDetectionService = inject(BlockChangeDetectionService);
  private blockStore = inject(BlockStore);
  private bucketStore = inject(BucketStore, {optional: true});
  private sessionStore = inject(SessionStore, {optional: true});
  private sessionFormStore = inject(SessionFormStore, {optional: true});

  private bucket: Bucket;
  private subs = new Subscription();

  constructor() {
    /**
     * We need to look for the current bucket-like thing in multiple stores.
     * Any of these stores may or may not be there at any given use of the localization service.
     * So we combine all possible stores into one datasource here.
     *
     * By replacing the non-existent stores with an empty 'of' and then setting a default value on the subject,
     * we make sure we always return a value for the combineLatest to do its job.
     */
    const subject = combineLatest([
      (this.bucketStore?.bucket.asObservable() ?? of())
        .pipe(defaultIfEmpty(null)),

      (this.sessionStore?.submission.asObservable() ?? of())
        .pipe(defaultIfEmpty(null)),

      (this.sessionFormStore?.formMetaInformation.asObservable() ?? of())
        .pipe(defaultIfEmpty(null))
    ])
      .pipe(
        // Make sure we have at least one bucket-like thing before we continue
        filter(buckets => !!buckets[0] || !!buckets[1] || !!buckets[2]),
        map((buckets: [any, Submission, any]) => {
          // Select the first possible bucket-like thing
          return buckets?.[0] ?? buckets?.[1]?.submittable ?? buckets?.[2] ?? {};
        })
      ) as Observable<Bucket>;

    this.uiLocale.next(this.translocoService.getActiveLang() as unknown as UILocale);

    this.subs.add(subject
      .subscribe((newBucket: Bucket) => {
        const oldBucket = this.bucket;
        this.bucket = newBucket;

        if (!this.currentContentLocale || oldBucket?.id !== newBucket?.id) {
          this.setContentLocale(newBucket.original_content_locale);
        }
      }));
  }

  get originalContentLocale(): ContentLocale {
    return this.bucket?.original_content_locale;
  }

  get configuredContentLocaleSettings(): ContentLocaleSetting[] {
    return this.bucket?.content_locale_settings;
  }

  setContentLocale(locale: ContentLocale) {
    if (!this.bucket.configuredContentLocales.includes(locale as unknown as string)) {
      return;
    }

    this.contentLocale.next(locale);
    this.currentContentLocale = locale;
    this.blockChangeDetectionService.setChangedBlockIds(this.blockStore.blocksArray.map(b => b.id));
  }

  setUILocale(locale: UILocale) {
    this.uiLocale.next(locale);
    this.translocoService.setActiveLang(locale as unknown as string);
  }

  // Try to set ui and content locale based on navigator language and participant locales in submission ui
  // Uses the navigator language to set the content locale
  // If the navigator language is not a configured content locale, fall back to the current participant's content locale
  // If the current participant's content locale is not a configured content locale, fall back to the original content locale
  // If the navigator language is not a configured ui locale, fall back to the participant's ui locale
  // If the participant's ui locale is not a configured ui locale, fall back to en-US
  attemptToSetOptimalUIAndContentLocaleForParticipant(participant: Participant) {
    const localeFromNavigatorLanguage = this.localeFromLanguage(navigator.language);
    const configuredContentLocales = this.bucket.configuredContentLocales;
    const participantContentLocale = participant.content_locale;
    const participantUILocale = participant.ui_locale;
    const availableUILocales = Object.values(UILocale);

    if (configuredContentLocales.includes(localeFromNavigatorLanguage)) {
      this.setContentLocale(localeFromNavigatorLanguage as unknown as ContentLocale);
    } else if (configuredContentLocales.includes(participantContentLocale as unknown as string)) {
      this.setContentLocale(participantContentLocale as unknown as ContentLocale);
    } else {
      this.setContentLocale(this.bucket.original_content_locale);
    }

    if (availableUILocales.includes(localeFromNavigatorLanguage as unknown as UILocale)) {
      this.setUILocale(localeFromNavigatorLanguage as unknown as UILocale);
    } else if (availableUILocales.includes(participantUILocale)) {
      this.setUILocale(participantUILocale as unknown as UILocale);
    } else {
      this.setUILocale(UILocale.en_US);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private localeFromLanguage(language: string): LOCALE {
    // attempt to find exact match
    if (Object.values(LOCALE).includes(language as unknown as LOCALE)) {
      return language as unknown as LOCALE;
    }

    // attempt to match language only
    const languageOnly = language.split('-')[0];
    const locale = Object.values(LOCALE).find(locale => locale.split('-')[0] === languageOnly);
    if (locale) {
      return locale;
    }

    // otherwise return null
    return null;
  }
}
