import {Size} from '../util/size';
import {Input, InputSettings, InputStyles} from './input';
import {FlexAlign} from './flex-align';

export class SignatureInput extends Input {
  default_value: SignatureInputValue;
  settings: SignatureInputSettings;
  styles: SignatureInputStyles;

  constructor(v: Partial<SignatureInput> = {}) {
    super(v);
    this.default_value = v.default_value ?? {
      location: null,
      custom_value_1: null,
      custom_value_2: null,
      custom_value_3: null
    };
    this.settings = new SignatureInputSettings(v.settings ?? {});
    this.styles = new SignatureInputStyles(v.styles ?? {});
  }
}

export class SignatureInputSettings extends InputSettings {
  show_date: boolean;
  show_location: boolean;
  show_custom_value_1: boolean;
  show_custom_value_2: boolean;
  show_custom_value_3: boolean;
  custom_value_1_label: string;
  custom_value_2_label: string;
  custom_value_3_label: string;
  options_order: string[];
  aspectRatio = 3.125;

  get minSize(): Size {
    return new Size({
      width: 100,
      height: 100 / this.aspectRatio
    });
  }

  get maxSize(): Size {
    return super.maxSize;
  }

  get defaultSize(): Size {
    return new Size({
      width: 180,
      height: 180 / this.aspectRatio
    });
  }

  constructor(v: Partial<SignatureInputSettings> = {}) {
    super(v);
    this.required = v.required ?? true;
    this.hide_name = v.hide_name ?? false;
    this.show_date = v.show_date ?? true;
    this.show_location = v.show_location ?? true;
    this.show_custom_value_1 = v.show_custom_value_1 ?? false;
    this.show_custom_value_2 = v.show_custom_value_2 ?? false;
    this.show_custom_value_3 = v.show_custom_value_3 ?? false;
    this.custom_value_1_label = v.custom_value_1_label ?? '';
    this.custom_value_2_label = v.custom_value_2_label ?? '';
    this.custom_value_3_label = v.custom_value_3_label ?? '';
    this.options_order = v.options_order ?? ['location', 'date', 'custom_value_1', 'custom_value_2', 'custom_value_3'];
    this.resizeAxis = 'both';
  }
}

export class SignatureInputStyles extends InputStyles {
  align: FlexAlign;

  constructor(v: Partial<SignatureInputStyles> = {}) {
    super(v);
    this.align = v.align ?? FlexAlign.left;
  }
}

export interface SignatureInputValue {
  signature_image_signed_id?: string;
  signature_type?: string;
  signature_text?: string;
  location?: string;
  date?: string | Date;
  custom_value_1?: string;
  custom_value_2?: string;
  custom_value_3?: string;
}

export interface SignatureOption {
  id: string;
  parent_id: string;
  name: string;
  disabled: boolean;
  show: boolean;
  deletable: boolean;
}
