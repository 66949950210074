import {excluded} from './util/excluded';
import {ListColumn} from './column';
import {User} from './user';
import {BillingSubscription} from './billing';
import {Designs} from './designs';

export class Organization {
  static columns: ListColumn[] = [
    new ListColumn({property: 'name', fixed: true, align: 'left'}),
  ];

  id: number;
  name: string;
  @excluded tenant: string;
  @excluded created_at: Date;
  @excluded updated_at: Date;
  @excluded owner: User;
  @excluded billing_subscription: BillingSubscription;
  @excluded auth_settings?: OrganizationAuthSettings;
  two_factor_authentication_enabled_at?: Date;
  @excluded submission_frontend_base_url?: string;
  @excluded designs: Designs;

  constructor(v: Partial<Organization> = {}) {
    Object.assign(this, v);
    this.billing_subscription = new BillingSubscription(v.billing_subscription ?? {});
    this.auth_settings = v.auth_settings ? new OrganizationAuthSettings(v.auth_settings) : undefined;
    this.designs = new Designs(v.designs as any);
    this.two_factor_authentication_enabled_at = v.two_factor_authentication_enabled_at ? new Date(v.two_factor_authentication_enabled_at) : undefined;
    this.submission_frontend_base_url = v.submission_frontend_base_url;
  }

  get login_url(): string {
    if (this.tenant) {
      return `https://app.paperless.io/login/sso/saml/${this.tenant}`;
    } else {
      return 'https://app.paperless.io/login';
    }
  }
}

export class OrganizationAuthSettings {
  enabled_providers: OrganizationAuthProviders[];

  constructor(v: Partial<OrganizationAuthSettings> = {}) {
    this.enabled_providers = v.enabled_providers;
  }

  saml_provider_enabled(): boolean {
    return this.enabled_providers.includes(OrganizationAuthProviders.saml);
  }
}

export enum OrganizationAuthProviders {
  saml = 'saml'
}
