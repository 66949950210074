import {Block, BlockSettings} from './block';

export class Column extends Block {
  settings: ColumnSettings;

  constructor(v: Partial<Column> = {}) {
    super(v);
    this.settings = new ColumnSettings(v.settings ?? {});
  }
}

export enum ColumnSize {
  auto = 'col-md-auto',
  default = 'col-md',
  col01 = 'col-md-1',
  col02 = 'col-md-2',
  col03 = 'col-md-3',
  col04 = 'col-md-4',
  col05 = 'col-md-5',
  col06 = 'col-md-6',
  col07 = 'col-md-7',
  col08 = 'col-md-8',
  col09 = 'col-md-9',
  col10 = 'col-md-10',
  col11 = 'col-md-11',
  col12 = 'col-md-12'
}

export class ColumnSettings extends BlockSettings {
  size: ColumnSize;

  constructor(v: Partial<ColumnSettings> = {}) {
    super(v);
    this.size = v.size ?? ColumnSize.default;
  }
}
