import {ApplicationPolicy} from './application_policy';
import {Workspace, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class WorkspacePolicy extends ApplicationPolicy {
  record: Workspace;

  index(): boolean {
    return true;
  }

  show(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.guest);
  }

  memberships(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.guest);
  }

  create(): boolean {
    return false; // not used
  }

  copy(): boolean {
    return this.organization_membership.at_least(WorkspaceMembershipRole.admin);
  }

  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.owner) || this.organization_membership.at_least(WorkspaceMembershipRole.admin);
  }
}
