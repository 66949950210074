import {Participant} from './participant';
import {RexCalculationType} from './calculation-type';

export class ParticipationFlow {
  id: number;
  edges: ParticipationFlowEdge[];
  nodes: ParticipationFlowNode[];
  updated_at: Date;
  created_at: Date;

  constructor(v: Partial<ParticipationFlow> = {}) {
    this.id = v.id;
    this.nodes = v.nodes?.map(n => new ParticipationFlowNode(n));
    this.edges = v.edges?.map(e => new ParticipationFlowEdge(e));
    this.created_at = v.created_at ? new Date(v.created_at) : null;
    this.updated_at = v.updated_at ? new Date(v.updated_at) : null;
  }

  get participantNodes(): ParticipationFlowNode[] {
    return this.nodes.filter(node => node.type === ParticipationFlowNodeType.ParticipationFlowParticipantNode);
  }

  get startNode(): ParticipationFlowNode {
    return this.nodes.filter(node => node.type === ParticipationFlowNodeType.ParticipationFlowStartNode)[0];
  }

  get endNode(): ParticipationFlowNode {
    return this.nodes.filter(node => node.type === ParticipationFlowNodeType.ParticipationFlowEndNode)[0];
  }

  get emailsUnique(): boolean {
    const emails = this.participantNodes
      .map(node => node.participant)
      .filter(participant => !!participant.email)
      .map(participant => participant.email.toLowerCase());

    return emails.length === new Set(emails).size;
  }

  get validForDispatch(): boolean {
    return this.participantNodes.every(node => node.participant.validForDispatch) && this.emailsUnique;
  }

  getParticipant(id: number) {
    return this.participantNodes.find(pn => pn.participant_id === id)?.participant;
  }
}

export class ParticipationFlowEdge {
  id: number;
  local_uuid?: string;
  source_id: number;
  destination_id: number;

  // for UI layout
  points?: any;
  line?: string;
  oldLine?: any;

  constructor(v: Partial<ParticipationFlowEdge> = {}) {
    this.id = v.id;
    this.local_uuid = v.local_uuid;
    this.source_id = v.source_id;
    this.destination_id = v.destination_id;
  }
}

export class ParticipationFlowNode {
  id: number;
  local_uuid?: string;
  type: ParticipationFlowNodeType;
  participant_id?: number;
  participant?: Participant;

  may_complete_calculation_javascript_definition?: string;
  may_complete_calculation_type: RexCalculationType;

  dispatch_condition_calculation_type: RexCalculationType;
  dispatch_condition: boolean;
  dispatch_condition_calculation_javascript_definition?: string;

  // for UI layout
  order: number;
  // to fake node position for optimistic UI for drag and drop
  orderDelta = 0;
  position?: NodePosition;
  dimension?: NodeDimension;
  transform?: string;
  data?: any;
  meta?: any;

  get conditionalDispatchCalculation(): boolean {
    return this.dispatch_condition_calculation_type !== RexCalculationType.CONSTANT;
  }

  set conditionalDispatchCalculation(value: boolean) {
    if (value === true && this.dispatch_condition_calculation_type === RexCalculationType.CONSTANT) {
      this.dispatch_condition_calculation_type = RexCalculationType.JAVASCRIPT;
      if (!this.dispatch_condition_calculation_javascript_definition) {
        this.dispatch_condition_calculation_javascript_definition = 'true';
      }
    } else if (value === false) {
      this.dispatch_condition_calculation_type = RexCalculationType.CONSTANT;
    }
  }

  constructor(v: Partial<ParticipationFlowNode> = {}) {
    this.id = v?.id;
    this.local_uuid = v?.local_uuid;
    this.type = v?.type;
    this.participant = v?.participant ? new Participant(v?.participant) : null;
    this.participant_id = v?.participant_id;
    this.may_complete_calculation_javascript_definition = v?.may_complete_calculation_javascript_definition;
    this.may_complete_calculation_type = v?.may_complete_calculation_type;
    this.dispatch_condition_calculation_type = v?.dispatch_condition_calculation_type;
    this.dispatch_condition = v?.dispatch_condition;
    this.dispatch_condition_calculation_javascript_definition = v?.dispatch_condition_calculation_javascript_definition;
  }
}

export enum ParticipationFlowNodeType {
  ParticipationFlowStartNode = 'ParticipationFlow::StartNode',
  ParticipationFlowEndNode = 'ParticipationFlow::EndNode',
  ParticipationFlowParticipantNode = 'ParticipationFlow::ParticipantNode'
}

export enum ParticipationFlowNodeDestroyErrors {
  LAST_NODE = 'last_node',
  OWNS_BLOCKS = 'owns_blocks'
}

export interface ParticipationFlowGraph {
  edges: ParticipationFlowEdge[];
  nodes: ParticipationFlowNode[];
  width: number;
  height: number;
  edgeLabels?: any; // for layout
}

export interface NodePosition {
  x: number;
  y: number;
}

export interface NodeDimension {
  width: number;
  height: number;
}
