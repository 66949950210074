import * as uuid from 'uuid';
import {Block, BlockStyles, BlockType, BucketType, Column, RexCalculationType} from '@paperlessio/sdk/api/models';
import {BlockSerializer} from '@paperlessio/sdk/api/serializers';
import {BlockMutationAction} from './block-mutation-action.enum';
import {translate} from '@ngneat/transloco';
import {nextSlugForBlockType} from '@blocks/block/block.store';

export class BlockMutationCreate {
  local_uuid: string = uuid.v4();
  name: BlockMutationAction = BlockMutationAction.create;
  type: string;
  bucket_type: string;
  bucket_id: number;
  parent_id?: number;
  parent_local_uuid?: string;
  default_value?: any;
  position: number;
  slug?: string;
  settings?: any;
  styles?: any;
  file?: any;
  block_owner_participant_ids: number[];
  localized_attributes;

  constructor(block: Block, bucket_type: BucketType | string, bucket_id: number) {
    this.type = block.type;
    this.local_uuid = block.local_uuid || this.local_uuid;
    this.bucket_type = block.bucket_type || bucket_type;
    this.bucket_id = block.bucket_id || bucket_id;
    this.parent_id = block.parent_id && block.parent_id > 0 ? block.parent_id : undefined;
    this.parent_local_uuid = block.parent_local_uuid || undefined;
    this.default_value = block.default_value || undefined;
    this.position = block.position;
    this.slug = block.slug || nextSlugForBlockType(block.type);
    this.settings = block.settings;
    this.styles = block.styles;
    this.file = (block as any)?.file;
    this.block_owner_participant_ids = block.block_owner_participant_ids;
    this.localized_attributes = block.localized_attributes;

    // TODO remove indirect change of id in block
    block.id = --localId;
    block.local_uuid = this.local_uuid;
  }
}

export class BlockMutationUpdate {
  local_uuid: string = uuid.v4();
  name: BlockMutationAction = BlockMutationAction.update;
  block_id?: number;
  slug?: string;
  visible?: boolean;
  visible_calculation_type: RexCalculationType;
  visible_calculation_javascript_definition: string;
  visible_calculation_json_logic_definition: object;
  html?: string;
  html_calculation_type: RexCalculationType;
  html_calculation_javascript_definition: string;
  html_calculation_liquid_definition: string;
  image?: string;
  file?: string;
  settings?: any;
  styles?: any;
  block_owner_participant_ids: number[];
  default_value: any;
  localized_attributes;

  constructor(block) {
    this.block_id = block.id < 0 ? undefined : block.id;
    this.slug = block.slug;
    this.visible = block.visible;
    this.visible_calculation_type = block.visible_calculation_type;
    this.visible_calculation_javascript_definition = block.visible_calculation_javascript_definition ?? undefined;
    this.visible_calculation_json_logic_definition = block.visible_calculation_json_logic_definition ?? undefined;
    this.html = block.html ?? undefined;
    this.html_calculation_type = block.html_calculation_type ?? undefined;
    this.html_calculation_javascript_definition = block.html_calculation_javascript_definition ?? undefined;
    this.html_calculation_liquid_definition = block.html_calculation_liquid_definition ?? undefined;
    // TODO migrate to file when backend is ready
    this.image = block.type === 'Block::Image' ? block.file?.toJSON() : undefined;
    this.file = block.type !== 'Block::Image' ? block.file?.toJSON(): undefined;
    this.settings = block.settings;
    this.styles = block.styles;
    this.block_owner_participant_ids = block.block_owner_participant_ids;
    this.default_value = block.default_value;
    this.localized_attributes = block.localized_attributes;

    // TODO: this is a very specific handling of SignatureInput Blocks
    // they MUST NOT have default_values for signature_image_signed_id and date, as per API spec
    if (this.default_value?.hasOwnProperty
      && this.default_value.hasOwnProperty('signature_image_signed_id')
      && this.default_value.hasOwnProperty('date')) {
      delete this.default_value.signature_image_signed_id;
      delete this.default_value.date;
    }
  }
}

export class BlockMutationMove {
  local_uuid: string = uuid.v4();
  name: BlockMutationAction = BlockMutationAction.move;
  block_id: number;
  parent_local_uuid: string;
  parent_id: number;
  position: number;

  constructor(block: Block,
              position: number,
              parent_id: number,
              parent_local_uuid: string = null) {
    this.block_id = block.id < 0 ? undefined : block.id;
    this.parent_local_uuid = parent_local_uuid ?? undefined;
    this.parent_id = parent_id && parent_id > 0 ? parent_id : undefined;
    this.position = position;
  }
}

export class BlockMutationDelete {
  local_uuid: string = uuid.v4();
  name: BlockMutationAction = BlockMutationAction.delete;
  block_id: number;

  constructor(block: Block) {
    this.block_id = block?.id;
  }
}

export class BlockMutationDuplicate {
  local_uuid: string = uuid.v4();
  name: BlockMutationAction = BlockMutationAction.duplicate;
  source_block_id: number;
  source_local_uuid: string;
  destination_bucket_type: BucketType;
  destination_bucket_id: number;
  destination_parent_id: number;
  destination_position: number;
  destination_parent_local_uuid: string;
  // disable for now. empty array is not allowed by API
  // block_owner_participant_ids_mapping = [];

  constructor(block: Block,
              destination_bucket_type: BucketType,
              destination_bucket_id: number,
              destination_parent_id: number,
              destination_position: number,
              destination_parent_local_uuid: string) {
    this.source_block_id = block.id;
    this.source_local_uuid = block.local_uuid ?? undefined;
    this.destination_bucket_type = destination_bucket_type;
    this.destination_bucket_id = destination_bucket_id;
    this.destination_parent_id = destination_parent_id || undefined;
    this.destination_position = destination_position;
    this.destination_parent_local_uuid = destination_parent_local_uuid || undefined;
  }
}

export type BlockMutation = BlockMutationCreate | BlockMutationUpdate | BlockMutationMove | BlockMutationDelete | BlockMutationDuplicate;

export class BlockMutationCollection {
  local_uuid: string = uuid.v4();
  bucket_type?: string | BucketType;
  bucket_id?: number;
  mutations: BlockMutation[] = [];

  constructor(bucket_type: string, bucket_id: number) {
    this.bucket_type = bucket_type;
    this.bucket_id = bucket_id;
  }

  add(mutations: BlockMutation[]) {
    this.mutations = this.mutations.concat(mutations);
    return this;
  }

  createBlock(block: Block, parent: Block, position: number) {
    if (parent) {
      parent.addChild(block, position);
    } else {
      block.position = position;
      block.remove();
    }

    if (block.type.toString().includes('::Input::') && block.type !== BlockType.SelectOption) {
      block.settings.name = block.settings.name || translate('blocks.' + block.type + '.title');
    }

    this.mutations.push(new BlockMutationCreate(block, this.bucket_type, this.bucket_id));

    switch (block.type) {
      case BlockType.Row:
        this.createNestedBlock(block, BlockType.Column);
        this.createNestedBlock(block, BlockType.Column);

        break;

      case BlockType.Table:
        const rowMutations = [1,2,3];
        rowMutations
          .map(_ => this.createNestedBlock(block, BlockType.TableRow))
          .map(row => {
            for (let i = 0; i < 3; i++) {
              const cell = this.createNestedBlock(row.block, BlockType.TableCell);
              this.createNestedBlock(cell.block, BlockType.RichText);
            }
          });
        break;

      case BlockType.CheckboxInput:
      case BlockType.RadioInput:
        this.createNestedBlock(block, BlockType.SelectOption);
        // only a second one in non-overlay mode
        if (block.parent?.type !== BlockType.PdfPart) {
          this.createNestedBlock(block, BlockType.SelectOption);
        }
    }
    return this;
  }

  updateBlock(block: Block): BlockMutationCollection {
    this.mutations.push(new BlockMutationUpdate(block));
    return this;
  }

  moveBlock(block: Block, position: number, newParent: Block): BlockMutationCollection {
    const oldParent = block.parent;
    const oldPosition = block.position;

    if (newParent) {
      newParent.addChild(block, position);
    } else {
      block.position = position;
      block.remove();
    }

    // the api backend works as follows when moving down a list entry (same parent, but down the list):
    // 1. delete from list (everything below moves up by one; 2. re-add to list at new postion
    // to circumvent having the moved block one position too far down, we decrease the position by 1
    // TODO: fix on server-side to get a unified behavior
    if (block.type !== BlockType.Page && oldParent?.id === newParent?.id && position > oldPosition) {
      position -= 1;
    }

    this.mutations.push(new BlockMutationMove(block, position, newParent?.id, newParent?.local_uuid));

    this.removeEmptyColumn(oldParent);

    return this;
  }

  deleteBlock(block: Block): BlockMutationCollection {
    const oldParent = block.parent;

    block.remove();
    this.mutations.push(new BlockMutationDelete(block));
    this.removeEmptyColumn(oldParent);

    return this;
  }

  duplicateBlock(block: Block, parent: Block, position: number): BlockMutationCollection {
    this.mutations.push(
      new BlockMutationDuplicate(block, this.bucket_type as BucketType, this.bucket_id, parent?.id, position, parent?.local_uuid)
    );

    return this;
  }

  private createNestedBlock(parent: Block, type: BlockType, styles?: BlockStyles): {block: Block, mutation: BlockMutationCreate} {
    const nestedBlock = new BlockSerializer().fromJson({type, styles});

    parent.addChild(nestedBlock, parent.children.length);

    const mutation = new BlockMutationCreate(nestedBlock, this.bucket_type, this.bucket_id);
    this.mutations.push(mutation);
    return {block: nestedBlock, mutation};
  }

  private removeEmptyColumn(parent: Block) {
    if (!(parent instanceof Column) || parent.children.length) {
      return;
    }

    parent.remove();

    this.mutations.push(new BlockMutationDelete(parent));

    this.removeEmptyRow(parent);
  }

  private removeEmptyRow(column: Column) {
    const row = column.parent;
    const columns = row.children;

    if (columns.length > 1) {
      return;
    }

    const parent = row.parent;
    const children = columns[0].children;

    for (const child of children) {
      const position = row.position;

      parent.addChild(child, position);

      this.mutations.push(new BlockMutationMove(child, position, parent.id));
    }

    row.remove();

    this.mutations.push(new BlockMutationDelete(row));
  }
}

export const BlockPageParentId = -1; // Pages don't have a parent

let localId = 0;
