import {Input, InputSettings} from './input';
import {Size} from '../util/size';
import {Blob} from '../blob';

export class FileUploadInput extends Input {
  settings: FileUploadInputSettings;
  file: Blob;

  constructor(v: Partial<FileUploadInput> = {}) {
    super(v);
    this.settings = new FileUploadInputSettings(v.settings ?? {});
  }

  reset() {
    this.file = undefined;
  }
}

export class FileUploadInputSettings extends InputSettings {
  maxFiles: number;
  fontSize: number;

  get minSize(): Size {
    return new Size({
      width: 2 * this.fontSize * 3 + 3,
      height: this.fontSize * 2 + 10
    });
  }

  get maxSize(): Size {
    return new Size({
      width: 0,
      height: 0
    });
  }

  get defaultSize(): Size {
    return new Size({
      width: 440,
      height: 47
    });
  }

  constructor(v: Partial<FileUploadInputSettings> = {}) {
    super(v);

    this.fontSize = v.fontSize ?? 16;
    this.maxFiles = v.maxFiles ?? null;
  }
}
