import {ApplicationPolicy} from './application_policy';
import {Template, WorkspaceMembershipRole} from '@paperlessio/sdk/api/models';

export class TemplatePolicy extends ApplicationPolicy {
  record: Template;

  // Guests can see templates list
  index(): boolean {
    return true;
  }

  // Guests can see a single template
  show(): boolean {
    return true;
  }

  // User must be at least admin to create a new template
  create(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  // Only admin and above can edit templates
  update(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  // Only Sender and above can prepare a template for sending
  prepare(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.sender);
  }

  // Only admin and above can duplicate templates (create permission)
  duplicate(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin);
  }

  // Only Admin and above can delete templates
  destroy(): boolean {
    return this.workspace_membership.at_least(WorkspaceMembershipRole.admin) || this.record.creator_id === this.user.id;
  }
}
