import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {IconLibraryService} from './icon-library.service';
import {plIcons} from './icons/pl-icons';

@Component({
  selector: 'pl-icon',
  template: `<div class="pl-icon-wrapper" [innerHTML]="svgData"></div>`,
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  constructor(private iconLibraryService: IconLibraryService, private sanitizer: DomSanitizer) { }

  @Input()
  set icon(iconName: plIcons) {
    this.svgData = this.sanitizer.bypassSecurityTrustHtml(this.iconLibraryService.getIcon(iconName));
  }

  svgData: SafeHtml;
}
