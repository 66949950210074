import {Expandable} from '@paperlessio/sdk/api/util';
import {Submittable} from './submittable';
import {ListColumn, ListColumnSize, ListColumnType} from './column';
import {BucketType} from './bucket';

export class FormVersion extends Submittable {
  static expandable = new Expandable<FormVersion, 'creator' | 'blocks' | 'designs' | 'participation_flow' | 'participants' | 'tokens' | 'participation_flow.participant'>();

  static columns: ListColumn[] = [
    new ListColumn({property: 'revision_number', fixed: true, align: 'left', size: ListColumnSize.col01}),
    new ListColumn({property: 'state', default: true, align: 'left', type: ListColumnType.badge, size: ListColumnSize.col03}),
    new ListColumn({property: 'published_at', default: true, align: 'left', type: ListColumnType.timeAgo, size: ListColumnSize.col02}),
    new ListColumn({property: 'unpublished_at', default: true, align: 'left', type: ListColumnType.timeAgo, size: ListColumnSize.col02}),
    new ListColumn({property: 'creator', default: false, align: 'left', type: ListColumnType.creator}),
  ];

  static defaultColumns = FormVersion.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  form_id: number;
  form_version_id: number;
  revision_number: number;
  type: BucketType.FormVersion;
  participant_completed_redirect_url: string;
  participants_dispatch_strategy: 'api_response_initial_participants' | 'email' | 'per_participant';
  forwarding_allowed: boolean;
  delegation_allowed: boolean;
  published_at: Date;
  unpublished_at: Date;
  archived_at: Date;
  revision_message: string;

  constructor(v: Partial<FormVersion> = {}) {
    super(v);

    this.type = BucketType.FormVersion;
    this.form_id = v.form_id;
    this.revision_number = v.revision_number;
    this.participant_completed_redirect_url = v.participant_completed_redirect_url;
    this.participants_dispatch_strategy = v.participants_dispatch_strategy;
    this.published_at = v.published_at != null ? new Date(v.published_at) : undefined;
    this.unpublished_at = v.unpublished_at != null ? new Date(v.unpublished_at) : undefined;
    this.archived_at = v.archived_at != null ? new Date(v.archived_at) : undefined;
  }
}
