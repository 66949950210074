import {Block, BlockSettings} from './block';

export class FileDownload extends Block {
  file: string;
  file_preview_url: string;
  file_url: string;
  settings: FileDownloadSettings = new FileDownloadSettings();

  constructor(v: Partial<FileDownload> = {}) {
    super(v);
    this.file = v.file;
    this.file_preview_url = v.file_preview_url;
    this.file_url = v.file_url;
    this.settings = new FileDownloadSettings(v.settings ?? {});
  }

  get fileEnding() {
    if (this.file_url) {
      const splitted = this.file_url.split(/\./g);
      return splitted[splitted.length - 1];
    } else {
      return '';
    }
  }

  get fileName() {
    if (this.file_url) {
      let splitted = this.file_url.split(/\//g);
      splitted = splitted[splitted.length - 1].split('.');
      return decodeURI(splitted[0]);
    } else {
      return '';
    }
  }
}

export class FileDownloadSettings extends BlockSettings {
  file_size: number;

  constructor(v: Partial<FileDownloadSettings> = {}) {
    super(v);
    this.file_size = v.file_size;
  }
}
