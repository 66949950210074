import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {User, WorkspaceMembership} from '@paperlessio/sdk/api/models';
import {filter, startWith} from 'rxjs/operators';
import {TranslocoService} from '@ngneat/transloco';

export type InteropCommand =
  '[NavigationService]toggleHide' |
  '[AuthenticationStore]logout';

@Injectable({providedIn: 'root'})
export class InteropService {
  membership = new BehaviorSubject<WorkspaceMembership>(null);
  currentUser = new BehaviorSubject<User>(null);
  title = new BehaviorSubject<string>('');

  private command = new Subject<InteropCommand>();

  private translocoService = inject(TranslocoService);

  private _titleI18nKey = new BehaviorSubject<{key: string, params: any}>({key: '', params: {}});

  constructor() {
    combineLatest([
      this._titleI18nKey,
      this.translocoService.langChanges$, // locale changes
      this.translocoService.events$.pipe(startWith({})) // we might need to wait for loading of i18n file
    ]).subscribe(([i18n, _, __]) => {
      this.title.next(this.translocoService.translate(i18n.key, i18n.params));
    });
  }

  setTitle(i18nKey: string, params?: any) {
    this._titleI18nKey.next({key: i18nKey, params});
  }

  sendCommand(command: InteropCommand) {
    this.command.next(command);
  }

  selectCommand(command: InteropCommand) {
    return this.command.pipe(filter(cmd => cmd === command));
  }
}
