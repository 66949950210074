import * as Sentry from '@sentry/angular-ivy';
import {SeverityLevel} from '@sentry/angular-ivy';

export enum ErrorTrigger {
  Other = 'Other',
  BaseStore = 'BaseStore'
}

// the _ is to raise it up in the sentry ui (maybe there is a better way?)
export const ERROR_OBJECT_NAME = '_error object';
export const ERROR_TRIGGER_NAME = '_error trigger';

export class SentryHelper {
  public static captureError(message: string, level: SeverityLevel, errorObject: any, errorTrigger: ErrorTrigger = ErrorTrigger.Other) {
    Sentry.withScope(scope => {
      scope.setLevel(level);
      scope.setTag(ERROR_TRIGGER_NAME, errorTrigger);
      scope.setExtra(ERROR_OBJECT_NAME, errorObject);
      Sentry.captureMessage(`[${errorTrigger.toUpperCase()}] ${message}`);
    });
  }

  public static captureBaseStoreError(message: string, level: SeverityLevel, errorObject: any) {
    this.captureError(message, level, errorObject, ErrorTrigger.BaseStore);
  }
}
