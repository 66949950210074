import {TranslocoService} from '@ngneat/transloco';
import {normalizeLocale} from '@paperlessio/sdk/api/util';

export function setActiveLanguage(transloco: TranslocoService) {
  return () => {
    const lang = normalizeLocale((navigator.language || navigator.languages[0] || 'en').slice(0, 2));
    transloco.setActiveLang(lang);
    return transloco.load(lang).toPromise();
  };
}
