import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from './dropdown.component';
import {DropdownTriggerDirective} from './directives/dropdown-trigger.directive';
import {DropdownPanelDirective} from './directives/dropdown-panel.directive';
import {PortalModule} from '@angular/cdk/portal';
import {DropdownItemsComponent} from './dropdown-items/dropdown-items.component';
import {DropdownItemComponent} from './dropdown-item/dropdown-item.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    DropdownComponent,
    DropdownTriggerDirective,
    DropdownPanelDirective,
    DropdownItemsComponent,
    DropdownItemComponent
  ],
  exports: [
    DropdownComponent,
    DropdownTriggerDirective,
    DropdownPanelDirective,
    DropdownItemsComponent,
    DropdownItemComponent
  ],
  imports: [
    CommonModule,
    PortalModule,
    FontAwesomeModule
  ]
})
export class DropdownModule { }
