import {BaseSerializer} from '@paperlessio/sdk/api/util';

export class Serializer<T> implements BaseSerializer<T> {
  constructor(private type: new(v: {}) => T, private name?: string) {
  }

  fromJson(json: any): T {
    return new this.type(json);
  }

  toJson(obj: T | Partial<T>): object {
    const params: any = this.name ? {[this.name]: obj} : obj;
    const sanitizer = (key, value) => {
      if (this.type.prototype['json_exclusions']?.indexOf(key) > -1) {
        return undefined;
      } else {
        return value;
      }
    };

    return JSON.parse(JSON.stringify(params, sanitizer));
  }
}


