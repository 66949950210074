import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconComponent} from './icon.component';
import {IconLibraryService} from './icon-library.service';

@NgModule({
  declarations: [
    IconComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IconComponent
  ]
})
export class IconsModule {
  static forRoot(): ModuleWithProviders<IconsModule> {
    return {
      ngModule: IconsModule,
      providers: [ IconLibraryService ]
    };
  }
}

