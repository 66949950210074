import {Block, BlockMutationResult, BlockType, BucketType} from '@paperlessio/sdk/api/models';
import {BlockSerializer} from '@paperlessio/sdk/api/serializers';
import {translate} from '@ngneat/transloco';

// TODO: Use hash maps instead of arrays for updated blocks
export function processMutation(blockMutationResult: BlockMutationResult, blocks: Block[], skipUpdate = false) {
  const serializer = new BlockSerializer();
  const createdBlocks = blockMutationResult.created_blocks.map(jsonBlock => serializer.fromJson(jsonBlock));
  const updatedBlocks = blockMutationResult.updated_blocks.map(jsonBlock => serializer.fromJson(jsonBlock));
  const deletedBlockIds = blockMutationResult.deleted_block_ids;
  const blockPositions = blockMutationResult.block_positions;

  const sort = (a: Block, b: Block) => a.position - b.position;
  const deleteFilter = (block: Block) => !deletedBlockIds.includes(block.id);
  const localFilter = (block: Block) =>
    !createdBlocks.find(createdBlock => createdBlock.local_uuid && createdBlock.local_uuid === block.local_uuid);
  const update = (block: Block) => {
    block = skipUpdate ? block : (updatedBlocks.find(updatedBlock => updatedBlock.id === block.id) || block);

    const blockMutationPosition = blockPositions.find(blockPosition => blockPosition.id === block.id);

    // blockMutationPosition was null when user pasted data into table that created a new row
    if (blockMutationPosition != null) {
      block.parent_id = blockMutationPosition.parent_id;
      block.position = blockMutationPosition.position;
    }

    return block;
  };

  return [...blocks.filter(localFilter), ...createdBlocks].filter(deleteFilter).map(update).sort(sort);
}

export function containsPage(blocks: Block[], bucket_type: BucketType) {
  return bucket_type !== BucketType.Partial && !blocks.some(block => block.type === BlockType.Page);
}

export function createPageBlock(bucket_type: BucketType, bucket_id: number): Block {
  const blockSerializer = new BlockSerializer();

  return blockSerializer.fromJson({
    type: BlockType.Page,
    settings: {name: translate('editor.pages.first')},
    bucket_id,
    bucket_type: Object.keys(BucketType).filter(bt => BucketType[bt] === bucket_type)[0],
    parent_id: null,
    position: 0
  });
}
