export class TokenHelper {
  static REGEX = /[\[][a-zA-Z0-9._]+[\]]/g;
  static CONTENDER = /^[a-zA-Z0-9._]+$/;

  static hasToken(...args: string[]) {
    return args
      .filter(x => x?.length > 2)
      .some(value => !!value.match(TokenHelper.REGEX));
  }

  static extractTokens(...args: string[]) {
    return args
      .filter(x => x?.length > 2)
      .map(x => x.match(TokenHelper.REGEX));
  }

  static transformToEligibleToken(input: string): string {
    return this.removeUmlauts(input.trim())
      .replace(/\s/g, '.')
      .replace(/[^A-Za-z0-9_\-.]/g, '');
  }

  static removeUmlauts(input: string): string {
    return input
      .replace('ß', 'ss')
      .replace('Ä', 'Ae')
      .replace('Ö', 'Oe')
      .replace('Ü', 'Ue')
      .replace('ä', 'ae')
      .replace('ö', 'oe')
      .replace('ü', 'ue');
  }
}
