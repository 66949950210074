import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SessionTosCheckboxComponent} from './session-tos-checkbox.component';
import {FormsModule} from '@angular/forms';
import {TranslocoModule} from '@ngneat/transloco';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';

@NgModule({
  declarations: [
    SessionTosCheckboxComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslocoModule,
    FontAwesomeModule
  ],
  exports: [
    SessionTosCheckboxComponent
  ]
})
export class SessionTosCheckboxModule {
  constructor(lib: FaIconLibrary) {
    lib.addIcons(faArrowRight);
  }
}
