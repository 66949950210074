import {BrandColor} from './brand-design';

class EmailColors {
  button: string;
  button_internal: string;
  button_text: string;
  button_text_internal: string;

  constructor(v: Partial<EmailColors> = {}) {
    this.button = v.button ?? '#313438';
    this.button_internal = v.button_internal ?? '#313438';
    this.button_text = v.button_text ?? '#FFFFFF';
    this.button_text_internal = v.button_text_internal ?? '#FFFFFF';
  }

  normalizeColors(colors: BrandColor[]) {
    this._normalizeColorAttribute('button', colors);
    this._normalizeColorAttribute('button_text', colors);
  }

  private _normalizeColorAttribute(attribute: string, colors: BrandColor[]) {
    if (this[attribute+'_internal'].startsWith('var(')) {
      const colorName = this[attribute+'_internal'].replace('var(--design-', '').replace(')', '');
      const color = colors.find(e => e.name === colorName);
      if (color) {
        this[attribute] = color.value;
      }
    } else {
      this[attribute] = this[attribute+'_internal'];
    }
  }
}

export class EmailStyles {
  colors: EmailColors;

  constructor(v: Partial<EmailStyles> = {}) {
    this.colors = new EmailColors(v.colors ?? {});
  }
}
