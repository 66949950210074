import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BrandDesign, SubmissionDesign} from '@paperlessio/sdk/api/models';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {GizmoState, NavigationState} from '@shared/submission-gizmo/navigation.service';
import {AdditionalSubmissionHeaderButtonConfig} from '@paperlessio/sdk/api/util';

/*
* Thin wrapper around SessionHeader and SessionContent which passes inputs and outputs through.
* ng-content within SessionContent allows projecting content from outside inside content area:
* In submission frontend, SessionWrapper projects router-outlet (differnt pages loaded via SessionBlocks).
* In management, SubmissionDesigner projects a placeholder page or a specific page.
*/
@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit, OnDestroy {
  @Input() brandDesign: BrandDesign;
  @Input() submissionDesign: SubmissionDesign;
  @Input() showHeader = true;
  @Input() showNavigation: boolean;
  @Input() showPrev: boolean;
  @Input() showNext: boolean;
  @Input() showReadOnly: boolean;
  @Input() showApprove: boolean;
  @Input() showComplete: boolean;
  @Input() showCompleteArrow: boolean;
  @Input() showSave: boolean;
  @Input() participantComplete: boolean;
  @Input() submissionCompleted: boolean;
  @Input() downloadPdfUrl: string;
  @Input() mustAcceptTerms: boolean;
  @Input() termsAccepted: boolean;
  @Input() hasInputs: boolean;

  @Input() documentName: string;
  @Input() authorName: string;
  @Input() authorAvatarUrl: string;
  @Input() additionalInfo: string;
  @Input() additionalButtons?: AdditionalSubmissionHeaderButtonConfig[];

  @Input() gizmoState: GizmoState;
  @Input() navigationState: NavigationState;

  @Output() prev = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() start = new EventEmitter<boolean>();
  @Output() complete = new EventEmitter();
  @Output() save = new EventEmitter();

  @ViewChild('scrollcontainer', {read: ElementRef}) scrollcontainer: ElementRef;

  subs = new Subscription();

  constructor(private router: Router) { }

  ngOnInit() {
    this.subs.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.scrollcontainer.nativeElement.style['scroll-behavior'] = 'auto';
        this.scrollcontainer.nativeElement.scrollTop = 0;
        this.scrollcontainer.nativeElement.style['scroll-behavior'] = null;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
