import {Expandable} from '@paperlessio/sdk/api/util';
import {ListColumn, ListColumnSize, ListColumnType} from './column';
import {ProcessFlow} from './process_flow/process-flow';
import {Bundle} from './bundle';
import {ProcessRunLabel} from './process-run-label';

export class ProcessRun {
  static expandable = new Expandable<ProcessRun, 'labels' | 'process_flow' | 'process_flow.nodes' | 'process_flow.nodes.input' | 'process_flow.nodes.output' | 'bundle' | 'bundle.submissions' | 'bundle.submissions.submittable' | 'bundle.submissions.submittable.blocks' | 'bundle.submissions.participants' | 'bundle.submissions.participation_flow' | 'bundle.submissions.aggregated_submit_event_values'>();

  static columns: ListColumn[] = [
    new ListColumn({property: 'name', default: true, fixed: true, align: 'left', size: ListColumnSize.col03}),
    new ListColumn({property: 'state', default: true, align: 'left', size: ListColumnSize.col02}),
    new ListColumn({property: 'labels', default: true, align: 'left', size: ListColumnSize.col02, type: ListColumnType.processRunLabels}),
    new ListColumn({property: 'created_at', default: true, align: 'left', size: ListColumnSize.col02, type: ListColumnType.timeAgo}),
    new ListColumn({property: 'updated_at', default: true, align: 'left', size: ListColumnSize.col02, type: ListColumnType.timeAgo}),
  ];

  static defaultColumns = ProcessRun.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  id: number;
  process_definition_version_id: number;
  slug: string;
  state: any;
  name: string;
  custom_data: Record<string, any>;
  process_flow: ProcessFlow;
  bundle_id: number;
  bundle: Bundle;
  labels: ProcessRunLabel[];
  updated_at: Date;
  created_at: Date;

  constructor(v: Partial<ProcessRun>) {
    this.id = v.id;
    this.process_definition_version_id = v.process_definition_version_id;
    this.slug = v.slug;
    this.state = v.state;
    this.name = v.name;
    this.custom_data = v.custom_data;
    this.process_flow = v.process_flow ? new ProcessFlow(v.process_flow) : null;
    this.bundle_id = v.bundle_id;
    this.bundle = v.bundle ? new Bundle(v.bundle) : null;
    this.labels = v.labels?.length > 0 ? v.labels.map(l => new ProcessRunLabel(l)) : [];
    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
  }
}
