import {Input, InputSettings} from './input';

export class SelectInput extends Input {
  default_value: number;
  settings: SelectInputSettings;

  constructor(v: Partial<SelectInput> = {}) {
    super(v);
    this.default_value = v.default_value ?? undefined;
    this.settings = new SelectInputSettings(v.settings ?? {});
  }

  get choose(): string {
    return this.settings?.choose_text?.length > 0 ?
      this.settings.choose_text : '...';
  }

  set choose(text: string) {
    this.settings.choose_text = text;
  }
}

export class SelectInputSettings extends InputSettings {
  choose_text: string;

  constructor(v: Partial<SelectInputSettings> = {}) {
    super(v);
    this.choose_text = v.choose_text ?? '';
  }
}
