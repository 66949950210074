import {excluded} from './util/excluded';

export class ProcessRunLabel {
  id: number;
  process_definition_id: number;
  name: string;

  /**
   * minLength: 1
   * maxLength: 255
   * pattern: ^[a-zA-Z0-9_]+$
   */
  slug: string;

  /**
   * pattern: ^#[0-9a-f]{6}$
   * example: #abcdef
   */
  text_color: string;

  /**
   * pattern: ^#[0-9a-f]{6}$
   * example: #abcdef
   */
  background_color: string;

  @excluded updated_at: Date;
  @excluded created_at: Date;

  constructor(v?: Partial<ProcessRunLabel>) {
    this.id = v?.id;
    this.process_definition_id = v?.process_definition_id;
    this.name = v?.name;
    this.slug = v?.slug;
    this.text_color = v?.text_color ?? '#70777d';
    this.background_color = v?.background_color ?? '#eceded';

    this.updated_at = v?.updated_at != null ? new Date(v?.updated_at) : null;
    this.created_at = v?.created_at != null ? new Date(v?.created_at) : null;
  }
}
