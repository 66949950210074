import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseServiceV1} from './base.service.v1';
import {Serializer} from '@paperlessio/sdk/api/serializers';
import {DocumentState, FormVersion} from '@paperlessio/sdk/api/models';
import {catchError, filter, map} from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import {Observable, throwError} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FormVersionsService extends BaseServiceV1<FormVersion> {
  constructor(http: HttpClient) {
    super(http);
    this.endpoint = 'form_versions';
    this.serializer = new Serializer<FormVersion>(FormVersion);
  }

  publish(id: number, revision_message: string) {
    return this.http
      .put<FormVersion>(`${this.BASE_URL_V1 + this.endpoint}/${id}`, {
        state: DocumentState.published,
        revision_message: revision_message
      })
      .pipe(
        filter(x => !!x),
        map(x => new Serializer<FormVersion>(FormVersion).fromJson(x)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  unpublish(id: number) {
    return this.http
      .put<FormVersion>(`${this.BASE_URL_V1 + this.endpoint}/${id}`, {state: DocumentState.unpublished})
      .pipe(
        filter(x => !!x),
        map(x => new Serializer<FormVersion>(FormVersion).fromJson(x)),
        catchError(e => {
          Sentry.captureException(e);
          return throwError(e);
        }));
  }

  update(id: number, data: Partial<FormVersion> | FormVersion, params: {} = {}): Observable<FormVersion> {
    if (data.participants_dispatch_strategy === 'per_participant') {
      data.participants_dispatch_strategy = undefined;
    }

    return super.update(id, data, params);
  }
}
