import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Data} from '@angular/router';
import {TranslocoService} from '@ngneat/transloco';
import {RouterStore} from '@paperlessio/sdk/util';
import {combineLatest} from 'rxjs';
import {CurrentWorkspaceStore} from '@paperlessio/sdk/api/stores';
import {Bucket} from '@paperlessio/sdk/api/models';
import {filter, startWith} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class TitleService {
  constructor(
    private title: Title,
    private routerStore: RouterStore,
    private translocoService: TranslocoService,
    private currentWorkspaceStore: CurrentWorkspaceStore) {
  }

  private lastTitleFragments: string[] = [];
  private lastRouteData: Data;

  appTitle = 'Paperless';
  currentBucket: Bucket;

  init() {
    combineLatest([
      this.routerStore.titleFragments,
      this.routerStore.routeData, // data changes (e.g. same route, different workspace)
      this.translocoService.langChanges$, // locale changes
      this.translocoService.events$.pipe(startWith({})) // we might need to wait for loading of i18n file
    ]).pipe(
      filter(([titleFragments, data]) => titleFragments != null && titleFragments.length > 0 && data != null)
    ).subscribe(([titleFragments, data]) => {
      this.lastTitleFragments = titleFragments;
      this.lastRouteData = data;
      this.setTitle();
    });
  }

  /**
   * Sets the window title based on the current route data.
   */
  setTitle() {
    if (this.lastRouteData?.bucket != null && this.currentBucket != null) {
      this.lastRouteData.bucket = {...this.lastRouteData.bucket, ...this.currentBucket};
    }

    if (this.lastRouteData?.workspace != null) {
      const membership = this.currentWorkspaceStore.membership.value;
      if (membership?.workspace) {
        this.lastRouteData.workspace = {...this.lastRouteData.workspace, ...membership.workspace};
      }
    }

    let newTitle = this.appTitle;
    this.lastTitleFragments.forEach(titleFragment => {
      let resolvedTitleFragment = '';
      if (titleFragment.startsWith('$')) {
        resolvedTitleFragment = this.resolveTitle(titleFragment, this.lastRouteData);
      } else {
        resolvedTitleFragment = this.translocoService.translate(titleFragment);
        if (resolvedTitleFragment === titleFragment) {
          resolvedTitleFragment = '';
        }
      }

      newTitle = resolvedTitleFragment?.length > 0 ? resolvedTitleFragment + ' | ' + newTitle : newTitle;
    });

    this.title.setTitle(newTitle);
  }

  /**
   * Resolves the dynamic titles and fills the placeholders with actual data from the routeData.
   * @param titleFragment The fragment that contains the placeholder.
   * @param data The current routeData.
   */
  private resolveTitle(titleFragment: string, data: Data) {
    let val: any = data;
    const keys = titleFragment.slice(1).split('.');
    keys.forEach(key => {
      val = val != null ? val[key] : '';
    });
    return val as string;
  }
}
