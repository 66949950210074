import {excluded} from './util/excluded';
import {BlockType} from './blocks/block-type';
import {Participant} from './participant';
import {Blob} from './blob';
import {ParticipationFlow} from './participation-flow';

export class SubmitEventValue {
  id: number;
  @excluded block_id: number;
  @excluded block_name?: string;
  @excluded block_type: BlockType;
  slug: string;
  value: any;
  mapped_value: any;
  files: Blob[];
  participant_id: number;

  constructor(v: Partial<SubmitEventValue>) {
    this.id = v.id;
    this.block_id = v.block_id;
    this.block_name = v.block_name ? v.block_name : '';
    this.block_type = v.block_type;
    this.slug = v.slug;
    this.value = v.value;
    this.mapped_value = v.mapped_value;
    this.files = (v.files ?? []).map(f => new Blob(f));
    this.participant_id = v.participant_id;
  }

  toDownloadableSubmitEventValue(participationFlow: ParticipationFlow): DownloadableSubmitEventValue[] {
    const downloadableObjects = [];
    const participant = participationFlow
      .participantNodes
      .find(node => node.participant.id === this.participant_id)
      .participant;

    switch(this.block_type) {
      case BlockType.BankAccount:
      case BlockType.SignatureInput:
        Object.keys(this.value).forEach(key => {
          if(this.value[key] && key !== 'signature_image') {
            downloadableObjects.push(new DownloadableSubmitEventValue(this, key, this.value[key], participant));
          }
        })
        break;
      case BlockType.FileUploadInput:
      case BlockType.SecureFileUploadInput:
        this.files.forEach(file => {
          downloadableObjects.push(new DownloadableSubmitEventValue(this, 'filename', file.filename, participant));
          downloadableObjects.push(new DownloadableSubmitEventValue(this, 'url', file.url, participant));
        });
        break;
      case BlockType.CheckboxInput:
        const values = this.mapped_value.split(', ');
        this.value.forEach((val, index) => {
          downloadableObjects.push(new DownloadableSubmitEventValue(this, 'checkbox_' + val, values[index], participant));
        });
        break;
      case BlockType.RadioInput:
        downloadableObjects.push(new DownloadableSubmitEventValue(this, 'radio_' + this.value, this.mapped_value, participant));
        break;
      case BlockType.TextInput:
        downloadableObjects.push(new DownloadableSubmitEventValue(this, 'text', this.value, participant));
        break;
      default:
        downloadableObjects.push(new DownloadableSubmitEventValue(this, this.block_name.toLowerCase(), this.value, participant));
    }

    return downloadableObjects;
  }
}

export class DownloadableSubmitEventValue {
  id: number;
  block_name: string;
  block_type: string;
  slug: string;
  key: string;
  value: string;
  participant_slot_name: string;
  participant_name: string;
  participant_email: string;

  constructor(v: Partial<SubmitEventValue>, key: string, value: string, participant?: Participant) {
    this.id = v.id;
    this.block_name = v.block_name ? v.block_name : '';
    this.block_type = v.block_type;
    this.slug = v.slug;

    this.participant_slot_name = participant?.slot_name ? participant.slot_name : '';
    this.participant_name = participant?.name ? participant.name: '';
    this.participant_email = participant?.email ? participant.email: '';

    this.key = key;
    this.value = value;
  }
}
