import {Expandable} from '@paperlessio/sdk/api/util';
import {ListColumn, ListColumnSize, ListColumnType} from './column';
import {User} from './user';
import {ProcessDefinitionVersion} from './process-definition-version';

export class ProcessDefinition {
  static expandable = new Expandable<ProcessDefinition, 'creator' | 'published_process_definition_version'>();

  static columns: ListColumn[] = [
    new ListColumn({property: 'name', default: true, fixed: true, align: 'left', size: ListColumnSize.col03, type: ListColumnType.bucketName}),
    new ListColumn({property: 'state', default: true, align: 'left', type: ListColumnType.badge, size: ListColumnSize.col02}),
    new ListColumn({property: 'updated_at', default: true, align: 'left', type: ListColumnType.timeAgo}),
    new ListColumn({property: 'creator', default: true, align: 'left', type: ListColumnType.creator}),
  ];

  static defaultColumns = ProcessDefinition.columns.filter(x => x.default && !x.fixed).map(x => x.property);

  id: number;
  workspace_id: number;
  published_process_definition_version_id: number;
  name: string;
  description: string;
  slug: string;

  creator: User;
  published_process_definition_version: ProcessDefinitionVersion;

  created_at: Date;
  updated_at: Date;

  constructor(v: Partial<ProcessDefinition>) {
    this.id = v.id;
    this.workspace_id = v.workspace_id;
    this.published_process_definition_version_id = v.published_process_definition_version_id;
    this.name = v.name;
    this.description = v.description;
    this.slug = v.slug;

    this.creator = v.creator != null ? new User(v.creator) : null;
    this.published_process_definition_version =
      v.published_process_definition_version != null ? new ProcessDefinitionVersion(v.published_process_definition_version) : null;

    this.updated_at = v.updated_at != null ? new Date(v.updated_at) : null;
    this.created_at = v.created_at != null ? new Date(v.created_at) : null;
  }

  // Get a fake state to be able to use the existing badge column type and the already supported badge types
  get state(): string {
    if (this.published_process_definition_version) {
      return 'published';
    } else {
      return 'draft';
    }
  }

  get published(): boolean {
    return this.published_process_definition_version != null;
  }
}
